import React, { useState, useEffect, useRef, useContext } from "react";
import {
  doFetch,
  getModalError,
  getModalWarning,
  getNotificationSuccess,
  getModalInfo,
  getNotificationSuccessCustom,
  getNotificationError,
} from "../../lib/functions/general_functions";
import { DIDBResource } from "../../lib/definitions/general_definitions";
import { SuperTable } from "../superTable";
import { membersColumns } from "../../lib/definitions/table_column_definitions";
import { Button, Modal, Select, Divider } from "antd";
import { EnvironmentContext, MeContext, MyTeamsContext } from "../../lib/contexts";
import tykPng from "../../lib/images/tyk.png";
import { TykStatusContent } from "./tykStatusContent";
import { fetchMemberOptions } from "../../lib/functions/ad_functions";
import { authProvider } from "../../lib/authProvider";

import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { Team } from "../../lib/definitions/models";
import { getMyEmail } from "../../lib/functions/profile_functions";
import { Spinner } from "../general/spinner";

interface TeamPanelContentProps {
  teamId: string;
  teamName: string;
}

export const TeamPanelContent: React.FC<TeamPanelContentProps> = (props) => {
  const [error, setError] = useState(false);
  const [team, setTeam] = useState<Team>();
  const [members, setMembers] = useState<any[]>([]);
  const [isFetchingTeam, setIsFetchingTeam] = useState(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const [selectedMember, setSelectedMember] = useState<{ name: string; email: string }>();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newMemberMails, setNewMemberMails] = useState<any>();
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [isLeavingTeam, setIsLeavingTeam] = useState(false);
  const [isAddingMembers, setIsAddingMembers] = useState(false);
  const [memberOptions, setMemberOptions] = useState<any[]>([]);
  const [isFetchingAllUsers, setIsFetchingAllUsers] = useState(false);
  const [isSynchronizing, setIsSynchronizing] = useState(false);

  const { refreshMyTeams } = useContext(MyTeamsContext);
  const { data } = useContext(MeContext);
  const { environments } = useContext(EnvironmentContext);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsFetchingTeam(true);
    doFetch(
      "GET",
      `${DIDBResource.Teams}/${props.teamId}`,
      isMounted,
      (res) => setTeam(res.value),
      () => setError(true),
      () => setIsFetchingTeam(false)
    );
    setIsFetchingMembers(true);
    doFetch(
      "GET",
      `${DIDBResource.AuxMembers}/${props.teamId}`,
      isMounted,
      (res) => setMembers(res.value),
      () => setError(true),
      () => setIsFetchingMembers(false)
    );
  }, [props.teamId]);

  function fetchMembers() {
    setIsFetchingMembers(true);
    doFetch(
      "GET",
      `${DIDBResource.AuxMembers}/${props.teamId}`,
      isMounted,
      (res) => setMembers(res.value),
      () => setError(true),
      () => setIsFetchingMembers(false)
    );
  }

  function deleteTeam() {
    getModalWarning("Delete team")(
      `Are you sure you want to delete team ${props.teamName}?`,
      () =>
        doFetch(
          "DELETE",
          `${DIDBResource.Teams}/${props.teamId}`,
          isMounted,
          () => {
            refreshMyTeams();
            getNotificationSuccess("Deleted", "Team", team?.name);
          },
          getModalError("Deleting team")
        ),
      {
        okButtonProps: { danger: true, type: "primary" },
        okText: "Delete",
      }
    );
  }

  function createErrorForLeaveTeam() {
    if (members.length === 1) {
      return getModalError("A team must consist of atleast one member");
    } else {
      return getModalError("Leaving team");
    }
  }

  function leaveTeam() {
    if (members.length === 1) {
      getModalError("A team must have at least one members");
    }

    setIsLeavingTeam(true);
    doFetch(
      "DELETE",
      DIDBResource.AuxMembers,
      isMounted,
      () => {
        getNotificationSuccessCustom("Leaving team", "Successfully left team");
        refreshMyTeams();
      },
      createErrorForLeaveTeam(),
      () => setIsLeavingTeam(false),
      { teamId: props.teamId, memberEmails: [authProvider.getAccount().userName] }
    );
  }

  function removeMember() {
    if (!selectedMember) {
      return;
    }
    setIsRemovingMember(true);
    doFetch(
      "DELETE",
      DIDBResource.AuxMembers,
      isMounted,
      () => {
        getNotificationSuccessCustom(
          "Removing member from team",
          `Successfully removed member ${selectedMember.email} from team`
        );
        fetchMembers();
      },
      getModalError("Removing member from team"),
      () => setIsRemovingMember(false),
      { teamId: props.teamId, memberEmails: [selectedMember.email] }
    );
  }

  function addMembersToTeam() {
    setIsAddingMembers(true);
    doFetch(
      "POST",
      DIDBResource.AuxMembers,
      isMounted,
      (response) => {
        getNotificationSuccessCustom("Adding members to team", `Successfully finished adding members to team`);
        if (response.errors.length > 0) {
          response.errors.forEach((err: any) =>
            getNotificationError("Adding member to team", `${err.message}. Request ID: ${response.requestId}`)
          );
        }
        fetchMembers();
        setIsPopupVisible(false);
      },
      getModalError("Adding members to team"),
      () => setIsAddingMembers(false),
      { teamId: props.teamId, memberEmails: newMemberMails }
    );
  }

  function findUserGroupTeamOnPortal() {
    if (team?.tykUserGroups) {
      return Object.values(team?.tykUserGroups).find((groupId) => groupId === data.tykUserGroupId) || "";
    }
    return "";
  }

  function swapTeamOnTyk() {
    console.log(team);
    doFetch(
      "PUT",
      DIDBResource.AuxChangeUserGroupTyk,
      isMounted,
      () => {
        getNotificationSuccessCustom("Swaped user group on TYK", `Succesfully joined ${team?.name} on TYK`);
      },
      getModalError("Swapping team on TYK"),
      () => console.log(),
      { userGruopId: team?.tykUserGroups, userEmail: getMyEmail() }
    );
  }

  return (
    <div>
      {isFetchingTeam || isFetchingMembers ? (
        "Loading..."
      ) : (
        <div>
          {error ? (
            "No team information available. Try again later"
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!findUserGroupTeamOnPortal() && <Button onClick={swapTeamOnTyk}>Swap to this team on TYK</Button>}
                <Button
                  loading={isSynchronizing}
                  onClick={() => {
                    setIsSynchronizing(true);
                    doFetch(
                      "GET",
                      `${DIDBResource.AuxSynchronizeTyk}/${props.teamId}`,
                      isMounted,
                      (response) =>
                        getModalInfo(
                          "Synchronizing team with Tyk",
                          <TykStatusContent statusObject={response.value} environments={environments} />
                        ),
                      getModalError("Synchronizing with Tyk"),
                      () => setIsSynchronizing(false)
                    );
                  }}
                >
                  <img src={tykPng} alt="Tyk" style={{ width: "32px", marginRight: "8px" }} />
                  Synchronize
                </Button>
              </div>
              <div style={{ margin: "16px 0" }}>
                <SElementInput label="Description" value={team?.description} fieldType="textarea" readonly />
              </div>
              <h3>Members</h3>
              <SuperTable
                rows={members}
                columns={membersColumns}
                rowKey="name"
                noSearch
                noPagination
                onRowClick={setSelectedMember}
                selectedRow={selectedMember}
              />
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px", alignItems: "center" }}>
                <div style={{ marginRight: "16px", color: "gray" }}>
                  A change to the team's member list may take a few seconds to be reflected (refresh needed)
                </div>
                <Button
                  onClick={() => {
                    setIsPopupVisible(true);
                    fetchMemberOptions(isMounted, setMemberOptions, setIsFetchingAllUsers);
                  }}
                  style={{ marginRight: "8px" }}
                >
                  Add member
                </Button>
                {selectedMember &&
                  selectedMember.email.toLowerCase() !== authProvider.getAccount().userName.toLowerCase() && (
                    <Button loading={isRemovingMember} danger onClick={removeMember} style={{ marginRight: "8px" }}>
                      Remove member
                    </Button>
                  )}
                {members.length > 1 ? (
                  <Button danger onClick={leaveTeam} style={{ marginRight: "8px" }} loading={isLeavingTeam}>
                    Leave team
                  </Button>
                ) : (
                  <Button type="primary" danger onClick={deleteTeam}>
                    Delete team
                  </Button>
                )}
                <Modal
                  centered
                  visible={isPopupVisible}
                  okText="Add member(s)"
                  onCancel={() => setIsPopupVisible(false)}
                  onOk={addMembersToTeam}
                  confirmLoading={isAddingMembers}
                >
                  <Divider>Add members</Divider>
                  {isFetchingAllUsers && <Spinner />}
                  <Select
                    placeholder="Select members..."
                    mode="tags"
                    options={memberOptions}
                    style={{ width: "100%", marginTop: "12px" }}
                    onChange={setNewMemberMails}
                    loading={isFetchingAllUsers}
                  />
                </Modal>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { doFetch, getModalError, useSHistory } from "../lib/functions/general_functions";
import { applicationsColumns } from "../lib/definitions/table_column_definitions";
import { DIDBResource, getPageBreadcrumb, nameToPath } from "../lib/definitions/general_definitions";
import { ShowInfoJson } from "../components/general/showInfoJson";
import { BreadcrumbContext, EnvironmentContext } from "../lib/contexts";
import { useParams } from "react-router-dom";
import { SuperTable } from "../components/superTable";
import { useCallback } from "react";

export const ApplicationsPage = () => {
  const [applications, setApplications] = useState<any[]>([]);
  const [isFetchingApplications, setIsFetchingApplications] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState();
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const history = useSHistory();
  const { id } = useParams<{ id: string }>();
  const isMounted = useRef(true);

  const { selectedEnvironment } = useContext(EnvironmentContext);

  useEffect(() => setBreadcrumbs([getPageBreadcrumb("Applications")]), [setBreadcrumbs]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchApplications = useCallback(() => {
    setIsFetchingApplications(true);
    doFetch(
      "GET",
      DIDBResource.Applications,
      isMounted,
      (res) => setApplications(res.value.filter((a: any) => a.env === selectedEnvironment?._id)),
      getModalError("Fetching applications"),
      () => setIsFetchingApplications(false)
    );
  }, [selectedEnvironment?._id]);

  useEffect(fetchApplications, [fetchApplications]);

  useEffect(() => {
    if (!id) {
      return;
    }
    doFetch(
      "GET",
      `${DIDBResource.Applications}/${id}`,
      isMounted,
      (res) => setSelectedApplication(res.value),
      getModalError("Fetching application")
    );
  }, [id]);

  return (
    <div>
      <div className="split-table-tree">
        <SuperTable
          columns={applicationsColumns}
          rowKey="_id"
          rows={applications}
          refreshProps={{ onRefresh: fetchApplications, isRefreshing: isFetchingApplications }}
          onRowClick={(team) => history.push(`${nameToPath["Applications"]}/${team._id}`)}
          selectedRow={selectedApplication}
        />
        <ShowInfoJson object={selectedApplication} />
      </div>
    </div>
  );
};

import React from "react";
import Title from "antd/lib/typography/Title";

interface SDetailSectionProps {
  children?: React.ReactNode;
  title?: string;
  frameless?: boolean;
}

export const SSectionCustom = (props: SDetailSectionProps) => {
  return (
    <div className={props.frameless ? "sdetail-section__frameless" : "sdetail-section__container"}>
      <Title
        level={4}
        style={props.frameless ? { paddingLeft: "16px", marginBottom: 0, color: "#000000" } : { color: "#000000" }}
      >
        {props.title}
      </Title>
      {props.children}
    </div>
  );
};

import React, { useState, useEffect, useCallback, useRef } from "react";
import Title from "antd/lib/typography/Title";
import { Upload, Button, List, Empty } from "antd";
import { RcFile } from "antd/lib/upload";
import {
  FilePdfTwoTone,
  FileExcelTwoTone,
  FileUnknownTwoTone,
  FileWordTwoTone,
  FileTextTwoTone,
  FilePptTwoTone,
  FileImageTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  listEntityAttachments,
  uploadEntityAttachments,
  deleteEntityAttachment,
  downloadAttachment,
} from "../../../lib/functions/aws_functions";
import {
  getModalWarning,
  getNotificationSuccess,
  getModalError,
  getNotificationError,
} from "../../../lib/functions/general_functions";
import { AttachmentEntityType, Attachment } from "../../../lib/definitions/aws_definitions";

interface SFilesProps {
  title: string;
  teamId: string | undefined;
  entityType: AttachmentEntityType;
  entityId: string | undefined;
}

export const SFiles = (props: SFilesProps) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadFilesFromBucket = useCallback(async () => {
    setFileList([]);
    setIsLoading(true);

    if (props.teamId && props.entityId) {
      await listEntityAttachments(props.teamId, props.entityType, props.entityId, (fileList: Attachment[]) => {
        if (isMounted.current) setFileList(fileList);
      });
    }
    if (isMounted.current) setIsLoading(false);
  }, [props.entityId, props.entityType, props.teamId]);

  async function uploadAttachements(fileList: File[]) {
    if (props.teamId && props.entityId && fileList.length >= 1) {
      setIsUploading(true);
      await uploadEntityAttachments(
        props.teamId,
        props.entityType,
        props.entityId,
        fileList,
        () => {
          if (isMounted.current) {
            setIsUploading(false);
            getNotificationSuccess("Uploaded", "Attachments");
          }
        },
        () => {
          if (isMounted.current) {
            setIsUploading(false);
            getModalError("Error uploading attachments")("Attachments were NOT uploaded");
          }
        }
      );
      loadFilesFromBucket();
    }
  }

  useEffect(() => {
    if (isMounted.current) loadFilesFromBucket();
  }, [props.entityId, props.teamId, loadFilesFromBucket]);

  async function removeAttachement(file: any) {
    if (props.teamId && props.entityId) {
      await deleteEntityAttachment(props.teamId, props.entityType, props.entityId, file.name);
      if (isMounted.current) loadFilesFromBucket();
    }
  }

  function doDownloadAttachment(fileName: string) {
    if (props.teamId && props.entityId) {
      downloadAttachment(props.teamId, props.entityType, props.entityId, fileName);
    } else {
      getNotificationError("Downloading attachment", "An error occured");
    }
  }

  return (
    <div className="sdetail-section__container">
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={4} style={{}}>
          {props.title}
        </Title>
        <Upload
          multiple
          beforeUpload={(_, fileList: RcFile[]) => uploadAttachements(fileList)}
          onRemove={removeAttachement}
          showUploadList={false}
        >
          <Button icon={<PlusOutlined />} type="primary" loading={isUploading} style={{ marginTop: "4px" }}>
            Add files
          </Button>
        </Upload>
      </span>
      <List style={{ padding: "8px", overflow: "auto", maxHeight: "300px" }} loading={isLoading}>
        {fileList.map((file: any) => {
          return (
            <List.Item
              key={file.name}
              actions={[
                <Button
                  key="download"
                  type="link"
                  style={{ padding: 0, border: 0 }}
                  onClick={() => doDownloadAttachment(file.name)}
                >
                  Download
                </Button>,
                <Button
                  key="delete"
                  type="link"
                  danger
                  style={{ padding: 0, border: 0 }}
                  onClick={() =>
                    getModalWarning("Deleting " + file.name)(
                      "Are you sure you want to delete this?",
                      () => removeAttachement(file),
                      {
                        okText: "Delete",
                        okType: "primary",
                        okButtonProps: { danger: true },
                      }
                    )
                  }
                >
                  Delete
                </Button>,
              ]}
              className="sdetail-files__list-item"
            >
              <List.Item.Meta
                title={file.name}
                avatar={getFileIcon(file.name)}
                className="sdetail-files__list-item-meta"
              />
            </List.Item>
          );
        })}
      </List>
      {fileList.length === 0 && !isLoading && (
        <Empty style={{ marginTop: "0px", marginBottom: "16px" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
};

function getFileIcon(fileString: string) {
  let re = /(?:\.([^.]+))?$/;
  let extension: any[1];
  extension = re.exec(fileString);

  switch (extension[1]) {
    case "pdf":
      return <FilePdfTwoTone className="sdetail-files__icons" />;
    case "xls":
    case "xlsx":
      return <FileExcelTwoTone className="sdetail-files__icons" />;
    case "zip":
      return <FilePdfTwoTone className="sdetail-files__icons" />;
    case "doc":
    case "docx":
      return <FileWordTwoTone className="sdetail-files__icons" />;
    case "txt":
      return <FileTextTwoTone className="sdetail-files__icons" />;
    case "ppt":
    case "pptx":
      return <FilePptTwoTone className="sdetail-files__icons" />;
    case "jpg":
    case "png":
    case "bmp":
    case "jpeg":
    case "gif":
      return <FileImageTwoTone className="sdetail-files__icons" />;
    default:
      return <FileUnknownTwoTone className="sdetail-files__icons" />;
  }
}

import React, { useState, useEffect } from "react";
import { ServiceCard } from "./serviceCard";
import { Pagination, Spin } from "antd";

interface ServicesGridProps {
  services: any[];
  isFetchingServices: boolean;
}

const ITEMS_PER_PAGE = 9;

export const ServicesGrid: React.FC<ServicesGridProps> = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [shownServices, setShownServices] = useState<any[]>([]);

  useEffect(() => {
    const numberOfPages = Math.ceil(props.services.length / ITEMS_PER_PAGE);
    if (numberOfPages < currentPage) {
      setCurrentPage(Math.max(numberOfPages, 1));
    }

    const shown = props.services.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );
    setShownServices(shown);
  }, [currentPage, props.services]);

  return (
    <div>
      <div
        className={
          "services__content" +
          (props.isFetchingServices ? " services__content--center" : "")
        }
      >
        {props.isFetchingServices ? (
          <Spin size="large" />
        ) : (
            <div className="services__grid">
              {shownServices.map((serv) => (
                <ServiceCard key={JSON.stringify(serv)} service={serv} />
              ))}
            </div>
          )}
      </div>
      <Pagination
        simple
        current={currentPage}
        onChange={(v) => setCurrentPage(v)}
        pageSize={ITEMS_PER_PAGE}
        total={props.services.length}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      />
    </div>
  );
};

import React, { useRef, useContext, useMemo, useState, useEffect } from "react";
import { ServiceEvent } from "../../../lib/definitions/models";
import { useHistory, useParams } from "react-router-dom";
import { nameToPath, DIDBResource } from "../../../lib/definitions/general_definitions";
import { doFetch, getModalError, getNotificationSuccess } from "../../../lib/functions/general_functions";
import {
  MyTeamsContext,
  EnvironmentContext,
  ProviderContractContext,
  RequesterContractContext,
} from "../../../lib/contexts";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormInput } from "../createForm/sFormInput";
import { getMyEmail } from "../../../lib/functions/profile_functions";
import { useForm } from "antd/lib/form/Form";
import { Spinner } from "../spinner";

export function CreateEventRequestPublisher() {
  const [serviceEvent, setServiceEvent] = useState<ServiceEvent>();
  const [isFetching, setIsFetching] = useState(false);

  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const { refreshProviderContracts } = useContext(ProviderContractContext);
  const { refreshConsumerContracts } = useContext(RequesterContractContext);

  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const isMounted = useRef(true);

  const [form] = useForm();

  useEffect(() => {
    setIsFetching(true);
    doFetch(
      "GET",
      `${DIDBResource.Events}/${id}`,
      isMounted,
      (res) => setServiceEvent(res.value),
      (errorMessage) => {
        getModalError("Fetching event information")(errorMessage);
        history.goBack();
      },
      () => setIsFetching(false)
    );
  }, [history, id]);

  async function postRequest(values: any) {
    await doFetch(
      "POST",
      DIDBResource.EventPublishers,
      isMounted,
      () => {
        getNotificationSuccess("Created", "Request", id);
        history.push(nameToPath["Services"]);
      },
      getModalError("Creating request"),
      () => {
        refreshConsumerContracts();
        refreshProviderContracts();
      },
      {
        serviceId: id,
        consumingAppId: values.applicationId,
        description: values.description,
        requester_mail: getMyEmail(),
        env: selectedEnvironment?._id,
      }
    );
  }

  const applicationOptions = useMemo(
    () =>
      (selectedTeam?.applications || [])
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application) => ({ label: application.name, value: application._id })),
    [selectedTeam, selectedEnvironment]
  );

  return (
    <div>
      {isFetching && <Spinner />}
      {serviceEvent && (
        <SForm
          title="Create Publication Request"
          onBack={() => history.push(`${nameToPath["Services"]}/event/${id}`)}
          onCreate={postRequest}
          form={form}
        >
          <SFormSection>
            <SFormSelect label="Application" field="applicationId" options={applicationOptions} />
            <SFormInput label="Justification" field="description" textarea optional />
          </SFormSection>
        </SForm>
      )}
    </div>
  );
}

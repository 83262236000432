import React, { useEffect, useState, useContext } from "react";
import { Dropdown, Menu, Badge } from "antd";
import { authProvider } from "../../lib/authProvider";

import { nameToPath, MSGraphResource } from "../../lib/definitions/general_definitions";
import { UserOutlined, LogoutOutlined, MailOutlined, LoadingOutlined, NotificationOutlined } from "@ant-design/icons";
import { getModalFeedback, useSHistory } from "../../lib/functions/general_functions";
import { MessagesContext, ProviderContractContext } from "../../lib/contexts";
import { FeedbackModalContent } from "../general/feedbackModalContent";
import MenuItem from "antd/lib/menu/MenuItem";

export const ProfileItem = () => {
  const [image, setImage] = useState<Blob | undefined>();
  const [isFetchingImage, setIsFetchingImage] = useState(true);
  const history = useSHistory();
  const { specificTeamProviderContracts } = useContext(ProviderContractContext);
  const { messages } = useContext(MessagesContext);

  useEffect(() => {
    authProvider.getAccessToken().then((token: any) => {
      fetch(`${MSGraphResource.Me}/photo/$value`, {
        headers: {
          Authorization: token.accessToken,
        },
        method: "GET",
      })
        .then((res) => res.blob())
        .then(setImage)
        .finally(() => setIsFetchingImage(false));
    });
  }, []);

  const url = image && URL.createObjectURL(image);

  return (
    <div className="menubar__image-item">
      {isFetchingImage ? (
        <LoadingOutlined style={{ color: "#1890ff" }} spin />
      ) : (
        <Dropdown
          placement="bottomRight"
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item disabled style={{ cursor: "unset" }}>
                {authProvider.getAccount().name}
              </Menu.Item>
              <Menu.Item onClick={() => history.push(nameToPath["Profile"])}>
                <div>
                  <UserOutlined style={{ marginRight: "8px" }} />
                  Profile
                </div>
              </Menu.Item>
              <Menu.Item onClick={() => history.push(nameToPath["Requests"])}>
                <div>
                  <MailOutlined style={{ marginRight: "8px" }} />
                  <Badge
                    count={specificTeamProviderContracts.filter((request) => request.status === "pending").length}
                    offset={[16, 6]}
                  >
                    Requests
                  </Badge>
                </div>
              </Menu.Item>
              <Menu.Item onClick={() => history.push(nameToPath["Inbox"])}>
                <div>
                  <MailOutlined style={{ marginRight: "8px" }} />
                  <Badge count={messages.filter((m) => !m.read).length} offset={[16, 6]}>
                    Team Inbox
                  </Badge>
                </div>
              </Menu.Item>
              <MenuItem
                onClick={() =>
                  getModalFeedback("Help i2 improve by giving your feedback")((close) => (
                    <FeedbackModalContent feedbackType="general" action="General" onClose={close} />
                  ))
                }
              >
                <NotificationOutlined style={{ marginRight: "8px" }} />
                Feedback
              </MenuItem>
              <Menu.Item onClick={authProvider.logout}>
                <div>
                  <LogoutOutlined style={{ marginRight: "8px" }} />
                  Sign out
                </div>
              </Menu.Item>
            </Menu>
          }
        >
          <Badge
            dot
            count={
              specificTeamProviderContracts.filter((request) => request.status === "pending").length +
              messages.filter((m) => !m.read).length
            }
            offset={[0, 5]}
          >
            <img className="menubar__image" src={url} alt="" />
          </Badge>
        </Dropdown>
      )}
    </div>
  );
};

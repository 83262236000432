import React, { useContext, useEffect } from "react";
import { SuperTable } from "../superTable";
import { BreadcrumbContext, MyTeamsContext } from "../../lib/contexts";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { getPropArray, useSHistory } from "../../lib/functions/general_functions";
import { applicationsColumns } from "../../lib/definitions/table_column_definitions";
import { SView } from "../general/detailView/sView";

export const ApplicationsPane = () => {
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const { selectedTeam } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);
  const history = useSHistory();

  useEffect(() => {
    setBreadcrumbs([]);
  }, [setBreadcrumbs]);

  const applications = getPropArray(selectedTeam, "applications");

  return (
    <SView title="Applications" subTitle={applications.length} onBack={() => history.push(nameToPath["Home"])}>
      <SuperTable
        rows={applications}
        rowKey="_id"
        columns={applicationsColumns}
        onRowClick={(row) => history.push(`${nameToPath["HomeApplications"]}/${row._id}`)}
        onCreate={() => history.push(nameToPath["HomeApplications"] + nameToPath["Create"])}
      />
    </SView>
  );
};

import { Spin } from "antd";
import { BasicCard } from "./dashboardCards/basicCard";
import React, { useEffect } from "react";
import { HistogramGraph } from "./dashboardCards/histogramGraph";
import { LongCard } from "./dashboardCards/longCard";
import { useContext } from "react";
import {
  EnvironmentContext,
  LogsContext,
  MyTeamsContext,
  ProviderContractContext,
  RequesterContractContext,
} from "../../lib/contexts";
import { useSHistory } from "../../lib/functions/general_functions";

interface DashboardGridProps {
  isFetchingServices: boolean;
  applications: any[];
  errors?: string | number;
}

export const DashboardGrid = (props: DashboardGridProps) => {
  const history = useSHistory();
  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const { specificTeamConsumerContracts } = useContext(RequesterContractContext);
  const { specificTeamProviderContracts } = useContext(ProviderContractContext);
  const {
    refreshLogs,
    mappedLogs,
    transactionLogs,
    setTransactionLogs,
    errorLogs,
    setErrorLogs,
    isFetchingLogs,
  } = useContext(LogsContext);
  const LOG_SOURCE = "EBM-Mapping";

  useEffect(() => {
    if (!mappedLogs) {
      refreshLogs();
    }
  }, [refreshLogs, mappedLogs]);

  useEffect(() => {
    setTransactionLogs(
      mappedLogs.filter(
        (ml) =>
          ml.team === selectedTeam?.name &&
          ml.source === LOG_SOURCE &&
          !ml.error?.code &&
          ml.env === selectedEnvironment?._id &&
          isToday(ml.timestamp)
      )
    );
    setErrorLogs(
      mappedLogs.filter(
        (ml) =>
          ml.team === selectedTeam?.name &&
          ml.error?.code &&
          ml.env === selectedEnvironment?._id &&
          isToday(ml.timestamp)
      )
    );
  }, [mappedLogs, selectedTeam, setErrorLogs, setTransactionLogs, refreshLogs, selectedEnvironment]);

  function isToday(timestamp: number): boolean {
    return new Date(timestamp).getDate() === new Date().getDate();
  }

  return (
    <div>
      <div className={"dashboard__content" + (props.isFetchingServices ? " services__content--center" : "")}>
        {props.isFetchingServices ? (
          <Spin size="large" />
        ) : (
          <div className="dashboard__grid">
            <LongCard title="Overview" />
            <HistogramGraph data={props.applications} />
            <BasicCard
              title="Approvals"
              titleColor="yellow"
              text={`${
                specificTeamConsumerContracts.filter((contract) => contract.status === "pending").length
              } Waiting`}
              onClick={() => history.push("/approvals")}
            />
            <BasicCard
              title="Requests"
              titleColor="yellow"
              text={`${
                specificTeamProviderContracts.filter((contract) => contract.status === "pending").length
              } Pending`}
              onClick={() => history.push("/requests")}
            />

            <BasicCard
              title="Errors"
              loading={isFetchingLogs}
              titleColor="red"
              text={isFetchingLogs ? `` : `${errorLogs.length} Errors`}
              onClick={() => history.push("/errors")}
            />

            <BasicCard
              title="Today"
              loading={isFetchingLogs}
              titleColor="green"
              text={isFetchingLogs ? `` : `${transactionLogs.length} Transactions`}
              onClick={() => {
                history.push("/logs");
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

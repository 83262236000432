import React, { useContext, useEffect, useState, useRef } from "react";
import { BreadcrumbContext, MeContext, MyTeamsContext } from "../lib/contexts";
import { getPageBreadcrumb } from "../lib/definitions/general_definitions";
import { Button, Collapse, Alert } from "antd";
import { authProvider } from "../lib/authProvider";

import { RegisterPopup } from "../components/general/registerPopup";
import { Preferences } from "../components/profile/preferences";
import { TeamPanelContent } from "../components/profile/teamPanelContent";
import { SView } from "../components/general/detailView/sView";
import { SSectionCustom } from "../components/general/detailView/sSectionCustom";
import { CopyButton } from "../components/general/copyButton";

export const ProfilePage = () => {
  const [accessToken, setAccessToken] = useState("");
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const { myTeams, selectedTeam } = useContext(MyTeamsContext);
  const { data } = useContext(MeContext);
  const isMounted = useRef(true);

  useEffect(() => {
    (async () => setAccessToken((await authProvider.getAccessToken()).accessToken))();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => setBreadcrumbs([getPageBreadcrumb("Profile")]), [setBreadcrumbs]);

  return (
    <>
      <SView title={`Profile page for ${authProvider.getAccount().name}`} onBack={undefined} wide>
        <SSectionCustom title={myTeams.length > 1 ? "Teams" : "Team"}>
          {myTeams.length === 0 && (
            <Alert
              message="Not a member of a team"
              description="It seems that you are not yet registered on a team in the i2 system. Register yourself below to start using all of the features on the i2"
              type="info"
              showIcon
              style={{ gridColumn: "span 2", marginBottom: "24px" }}
            />
          )}
          <div>
            {myTeams.length === 0 ? (
              "Not registered on a team"
            ) : (
              <Collapse style={{ userSelect: "none" }} accordion defaultActiveKey={selectedTeam?._id}>
                {myTeams.map((team) => (
                  <Collapse.Panel header={team.name} key={team._id}>
                    <TeamPanelContent teamName={team.name} teamId={team._id} key={team._id} />
                  </Collapse.Panel>
                ))}
              </Collapse>
            )}
            {(myTeams.length === 0 || data.isAdmin) && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginTop: "8px" }} onClick={() => setShowRegisterPopup(true)} type="primary">
                  Register
                </Button>
              </div>
            )}
          </div>
        </SSectionCustom>
        <Preferences />
        <SSectionCustom title="Access token">
          {
            <div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                <Button onClick={() => setShowAccessToken(!showAccessToken)} style={{ marginRight: "16px" }}>
                  {showAccessToken ? "Hide" : "Show"}
                </Button>

                <CopyButton title="Copy access token" content={accessToken} />
              </div>
              {showAccessToken ? (
                <div style={{ background: "#f1f1f1", padding: "16px" }}>
                  <code style={{ overflowWrap: "anywhere" }}>{accessToken}</code>
                </div>
              ) : (
                "Access token hidden"
              )}
            </div>
          }
        </SSectionCustom>
      </SView>
      <RegisterPopup open={showRegisterPopup} onClose={() => setShowRegisterPopup(false)} />
    </>
  );
};

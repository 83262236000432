import { SuperTable } from "../components/superTable";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { Service } from "../lib/definitions/models";
import { monitorColoumns } from "../lib/definitions/table_column_definitions";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { EnvironmentContext } from "../lib/contexts";

export function MonitorPage() {
  const [services, setServices] = useState<Service[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const isMounted = useRef(true);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchServices = useCallback(() => {
    setIsFetching(true);
    doFetch(
      "GET",
      `${DIDBResource.ExpandedServices}?env=${selectedEnvironment?._id}`,
      isMounted,
      (res) => setServices(res.value),
      getModalError("Fetching Services"),
      () => setIsFetching(false)
    );
  }, [selectedEnvironment]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  return (
    <div>
      <SuperTable
        rows={services}
        columns={monitorColoumns}
        rowKey="_id"
        defaultPageSize={20}
        refreshProps={{ isRefreshing: isFetching, onRefresh: fetchServices }}
      />
    </div>
  );
}

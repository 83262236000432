import { SSelectOption } from "../../components/general/detailView/Elements/sElementSelect";

export interface S3Credentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: string;
}

export type AttachmentEntityType = "API" | "Application" | "Event";

export interface Attachment {
  name: string;
  key: string;
}

export const RegionOptions: SSelectOption[] = [
  { label: "AF South 1", value: "af-south-1" },
  { label: "AP East 1", value: "ap-east-1" },
  { label: "AP Northeast 1", value: "ap-northeast-1" },
  { label: "AP Northeast 2", value: "ap-northeast-2" },
  { label: "AP Northeast 3", value: "ap-northeast-3" },
  { label: "AP South 1", value: "ap-south-1" },
  { label: "AP Southeast 1", value: "ap-southeast-1" },
  { label: "AP Southeast 2", value: "ap-southeast-2" },
  { label: "CA Central 1", value: "ca-central-1" },
  { label: "EU Central 1", value: "eu-central-1" },
  { label: "EU North 1", value: "eu-north-1" },
  { label: "EU South 1", value: "eu-south-1" },
  { label: "EU West 1", value: "eu-west-1" },
  { label: "EU West 2", value: "eu-west-2" },
  { label: "EU West 3", value: "eu-west-3" },
  { label: "ME South 1", value: "me-south-1" },
  { label: "SA East 1", value: "sa-east-1" },
  { label: "US East 1", value: "us-east-1" },
  { label: "US East 2", value: "us-east-2" },
  { label: "US West 1", value: "us-west-1" },
  { label: "US West 2", value: "us-west-2" },
].sort((a, b) => a.label.localeCompare(b.label));

import React, { useState, useEffect, useRef, useContext } from "react";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { DIDBResource, getPageBreadcrumb, nameToPath } from "../lib/definitions/general_definitions";
import { FilterColumn } from "../components/services/filterColumn";
import { Input, Button } from "antd";
import { ColumnSelection } from "../lib/definitions/services_definitions";
import { filterItems } from "../lib/functions/services_functions";
import { ServicesGrid } from "../components/services/servicesGrid";
import { BreadcrumbContext, EnvironmentContext } from "../lib/contexts";
import { useHistory } from "react-router-dom";

export const Services = () => {
  const [services, setServices] = useState<any[]>([]);
  const [searchedServives, setSearchedServices] = useState<any[]>([]);
  const [filteredServices, setFilteredServices] = useState<any[]>([]);
  const [isFetchingServices, setIsFetchingServices] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [serviceTypeSelections, setServiceTypeSelections] = useState<ColumnSelection[]>([]);
  const [applicationsSelections, setApplicationsSelections] = useState<ColumnSelection[]>([]);
  const [teamsSelections, setTeamsSelections] = useState<ColumnSelection[]>([]);

  const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useHistory();
  const isMounted = useRef(true);
  const setBreadcrumbs = useContext(BreadcrumbContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => setBreadcrumbs([getPageBreadcrumb("Services")]), [setBreadcrumbs]);

  useEffect(() => {
    setIsFetchingServices(true);
    doFetch(
      "GET",
      `${DIDBResource.ExpandedServices}?env=${selectedEnvironment?._id}`,
      isMounted,
      (res) => setServices(res.value),
      getModalError("Fetching services"),
      () => setIsFetchingServices(false)
    );
  }, [selectedEnvironment?._id]);

  // search through all services
  useEffect(() => {
    setSearchedServices(filterItems(services, searchValue));
  }, [services, searchValue]);

  // filter searched services based on filter column
  useEffect(() => {
    let newServices = searchedServives;

    const allSelections: ColumnSelection[] = serviceTypeSelections
      .concat(applicationsSelections)
      .concat(teamsSelections);

    if (allSelections.filter((sel) => sel.checked).length > 0) {
      newServices = [];

      serviceTypeSelections.forEach((sel) => {
        if (sel.checked) {
          newServices.push(...searchedServives.filter((servs) => servs.serviceType === sel.name));
        }
      });
      applicationsSelections.forEach((sel) => {
        if (sel.checked) {
          newServices.push(...searchedServives.filter((servs) => servs.applicationName === sel.name));
        }
      });
      teamsSelections.forEach((sel) => {
        if (sel.checked) {
          newServices.push(...searchedServives.filter((servs) => servs.teamName === sel.name));
        }
      });
    }

    newServices = Array.from(new Set(newServices));

    newServices.sort((a, b) => a.name.localeCompare(b.name, "en"));

    setFilteredServices(newServices);
  }, [searchedServives, serviceTypeSelections, applicationsSelections, teamsSelections]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "256px auto",
        columnGap: "40px",
      }}
      className="services"
    >
      <FilterColumn
        searchedServices={searchedServives}
        serviceTypeSelections={serviceTypeSelections}
        applicationsSelections={applicationsSelections}
        teamsSelections={teamsSelections}
        setServiceTypeSelections={setServiceTypeSelections}
        setApplicationsSelections={setApplicationsSelections}
        setTeamsSelections={setTeamsSelections}
      />
      <div>
        <div className="services__search-bar">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "400px" }}>
              <Input.Search
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                allowClear
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    setSearchValue("");
                  }
                }}
                placeholder="Search"
              />
            </div>
            <b style={{ marginLeft: "12px" }}>
              {searchedServives.length + " results" + (searchValue ? ` for "${searchValue}"` : "")}
            </b>
          </div>
          <div>
            <Button type="primary" onClick={() => history.push(nameToPath["HomeServices"] + nameToPath["Create"])}>
              Create service
            </Button>
          </div>
        </div>
        <ServicesGrid services={filteredServices} isFetchingServices={isFetchingServices} />
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { SwapOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tag, Dropdown, Menu } from "antd";
import { EnvironmentContext } from "../../lib/contexts";
import * as localForage from "localforage";
import { LAST_ENV_USED } from "../../lib/definitions/localForage_definitions";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { useSHistory } from "../../lib/functions/general_functions";

export const EnvironmentSelect = () => {
  const { selectedEnvironment, environments, isFetchingEnvironments, setSelectedEnvironment } = useContext(
    EnvironmentContext
  );
  const history = useSHistory();

  const menuItemsWithDivider = environments
    .sort((a, b) => a.stageIndex - b.stageIndex)
    .map((environment) => (
      <Menu.Item
        key={environment._id}
        onClick={() => {
          setSelectedEnvironment(environment);
          history.push(nameToPath["Home"]);
          localForage.setItem(LAST_ENV_USED, environment._id);
        }}
      >
        {environment.name}
      </Menu.Item>
    ));
  menuItemsWithDivider.splice(menuItemsWithDivider.length - 1, 0, <Menu.Divider key="divider" />);

  return (
    <div style={{ marginRight: "24px" }}>
      <Dropdown placement="bottomRight" trigger={["click"]} overlay={<Menu>{menuItemsWithDivider}</Menu>}>
        <div style={{ cursor: "pointer" }}>
          {selectedEnvironment && (
            <Tag style={{ cursor: "pointer" }} color={selectedEnvironment.colorCode}>
              {selectedEnvironment.name}
            </Tag>
          )}
          {isFetchingEnvironments ? <LoadingOutlined style={{ color: "#1890ff" }} spin /> : <SwapOutlined />}
        </div>
      </Dropdown>
    </div>
  );
};

import { SuperTable } from "../components/superTable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { logsColumns } from "../lib/definitions/table_column_definitions";
import { JsonAsTree } from "../components/general/jsonAsTree";
import { EnvironmentContext, LogsContext, MeContext, MyTeamsContext } from "../lib/contexts";
import { Button, Modal, TimePicker } from "antd";
import { DatePicker } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { DagreFundFlowGraph } from "@ant-design/charts";
import { LogsChart } from "../components/logs/logsChart";
import { CloseOutlined } from "@ant-design/icons";

interface GraphConfig {
  data: { nodes: any[]; edges: any[] };
  colorMap: {};
  behaviors: string[];
}

export const LogsPage = () => {
  const [singleLog, setSingleLog] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDateString, setStartDate] = useState<string>();
  const [endDateString, setEndDate] = useState<string>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const [finalStartDate, setFinalStartDate] = useState<string>();
  const [finalEndDate, setFinalEndDate] = useState<string>();
  const [ebmMapping, setEbmMapping] = useState(false);
  const [ebmPublisher, setEbmPublisher] = useState(true);
  const [ebmDistributer, setEbmDistributer] = useState(false);
  const [ebmDequeue, setEbmDequeue] = useState(true);
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [seeAllLogs, setSeeAllLogs] = useState(false);
  const [tracedLogs, setTracedLogs] = useState<any[]>([]);
  const [traceFetchingLogId, setTraceFetchingLogId] = useState("");
  const [isHoveringNode, setIsHoveringNode] = useState(false);

  const [tracedGraphConfig, setTracedGraphConfig] = useState<GraphConfig>();

  const { refreshLogs, mappedLogs, isFetchingLogs } = useContext(LogsContext);
  const { data } = useContext(MeContext);
  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const reffedLogs = useRef<any[]>([]);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    reffedLogs.current = mappedLogs;
  }, [mappedLogs]);

  useEffect(() => {
    if (startDateString && endDateString && startTime && endTime) {
      const startDate = new Date(startDateString);
      const startTimeDate = new Date(startTime);
      startTimeDate.setDate(startDate.getDate());
      setFinalStartDate(startTimeDate.toString());

      const endDate = new Date(endDateString);
      const endTimeDate = new Date(endTime);
      endTimeDate.setDate(endDate.getDate());
      setFinalEndDate(endTimeDate.toString());
    } else {
      setFinalStartDate(startDateString);
      setFinalEndDate(endDateString);
    }
  }, [startTime, endTime, startDateString, endDateString]);

  const handleOk = () => {
    if (finalStartDate && finalEndDate) {
      refreshLogs(finalStartDate, finalEndDate);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onDateChange = (value: any) => {
    setStartDate(value[0].startOf("day").toString());
    setEndDate(value[1].endOf("day").toString());
  };

  const onTimeChange = (value: any) => {
    setStartTime(value[0].toString());
    setEndTime(value[1].toString());
  };

  const isMyLog = (team: string) => {
    if (seeAllLogs) return true;
    return selectedTeam?.name === team;
  };

  const handleEdgeClick = (item: any, graph: any) => {
    graph.setItemState(item, "selected", true);
  };

  function handleOnNodeClick(item: any, graph: any) {
    const clickedLog = reffedLogs.current.find((log) => log._id === item._cfg.model.key);
    // setSingleLog(clickedLog);
    graph.setItemState(item, "selected", true);
    Modal.info({
      title: `Log content - ${clickedLog.source}`,
      className: "general__fitting-modal",
      content: (
        <pre
          style={{
            width: "min-content",
          }}
        >
          <code>{JSON.stringify(clickedLog, null, 4)} </code>
        </pre>
      ),
    });
  }
  const handleCanvasClick = (graph: any) => {
    const selectedEdges = graph.findAllByState("edge", "selected");
    selectedEdges.forEach((edge: any) => {
      graph.setItemState(edge, "selected", false);
    });
    const selectedNodes = graph.findAllByState("node", "selected");
    selectedNodes.forEach((node: any) => {
      graph.setItemState(node, "selected", false);
    });
  };

  function createTimestamp(timestamp: any) {
    const d = new Date(timestamp);
    if (d.toString() === "Invalid Date") {
      return "Invalid Date";
    }
    return `${
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2) +
      ":" +
      ("0" + d.getSeconds()).slice(-2)
    }`;
  }

  useEffect(() => {
    const arr = [];
    if (ebmDistributer) arr.push("EBM-Distribute");
    if (ebmMapping) arr.push("EBM-Mapping");
    if (ebmPublisher) arr.push("EBM-Publisher");
    if (ebmDequeue) arr.push("EBM-Dequeue");
    setSourceList(arr);
  }, [ebmDistributer, ebmMapping, ebmPublisher, ebmDequeue]);

  //should be a function, remove state for tracedLog

  function createTraceGraph(logs: any[]) {
    const nodes: any[] = [];
    const edges: any[] = [];
    let nodeLabel: string = "";
    setTracedLogs(logs);
    let dataType: string;
    logs.forEach((log) => {
      const mappedLog = reffedLogs.current.find((reffedLog) => reffedLog._id === log._id);
      if (log.error) {
        dataType = "E";
      } else {
        dataType = "S";
      }
      if (mappedLog.source === "EBM-Dequeue" && dataType === "S") {
        nodeLabel = `${mappedLog.source} (${mappedLog.endpointType})`;
      } else {
        nodeLabel = mappedLog.source;
      }
      nodes.push({
        id: mappedLog.messageId,
        key: mappedLog._id,
        label: nodeLabel,
        dataType: dataType,
      });
      if (log.parentMessageId) {
        edges.push({
          source: mappedLog.parentMessageId,
          target: mappedLog.messageId,
          dataType: dataType,
          label: mappedLog.application,
          subLabel: createTimestamp(mappedLog.timestamp),
        });
      }
    });
    setTracedGraphConfig({
      data: { nodes: nodes, edges: edges },
      colorMap: {
        S: "#72CC4A",
        W: "#1A91FF",
        E: "#FF0000",
      },
      behaviors: ["drag-canvas", "zoom-canvas"],
    });
  }

  return (
    <div>
      <Button
        style={{ float: "right" }}
        type="primary"
        onClick={() => setIsModalVisible(true)}
        disabled={isFetchingLogs}
      >
        Filter logs
      </Button>
      <div style={{ marginBottom: "48px" }}>
        <LogsChart seeAllLogs={seeAllLogs} />
      </div>
      <h1>Latest logs</h1>
      <SuperTable
        rows={
          data.isAdmin
            ? mappedLogs.filter(
                (ml) => ml.env === selectedEnvironment?._id && sourceList.includes(ml.source) && isMyLog(ml.team)
              )
            : mappedLogs.filter(
                (ml) =>
                  ml.team === selectedTeam?.name &&
                  ml.env === selectedEnvironment?._id &&
                  sourceList.includes(ml.source)
              )
        }
        columns={logsColumns}
        rowKey="_id"
        refreshProps={{ onRefresh: refreshLogs, isRefreshing: isFetchingLogs }}
        onRowClick={setSingleLog}
        defaultPageSize={20}
        actions={[
          {
            buttonText: "Trace",
            onClick: (row) => {
              setTraceFetchingLogId(row._id);
              doFetch(
                "GET",
                DIDBResource.Logs + `?env=${selectedEnvironment?._id}&traceid=${row.traceId}`,
                isMounted,
                (response) => {
                  createTraceGraph(response.value);
                },
                getModalError("Fetching trace of logs"),
                () => setTraceFetchingLogId("")
              );
            },
            description: `Trace the log and see an overview of the data flow`,
            loading: (row) => row._id === traceFetchingLogId,
          },
        ]}
        selectedRow={singleLog}
      />
      {singleLog && (
        <div>
          <h1 style={{ alignItems: "center", display: "flex" }}>
            <Button icon={<CloseOutlined />} style={{ marginRight: "16px" }} onClick={() => setSingleLog(undefined)} />
            Log content: {singleLog.source}
          </h1>
          <JsonAsTree json={singleLog} />
        </div>
      )}
      <Modal
        title="Select interval"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type={"primary"} onClick={handleOk} key={1}>
            OK
          </Button>,
        ]}
      >
        <p>Pick dates</p>
        <DatePicker.RangePicker format="DD-MM-YYYY" onChange={onDateChange} /> <br />
        <div
          style={{
            marginTop: "6px",
          }}
        >
          <TimePicker.RangePicker onChange={onTimeChange} minuteStep={15} format="HH:mm" /> <br />
        </div>
        <div style={{ marginTop: "10px" }}>
          Include logs from: <br></br>
          <div style={{ marginTop: "10px" }}>
            <Checkbox checked={ebmMapping} onChange={(res) => setEbmMapping(res.target.checked)}>
              Mapping
            </Checkbox>
            <Checkbox checked={ebmDistributer} onChange={(res) => setEbmDistributer(res.target.checked)}>
              Distributer
            </Checkbox>
            <Checkbox checked={ebmPublisher} onChange={(res) => setEbmPublisher(res.target.checked)}>
              Publisher
            </Checkbox>
            <Checkbox checked={ebmDequeue} onChange={(res) => setEbmDequeue(res.target.checked)}>
              Dequeue
            </Checkbox>
          </div>
          <br />
          {data.isAdmin && (
            <div>
              <p>Admin options</p>
              <Checkbox checked={seeAllLogs} onChange={(res) => setSeeAllLogs(res.target.checked)}>
                See all logs
              </Checkbox>
            </div>
          )}
        </div>
      </Modal>
      {tracedLogs.length !== 0 && (
        <div style={{ marginTop: "48px" }}>
          <h1 style={{ alignItems: "center", display: "flex" }}>
            <Button icon={<CloseOutlined />} style={{ marginRight: "16px" }} onClick={() => setTracedLogs([])} />
            Traced logs
          </h1>
          {tracedGraphConfig?.data && (
            <div className={isHoveringNode ? "general__canvas-pointer" : undefined} style={{ userSelect: "none" }}>
              <DagreFundFlowGraph
                {...tracedGraphConfig}
                nodeStyle={{ stroke: "#40a9ff" }}
                edgeStyle={{ stroke: "#91d5ff", endArrow: true }}
                handleEdgeClick={handleEdgeClick}
                handleCanvasClick={handleCanvasClick}
                handleNodeClick={handleOnNodeClick}
                handleNodeHover={() => setIsHoveringNode(true)}
                handleNodeUnHover={() => setIsHoveringNode(false)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

import React from "react";
import { Typography } from "antd";

interface SFormSectionProps {
  title?: string;
  custom?: boolean;
  children?: React.ReactNode;
}

export const SFormSection = (props: SFormSectionProps) => {
  return (
    <div className="sform__section">
      <Typography.Title style={{ fontSize: "18px", marginBottom: "18px" }}>{props.title || "General"}</Typography.Title>
      <div
        style={
          props.custom
            ? undefined
            : {
                display: "grid",
                gridTemplateColumns: `repeat(2, min-content minmax(0,1fr) min-content)`,
                columnGap: "8px",
              }
        }
      >
        {props.children}
      </div>
    </div>
  );
};

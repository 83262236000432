import React, { useEffect, useContext, useRef, useMemo, useState } from "react";
import {
  getModalError,
  doFetchPromise,
  getNotificationSuccess,
  doFetch,
  getNotificationError,
  getNotificationFeedbackRating,
  useQuery,
  useSHistory,
} from "../../../lib/functions/general_functions";
import {
  camelCaseRegex,
  DIDBResource,
  nameLengthRange,
  nameToPath,
} from "../../../lib/definitions/general_definitions";
import { BreadcrumbContext, MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import { SForm } from "../createForm/sForm";
import { SFormInput } from "../createForm/sFormInput";
import {
  serviceApiTypeOptions,
  serviceAuthenticationOptions,
  serviceVisibilityOptions,
} from "../../../lib/definitions/home_definitions";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormTags } from "../createForm/sFormTags";
import { SFormSection } from "../createForm/sFormSection";
import { CreateApplication } from "./createApplication";
import { CreateNamespace } from "./createNamespace";
import { SFormEnum } from "../createForm/sFormEnum";
import { SFormFiles } from "../createForm/sFormFiles";
import { uploadEntityAttachments } from "../../../lib/functions/aws_functions";
import { SSectionCustom } from "../detailView/sSectionCustom";
import Dragger from "antd/lib/upload/Dragger";
import { CheckCircleTwoTone, InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { useForm } from "antd/lib/form/Form";
import { Alert, Radio } from "antd";
import { SLabel } from "../createForm/sLabel";

const yaml = require("js-yaml");

export const CreateAPIForm = () => {
  const [swaggerFile, setSwaggerFile] = useState<RcFile>();
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const [form] = useForm();
  const history = useSHistory();
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const [url, setUrl] = useState<string>("");
  const [selectedNamespaceLabel, setSelectedNamespaceLabel] = useState<string>();
  const [targetUrl, setTargetUrl] = useState<string>();
  const [listenPath, setListenPath] = useState<string>();
  const [createTykApi, setCreateTykApi] = useState<boolean>(false);

  const { query, getNewQuery } = useQuery();
  const filesRef = useRef<any>();
  const isMounted = useRef(true);

  useEffect(() => {
    form.setFieldsValue({ targetApiGatewayId: "5f31142be18e180008e9c56e", visibility: "team" });
    return () => {
      isMounted.current = false;
    };
  }, [form]);

  useEffect(() => {
    setBreadcrumbs([]);
    return () => {
      isMounted.current = false;
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs]);

  useEffect(() => {
    const augListenPath = listenPath ? "/" + listenPath : "";
    const namespaceValue = selectedNamespaceLabel;

    if (namespaceValue) {
      setUrl("https://" + namespaceValue + augListenPath);
    } else if (targetUrl) {
      setUrl(targetUrl);
    } else {
      setUrl("");
    }
  }, [selectedNamespaceLabel, targetUrl, listenPath]);

  useEffect(() => {
    const applicationId = query.get("applicationid");
    const namespaceId = query.get("namespaceid");
    if (applicationId) form.setFieldsValue({ applicationId });
    if (namespaceId) form.setFieldsValue({ namespaceId });
  }, [form, query]);

  const applicationOptions = useMemo(
    () =>
      (selectedTeam?.applications || [])
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application: any) => ({
          label: application.name,
          value: application._id,
        })),
    [selectedTeam, selectedEnvironment]
  );

  const namespaceOptions = useMemo(
    () =>
      (selectedTeam?.namespaces || [])
        .filter((namespace) => namespace.env === selectedEnvironment?._id)
        .map((namespace: any) => ({
          label: namespace.name,
          value: namespace._id,
          dnsid: namespace.dnsId,
        })),
    [selectedTeam, selectedEnvironment]
  );

  async function createApi(values: any, files: any[]) {
    await doFetch(
      "POST",
      DIDBResource.APIs,
      isMounted,
      (response) => {
        getNotificationSuccess("Created", "API", values.name);
        refreshMyTeams(`${nameToPath["HomeAPIs"]}/${response.value.id}`);
        getNotificationFeedbackRating("API");
        if (selectedTeam?._id) {
          if (swaggerFile) {
            files.push(swaggerFile);
          }
          uploadEntityAttachments(selectedTeam._id, "API", response.value.id, files, undefined, () =>
            getNotificationError("Uploading attachments", "An error occured")
          );
        }
      },
      getModalError("Creating API"),
      undefined,
      { ...values, env: selectedEnvironment?._id, definitionFile: swaggerFile?.name, createTykApi: createTykApi }
    );
  }

  async function getTargetApiGatewayOptions() {
    try {
      const response = await doFetchPromise("GET", `${DIDBResource.Targets}?type=api`);
      return response.value.map((target: any) => ({ label: target.name, value: target._id }));
    } catch {
      return [];
    }
  }

  async function getApiNames() {
    try {
      const response = await doFetchPromise("GET", DIDBResource.APIs);
      return response.value.map((api: any) => api.name);
    } catch {
      return [];
    }
  }

  function updateFieldsFromSwagger(swaggerfile: any) {
    if (swaggerfile?.openapi) {
      const targetUrl = swaggerfile.servers ? swaggerfile.servers[0]?.url : "";
      setTargetUrl(targetUrl);
      form.setFieldsValue({
        name: (swaggerfile.info?.title).replace(/\s/g, "-"),
        description: swaggerfile.info?.description,
        targetUrl: targetUrl,
        tags: swaggerfile.tags?.map((tag: any) => {
          return tag.name;
        }),
      });
    } else if (swaggerfile?.swagger) {
      const targetUrl = swaggerfile.host ? swaggerfile.host : "";
      setTargetUrl(targetUrl + (swaggerfile.basePath ? swaggerfile.basePath : ""));
      form.setFieldsValue({
        name: (swaggerfile.info?.title).replace(/\s/g, "-"),
        description: swaggerfile.info?.description,
        targetUrl: targetUrl + (swaggerfile.basePath ? swaggerfile.basePath : ""),
        tags: swaggerfile.tags?.map((tag: any) => {
          return tag.name;
        }),
      });
    }
  }

  function generateDataFromSwagger(text: string, type: string | undefined) {
    if (type?.toLowerCase() === "yaml") {
      yaml.safeLoadAll(text, (swaggerfile: any) => {
        updateFieldsFromSwagger(swaggerfile);
      });
    } else if (type?.toLowerCase() === "json") {
      updateFieldsFromSwagger(JSON.parse(text));
    }
    form.validateFields(["name", "description", "targetUrl", "listenPath", "tags"]);
  }

  function updateNamespaceValue(value: any) {
    if (selectedEnvironment && value) {
      doFetch(
        "GET",
        `${DIDBResource.DNS}?_id=${value.dnsid}`,
        isMounted,
        (res) => {
          setSelectedNamespaceLabel(res.value[0].name + "/" + value.label);
        },
        (err: any) => {
          getModalError("Fetching DNS name for URL")(err);
          setSelectedNamespaceLabel("");
        },
        () => console.log
      );
    } else {
      setSelectedNamespaceLabel("");
    }
  }

  return (
    <>
      <SForm
        title="Create API"
        onBack={() => history.push(nameToPath["HomeServices"] + nameToPath["Create"])}
        onCreate={(values) => createApi(values, filesRef.current?.getFiles())}
        form={form}
      >
        <SFormSection>
          <SFormInput
            label="Name"
            field="name"
            regex={camelCaseRegex}
            lengthRange={nameLengthRange}
            getReservedValues={getApiNames}
            reservedValuesCaseInsensitive
          />
          <SLabel label="URL" />
          <span style={{ whiteSpace: "nowrap", textAlign: "left", marginTop: "5px" }}>{url}</span>
          <div></div>
          <SFormSelect
            label="Application"
            field="applicationId"
            options={applicationOptions}
            onNewForm={(close) => (
              <CreateApplication
                extraHook={(applicationId: string) => {
                  close();
                  history.push({
                    pathname: history.location.pathname,
                    search: getNewQuery("applicationid", applicationId),
                  });
                }}
              />
            )}
          />
          <SFormEnum label="Visibility" field="visibility" options={serviceVisibilityOptions} />
          <SFormInput label="Target URL" field="targetUrl" onChange={(event) => setTargetUrl(event.target.value)} />
          <SFormInput label="Description" field="description" textarea optional />
          <SFormEnum label="Authentication" field="authentication" options={serviceAuthenticationOptions} />
          <SFormTags label="Tags" field="tags" optional />
          <SFormEnum label="API Type" field="apitype" options={serviceApiTypeOptions} />
          <SFormFiles label="Attachments" ref={filesRef} />
        </SFormSection>
        <Radio
          style={{ margin: "10px" }}
          checked={createTykApi as boolean}
          onClick={() => {
            setCreateTykApi(!createTykApi);
          }}
        >
          Create tyk gateway for api
        </Radio>
        <SFormSection title="Virtualization">
          <SFormSelect
            label="Namespace"
            field="namespaceId"
            options={namespaceOptions}
            optional
            onChange={(_, option) => {
              updateNamespaceValue(option);
              if (!option) {
                form.setFieldsValue({ listenPath: "" });
              }
            }}
            onNewForm={(close) => (
              <CreateNamespace
                extraHook={(namespaceId: string) => {
                  close();
                  history.push({
                    pathname: history.location.pathname,
                    search: getNewQuery("namespaceid", namespaceId),
                  });
                }}
              />
            )}
          />
          <SFormInput
            label="Listen path"
            field="listenPath"
            optional
            onChange={(event) => setListenPath(event.target.value)}
            addonBefore="/"
            disabled={!form.getFieldValue("namespaceId")}
          />
          <SFormSelect
            label="API gateway"
            field="targetApiGatewayId"
            getOptions={getTargetApiGatewayOptions}
            optional
            disabled={!form.getFieldError("namespaceId")}
          />
        </SFormSection>
        <SSectionCustom title="Generate from Swagger">
          {swaggerFile && (
            <Alert
              message="Successfully generated fields from Swaggerfile. File has been added as attachement"
              type="success"
              style={{ marginBottom: "4px" }}
            />
          )}
          <Dragger
            name="file"
            style={{ marginBottom: "8px" }}
            accept=".yaml,.json"
            beforeUpload={(file: RcFile) => {
              file.text().then((text: string) => generateDataFromSwagger(text, file.name.split(".").pop()));
              setSwaggerFile(file);
              filesRef.current.setFiles((files: any) => files.concat(file));
              return false;
            }}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              {swaggerFile === undefined ? <InboxOutlined /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}
            </p>
            <p className="ant-upload-text">Click or drag a .yaml or .json file to this area to generate</p>
            <p className="ant-upload-hint">The Swagger file will be uploaded as an attachement to the API</p>
          </Dragger>
        </SSectionCustom>
      </SForm>
    </>
  );
};

import React, { useContext, useState, useEffect, useMemo } from "react";
import { Card, Button, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import { PlusOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { MyTeamsContext } from "../../../lib/contexts";
import { Service } from "../../../lib/definitions/models";
import { nameToPath } from "../../../lib/definitions/general_definitions";
import { useSHistory } from "../../../lib/functions/general_functions";

interface LongCardProps {
  title: string;
}

export const LongCard = (props: LongCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [applications, setApplications] = useState(0);
  const [services, setServices] = useState(0);
  const [namespaces, setNamespaces] = useState(0);
  const [dns, setDNS] = useState(0);

  const { selectedTeam } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const serviceAmount = useMemo(
    () =>
      selectedTeam?.applications.reduce(
        (arr: Service[], app) =>
          arr.concat(...app.services.map((service) => ({ ...service, applicationName: app.name }))),
        []
      ) || [],
    [selectedTeam]
  );

  useEffect(() => {
    if (!selectedTeam) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
    setApplications(selectedTeam?.applications.length);
    setServices(serviceAmount.length);
    setNamespaces(selectedTeam?.namespaces.length);
    setDNS(selectedTeam?.dns.length);
  }, [selectedTeam, serviceAmount]);

  function getDisplayItem(title: string, amount: number, viewURL: string) {
    return (
      <div style={{ margin: "8px 8px 8px 8px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3 style={{ margin: 0 }}>{title}</h3>
        </div>
        <div style={{ display: "flex" }}>
          <Title style={{ margin: 0 }} level={1}>
            {amount}
          </Title>
        </div>
        <div style={{ margin: "8px 0 8px 0", display: "flex" }}>
          <Button icon={<ArrowRightOutlined />} onClick={() => history.push(viewURL)} style={{ minWidth: "50px" }}>
            View
          </Button>
          <Button
            style={{ marginLeft: "8px", minWidth: "50px" }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push(viewURL + nameToPath["Create"])}
          >
            New
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Card
      title={
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "12px 0",
            }}
            title={props.title + ""}
          >
            {props.title}
          </div>
        </>
      }
      loading={isLoading}
      style={{ gridColumn: "span 4" }}
      className="dashboard__grid--longcard "
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 1fr 5fr 1fr 5fr 1fr 5fr",
          alignContent: "center",
          height: "130px",
          paddingLeft: "8px",
        }}
      >
        {getDisplayItem("Applications", applications, nameToPath["HomeApplications"])}
        <Divider type="vertical" style={{ height: "100%", margin: "0px 16px 0px 16px" }} />
        {getDisplayItem("Services", services, nameToPath["HomeServices"])}
        <Divider type="vertical" style={{ height: "100%", margin: "0px 16px 0px 16px" }} />
        {getDisplayItem("Namespaces", namespaces, nameToPath["HomeNamespaces"])}
        <Divider type="vertical" style={{ height: "100%", margin: "0px 16px 0px 16px" }} />
        {getDisplayItem("DNS", dns, nameToPath["HomeDNS"])}
      </div>
    </Card>
  );
};

import React from "react";

interface SLabelProps {
  label: string;
  required?: boolean;
}

export const SLabel = (props: SLabelProps) => {
  return (
    <span style={{ whiteSpace: "nowrap", textAlign: "right", marginTop: "5px" }}>
      <span style={{ color: "#ff4d4f" }}>{props.required ? "* " : ""}</span>
      {`${props.label}:`}
    </span>
  );
};

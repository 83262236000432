import React, { useState, useContext } from "react";
import { TeamsPage } from "../../pages/teamsPage";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { Services } from "../../pages/servicesPage";
import { nameToPath, BreadcrumbItem, BreadcrumbLoadingText } from "../../lib/definitions/general_definitions";
import { ApplicationsPage } from "../../pages/applicationsPage";
import { SubsciberPage } from "../../pages/subscriberPage";
import { EventDetailsPage } from "../../pages/eventDetails";
import { ProfilePage } from "../../pages/profilePage";
import { Breadcrumb } from "antd";
import { BreadcrumbContext, MyTeamsContext } from "../../lib/contexts";
import { HomeOutlined } from "@ant-design/icons";
import { NotFoundPage } from "../../pages/notFoundPage";
import { HomePage } from "../../pages/homePage";
import { APIDetailsPage } from "../../pages/apiDetailsPage";
import { CreateAPIRequest } from "./forms/createAPIRequest";
import { RequestsPage } from "../../pages/requestsPage";
import { LogsPage } from "../../pages/logsPage";
import { ErrorsPage } from "../../pages/errorsPage";
import { FeedbackPage } from "../../pages/feedbacksPage";
import { MonitorPage } from "../../pages/monitorPage";
import { SwaggerUIPage } from "../../pages/swaggerUIPage";
import { NamespacePage } from "../../pages/namespacePage";
import { InboxPage } from "../../pages/inboxPage";
import { DataObjectsPage } from "../../pages/dataObjectsPage";
import { CreateEventRequestSubscriber } from "./forms/createEventRequestSubscriber";
import { CreateEventRequestPublisher } from "./forms/createEventRequestPublisher";

export const MainContainer = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[] | undefined>([]);
  const { selectedTeam } = useContext(MyTeamsContext);

  function generateBreadcrumbs() {
    let somethingIsLoadingState = 0;
    return breadcrumbItems
      ? breadcrumbItems.map((item, index) => {
          if (item.name === BreadcrumbLoadingText) {
            somethingIsLoadingState++;
          }
          if (somethingIsLoadingState < 2) {
            return (
              <Breadcrumb.Item key={item.name}>
                {index === breadcrumbItems.length - 1 ? (
                  item.name
                ) : (
                  <Link to={item.link}>
                    {item.name === "Home" && <HomeOutlined style={{ marginRight: "4px" }} />}
                    {item.name}
                  </Link>
                )}
              </Breadcrumb.Item>
            );
          } else {
            return null;
          }
        })
      : null;
  }

  return (
    <BreadcrumbContext.Provider value={setBreadcrumbItems}>
      <div id="main-container" className="main-container">
        <div id="main-container-content" className="main-container__content">
          <Breadcrumb style={{ marginBottom: "24px", userSelect: "none" }}>
            {breadcrumbItems && (
              <>
                <Breadcrumb.Item key="Home">
                  <Link to={nameToPath["Home"]}>
                    <HomeOutlined style={{ marginRight: "4px" }} />
                    {selectedTeam ? selectedTeam.name : "(No team name)"}
                  </Link>
                </Breadcrumb.Item>
                {generateBreadcrumbs()}
              </>
            )}
          </Breadcrumb>
          <Switch>
            <Route exact path={"/"} render={() => <Redirect to={nameToPath["Home"]} />} />
            <Route path={nameToPath["Home"]} component={HomePage} />
            <Route exact path={nameToPath["Logs"]} component={LogsPage} />
            <Route exact path={`${nameToPath["Teams"]}/:id?`} component={TeamsPage} />
            <Route exact path={`${nameToPath["Applications"]}/:id?`} component={ApplicationsPage} />
            <Route exact path={nameToPath["Feedbacks"]} component={FeedbackPage} />
            <Route exact path={nameToPath["Monitor"]} component={MonitorPage} />
            <Route exact path={nameToPath["Services"]} component={Services} />
            <Route exact path={`${nameToPath["Services"]}/api/:id`} component={APIDetailsPage} />
            <Route exact path={`${nameToPath["Services"]}/api/:id/swaggerui`} component={SwaggerUIPage} />
            <Route exact path={`${nameToPath["Namepaces"]}`} component={NamespacePage} />
            <Route exact path={`${nameToPath["DataObjects"]}`} component={DataObjectsPage} />
            <Route
              exact
              path={`${nameToPath["Services"]}/api/:id${nameToPath["Request"]}`}
              component={CreateAPIRequest}
            />
            <Route exact path={`${nameToPath["Services"]}/event/:id`} component={EventDetailsPage} />
            <Route
              exact
              path={`${nameToPath["Services"]}/event/:id${nameToPath["Request"]}/subscribe`}
              component={CreateEventRequestSubscriber}
            />
            <Route
              exact
              path={`${nameToPath["Services"]}/event/:id${nameToPath["Request"]}/publish`}
              component={CreateEventRequestPublisher}
            />
            <Route exact path={nameToPath["Publishers/Subscribers"]} component={SubsciberPage} />
            <Route exact path={nameToPath["Profile"]} component={ProfilePage} />
            <Route path={nameToPath["Requests"]} component={RequestsPage} />
            <Route path={nameToPath["Approvals"]} component={RequestsPage} />
            <Route exact path={nameToPath["Inbox"]} component={InboxPage} />
            <Route exact path={nameToPath["Errors"]} component={ErrorsPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    </BreadcrumbContext.Provider>
  );
};

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Alert, Button, Tooltip } from "antd";
import { JsonReader } from "../fileArea/jsonReader";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { CheckCircleTwoTone, ExclamationCircleTwoTone, UnorderedListOutlined } from "@ant-design/icons";
import { COLOR_GREEN, COLOR_RED } from "../../lib/definitions/style_definitions";
import TextArea from "antd/lib/input/TextArea";

interface EditDataObjectProps {
  initialJson: object;
  errorMessage: string;
  onChangeToSchemaTab(): void;
  onValidExampleJsonChange(json: object): void;
}

export const EditDataObject: React.FC<EditDataObjectProps> = (props) => {
  const [editorValue, setEditorValue] = useState(JSON.stringify(props.initialJson, null, 4));
  const [isValid, setIsValid] = useState(true);

  const lastValidJson = useRef();

  const { onValidExampleJsonChange } = props;

  const isMounted = useRef(true);
  const hasMounted = useRef(false);

  const format = useCallback(() => {
    try {
      setEditorValue(JSON.stringify(JSON.parse(editorValue), null, 4));
    } catch {}
  }, [editorValue]);

  useEffect(() => {
    if (!hasMounted.current) {
      format();
      hasMounted.current = true;
    }
  }, [format]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    try {
      const parsedExample = JSON.parse(editorValue);
      if (JSON.stringify(parsedExample) === JSON.stringify(lastValidJson.current)) {
        return;
      }
      onValidExampleJsonChange(parsedExample);
      lastValidJson.current = parsedExample;

      setIsValid(true);
    } catch {
      setIsValid(false);
    }
  }, [editorValue, onValidExampleJsonChange]);

  return (
    <div>
      <div>
        <div style={{ marginBottom: "8px", display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={format} disabled={!isValid} style={{ marginRight: "8px" }}>
              Format
            </Button>
            <Tooltip title={isValid ? "Input is valid JSON" : "Input is invalid JSON"}>
              {isValid ? (
                <CheckCircleTwoTone twoToneColor={COLOR_GREEN} style={{ fontSize: "20px" }} />
              ) : (
                <ExclamationCircleTwoTone twoToneColor={COLOR_RED} style={{ fontSize: "20px" }} />
              )}
            </Tooltip>
          </div>
          <Button onClick={() => window.open(nameToPath["DataObjects"])} icon={<UnorderedListOutlined />}>
            Field List
          </Button>
        </div>
        {props.errorMessage && (
          <Alert
            style={{ marginBottom: "8px" }}
            message={
              <span>
                Example invalid according to schema: {props.errorMessage}. Fix example JSON or update your schema in the{" "}
                <span className="general__link" onClick={props.onChangeToSchemaTab}>
                  Schema section
                </span>
                .
              </span>
            }
            type="error"
            showIcon
          />
        )}
        <div
          style={{
            height: "500px",
          }}
        >
          <TextArea
            value={editorValue}
            placeholder={JSON.stringify({ someKey: "someValue..." }, null, 4)}
            onChange={(e) => setEditorValue(e.target.value)}
            className="general__code-text-area"
          />
        </div>
      </div>
      <JsonReader
        onFileSelect={(text) => {
          try {
            setEditorValue(JSON.stringify(JSON.parse(text), null, 4));
            setIsValid(true);
          } catch {
            setEditorValue(text);
            setIsValid(false);
          }
        }}
        style={{ marginTop: "12px" }}
        width="100%"
      />
    </div>
  );
};

import { SmileOutlined, AlertOutlined } from "@ant-design/icons";
import { Button, Card, Statistic } from "antd";
import React from "react";
import { DIDBResource } from "../../../lib/definitions/general_definitions";
import { doFetch, getNotificationSuccess, getModalError } from "../../../lib/functions/general_functions";

interface DeadLetterQueueProps {
  selectedContract: any;
  dlqMessages: number;
  setDlqMessages: (value: React.SetStateAction<number>) => void;
  isFetchingDlq: boolean;
  setIsFetchingDlq: (value: React.SetStateAction<boolean>) => void;
  dlqIsLive: boolean;
  isMounted: React.MutableRefObject<boolean>;
}

export const DeadLetterQueue: React.FC<DeadLetterQueueProps> = (props) => {
  return (
    <div>
      <Card
        bodyStyle={{ padding: "12px" }}
        style={{ margin: "auto", border: "1px solid #d9d9d9", borderRadius: "2px", background: "#fafafa" }}
      >
        {!props.dlqIsLive && (
          <div style={{ textAlign: "center" }}>
            Dead letter queue is offline. Activate it by clicking the 'Fix Queues' button above in the 'Queue status'
            segment. If no button appears, try refreshing the page.
          </div>
        )}
        {props.dlqIsLive && (
          <>
            <Statistic
              style={{ textAlign: "center", marginBottom: "20px" }}
              title="Messages in dead-letter queue"
              //@ts-ignore
              loading={props.isFetchingDlq}
              value={props.dlqMessages}
              valueStyle={props.dlqMessages <= 0 ? { color: "#26D62E" } : { color: "#FFE400" }}
              prefix={props.dlqMessages <= 0 ? <SmileOutlined /> : <AlertOutlined />}
              suffix="Messages"
            />
            <Button
              onClick={() =>
                doFetch(
                  "POST",
                  `${DIDBResource.DLQ}/${props.selectedContract._id}/retry`,
                  props.isMounted,
                  () => {
                    getNotificationSuccess("Sent", "Data Object");
                    props.setIsFetchingDlq(true);
                    doFetch(
                      "GET",
                      `${DIDBResource.DLQ}/${props.selectedContract._id}`,
                      props.isMounted,
                      (res) => props.setDlqMessages(JSON.parse(res.value.toString()).ApproximateNumberOfMessages),
                      () => getModalError("couldn't fetch dlq"),
                      () => props.setIsFetchingDlq(false)
                    );
                  },
                  getModalError("Sending to queue")
                )
              }
              style={{ marginRight: "8px" }}
            >
              Retry
            </Button>
            <Button
              onClick={() =>
                doFetch(
                  "POST",
                  `${DIDBResource.DLQ}/${props.selectedContract._id}/purge`,
                  props.isMounted,
                  () => {
                    getNotificationSuccess("Deleted", "Data Object");
                    props.setIsFetchingDlq(true);
                    doFetch(
                      "GET",
                      `${DIDBResource.DLQ}/${props.selectedContract._id}`,
                      props.isMounted,
                      (res) => props.setDlqMessages(JSON.parse(res.value.toString()).ApproximateNumberOfMessages),
                      () => getModalError("couldn't fetch dlq"),
                      () => props.setIsFetchingDlq(false)
                    );
                  },
                  getModalError("Purging queue")
                )
              }
            >
              Purge
            </Button>
          </>
        )}
      </Card>
    </div>
  );
};

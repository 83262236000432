import React from "react";
import { Form, Select } from "antd";
import { SLabel } from "./sLabel";

interface SFormTagsProps {
  label: string;
  field: string;
  optional?: boolean;
  disabled?: boolean;
  onChange?(value: string[]): void;
}

export const SFormTags = (props: SFormTagsProps) => {
  return (
    <>
      <SLabel label={props.label} required={!props.optional} />
      <Form.Item
        name={props.field}
        rules={[{ required: !props.optional }]}
        hasFeedback
        style={{ overflow: "hidden" }}
        className=""
      >
        <Select
          placeholder={`${props.label}...`}
          mode="tags"
          getPopupContainer={() => document.getElementById("main-container-content")!}
          open={false}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      </Form.Item>
      <div />
    </>
  );
};

import React, { useState, useEffect, useMemo, Key } from "react";
import { Select, Radio, Input, InputNumber } from "antd";
import { StringFormat, RuleDefinition } from "../../lib/definitions/services_definitions";
import { SSelectOption } from "../general/detailView/Elements/sElementSelect";
import { getSubSchemaFromRuleKey } from "../../lib/functions/services_functions";
import { isNumberOrBlank } from "../../lib/functions/general_functions";

interface RuleEditorProps {
  ruleKey: Key;
  schema: any;
  onRuleChange(rule: RuleDefinition): void;
  onCloseRuleEditor(): void;
  onValidityChange(isValid: boolean): void;
}

export const RuleEditor: React.FC<RuleEditorProps> = (props) => {
  const subSchema = useMemo(() => getSubSchemaFromRuleKey(props.ruleKey, props.schema), [props.ruleKey, props.schema]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {subSchema.type === "string" && (
        <StringRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
      {subSchema.type === "boolean" && (
        <BooleanRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
      {(subSchema.type === "number" || subSchema.type === "integer") && (
        <NumberRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
      {subSchema.type === "array" && subSchema.items.type === "string" && (
        <StringArrayRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
      {subSchema.type === "array" && (subSchema.items.type === "integer" || subSchema.items.type === "number") && (
        <NumberArrayRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
      {subSchema.type === "array" && subSchema.items.type === "boolean" && (
        <BooleanArrayRuleInput
          subSchema={subSchema}
          onRuleChange={props.onRuleChange}
          onValidityChange={props.onValidityChange}
        />
      )}
    </div>
  );
};

interface RuleInputProps {
  subSchema: any;
  onRuleChange(subRules: RuleDefinition): void;
  onValidityChange(isValid: boolean): void;
}

const formatRuleOptions = [
  { label: "Date-Time", value: "date-time" },
  { label: "Time", value: "time" },
  { label: "Date", value: "date" },
  { label: "Email", value: "email" },
  { label: "IDN Email", value: "idn-email" },
  { label: "Hostname", value: "hostname" },
  { label: "IDN Hostname", value: "idn-hostname" },
  { label: "IPv4", value: "ipv4" },
  { label: "IPv6", value: "ipv6" },
  { label: "URI", value: "uri" },
  { label: "URI Reference", value: "uri-reference" },
  { label: "IRI", value: "iri" },
  { label: "IRI Reference", value: "iri-reference" },
  { label: "URI Template", value: "uri-template" },
  { label: "JSON Pointer", value: "json-pointer" },
  { label: "Relative JSON Pointer", value: "relative-json-pointer" },
].sort((a, b) => a.label.localeCompare(b.label)) as SSelectOption[];

type StringInputType = "String" | "Regex" | "Format";

function StringRuleInput(props: RuleInputProps) {
  let initialSelectedType: StringInputType = "String";
  if (props.subSchema.pattern) {
    initialSelectedType = "Regex";
  } else if (props.subSchema.format) {
    initialSelectedType = "Format";
  }

  const [selectedType, setSelectedType] = useState<StringInputType>(initialSelectedType);

  return (
    <>
      <div>
        <h4>Rule type</h4>
        <Radio.Group
          options={["String", "Regex", "Format"]}
          onChange={(event: any) => {
            setSelectedType(event.target.value);
            props.onValidityChange(false);
          }}
          value={selectedType}
          optionType="button"
          style={{ marginBottom: "16px" }}
        />
      </div>
      <h4>
        {selectedType === "String"
          ? "Value matches one of..."
          : selectedType === "Regex"
          ? "Value matches regular expression..."
          : "Value is in the format..."}
      </h4>
      {selectedType === "String" && (
        <Select
          mode="tags"
          dropdownClassName="services__hide-dropdown"
          defaultValue={props.subSchema.enum}
          onChange={(vals) => {
            props.onRuleChange({ enum: vals as string[] });
            props.onValidityChange((vals as string[]).length > 0);
          }}
          placeholder="Enter strings..."
          getPopupContainer={() => document.getElementById("main-container-content")!}
        />
      )}
      {selectedType === "Regex" && (
        <Input
          placeholder="Enter regular expression..."
          defaultValue={props.subSchema.pattern}
          onChange={(e) => {
            props.onRuleChange({ pattern: e.target.value });
            props.onValidityChange(!!e.target.value);
          }}
        />
      )}
      {selectedType === "Format" && (
        <Select
          defaultValue={props.subSchema.format}
          options={formatRuleOptions}
          placeholder="Select format..."
          onChange={(e: StringFormat) => {
            props.onRuleChange({ format: e as StringFormat });
            props.onValidityChange(true);
          }}
          getPopupContainer={() => document.getElementById("main-container-content")!}
        />
      )}
    </>
  );
}

function BooleanRuleInput(props: RuleInputProps) {
  return (
    <>
      <h4>Value is...</h4>
      <Radio.Group
        defaultValue={!!props.subSchema.boolean}
        onChange={(e) => {
          props.onRuleChange({
            boolean: e.target.value === "true",
          });
          props.onValidityChange(true);
        }}
        style={{ userSelect: "none" }}
      >
        <Radio.Button value="true">true</Radio.Button>
        <Radio.Button value="false">false</Radio.Button>
      </Radio.Group>
    </>
  );
}

type NumberInputType = "Specific" | "Range";

function NumberRuleInput(props: RuleInputProps) {
  let initialSelectedType: NumberInputType = "Specific";
  if (props.subSchema.minimum || props.subSchema.maximum) {
    initialSelectedType = "Range";
  }
  const [selectedType, setSelectedType] = useState<NumberInputType>(initialSelectedType);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [minHasInput, setMinHasInput] = useState(false);
  const [maxHasInput, setMaxHasInput] = useState(false);

  const { onValidityChange } = props;

  useEffect(() => {
    onValidityChange(minHasInput || maxHasInput || tagsAreValid(tags));
  }, [onValidityChange, minHasInput, maxHasInput, tags]);

  function tagsAreValid(tags: string[]) {
    for (let i = 0; i < tags.length; i++) {
      if (!isNumberOrBlank(tags[i])) {
        return false;
      }
    }

    return true;
  }

  return (
    <>
      <h4>Rule type</h4>
      <Radio.Group
        options={["Specific", "Range"]}
        onChange={(event: any) => {
          setSelectedType(event.target.value);
          props.onValidityChange(false);
        }}
        value={selectedType}
        optionType="button"
        style={{ marginBottom: "16px" }}
      />
      <h4>{selectedType === "Specific" ? "Value matches one of..." : "Value is in the range..."}</h4>
      {selectedType === "Specific" && (
        <Select
          mode="tags"
          defaultValue={props.subSchema.enum}
          dropdownClassName="services__hide-dropdown"
          onSearch={(v) => setInputValue(v)}
          searchValue={inputValue}
          onChange={(vals) => {
            setTags(vals);
            setInputValue("");
            props.onRuleChange({ enum: vals as number[] });
            props.onValidityChange((vals as number[]).length > 0);
          }}
          open
          getPopupContainer={() => document.getElementById("main-container-content")!}
          placeholder="Enter numbers..."
        />
      )}
      {selectedType === "Range" && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr min-content 1fr",
            columnGap: "8px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InputNumber
            defaultValue={props.subSchema.minimum}
            placeholder="Min"
            style={{ width: "100%" }}
            type="number"
            onChange={(num) => {
              const number = Number.parseFloat(num + "");
              if (!Number.isNaN(number)) {
                props.onRuleChange({ minimum: number });
              }
              setMinHasInput(!Number.isNaN(number));
            }}
          />
          <b style={{ whiteSpace: "nowrap" }}>≤ value ≤</b>
          <InputNumber
            defaultValue={props.subSchema.maximum}
            placeholder="Max"
            type="number"
            style={{ width: "100%" }}
            onChange={(num) => {
              const number = Number.parseInt(num + "");
              if (!Number.isNaN(number)) {
                props.onRuleChange({ maximum: number });
              }
              setMaxHasInput(!Number.isNaN(number));
            }}
          />
        </div>
      )}
    </>
  );
}

function StringArrayRuleInput(props: RuleInputProps) {
  return (
    <>
      <h4>Array contains one of...</h4>
      <Select
        mode="tags"
        defaultValue={props.subSchema.contains?.enum}
        dropdownClassName="services__hide-dropdown"
        onChange={(vals) => {
          props.onRuleChange({ contains: { enum: vals } });
          props.onValidityChange((vals as string[]).length > 0);
        }}
        placeholder="Enter strings..."
        getPopupContainer={() => document.getElementById("main-container-content")!}
      />
    </>
  );
}

function NumberArrayRuleInput(props: RuleInputProps) {
  const [inputValue, setInputValue] = useState("");
  return (
    <>
      <h4>Array contains one of...</h4>
      <Select
        mode="tags"
        defaultValue={props.subSchema.contains?.enum}
        onSearch={(v) => {
          if (isNumberOrBlank(v)) {
            setInputValue(v);
          }
        }}
        searchValue={inputValue}
        onChange={(vals) => {
          setInputValue("");
          props.onRuleChange({ contains: { enum: vals } });
          props.onValidityChange((vals as number[]).length > 0);
        }}
        placeholder="Enter strings..."
        dropdownClassName="services__hide-dropdown"
        getPopupContainer={() => document.getElementById("main-container-content")!}
      />
    </>
  );
}

function BooleanArrayRuleInput(props: RuleInputProps) {
  return (
    <>
      <h4>Array contains one of...</h4>
      <Select
        mode="tags"
        defaultValue={props.subSchema.contains?.enum}
        dropdownClassName="services__hide-dropdown"
        onChange={(vals) => {
          props.onRuleChange({ contains: { enum: vals } });
          props.onValidityChange((vals as boolean[]).length > 0);
        }}
        placeholder="Enter strings..."
        getPopupContainer={() => document.getElementById("main-container-content")!}
      />
    </>
  );
}

export enum ServiceType {
  API = "API",
  Event = "Event",
}

export enum EventDirection {
  In = "in",
  Out = "out",
  None = "none",
}

export enum ServiceVisibility {
  Public = "public",
  LEGO = "lego",
  Team = "team",
}

export interface IPEnv {
  env: string;
}

export interface IPTag {
  tags: string[];
}

export interface Team {
  description: string | undefined;
  _id: string;
  name: string;
  azureAdGroupId: string;
  endpoints: string[];
  tykUserGroups: undefined | { [k in string]: string };
}

export interface Application extends IPEnv, IPTag {
  _id: string;
  name: string;
  teamId: string;
  description: string;
  leanIXId?: string;
  azureAdId?: string;
  apiKeyId: boolean;
}

export interface ServiceAPI extends IPEnv, IPTag {
  _id: string;
  applicationName: string;
  name: string;
  visibility: ServiceVisibility;
  applicationId: string;
  targetApiGatewayId: string;
  namespaceId: string;
  description: string;
  targetUrl: string;
  teamId: string;
  teamName: string;
  serviceType?: string;
  listenPath?: string;
  definitionFile?: string;
  tykApiId?: string;
}

export interface ServiceEvent extends IPEnv, IPTag {
  _id: string;
  applicationName: string;
  name: string;
  visibility: ServiceVisibility;
  direction: EventDirection;
  applicationId: string;
  description: string;
  example: object;
  schema: object;
  routingKey: string;
  teamId: string;
  teamName: string;
  serviceType?: string;
}

export type Service = ServiceAPI | ServiceEvent;

export interface Namespace extends IPEnv, IPTag {
  _id: string;
  name: string;
  teamId: string;
  dnsId: string;
  description: string;
  env: string;
  tags: Array<string>;
  status: boolean;
}

export interface DNS extends IPEnv, IPTag {
  _id: string;
  name: string;
  teamId: string;
  description: string;
  visible: boolean;
}

export interface DataObject {}

export interface Environment {
  _id: string;
  name: string;
  description: string;
  readOnly: boolean;
  tyk: {
    url: string;
    ssoUrl: string;
    orgId: string;
  };
  stageIndex: number;
  colorCode: string;
}

export interface ApplicationExpanded extends Application {
  services: Service[];
}

export interface TeamExpanded extends Team {
  azureAdGroupId: string;
  applications: ApplicationExpanded[];
  dns: DNS[];
  namespaces: Namespace[];
  dataObjects: DataObject[];
  unallocatedEntities: {
    [k in string]: UnallocatedEntity[];
  };
}

export interface Target extends IPTag {
  _id: string;
  type: ServiceType;
  name: string;
  description: string;
  notes: string;
}

export interface MeData {
  isAdmin: boolean;
  tykUserGroupId: string;
}

export interface UnallocatedEntity {
  _id: string;
  name: string;
  origin: "AWS" | "Tyk";
  type: "API";
  entity: any;
}

export interface DIDBError {
  code: string;
  message: string;
  requestId: string;
}

export interface RegexType {
  regex: RegExp;
  category: "camelCase" | "PascalCase" | "Email" | "URL" | "lowercase";
}

export interface Message {
  _id: string;
  timestamp: number;
  read: boolean;
  teamId: string;
  type: string;
  message: string;
  extra: object;
}

export interface MessageWTeamName extends Message {
  teamName: string;
}

export interface LogsChartDataPoint {
  fromTimestamp: number;
  toTimestamp: number;
  dateAsString: string;
  Published: number;
  Dequeued: number;
  publishedLogs: any[];
  dequeuedLogs: any[];
}

import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Modal, Select } from "antd";
import { getProp } from "../../../../lib/functions/general_functions";
import { getLineWidthSpan } from "../../../../lib/functions/sDetail_functions";
import { SViewContext } from "../../../../lib/contexts";
import { PlusOutlined } from "@ant-design/icons";

export interface SSelectOption {
  label: string;
  value: string | number;
  title?: string;
  disabled?: boolean;
}

interface SElementProps {
  label: string;
  options: SSelectOption[];
  field?: string;
  lineWidth?: "default" | "double" | "triple" | "wholeline";
  value?: string | number | string[] | number[];
  editonly?: boolean;
  readonly?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  onChange?(event: any): any;
  onNewForm?(closeModal: () => void): React.ReactNode;
}

export const SElementSelect = (props: SElementProps) => {
  const [value, setValue] = useState<string | number | string[] | number[]>();
  const [selectedLabel, setSelectedLabel] = useState<any>("");
  const [visible, setVisible] = useState(false);
  const { object, isEditing } = useContext(SViewContext);

  // Will find the corresponding label for the currently selected value to display
  useEffect(() => {
    if (value)
      setSelectedLabel(() => {
        for (let i = 0; i < props.options.length; i++) {
          if (props.options[i].value === value) {
            if (props.options[i].label) return props.options[i].label;
            return props.options[i].value;
          }
        }
        return "";
      });
  }, [props.options, props.field, value]);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    } else {
      setValue(getProp(object, props.field + ""));
    }
  }, [object, props.field, isEditing, props.value]);

  const displayView = (
    <span className="sdetail-element__container" style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      <p className="sdetail-element__value">{selectedLabel}</p>
    </span>
  );

  function handleOnChange(value: any) {
    if (props.onChange) props.onChange(value);
  }

  const updateableView = (
    <span className={"sdetail-element__container"} style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      <Form.Item name={props.field} initialValue={value} noStyle>
        <Select
          className="sdetail-element__value"
          style={{ padding: 0 }}
          value={value}
          disabled={props.readonly || props.disabled}
          options={props.options}
          mode={props.multiple ? "multiple" : undefined}
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
          allowClear
          onChange={handleOnChange}
        ></Select>
      </Form.Item>
    </span>
  );

  if (props.editonly || isEditing)
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {updateableView}
        {props.onNewForm ? (
          <>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setVisible(true)}
              style={{ marginLeft: "8px", width: "35px" }}
            />
            <Modal
              visible={visible}
              footer={null}
              onCancel={() => setVisible(false)}
              className="sform__modal"
              destroyOnClose
            >
              {props.onNewForm(() => setVisible(false))}
            </Modal>
          </>
        ) : (
          <div />
        )}
      </div>
    );
  return displayView;
};

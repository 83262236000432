import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { SLabel } from "./sLabel";

interface SFormFilesProps {
  label: string;
  disabled?: boolean;
}

function SFormFilesPre(props: SFormFilesProps, ref: any) {
  const [files, setFiles] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    getFiles: () => files,
    setFiles: setFiles,
  }));

  return (
    <>
      <SLabel label={props.label} />
      <div style={{ marginBottom: "24px" }}>
        <Upload
          name="file"
          multiple
          beforeUpload={(_, newFiles: any) => {
            const newFilesNames = newFiles.map((file: any) => file.name);
            const union = files.filter((existingFile) => !newFilesNames.includes(existingFile.name)).concat(newFiles);

            setFiles(union);
            return false;
          }}
          onRemove={(file) =>
            setFiles((files: any[]) => files.filter((existingFile) => existingFile.name !== file.name))
          }
          fileList={files}
        >
          <Button icon={<UploadOutlined />} disabled={props.disabled}>
            Attach files
          </Button>
        </Upload>
      </div>
      <div />
    </>
  );
}

export const SFormFiles = forwardRef(SFormFilesPre);

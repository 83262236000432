import React, { useState, useEffect, useRef, useContext } from "react";
import { doFetch, getModalError, useSHistory } from "../lib/functions/general_functions";
import { DIDBResource, nameToPath, getPageBreadcrumb } from "../lib/definitions/general_definitions";
import { useParams } from "react-router-dom";
import { BreadcrumbContext } from "../lib/contexts";
import Search from "antd/lib/input/Search";
import { Team } from "../lib/definitions/models";
import { Spinner } from "../components/general/spinner";
import { Card, Divider } from "antd";
import { SView } from "../components/general/detailView/sView";
import { SElementInput } from "../components/general/detailView/Elements/sElementInput";
import { SSectionCustom } from "../components/general/detailView/sSectionCustom";

export const TeamsPage = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [searchValue, setSearchValue] = useState("");
  const [isFetchingTeams, setIsFetchingTeams] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [isFetchingMembers, setIsFetchingMembers] = useState(false);
  const history = useSHistory();
  const { id } = useParams<{ id: string }>();
  const setBreadcrumbs = useContext(BreadcrumbContext);

  const isMounted = useRef(true);
  useEffect(() => {
    fetchTeams();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => setBreadcrumbs([getPageBreadcrumb("Teams")]), [setBreadcrumbs]);

  useEffect(() => {
    if (!id) {
      return;
    }
    setSelectedTeam(teams.find((team) => team._id === id));
  }, [id, teams]);

  useEffect(() => {
    setFilteredTeams(teams.filter((team) => team.name.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, teams]);

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }
    setIsFetchingMembers(true);
    doFetch(
      "GET",
      `${DIDBResource.AuxMembers}/${selectedTeam?._id}`,
      isMounted,
      (response) => setMembers(response.value.sort((a: any, b: any) => (a.name || "").localeCompare(b.name))),
      getModalError("Fetching team members information"),
      () => setIsFetchingMembers(false),
      undefined,
      undefined,
      true
    );
  }, [selectedTeam]);

  function fetchTeams() {
    setIsFetchingTeams(true);
    doFetch(
      "GET",
      DIDBResource.Teams,
      isMounted,
      (response) => setTeams(response.value.sort((a: Team, b: Team) => a.name.localeCompare(b.name))),
      getModalError("Fetching teams"),
      () => setIsFetchingTeams(false)
    );
  }
  return (
    <div
      style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "48px", marginTop: "48px" }}
    >
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Search
            placeholder="Search in teams..."
            style={{ width: "400px" }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <Divider style={{ marginBottom: 0 }} />
        {isFetchingTeams ? (
          <Spinner margin={200} />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "24px", marginTop: "24px" }}>
              {filteredTeams.map((team) => (
                <Card
                  key={team.name}
                  onClick={() => history.push(`${nameToPath["Teams"]}/${team._id}`)}
                  hoverable
                  className="general__card"
                >
                  {team.name}
                </Card>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedTeam && (
        <SView title={selectedTeam.name} onBack={undefined}>
          <SSectionCustom title="General">
            <SElementInput label="ID" value={id} readonly />
            {isFetchingTeams ? (
              "Loading..."
            ) : (
              <SElementInput label="Description" value={selectedTeam?.description} readonly fieldType="textarea" />
            )}
          </SSectionCustom>
          <SSectionCustom title="Members">
            {isFetchingMembers ? (
              "Loading..."
            ) : (
              <div style={{ display: "grid", gridAutoRows: "auto", rowGap: "8px" }}>
                {members.length === 0
                  ? "No members"
                  : members.map((member) => (
                      <div key={JSON.stringify(member)} style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{member.name}</div>
                        <div>{member.email}</div>
                      </div>
                    ))}
              </div>
            )}
          </SSectionCustom>
        </SView>
      )}
    </div>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { EditModeContext, SViewContext } from "../../../lib/contexts";
import { getModalWarning, setProp } from "../../../lib/functions/general_functions";
import Form, { FormInstance } from "antd/lib/form";
import { Button, PageHeader, Tooltip } from "antd";

interface sDetailViewProps {
  children?: React.ReactNode;
  title: string;
  form?: FormInstance<any>;
  object?: any;
  subTitle?: string | number;
  extra?: React.ReactNode;
  wide?: boolean;
  errorMessage?: string;
  onBack: undefined | (() => void);
  onSave?(object: any): Promise<boolean>;
  onDelete?(): Promise<void>;
  onEditMode?(state: boolean): void;
}

export const SView = (props: sDetailViewProps) => {
  const [object, setObject] = useState(props.object);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [form] = Form.useForm(props.form);
  const isMounted = useRef(true);
  const setInEditMode = useContext(EditModeContext).setInEditMode;

  useEffect(() => {
    return () => {
      isMounted.current = false;
      setInEditMode(false);
    };
  }, [setInEditMode]);

  useEffect(() => {
    setObject(props.object);
  }, [props.object]);

  const { onEditMode } = props;

  useEffect(() => {
    if (onEditMode) {
      onEditMode(isEditing);
    }
  }, [isEditing, onEditMode]);

  const HeaderButtons = () => {
    if (props.onSave) {
      if (isEditing)
        return (
          <Form.Item style={{ margin: 0 }}>
            <Button onClick={onEditCancel} danger style={{ marginRight: "4px" }} disabled={isSaving}>
              Cancel
            </Button>
            <Tooltip title={props.errorMessage}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "4px" }}
                loading={isSaving}
                disabled={!!props.errorMessage}
              >
                Save changes
              </Button>
            </Tooltip>
          </Form.Item>
        );
      return (
        <div>
          {props.extra}
          {props.onDelete && (
            <Button
              icon={<DeleteFilled />}
              type="primary"
              onClick={() =>
                getModalWarning("Deleting Object")("Are you sure you want to delete this?", deleteObject, {
                  okText: "Delete",
                  okType: "primary",
                  okButtonProps: { danger: true, id: "entity-delete-model-delete-button" },
                })
              }
              style={{ marginLeft: "8px" }}
              loading={isDeleting}
              danger
              data-cy="delete-entity"
            >
              Delete
            </Button>
          )}

          <Button
            id="sview-edit"
            icon={<EditFilled />}
            type="primary"
            onClick={() => setIsEditing(true)}
            style={{ marginLeft: "8px" }}
          >
            Edit
          </Button>
        </div>
      );
    }
    return <div>{props.extra}</div>;
  };

  function setDataChanged() {
    if (isEditing) setInEditMode(true);
  }

  async function saveChanges(formObject: any) {
    if (!props.onSave) return;
    setIsSaving(true);

    const newFormObject = {};

    for (const prop in formObject) {
      setProp(newFormObject, prop, formObject[prop]);
    }

    const updatedObject = { ...object, ...newFormObject };
    setObject(updatedObject);
    const success = await props.onSave(updatedObject);

    if (isMounted.current) setIsSaving(false);
    if (isMounted.current && success) setIsEditing(false);
  }

  async function deleteObject() {
    if (!props.onDelete) return;
    setIsDeleting(true);
    await props.onDelete();
    if (isMounted.current) {
      setIsDeleting(false);
      setIsEditing(false);
    }
  }

  function onEditCancel() {
    form.resetFields();
    setIsEditing(false);
  }

  return (
    <SViewContext.Provider value={{ object, isEditing }}>
      <Form form={form} onFinish={saveChanges} onFieldsChange={setDataChanged}>
        <PageHeader
          title={props.title}
          subTitle={props.subTitle}
          onBack={props.onBack}
          className="sdetail-view__page-content"
          style={props.wide ? { width: "1350px" } : { width: "1000px" }}
          extra={HeaderButtons()}
        >
          {props.children}
        </PageHeader>
      </Form>
    </SViewContext.Provider>
  );
};

import React from "react";
import Title from "antd/lib/typography/Title";

interface SDetailSectionProps {
  children?: React.ReactNode;
  columns?: "double" | "triple" | "quadrouple" | "adaptive";
  title: string;
}

// This component is used for a section full of fields. It should only contain
// SDetailElementField components as children.
export const SSection = (props: SDetailSectionProps) => {
  function getColumns() {
    switch (props.columns) {
      case "double":
        return { gridTemplateColumns: "1fr 1fr" };

      case "triple":
        return { gridTemplateColumns: "1fr 1fr 1fr" };

      case "quadrouple":
        return { gridTemplateColumns: "1fr 1fr 1fr 1fr" };

      case "adaptive":
        return { gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))" };

      case undefined:
        return { gridTemplateColumns: "1fr 1fr" };
    }
  }

  return (
    <div className="sdetail-section__container">
      <Title level={4}>{props.title}</Title>
      <div className={"sdetail-section__grid"} style={getColumns()}>
        {props.children}
      </div>
    </div>
  );
};

import React from "react";
import { message } from "antd";
import { CopyTwoTone } from "@ant-design/icons";

interface SJsonProps {
  json: any;
  extraCode?: any;
}

export const SJson = (props: SJsonProps) => {
  function getJson() {
    return (props.extraCode ? props.extraCode : "") + JSON.stringify(props.json, null, 2);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: "#f1f1f1",
        padding: "16px",
      }}
    >
      <pre style={{ margin: 0 }}>{getJson()}</pre>

      <CopyTwoTone
        style={{ fontSize: "24px" }}
        onClick={() => {
          navigator.clipboard.writeText(getJson());
          message.success("Copied!", 1.5);
        }}
      />
    </div>
  );
};

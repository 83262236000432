import React, { Key, useMemo, useState } from "react";

import { getProp } from "../../lib/functions/general_functions";
import { JsonAsTree } from "../general/jsonAsTree";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Empty, List, Modal } from "antd";

import { RuleEditor } from "../../components/createRequest/ruleEditor";
import {
  clearRulesForRuleKey,
  getRegisteredRuleKeys,
  getSubSchemaFromRuleKey,
  updateRuleSchema,
} from "../../lib/functions/services_functions";
import { getRuleDescription } from "../../lib/functions/createService_functions";

interface RuleSchemaEditorProps {
  maskedTypeJson: any;
  ruleSchema: any;
  setRuleSchema?(ruleSchema: any): void;
}

export function RuleSchemaEditor(props: RuleSchemaEditorProps) {
  const [tempRuleSchema, setTempRuleSchema] = useState<any>();
  const [currentRuleKey, setCurrentRuleKey] = useState<Key | "">("");
  const [showCreateRuleModal, setShowCreateRuleModal] = useState(false);
  const [isValidRule, setIsValidRule] = useState(false);

  function addRule() {
    if (props.setRuleSchema) {
      props.setRuleSchema(tempRuleSchema);
    }
  }

  function editRule(ruleKey: Key) {
    setCurrentRuleKey(ruleKey);
    setShowCreateRuleModal(true);
  }

  function clearRule(ruleKey: Key) {
    if (props.setRuleSchema) {
      props.setRuleSchema(clearRulesForRuleKey(ruleKey, props.ruleSchema));
    }
  }

  function closeRuleEditorModal() {
    setShowCreateRuleModal(false);
    setCurrentRuleKey("");
    setIsValidRule(false);
  }

  function atLeastOneRule() {
    return true;
  }

  const registeredRuleKeys = useMemo(() => getRegisteredRuleKeys(props.maskedTypeJson, props.ruleSchema), [
    props.maskedTypeJson,
    props.ruleSchema,
  ]);

  return (
    <div style={{ overflowX: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ marginBottom: "24px", width: "100%", display: "flex", flexDirection: "column" }}>
        {props.setRuleSchema && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "18px",
              marginLeft: "16px",
            }}
          >
            <span style={{ display: "block" }}>
              <span>
                <b style={{ marginRight: "12px" }}>Routing keys</b>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setShowCreateRuleModal(true)}>
                  New Rule
                </Button>
              </span>

              <p style={{ fontSize: "14px" }}>
                Create conditional rules on the routings keys to filter away unwanted data
              </p>
            </span>
          </div>
        )}
        <List style={{ margin: "0 16px 0 16px", overflow: "auto", maxHeight: "400px" }}>
          {registeredRuleKeys.length === 0
            ? "No rules exists on this contract..."
            : registeredRuleKeys.map((ruleKey) => (
                <List.Item
                  key={ruleKey}
                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                  actions={[
                    <Button key="edit" type="link" style={{ padding: 0, border: 0 }} onClick={() => editRule(ruleKey)}>
                      Edit
                    </Button>,
                    <Button
                      key="remove"
                      type="link"
                      danger
                      style={{ padding: 0, border: 0 }}
                      onClick={() => clearRule(ruleKey)}
                    >
                      Remove
                    </Button>,
                  ]}
                  className="sdetail-files__list-item"
                >
                  <List.Item.Meta
                    title={ruleKey}
                    className="sdetail-files__list-item-meta"
                    description={getRuleDescription(getSubSchemaFromRuleKey(ruleKey, props.ruleSchema))}
                  />
                </List.Item>
              ))}
        </List>
        {!atLeastOneRule() && (
          <div style={{ margin: "0 16px 0 16px", background: "#f3f3f3" }}>
            <Empty
              description="No rules"
              style={{ padding: "16px", marginBottom: "16px" }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
        <Collapse style={{ margin: "16px 16px 0 16px", background: "#f3f3f3" }} bordered={false}>
          <Collapse.Panel header="Show Schema" key="1" style={{ border: 0 }}>
            <pre style={{ padding: "16px", minWidth: "300px" }}>
              <code>{JSON.stringify(props.ruleSchema, null, 4)}</code>
            </pre>
          </Collapse.Panel>
        </Collapse>
      </div>

      <Modal
        visible={showCreateRuleModal}
        title="Create Rule"
        onOk={console.log}
        onCancel={closeRuleEditorModal}
        footer={[
          <Button key="back" onClick={closeRuleEditorModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              addRule();
              closeRuleEditorModal();
            }}
            disabled={!isValidRule}
          >
            Add rule
          </Button>,
        ]}
        width={700}
      >
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "50px" }}>
          <div>
            <h4>Routing Keys</h4>
            <JsonAsTree
              json={props.maskedTypeJson}
              onClick={(node) => {
                const val = getProp(props.maskedTypeJson, node.key);
                if (typeof val !== "object" || (val.___arrayType && val.___arrayType !== "object")) {
                  setCurrentRuleKey(node.key);
                }
              }}
              selectedKey={currentRuleKey}
              isTypes
            />
          </div>

          <div>
            {!currentRuleKey ? (
              "Click on a routing key to create a rule"
            ) : (
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "min-content min-content auto",
                    columnGap: "10px",
                    alignItems: "center",
                    rowGap: "4px",
                  }}
                ></div>
                {currentRuleKey && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto min-content",
                      gridColumnGap: "8px",
                      gridRowGap: "12px",
                      marginTop: "8px",
                    }}
                  >
                    <RuleEditor
                      ruleKey={currentRuleKey}
                      schema={props.ruleSchema}
                      onRuleChange={(rule) =>
                        setTempRuleSchema(updateRuleSchema(currentRuleKey, props.ruleSchema, rule))
                      }
                      onCloseRuleEditor={() => setCurrentRuleKey("")}
                      onValidityChange={setIsValidRule}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

import React, { useRef, useEffect, useContext, useState } from "react";
import { Button, Select, Form } from "antd";
import {
  getModalWarning,
  doFetch,
  getModalError,
  getNotificationSuccess,
  getNotificationError,
} from "../../lib/functions/general_functions";
import { DIDBResource, DocumentType, nameToPath } from "../../lib/definitions/general_definitions";
import { EnvironmentContext, MyTeamsContext } from "../../lib/contexts";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { SwapOutlined } from "@ant-design/icons";
import { SSelectOption } from "../general/detailView/Elements/sElementSelect";
import { ApplicationExpanded, DNS } from "../../lib/definitions/models";

interface ReassignButtonProps {
  entityId: string;
  parentId: string | undefined;
  type: DocumentType;
}

export const ReassignButton: React.FC<ReassignButtonProps> = (props) => {
  const [ownOptions, setOwnOptions] = useState<SSelectOption[]>([]);
  const [otherOptions, setOtherOptions] = useState<SSelectOption[]>([]);
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);

  const { selectedEnvironment } = useContext(EnvironmentContext);
  const { myTeams, refreshMyTeams } = useContext(MyTeamsContext);

  const isMounted = useRef(true);

  const [form] = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.type === "Application" || props.type === "DNS") {
      setOwnOptions(
        myTeams
          .map((team) => ({ label: team.name, value: team._id, disabled: props.parentId === team._id }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } else if (props.type === "API" || props.type === "Event") {
      setOwnOptions(
        myTeams
          .reduce((applications, team) => applications.concat(team.applications), [] as ApplicationExpanded[])
          .filter((application) => application.env === selectedEnvironment?._id)
          .map((application: ApplicationExpanded) => ({
            label: application.name,
            value: application._id,
            disabled: props.parentId === application._id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } else if (props.type === "Namespace") {
      setOwnOptions(
        myTeams
          .reduce((dnss, team) => dnss.concat(team.dns), [] as DNS[])
          .filter((dns) => dns.env === selectedEnvironment?._id)
          .map((dns: DNS) => ({
            label: dns.name,
            value: dns._id,
            disabled: props.parentId === dns._id,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [props.parentId, props.type, myTeams, selectedEnvironment]);

  useEffect(() => {
    let resource = getParentResourceFromType(props.type);

    if (resource) {
      if (resource !== DIDBResource.Teams) {
        resource += `?env=${selectedEnvironment?._id}`;
      }

      doFetch(
        "GET",
        resource,
        isMounted,
        (response) => {
          setOtherOptions(
            response.value
              .map((something: any) => ({ label: something.name, value: something._id }))
              .filter((otherOpt: any) => !ownOptions.find((ownOpt) => otherOpt.value === ownOpt.value))
              .sort((a: any, b: any) => a.label.localeCompare(b.label))
          );
        },
        () => getNotificationError("Fetching resources", "An error occured while fetching other resources"),
        () => setIsFetchingOptions(false)
      );
    } else {
      setIsFetchingOptions(false);
    }
  }, [props.type, selectedEnvironment, ownOptions]);

  const formattedType = props.type === "API" || props.type === "DNS" ? props.type : props.type.toLowerCase();

  const entitySelect = (
    <Form form={form} style={{ marginTop: "24px" }}>
      <h3>To own resource</h3>
      <Form.Item name="ownResource">
        <Select
          placeholder="Select own resource..."
          options={ownOptions}
          style={{ width: "100%" }}
          showSearch
          onChange={() => form.setFieldsValue({ otherResource: undefined })}
          filterOption={(input, option) => !!option?.label?.toString().toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
      <h3>To other resource</h3>
      <Form.Item name="otherResource">
        <Select
          placeholder="Select other resource..."
          options={otherOptions}
          style={{ width: "100%" }}
          showSearch
          onChange={() => form.setFieldsValue({ ownResource: undefined })}
          filterOption={(input, option) => !!option?.label?.toString().toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
    </Form>
  );

  return (
    <Button
      onClick={() => {
        getModalWarning(`Reassigning ${formattedType}`)(
          entitySelect,
          (close) =>
            doFetch(
              "PUT",
              `${getResourceFromType(props.type)}/${props.entityId}`,
              isMounted,
              () => {
                getNotificationSuccess("Reassigned", props.type);
                refreshMyTeams(getPathFromType(props.type, !!form.getFieldValue("otherResource")));
                close();
              },
              getModalError(`Reassigning ${formattedType}`),
              undefined,
              getBody(form, props.type)
            ),
          { okText: "Reassign" }
        );
      }}
      icon={<SwapOutlined />}
      style={{ marginLeft: "8px" }}
      disabled={isFetchingOptions}
    >
      Reassign
    </Button>
  );
};

function getParentResourceFromType(type: DocumentType) {
  if (type === "Application" || type === "DNS") {
    return DIDBResource.Teams;
  } else if (["API", "Event"].includes(type)) {
    return DIDBResource.Applications;
  } else if (type === "Namespace") {
    return DIDBResource.DNS;
  }

  return "";
}

function getResourceFromType(type: DocumentType) {
  if (type === "Application") {
    return DIDBResource.Applications;
  } else if (type === "DNS") {
    return DIDBResource.DNS;
  } else if (type === "API") {
    return DIDBResource.APIs;
  } else if (type === "Event") {
    return DIDBResource.Events;
  } else if (type === "Namespace") {
    return DIDBResource.Namespaces;
  }

  return "";
}

function getBody(form: FormInstance<any>, type: DocumentType) {
  let newParentId = form.getFieldValue("ownResource") || form.getFieldValue("otherResource");

  if (type === "Application" || type === "DNS") {
    return { teamId: newParentId };
  } else if (type === "API" || type === "Event") {
    return { applicationId: newParentId };
  } else if (type === "Namespace") {
    return { dnsId: newParentId };
  }

  return {};
}

function getPathFromType(type: DocumentType, toOther: boolean) {
  if (type === "Application") {
    return nameToPath["HomeApplications"];
  } else if (type === "DNS") {
    return nameToPath["HomeDNS"];
  }

  if (toOther) {
    if (type === "API") {
      return nameToPath["HomeAPIs"];
    } else if (type === "Event") {
      return nameToPath["HomeEvents"];
    } else if (type === "Namespace") {
      return nameToPath["HomeNamespaces"];
    }

    return nameToPath["Home"];
  }

  return undefined;
}

import React from "react";
import { Tag } from "antd";
import { ServiceType } from "../../lib/definitions/models";
import { COLOR_GREEN_TAG, COLOR_BLUE_TAG } from "../../lib/definitions/style_definitions";

interface ServiceTypeTagProps {
  type: string;
  large?: boolean;
}

export const ServiceTypeTag: React.FC<ServiceTypeTagProps> = (props) => {
  const color = props.type === ServiceType.API ? COLOR_GREEN_TAG : props.type === ServiceType.Event ? COLOR_BLUE_TAG : undefined;

  return (
    <Tag color={color} className={"services__type-tag" + (props.large ? " services__type-tag--large" : "")}>
      {color ? props.type.toLocaleUpperCase() : "N/A"}
    </Tag>
  );
};

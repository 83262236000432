import React from "react";
import { isOnProductionUrl } from "../../lib/functions/general_functions";

export function AlphaTag() {
  return !isOnProductionUrl() ? (
    <a
      style={{ marginRight: "16px", color: "#aaaaaa" }}
      href="https://gitlab.corp.lego.com/dkCasLar/integration-portal/-/commits/alpha"
      target="_blank"
      rel="noopener noreferrer"
    >
      alpha
    </a>
  ) : null;
}

import React from "react";
import { Card, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import { ArrowRightOutlined } from "@ant-design/icons";

interface BasicCardProps {
  title: string;
  titleColor: "red" | "yellow" | "blue" | "green" | undefined;
  text: string;
  loading?: boolean;
  onClick(): void;
}

export const BasicCard = (props: BasicCardProps) => {
  const { title, text, titleColor } = props;

  return (
    <Card
      loading={props.loading}
      title={
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "12px 0",
            }}
            title={title + ""}
          >
            <Tag color={titleColor} className={"services__type-tag"}>
              {title}
            </Tag>
          </div>
          <ArrowRightOutlined />
        </>
      }
      hoverable
      className="dashboard__grid--card"
      onClick={() => props.onClick()}
    >
      <div style={{ display: "flex", justifyContent: "center", padding: "25px", whiteSpace: "nowrap" }}>
        <Title level={3}>{text}</Title>
      </div>
    </Card>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { doFetch, getModalError, getModalWarning, doFetchPromise } from "../lib/functions/general_functions";
import { publishersSubscribersColumns } from "../lib/definitions/table_column_definitions";
import { DIDBResource, getPageBreadcrumb } from "../lib/definitions/general_definitions";
import { ShowInfoJson } from "../components/general/showInfoJson";
import { BreadcrumbContext } from "../lib/contexts";
import { SuperTable, SuperTableAction } from "../components/superTable";
import { DeleteOutlined } from "@ant-design/icons";

export const SubsciberPage = () => {
  const [publishersAndSubscribers, setPublishersAndSubscribers] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selected, setSelected] = useState();
  const setBreadcrumbs = useContext(BreadcrumbContext);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchPublishersAndSubscribers();
  }, []);

  useEffect(() => setBreadcrumbs([getPageBreadcrumb("Publishers/Subscribers")]), [setBreadcrumbs]);

  async function fetchPublishersAndSubscribers() {
    setIsFetching(true);
    try {
      const subscribersPromise = doFetchPromise("GET", DIDBResource.EventSubscribers);
      const publishersPromise = doFetchPromise("GET", DIDBResource.EventPublishers);
      const subscribers = (await subscribersPromise).value;
      const publishers = (await publishersPromise).value;
      const joined = subscribers
        .map((sub: any) => ({ type: "subscriber", ...sub }))
        .concat(publishers.map((pub: any) => ({ type: "publisher", ...pub })));
      setPublishersAndSubscribers(joined);
    } catch (error) {
      getModalError("Fetching publishers and subscribers")(error.message);
    } finally {
      setIsFetching(false);
    }
  }

  const actions: SuperTableAction[] = [
    {
      icon: <DeleteOutlined />,
      description: "Delete subsciber",
      danger: true,
      primary: true,
      onClick: (obj) =>
        getModalWarning(`Delete ${obj.type}`)(`Are you sure you want to delete ${obj.type} ${obj._id}?`, (close) =>
          doFetch(
            "DELETE",
            `${obj.type === "publisher" ? DIDBResource.EventPublishers : DIDBResource.EventSubscribers}/${obj._id}`,
            isMounted,
            fetchPublishersAndSubscribers,
            getModalError(`Deleting ${obj.type}`),
            close
          )
        ),
    },
  ];

  return (
    <div>
      <div className="split-table-tree">
        <SuperTable
          columns={publishersSubscribersColumns}
          rows={publishersAndSubscribers}
          rowKey="_id"
          refreshProps={{ onRefresh: fetchPublishersAndSubscribers, isRefreshing: isFetching }}
          onRowClick={setSelected}
          selectedRow={selected}
          actions={actions}
        />
        <ShowInfoJson object={selected} />
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useRef, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { MyTeamsContext } from "../../lib/contexts";
import { getModalWarning, doFetch, getModalError, useSHistory } from "../../lib/functions/general_functions";
import { DIDBResource, nameToPath } from "../../lib/definitions/general_definitions";
import { SuperTable, SuperTableAction } from "../superTable";
import { dnsColumns } from "../../lib/definitions/table_column_definitions";
import { DNS } from "../../lib/definitions/models";
import { SView } from "../general/detailView/sView";

export const DNSSPane = () => {
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const actions: SuperTableAction[] = [
    {
      icon: <DeleteOutlined />,
      description: "Delete DNS",
      danger: true,
      primary: true,
      onClick: (row) =>
        getModalWarning("Delete DNS")(`Are you sure you want to delete DNS '${row._id}'`, (close) =>
          doFetch(
            "DELETE",
            `${DIDBResource.DNS}/${row._id}`,
            isMounted,
            refreshMyTeams,
            getModalError("Deleting DNS"),
            close
          )
        ),
    },
  ];

  const dnss = useMemo(
    () =>
      selectedTeam?.dns.map((dns: DNS) => ({
        ...dns,
        key: dns._id,
      })) || [],
    [selectedTeam]
  );

  return (
    <SView title="DNS" subTitle={dnss.length + ""} onBack={() => history.push(nameToPath["Home"])}>
      <SuperTable
        rowKey="_id"
        rows={dnss}
        columns={dnsColumns}
        onRowClick={(dns) => history.push(`${nameToPath["HomeDNS"]}/${dns._id}`)}
        actions={actions}
        onCreate={() => history.push(nameToPath["HomeDNS"] + nameToPath["Create"])}
      />
    </SView>
  );
};

import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import { doFetch, getModalError, useSHistory } from "../../../lib/functions/general_functions";
import {
  DIDBResource,
  getPageBreadcrumb,
  getServiceBreadcrumb,
  nameToPath,
} from "../../../lib/definitions/general_definitions";
import { useParams } from "react-router-dom";
import { BreadcrumbContext, MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import { Spinner } from "../spinner";
import { ServiceAPI } from "../../../lib/definitions/models";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormInput } from "../createForm/sFormInput";
import { useForm } from "antd/lib/form/Form";

export const CreateAPIRequest = () => {
  const [isFetchingAPI, setIsFetchingAPI] = useState(true);
  const [api, setApi] = useState<ServiceAPI>();

  const { id } = useParams<{ id: string }>();
  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const setBreadcrumbs = useContext(BreadcrumbContext);
  const isMounted = useRef(true);
  const history = useSHistory();

  const [form] = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      getPageBreadcrumb("Services"),
      getServiceBreadcrumb("api", api),
      getPageBreadcrumb("Request", `${nameToPath["Services"]}/api/${id}${nameToPath["Request"]}`),
    ]);
  }, [setBreadcrumbs, api, id]);

  useEffect(() => {
    setIsFetchingAPI(true);
    doFetch(
      "GET",
      `${DIDBResource.APIs}/${id}`,
      isMounted,
      (res) => setApi(res.value),
      (err) => {
        getModalError("Fetching API")(err);
        history.push(nameToPath["Services"]);
      },
      () => setIsFetchingAPI(false)
    );
  }, [id, history]);

  async function postRequest() {
    alert("Not implemented");
    // doFetch(
    //   "POST",
    //   `${DIDBResource.EventSubscribers}/${id}`,
    //   isMounted,
    //   () =>
    //       history.push(nameToPath["Services"])
    //     ),
    //   getModalError("Creating API request"),
    //   undefined,
    //   values
    // );
  }

  const applicationOptions = useMemo(
    () =>
      selectedTeam?.applications
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application) => ({ label: application.name, value: application._id })),
    [selectedTeam, selectedEnvironment]
  );

  return isFetchingAPI ? (
    <Spinner margin={100} />
  ) : (
    <SForm
      title="Create API request"
      onBack={() => history.push(`${nameToPath["Services"]}/api/${id}`)}
      onCreate={postRequest}
      form={form}
    >
      <SFormSection>
        <SFormSelect label="Application" field="applicationId" options={applicationOptions} />
        <SFormInput label="Description" field="description" optional />
      </SFormSection>
    </SForm>
  );
};

/* eslint-disable */

const typeTemplate = "Field is not a valid ${type}";

export const customValidateMessages = {
  default: "Validation error on field Field",
  required: "Field is required",
  enum: "Field must be one of [${enum}]",
  whitespace: "Field cannot be empty",
  date: {
    format: "Field is invalid for format date",
    parse: "Field could not be parsed as date",
    invalid: "Field is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "Field must be exactly ${len} characters",
    min: "Field must be at least ${min} characters",
    max: "Field cannot be longer than ${max} characters",
    range: "Field must be between ${min} and ${max} characters",
  },
  number: {
    len: "Field must equal ${len}",
    min: "Field cannot be less than ${min}",
    max: "Field cannot be greater than ${max}",
    range: "Field must be between ${min} and ${max}",
  },
  array: {
    len: "Field must be exactly ${len} in length",
    min: "Field cannot be less than ${min} in length",
    max: "Field cannot be greater than ${max} in length",
    range: "Field must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "",
  },
};

import React from "react";
import { MessageWTeamName } from "../../lib/definitions/models";
import { COLOR_BLUE, COLOR_RED } from "../../lib/definitions/style_definitions";

interface MessageCardProps {
  message: MessageWTeamName;
  selectedMessageId?: string;
  onClick(): void;
}

function messageTypeToTitle(messageType: string) {
  return "Contract change";
}

export function MessageCard(props: MessageCardProps) {
  return (
    <div
      style={{
        padding: "2px",
        marginBottom: "24px",
        background: "#e4e4e4",
        width: "calc(100% - 12px)",
        position: "relative",
        cursor: "pointer",
        marginLeft: "1px",
        outline: props.selectedMessageId === props.message._id ? `1px solid ${COLOR_BLUE}` : undefined,
      }}
      onClick={props.onClick}
    >
      {!props.message.read && (
        <div
          style={{
            border: "1px solid white",
            background: COLOR_RED,
            position: "absolute",
            top: -4,
            right: -4,
            height: "12px",
            width: "12px",
            borderRadius: "50%",
          }}
        />
      )}
      <div style={{ color: "#636363", fontSize: "12px", margin: "4px 8px", fontStyle: "italic" }}>
        {new Date(props.message.timestamp).toString().slice(0, 24)}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h3 style={{ margin: "0 0 0 12px" }}>{messageTypeToTitle(props.message.type)}</h3>
        <h3 style={{ margin: "0 12px 0 0" }}>{props.message.teamName}</h3>
      </div>
      <div style={{ margin: "8px 8px", borderTop: "1px solid black" }} />
      <div style={{ margin: "12px" }}>{props.message.message}</div>
    </div>
  );
}

import React, { useState, useEffect, useRef, useContext } from "react";
import { Tree, Input } from "antd";
import { Key } from "antd/lib/table/interface";
import { getTeamTreeData } from "../../lib/functions/home_functions";
import { useLocation } from "react-router-dom";
import { EnvironmentContext, MyTeamsContext, RequesterContractContext } from "../../lib/contexts";
import {
  GlobalOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  TagsFilled,
  FileProtectOutlined,
} from "@ant-design/icons";
import {
  COLOR_GREEN_ICON,
  COLOR_BLUE_ICON,
  COLOR_ORANGE_ICON,
  COLOR_PURPLE_ICON,
} from "../../lib/definitions/style_definitions";

interface IndexTreeProps {
  onClick?(node: any): void;
}

export const IndexTree: React.FC<IndexTreeProps> = (props) => {
  const [tree, setTree] = useState<any[]>([]);
  const [iconTree, setIconTree] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedKeys, setSelectedKeys] = useState<string[]>([""]);
  const { requesterContracts } = useContext(RequesterContractContext);

  const { selectedTeam, myTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const { pathname } = useLocation();

  const canExpand = useRef(true);

  useEffect(() => {
    if (!selectedEnvironment) {
      return;
    }

    const { roots, expKeys } = getTeamTreeData(selectedTeam, searchInput, selectedEnvironment._id, requesterContracts);
    setTree(roots);
    setExpandedKeys(expKeys);
  }, [selectedTeam, searchInput, selectedEnvironment, requesterContracts, myTeams]);

  useEffect(() => {
    let treeCopy = tree;
    treeCopy.forEach((parent: any) => {
      parent.children.forEach((child: any) => {
        if (child.type === "applications") {
          // Not used right now, leaving code
          child.children.forEach((subChild: any) => {
            if (subChild.type === "consumerContract") {
              subChild.icon = getMatchingIcon(subChild.type, subChild.status);
            } else {
              subChild.icon = getMatchingIcon(subChild.type);
            }
          });
        } else {
          child.icon = getMatchingIcon(child.type);
        }
      });

      setIconTree(tree);
    });
  }, [tree]);

  function getMatchingIcon(type: string, status?: string) {
    switch (type) {
      case "dns":
        return <GlobalOutlined />;
      case "namespaces":
        return <TagsFilled />;
      case "apis":
        return <VerticalAlignBottomOutlined style={{ color: COLOR_GREEN_ICON }} />;
      case "events":
        return <VerticalAlignTopOutlined style={{ color: COLOR_BLUE_ICON }} />;
      case "consumerContract":
        if (status === "pending") {
          return <FileProtectOutlined style={{ color: COLOR_ORANGE_ICON }} />;
        } else if (status === "owner") {
          return <FileProtectOutlined style={{ color: COLOR_PURPLE_ICON }} />;
        } else {
          return <FileProtectOutlined style={{ color: COLOR_GREEN_ICON }} />;
        }
      default:
        console.log("Unknown type");
    }
  }

  useEffect(() => {
    let paths: string[] = [];
    const baseKey = pathname.replace("/home/", "").replace("/", ".");
    paths.push(baseKey);
    if (baseKey.startsWith("applications.")) {
      paths.push(baseKey.replace("applications", "applicationsapis"));
      paths.push(baseKey.replace("applications", "applicationsevents"));
    }
    setSelectedKeys(paths);
  }, [pathname]);

  function handleExpand(keys: Key[]) {
    if (canExpand.current) {
      setExpandedKeys(keys);
      canExpand.current = false;
      setTimeout(() => (canExpand.current = true), 600);
    }
  }

  return (
    <div
      style={{
        display: "grid",
        height: "100%",
        overflowX: "auto",
        gridTemplateRows: "min-content auto",
      }}
    >
      <Input
        placeholder="Search..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setSearchInput("");
          }
        }}
        style={{ marginBottom: "8px" }}
      />

      <Tree
        multiple
        autoExpandParent={!!searchInput}
        selectedKeys={selectedKeys}
        treeData={iconTree}
        onExpand={handleExpand}
        expandedKeys={expandedKeys}
        onClick={(_e, node) => {
          if (props.onClick) {
            props.onClick(node);
          }
        }}
        showIcon
        className="general__json-as-tree"
        style={{
          overflowX: "auto",
        }}
      />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { StackedColumn } from "@ant-design/charts";
import { Card } from "antd";
import { countServiceAmount } from "../../../lib/functions/home_functions";
import { COLOR_GREEN, COLOR_BLUE } from "../../../lib/definitions/style_definitions";

interface HistogramGraphProps {
  data: any[];
}

export const HistogramGraph = (props: HistogramGraphProps) => {
  const [data, setData] = useState<any[]>([]);
  const HISTOGRAM_TITLE = "Service Types";

  useEffect(() => {
    setData(countServiceAmount(props.data));
  }, [props.data]);

  const config = {
    forceFit: true,
    data,
    height: 290,
    xField: "type",
    yField: "value",
    color: [COLOR_GREEN, COLOR_BLUE],
    stackField: "type",
  };

  return (
    <Card
      data-cy="histogram"
      title={
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "12px 0",
            }}
            title={HISTOGRAM_TITLE}
          >
            {HISTOGRAM_TITLE}
          </div>
        </>
      }
      className="dashboard__grid--graph"
      onClick={() => console.log("Clicked on Histogram")}
    >
      <div style={{ height: "270px" }}>
        <StackedColumn {...config} />
      </div>
    </Card>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { getPropString } from "../../../../lib/functions/general_functions";
import { getLineWidthSpan } from "../../../../lib/functions/sDetail_functions";
import { SViewContext } from "../../../../lib/contexts";

interface SElementProps {
  label: string;
  field?: string;
  lineWidth?: "default" | "double" | "triple" | "wholeline";
  value?: string;
  fieldType?: "input" | "textarea";
  onChange?(event: any): any;
  render?(value: string): any;
}

export const SElementText = (props: SElementProps) => {
  const [value, setValue] = useState<string>("");
  const { object, isEditing } = useContext(SViewContext);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    } else {
      setValue(getPropString(object, props.field + ""));
    }
  }, [object, props.field, isEditing, props.value]);

  function getViewComponent() {
    switch (props.fieldType) {
      case "input":
        return props.render ? (
          <div>{props.render(value)}</div>
        ) : (
          <p style={{ overflow: "hidden" }} className="sdetail-element__value">
            {value}
          </p>
        );
      case "textarea":
        return (
          <p style={{ overflow: "hidden" }} className="sdetail-element__value">
            {value}
          </p>
        );
    }
  }

  return (
    <span className="sdetail-element__container" style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      {getViewComponent()}
    </span>
  );
};

import React, { useRef, useEffect, useContext } from "react";
import { Button, Select, Form, Input, Modal } from "antd";
import { doFetch, getModalError, getNotificationSuccess, getApisFromTeam } from "../../lib/functions/general_functions";
import { DIDBResource, DocumentType, SelectOption } from "../../lib/definitions/general_definitions";
import { MyTeamsContext, EnvironmentContext } from "../../lib/contexts";
import { useForm } from "antd/lib/form/Form";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { useState } from "react";

interface DeployButtonProps {
  entityId: string;
  type: DocumentType;
  subscriberContract?: any;
  callback?: () => void;
}

export const DeployButton: React.FC<DeployButtonProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const [destination, setDestination] = useState("");
  const [oAuthApplication, setOAuthApplication] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [env, setEnv] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);

  const { selectedTeam, myTeams, refreshMyTeams } = useContext(MyTeamsContext);
  const { environments, selectedEnvironment } = useContext(EnvironmentContext);

  const [form] = useForm();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  function onDeploy() {
    setIsDeploying(true);
    doFetch(
      "POST",
      DIDBResource.Deploy,
      isMounted,
      () => {
        getNotificationSuccess("Deployed", props.type);
        refreshMyTeams();
        if (props.callback) props.callback();
        onClose();
      },
      getModalError(`Deploying ${formattedType}`),
      () => setIsDeploying(false),
      {
        envId: form.getFieldValue("envId"),
        entityId: props.entityId,
        type: props.type.toLowerCase(),
        endpoint: form.getFieldsValue(),
      }
    );
  }

  function onClose() {
    setVisible(false);
    form.resetFields();
    setEnv("");
    setDestination("");
    setApiKey("");
    setOAuthApplication("");
  }

  const formattedType =
    props.type === "API" || props.type === "DNS"
      ? props.type
      : props.type === "SubscriberContract"
      ? "Subscription Contract"
      : props.type.toLowerCase();

  const apiOptions: SelectOption[] = useMemo(
    () =>
      getApisFromTeam(
        myTeams.find((t) => t._id === selectedTeam?._id),
        env
      ).map((api) => ({ label: api.name, value: api._id })),
    [selectedTeam, myTeams, env]
  );

  const buttonDisabled =
    !env ||
    (props.subscriberContract?.endpoint &&
      (props.subscriberContract.endpoint.type === "api"
        ? !destination ||
          (props.subscriberContract.endpoint.authType === "jwt"
            ? !oAuthApplication
            : props.subscriberContract.endpoint.authType === "apiKey"
            ? !apiKey
            : false)
        : props.subscriberContract.endpoint.type === "s3"
        ? !destination
        : props.subscriberContract.endpoint.type === "i2api" && !destination));

  return (
    <>
      <Button onClick={() => setVisible(true)} icon={<ArrowRightOutlined />} style={{ marginLeft: "8px" }}>
        Deploy
      </Button>
      <Modal
        okText="Deploy"
        cancelText="Cancel"
        title={`Deploy ${formattedType}`}
        visible={visible}
        onOk={onDeploy}
        centered
        okButtonProps={{ disabled: buttonDisabled, loading: isDeploying }}
        onCancel={onClose}
      >
        <Form form={form} style={{ marginTop: "24px" }}>
          <Form.Item name="envId">
            <Select
              placeholder="Select environment..."
              options={environments.map((env) => ({
                label: env.name,
                value: env._id,
                disabled: env._id === selectedEnvironment?._id,
              }))}
              onChange={(v) => setEnv(v?.toString() + "")}
              disabled={!!env}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {props.subscriberContract && env && (
            <>
              {props.subscriberContract.endpoint?.type === "s3" && (
                <Form.Item name="destination">
                  <Input placeholder="S3 URL..." onChange={(e) => setDestination(e.target.value)} />
                </Form.Item>
              )}
              {props.subscriberContract.endpoint?.type === "api" && (
                <>
                  <Form.Item name="destination">
                    <Input placeholder="API URL..." onChange={(e) => setDestination(e.target.value)} />
                  </Form.Item>
                  {props.subscriberContract.endpoint.authType === "jwt" ? (
                    <Form.Item name="oAuthApplication">
                      <Select
                        placeholder="OAuth Application..."
                        options={myTeams
                          .find((t) => t._id === selectedTeam?._id)
                          ?.applications.filter((app) => app.env === env)
                          .map((app) => ({
                            label: app.name,
                            value: app._id,
                          }))}
                        onChange={(val) => setOAuthApplication(val?.toString() + "")}
                      />
                    </Form.Item>
                  ) : (
                    props.subscriberContract.endpoint.authType === "apiKey" && (
                      <Form.Item name="apiKey">
                        <Input placeholder="API key..." onChange={(e) => setApiKey(e.target.value)} />
                      </Form.Item>
                    )
                  )}
                </>
              )}
              {props.subscriberContract.endpoint?.type === "i2api" && (
                <Form.Item name="destination">
                  <Select
                    placeholder="API..."
                    options={apiOptions}
                    onChange={(val) => setDestination(val?.toString() + "")}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React from "react";
import { Environment } from "../../lib/definitions/models";
import { COLOR_GREEN, COLOR_RED } from "../../lib/definitions/style_definitions";

interface TykStatusContentProps {
  statusObject: { inTeam: string[]; inTykBefore: any; inTykAfter: any };
  environments: Environment[];
}

export function TykStatusContent(props: TykStatusContentProps) {
  const tykOrgs = Object.keys(props.statusObject.inTykBefore);

  tykOrgs.sort(
    (a, b) =>
      (props.environments.find((e) => e._id === a)?.stageIndex || 0) -
      (props.environments.find((e) => e._id === b)?.stageIndex || 0)
  );

  const getIcon = (before: boolean, org: string) => {
    let status = true;
    const statusObject = before ? props.statusObject.inTykBefore : props.statusObject.inTykAfter;

    const emails = statusObject[org].map((s: string) => s.toLowerCase());

    props.statusObject.inTeam.forEach((email) => {
      if (!emails.includes(email.toLowerCase())) {
        status = false;
      }
    });

    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "20px" }}>
        {status ? <CheckOutlined style={{ color: COLOR_GREEN }} /> : <CloseOutlined style={{ color: COLOR_RED }} />}
      </div>
    );
  };

  const errorEmails = props.statusObject.inTeam.filter((email: string) =>
    Object.values(props.statusObject.inTykAfter).find(
      (emailsInOrg: any) => !emailsInOrg.map((s: string) => s.toLowerCase()).includes(email.toLowerCase())
    )
  );

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "min-content min-content min-content",
          fontSize: "18px",
          columnGap: "16px",
          rowGap: "4px",
          margin: "16px 0",
        }}
      >
        <div style={{ fontWeight: "bold", marginBottom: "2px" }}>Environment</div>
        <div style={{ fontWeight: "bold" }}>Before</div>
        <div style={{ fontWeight: "bold" }}>After</div>
        {tykOrgs.map((org) => (
          <React.Fragment key={org}>
            <div style={{ whiteSpace: "nowrap" }}>
              {`(${org}) ` + props.environments.find((env) => env._id === org)?.name}
            </div>
            {getIcon(true, org)}
            {getIcon(false, org)}
          </React.Fragment>
        ))}
      </div>
      {errorEmails.length > 0 && (
        <>
          <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "6px" }}>Error description</div>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            {errorEmails
              .map(
                (email) =>
                  `${email.toLowerCase()} could not be created in ${Object.entries(props.statusObject.inTykAfter)
                    .map(([org, emails]: [string, any]) =>
                      !emails.map((s: string) => s.toLowerCase()).includes(email.toLowerCase()) ? org : ""
                    )
                    .filter((s) => s)
                    .join(", ")}.`
              )
              .join(" ")}
          </div>
        </>
      )}
    </div>
  );
}

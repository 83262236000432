import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Namespace } from "../../lib/definitions/models";
import { nameToPath, DIDBResource } from "../../lib/definitions/general_definitions";
import { MyTeamsContext, EnvironmentContext } from "../../lib/contexts";
import {
  doFetch,
  getModalError,
  getModalNotFound,
  getPropString,
  getNotificationSuccess,
  useSHistory,
} from "../../lib/functions/general_functions";
import { SView } from "../general/detailView/sView";
import { SSection } from "../general/detailView/sSection";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SElementTags } from "../general/detailView/Elements/sElementTags";
import { DeployButton } from "./deployButton";
import { SuperTable } from "../superTable";
import { apisListNamespace } from "../../lib/definitions/table_column_definitions";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { ReassignButton } from "./reassignButton";
import { SElementSelect } from "../general/detailView/Elements/sElementSelect";
import { SElementCustom } from "../general/detailView/Elements/sElementCustom";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Popover } from "antd";
import { CreateDNS } from "../general/forms/createDNS";

export const NamespacePane = () => {
  const [namespace, setNamespace] = useState<Namespace>();
  const [dnsName, setDnsName] = useState();
  const [filteredAndModifiedApis, setFilteredAndModifiedApis] = useState<any[]>([]);
  const [isFetchingApis, setIsFetchingApis] = useState(false);
  const [dnsOptions, setDnsOptions] = useState<any[]>([]);
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { id } = useParams<{ id: string }>();
  const history = useSHistory();
  const isMounted = useRef(true);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      const ns = selectedTeam.namespaces.find((namespace) => namespace._id === id);
      if (ns) {
        setNamespace(ns);
      } else {
        getModalNotFound("Namespace", id, () => history.push(nameToPath["HomeNamespaces"]));
      }
    }
  }, [id, selectedTeam, history]);

  const createApiList = useCallback(
    (apis) => {
      if (dnsName) {
        const filteredApis = apis.filter((api: any) => api.namespaceId === namespace?._id);
        const modifiedApis = filteredApis.map((api: any) => {
          api.fullPath = `${dnsName}/${getPropString(namespace, "name")}/${api.listenPath}`;
          return api;
        });
        setFilteredAndModifiedApis(modifiedApis);
      }
    },
    [dnsName, namespace]
  );

  useEffect(() => {
    setIsFetchingApis(true);
    doFetch(
      "GET",
      `${DIDBResource.ExpandedApis}`,
      isMounted,
      (res) => createApiList(res.value),
      () => getModalError("Fetching APIs"),
      () => setIsFetchingApis(false)
    );
  }, [createApiList]);

  function deleteNamespace() {
    return doFetch(
      "DELETE",
      `${DIDBResource.Namespaces}/${id}`,
      isMounted,
      () => {
        getNotificationSuccess("Deleted", "Namespace", getPropString(namespace, "name"));
        refreshMyTeams();
        history.push(nameToPath["HomeNamespaces"]);
      },
      getModalError("Deleting namespace")
    );
  }

  async function putNamespace(namespaceValues: any): Promise<boolean> {
    delete namespaceValues["dnsName"];
    let success = true;
    await doFetch(
      "PUT",
      `${DIDBResource.Namespaces}/${id}`,
      isMounted,
      () => {
        getNotificationSuccess("Updated", "Namespace", namespaceValues?.name);
        refreshMyTeams();
        success = true;
      },
      getModalError("Updating namespace"),
      undefined,
      {
        ...namespaceValues,
        status: true,
      }
    );
    return success;
  }

  useEffect(() => {
    if (selectedEnvironment && namespace) {
      setDnsName(undefined);
      doFetch(
        "GET",
        `${DIDBResource.DNS}?_id=${namespace?.dnsId}`,
        isMounted,
        (res) => {
          res.value.map((dns: any) => setDnsName(dns.name));
        },
        (err: any) => getModalError("Fetching DNS")(err),
        () => console.log
      );
    }
  }, [selectedEnvironment, namespace]);

  useEffect(() => {
    doFetch(
      "GET",
      `${DIDBResource.DNS}`,
      isMounted,
      (res) => {
        setDnsOptions(res.value.map((dns: any) => ({ label: dns.name, value: dns._id })));
      },
      () => getModalError("Fetching DNS options")
    );
  }, []);

  return (
    <SView
      key={id}
      title={"Namespace"}
      subTitle={getPropString(namespace, "name")}
      onBack={() => history.push(nameToPath["HomeNamespaces"])}
      object={namespace}
      onSave={putNamespace}
      onDelete={deleteNamespace}
      extra={[
        <ReassignButton key="1" type="Namespace" entityId={id} parentId={namespace?.dnsId} />,
        <DeployButton key="2" type="Namespace" entityId={id} />,
      ]}
    >
      <SSection title="Details">
        <SElementInput label="Name" field="name" />
        <SElementSelect
          label="DNS"
          field="dnsId"
          options={dnsOptions}
          onNewForm={(close) => <CreateDNS extraHook={close} />}
        />
        <SElementTags label="Tags" field="tags" />
        <SElementInput label="Description" field="description" fieldType="textarea" lineWidth="double" />

        <SElementCustom
          label="Status"
          field="status"
          initialValue={0}
          children={
            <Popover
              title="Status on namespace"
              content="This shows wether the namespace is correctly implemented or not."
            >
              <Checkbox checked={namespace?.status} disabled />
            </Popover>
          }
        />
      </SSection>

      <SSectionCustom title={`Connected APIs (${filteredAndModifiedApis.length})`} frameless>
        <SuperTable
          rows={filteredAndModifiedApis}
          rowKey="_id"
          columns={apisListNamespace}
          refreshProps={{ isRefreshing: isFetchingApis }}
        />
      </SSectionCustom>
    </SView>
  );
};

import React, { CSSProperties } from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { COLOR_BLUE_LINK } from "../../lib/definitions/style_definitions";

interface JsonReaderProps {
  width: string;
  style?: CSSProperties;
  onFileSelect(text: string): void;
}

export const JsonReader: React.FC<JsonReaderProps> = (props) => {
  return (
    <div style={{ height: "108px", ...props.style }}>
      <Upload.Dragger
        name="file"
        accept=".json"
        beforeUpload={(file) => {
          file.text().then(props.onFileSelect);
          return false;
        }}
        showUploadList={false}
        style={{ width: props.width }}
        className="services__json-reader"
      >
        <p style={{ color: COLOR_BLUE_LINK, fontSize: "40px" }}>
          <InboxOutlined />
        </p>
        <p style={{ margin: "0 10px" }}>Click or drag a .json file to this area to generate</p>
      </Upload.Dragger>
    </div>
  );
};

import { Card, Col, Popover, Row } from "antd";
import React from "react";
import { SElementInput } from "../../general/detailView/Elements/sElementInput";
import { SSectionCustom } from "../../general/detailView/sSectionCustom";

interface RequesterInfoProps {
  requesterName: string;
  selectedContract: any;
  url: string;
}

export const RequesterInfo: React.FC<RequesterInfoProps> = (props) => {
  let dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <SSectionCustom title="Requester">
      <div style={{ margin: "15px" }}>
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <Row gutter={[16, 40]}>
              <Col span={18}>
                <SElementInput label="Name" value={props.requesterName} />
              </Col>
              <Col span={6}>
                <Popover content={`Press icon to chat with ${props.requesterName}`}>
                  <input
                    className="menubar__image"
                    type="image"
                    src={props.url}
                    alt="hej"
                    onClick={() =>
                      window.open(
                        `https://teams.microsoft.com/l/chat/0/0?users=${props.selectedContract.requester_mail}&topicName=Digital`
                      )
                    }
                  ></input>
                </Popover>
              </Col>
            </Row>
            <Row gutter={[16, 40]}>
              <Col span={24}>
                <SElementInput label="Team" value={props.selectedContract?.consumingTeam[0].name} />
              </Col>
            </Row>
            <Row gutter={[16, 40]}>
              <Col span={24}>
                <SElementInput label="Application" value={props.selectedContract?.consumingApplication.name} />
              </Col>
            </Row>
            <Row gutter={[16, 40]}>
              <Col span={24}>
                <SElementInput
                  label="Timestamp"
                  value={`${new Date(props.selectedContract?.timestamp).toLocaleString("en-GB", dateOptions)}`}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Card title="Justification" style={{ height: "100%" }} bodyStyle={{ overflow: "auto", maxHeight: 250 }}>
              {props.selectedContract?.description}
            </Card>
          </Col>
        </Row>
      </div>
    </SSectionCustom>
  );
};

import React from "react";
import { ColumnSelection } from "../../lib/definitions/services_definitions";
import { Checkbox } from "antd";

interface SelectionListProps {
  header: string;
  selections: ColumnSelection[];
  onCheck(name: string, checked: boolean): void;
}

export const SelectionList: React.FC<SelectionListProps> = (props) => {
  return (
    <>
      <h5 className="services__filter-column--header">
        <b>{`${props.header} (${props.selections.length})`}</b>
      </h5>
      <div className="services__filter-column--container">
        <div className="services__filter-column--column-selection-grid">
          {props.selections.map((sel) => (
            <React.Fragment key={sel.name}>
              <Checkbox
                onChange={(e) => props.onCheck(sel.name, e.target.checked)}
                checked={sel.checked}
                style={{ marginLeft: "8px" }}
              />
              <span
                title={sel.name}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {sel.name}
              </span>
              <span style={{ textAlign: "right" }}>{sel.hits}</span>
            </React.Fragment>
          ))}
          {props.selections.length === 0 && (
            <p style={{ gridColumn: "2 / span 2", whiteSpace: "nowrap" }}>
              No results...
            </p>
          )}
        </div>
      </div>
    </>
  );
};

import { SSelectOption } from "../../components/general/detailView/Elements/sElementSelect";

export const serviceVisibilityOptions: SSelectOption[] = [
  { label: "Public", value: "public" },
  { label: "LEGO", value: "lego" },
  { label: "Team", value: "team" },
];

export const serviceAuthenticationOptions: SSelectOption[] = [
  { label: "OAUTH", value: "oauth" },
  { label: "API Key", value: "apikey" },
  { label: "Other", value: "other" },
  { label: "Basic", value: "basic" },
];

export const serviceApiTypeOptions: SSelectOption[] = [
  { label: "REST", value: "rest" },
  { label: "GRAPHQL", value: "graphql" },
  { label: "SOAP", value: "soap" },
  { label: "Other", value: "other" },
];

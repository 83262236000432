import React, { useState, Key } from "react";
import { Radio } from "antd";
import { CopyButton } from "../general/copyButton";
import { JsonAsTree } from "../general/jsonAsTree";
import { MultiTreeTab } from "../../lib/definitions/services_definitions";

interface MultiTreeProps {
  jsonTypes: any;
  jsonExample: any;
  routingKeys?: any;
  checkedKeys?: Key[];
  disableCopy?: boolean;
  defaultTab?: "Example" | "Types";
  setRoutingKeys?(keys: Key[]): void;
  setCheckedKeys?(checkedKeys: Key[]): void;
}

export const MultiTree: React.FC<MultiTreeProps> = (props) => {
  const [selectedTree, setSelectedTree] = useState<MultiTreeTab>(props.defaultTab || "Example");

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Radio.Group
          onChange={(e) => setSelectedTree(e.target.value)}
          value={selectedTree}
          style={{ display: "flex", userSelect: "none" }}
        >
          <Radio.Button value="Types">Types</Radio.Button>
          <Radio.Button value="Example">Example</Radio.Button>
          {props.routingKeys && <Radio.Button value="Routings">Routings</Radio.Button>}
        </Radio.Group>
        {!props.disableCopy && selectedTree !== "Routings" && (
          <div style={{ marginLeft: "8px", display: "flex" }}>
            <CopyButton
              title={`Copy ${selectedTree === "Example" ? "example" : "types"} JSON`}
              content={JSON.stringify(selectedTree === "Example" ? props.jsonExample : props.jsonTypes, null, 4)}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: "12px" }}>
        {selectedTree === "Types" && (
          <JsonAsTree
            isTypes
            json={props.jsonTypes}
            checkedKeys={props.checkedKeys}
            setCheckedKeys={props.setCheckedKeys}
          />
        )}
        {selectedTree === "Example" && <JsonAsTree json={props.jsonExample} />}
        {selectedTree === "Routings" && (
          <JsonAsTree
            isTypes
            json={props.jsonTypes}
            checkedKeys={props.routingKeys}
            setCheckedKeys={props.setRoutingKeys}
          />
        )}
      </div>
    </div>
  );
};

import React, { useContext, useMemo } from "react";
import { SuperTable } from "../superTable";
import { MyTeamsContext } from "../../lib/contexts";
import { Service, ServiceType } from "../../lib/definitions/models";
import { allServicesColumns } from "../../lib/definitions/table_column_definitions";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { SView } from "../general/detailView/sView";
import { useSHistory } from "../../lib/functions/general_functions";

export const ServicesPane = () => {
  const { selectedTeam } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const services = useMemo(
    () =>
      selectedTeam?.applications.reduce(
        (arr: Service[], app) =>
          arr.concat(...app.services.map((service) => ({ ...service, applicationName: app.name }))),
        []
      ) || [],
    [selectedTeam]
  );

  return (
    <SView title="Services" subTitle={services.length} onBack={() => history.push(nameToPath["Home"])}>
      <SuperTable
        rows={
          selectedTeam?.applications.reduce(
            (arr: Service[], app) =>
              arr.concat(...app.services.map((service) => ({ ...service, applicationName: app.name }))),
            []
          ) || []
        }
        rowKey="_id"
        columns={allServicesColumns}
        onRowClick={(service) =>
          history.push(
            `${nameToPath[service.serviceType === ServiceType.API ? "HomeAPIs" : "HomeEvents"]}/${service._id}`
          )
        }
        onCreate={() => history.push(nameToPath["HomeServices"] + nameToPath["Create"])}
      />
    </SView>
  );
};

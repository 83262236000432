import { LogsChartDataPoint } from "../definitions/models";

export function getLastSevenDaysDates(): LogsChartDataPoint[] {
  const result: LogsChartDataPoint[] = [];

  const date = new Date();

  let previousTimestamp = date.getTime();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  for (let i = 0; i < 7; i++) {
    result.push({
      fromTimestamp: date.getTime(),
      toTimestamp: previousTimestamp,
      dateAsString: getDateAsString(date),
      Published: 0,
      Dequeued: 0,
      publishedLogs: [],
      dequeuedLogs: [],
    });

    previousTimestamp = date.getTime();
    date.setDate(date.getDate() - 1);
  }

  return result.reverse();
}

function getDateAsString(date: Date) {
  return (
    date.getDate() +
    " " +
    [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][date.getMonth()]
  );
}

import { Collapse } from "antd";
import React from "react";
import { getProp, uppercaseFirstLetter } from "../../../lib/functions/general_functions";
import { parseSchemaTypes } from "../../../lib/functions/services_functions";
import { SSectionCustom } from "../../general/detailView/sSectionCustom";
import { JsonAsTree } from "../../general/jsonAsTree";
import { Key } from "antd/lib/table/interface";
import { DeadLetterQueue } from "./deadLetterQueue";
import { SSection } from "../../general/detailView/sSection";
import { SElementInput } from "../../general/detailView/Elements/sElementInput";
import { RuleSchemaEditor } from "../../services/ruleSchemaEditor";

interface RequestDetailsInfoProps {
  selectedContract: any;
  dlqMessages: number;
  setDlqMessages: (value: React.SetStateAction<number>) => void;
  isFetchingDlq: boolean;
  setIsFetchingDlq: (value: React.SetStateAction<boolean>) => void;
  deployable: boolean;
  checkedKeys: Key[] | undefined;
  setCheckedKeys: React.Dispatch<React.SetStateAction<React.Key[] | undefined>>;
  editable: boolean;
  isMounted: React.MutableRefObject<boolean>;
  id: string;
  type: string;
  typeJson: any;
  ruleSchema: any;
  dlqIsLive: boolean;
  setRuleSchema: React.Dispatch<any>;
}

export const RequestDetailsInfo: React.FC<RequestDetailsInfoProps> = (props) => {
  const { Panel } = Collapse;

  return (
    <div id={props.selectedContract._id}>
      {props.type === "api" ? (
        <SSection title="API Details">
          <SElementInput label="Name" value={props.selectedContract?.service.name} />
          <SElementInput label="Application" value={props.selectedContract?.ProvidingApp[0].name} />
        </SSection>
      ) : (
        <SSectionCustom title={`Request Details - ${uppercaseFirstLetter(props.selectedContract?.eventType)}`}>
          {props.selectedContract?.eventType === "subscriber" ? (
            <DeadLetterQueue
              isFetchingDlq={props.isFetchingDlq}
              setIsFetchingDlq={props.setIsFetchingDlq}
              selectedContract={props.selectedContract}
              dlqMessages={props.dlqMessages}
              setDlqMessages={props.setDlqMessages}
              isMounted={props.isMounted}
              dlqIsLive={props.dlqIsLive}
            />
          ) : (
            <></>
          )}

          <Collapse style={{ marginTop: "24px", marginBottom: "8px" }}>
            <Panel header="Data Object" key="1">
              <JsonAsTree
                json={parseSchemaTypes(getProp(props.selectedContract?.service, "schema"))}
                checkedKeys={props.checkedKeys}
                setCheckedKeys={props.setCheckedKeys}
              ></JsonAsTree>
            </Panel>
            <Panel header="Routing rule" key="2">
              <RuleSchemaEditor
                maskedTypeJson={props.typeJson}
                ruleSchema={props.ruleSchema}
                setRuleSchema={props.setRuleSchema}
              />
            </Panel>
          </Collapse>
        </SSectionCustom>
      )}
    </div>
  );
};

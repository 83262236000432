import React, { useContext, useRef, useState } from "react";
import { MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import {
  getModalError,
  doFetch,
  doFetchPromise,
  getNotificationSuccess,
  getNotificationFeedbackRating,
  getNotificationError,
  useSHistory,
} from "../../../lib/functions/general_functions";
import {
  DIDBResource,
  nameLengthRange,
  pascalCaseRegex,
  nameToPath,
} from "../../../lib/definitions/general_definitions";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormInput } from "../createForm/sFormInput";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormTags } from "../createForm/sFormTags";
import { SFormFiles } from "../createForm/sFormFiles";
import { uploadEntityAttachments } from "../../../lib/functions/aws_functions";
import { RegionOptions } from "../../../lib/definitions/aws_definitions";
import { SFormCustom } from "../createForm/sFormCustom";
import { Checkbox } from "antd";
import { useForm } from "antd/lib/form/Form";

interface CreateApplicationProps {
  extraHook?(applicationId: string): void;
}

export const CreateApplication = (props: CreateApplicationProps) => {
  const [wantAwsRole, setWantAwsRole] = useState(false);

  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const filesRef = useRef<any>();

  const isMounted = useRef(true);

  const [form] = useForm();

  async function createApplication(values: any, files: any[]) {
    try {
      await doFetch(
        "POST",
        DIDBResource.Applications,
        isMounted,
        (response) => {
          getNotificationSuccess("Created", "Application", values.name);
          getNotificationFeedbackRating("application");
          refreshMyTeams(props.extraHook ? undefined : `${nameToPath["HomeApplications"]}/${response.value.id}`);
          if (props.extraHook) {
            props.extraHook(response.value.id);
          }
          if (selectedTeam?._id) {
            uploadEntityAttachments(selectedTeam._id, "Application", response.value.id, files, undefined, () =>
              getNotificationError("Uploading attachments", "An error occured")
            );
          }
        },
        getModalError("Creating application"),
        undefined,
        {
          ...values,
          teamId: selectedTeam?._id,
          env: selectedEnvironment?._id,
        }
      );
    } catch (error) {
      getModalError("Creating Application")(error.message);
    }
  }

  async function getLeanIXApplicationOptions() {
    try {
      const response = await doFetchPromise("GET", DIDBResource.AuxLeanIXApplications);
      return response.value.map((application: any) => ({ label: application.name, value: application.id }));
    } catch {
      return [];
    }
  }

  async function getApplicationNames() {
    try {
      const response = await doFetchPromise("GET", DIDBResource.Applications);
      return response.value.map((application: any) => application.name);
    } catch {
      return [];
    }
  }

  return (
    <div>
      <SForm
        onBack={() => history.push(nameToPath["HomeApplications"])}
        title="Create Application"
        onCreate={(values) => createApplication(values, filesRef.current?.getFiles())}
        form={form}
      >
        <SFormSection>
          <SFormInput
            label="Name"
            field="name"
            getReservedValues={getApplicationNames}
            reservedValuesCaseInsensitive
            lengthRange={nameLengthRange}
            regex={pascalCaseRegex}
          />
          <SFormSelect label="LeanIX application" field="leanIXId" optional getOptions={getLeanIXApplicationOptions} />
          <SFormInput label="Description" field="description" textarea optional />
          <SFormTags label="Tags" field="tags" optional />
          <SFormFiles label="Attachments" ref={filesRef} />
        </SFormSection>
        <SFormSection title="Identification">
          <SFormInput label="Azure AD (client ID/user ID)" field="azureAdId" optional />
          <SFormInput label="AWS (client ID/user ID)" field="awsId" optional />
          <SFormCustom
            first="Enable AWS inspection:"
            second={<Checkbox checked={wantAwsRole} onChange={() => setWantAwsRole(!wantAwsRole)} />}
          />
          {wantAwsRole && (
            <>
              <SFormInput label="Role ARN" field="awsRole.arn" />
              <SFormInput label="External ID" field="awsRole.externalId" optional />
              <SFormSelect label="Regions" field="awsRole.regions" multiple options={RegionOptions} />
            </>
          )}
        </SFormSection>
      </SForm>
    </div>
  );
};

import React from "react";
import { SuperTableColumn } from "../../components/superTable";
import { Button, Tag } from "antd";
import { Namespace, ServiceType } from "./models";
import { COLOR_GREEN_TAG, COLOR_BLUE_TAG } from "./style_definitions";
import { doFetch, getModalError, getModalInfo } from "../functions/general_functions";
import { DIDBResource } from "./general_definitions";

function renderTimestamp(timestamp: any) {
  const d = new Date(timestamp);
  if (d.toString() === "Invalid Date") {
    return "Invalid Date";
  }
  return (
    <>
      {d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2)}
    </>
  );
}

const idColumn: SuperTableColumn = {
  title: "ID",
  dataIndex: "_id",
  collapse: true,
};

const nameColumn: SuperTableColumn = {
  title: "Name",
  dataIndex: "name",
  defaultSortOrder: "ascend",
  collapse: true,
};

const descriptionColumn: SuperTableColumn = {
  title: "Description",
  dataIndex: "description",
};

const serviceTypeColumn: SuperTableColumn = {
  title: "Type",
  dataIndex: "serviceType",
  render: (serviceType: string) => (
    <Tag color={serviceType === ServiceType.API ? COLOR_GREEN_TAG : COLOR_BLUE_TAG} style={{ margin: 0 }}>
      {serviceType.toLocaleUpperCase()}
    </Tag>
  ),
  align: "center",
  collapse: true,
};

const statusColumn: SuperTableColumn = {
  title: "Status",
  dataIndex: "status",
  render: (status: string) => (
    <Tag color={status === "pending" ? "volcano" : "green"} key={status} style={{ margin: 0 }}>
      {status.toLocaleUpperCase()}
    </Tag>
  ),
  align: "center",
  collapse: true,
};

const tagsColumn: SuperTableColumn = {
  title: "Tags",
  dataIndex: "tags",
  align: "center",
  collapse: true,
  cellTitle: (tags: string[]) => (Array.isArray(tags) ? tags.sort().join(", ") : tags + ""),
  render: (tags: string[]) => (
    <div className="general__tags-column">
      {tags?.sort().map((tag) => (
        <Tag key={tag}>{tag}</Tag>
      ))}
    </div>
  ),
};

export const publishersSubscribersColumns: SuperTableColumn[] = [
  idColumn,
  {
    title: "Type",
    dataIndex: "type",
    render: (type: string) => (
      <Tag color={type === "publisher" ? "green" : "blue"}>{type.charAt(0).toUpperCase() + type.slice(1)}</Tag>
    ),
    align: "center",
  },
  {
    title: "Service ID",
    dataIndex: "serviceId",
  },
];

export const teamsColumns: SuperTableColumn[] = [
  idColumn,
  nameColumn,
  {
    title: "Azure AD group ID",
    dataIndex: "azureAdGroupId",
  },
];

export const logsColumns: SuperTableColumn[] = [
  {
    title: "Date",
    defaultSortOrder: "descend",
    dataIndex: "timestamp",
    collapse: true,
    cellTitle: (value) => new Date(value).toDateString(),
    render: renderTimestamp,
  },
  { title: "Team", dataIndex: "team" },
  { title: "Application", dataIndex: "application" },
  { title: "Service", dataIndex: "event" },
  {
    title: "Type",
    dataIndex: "source",
    render: (type: any) => {
      switch (type) {
        case "EBM-Publisher":
          return <Tag color={"gold"}>{type}</Tag>;
        case "EBM-Distribute":
          return <Tag color={"magenta"}>{type}</Tag>;
        case "EBM-Mapping":
          return <Tag color={"cyan"}>{type}</Tag>;
        case "EBM-Dequeue":
          return <Tag color={"purple"}>{type}</Tag>;
      }
    },
  },
  {
    title: "Tags",
    dataIndex: "eventTags",
    cellTitle: (tags: string[]) => (Array.isArray(tags) ? tags.sort().join(", ") : tags + ""),
    render: (tags: string[]) => (
      <div className="general__tags-column">
        {tags?.sort().map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </div>
    ),
  },
  {
    title: "Error",
    dataIndex: "error",
    collapse: true,
    render: (error: any) => {
      return error ? <Tag color={"red"}>Errors</Tag> : <Tag color={COLOR_GREEN_TAG}>No Errors</Tag>;
    },
  },
];

export const feedbacksColoumns: SuperTableColumn[] = [
  {
    title: "Users email",
    dataIndex: "userEmail",
    collapse: true,
    render: (mail: any) => {
      if (!mail) {
        return "Anonymous";
      } else {
        return mail;
      }
    },
  },
  {
    title: "Rating",
    dataIndex: "rating",
  },
  {
    title: "Comments",
    dataIndex: "text",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    render: renderTimestamp,
    defaultSortOrder: "descend",
  },
];

export const suggestionsColoumns: SuperTableColumn[] = [
  {
    title: "Users email",
    dataIndex: "userEmail",
    collapse: true,
  },
  {
    title: "Suggestion",
    dataIndex: "text",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
    render: renderTimestamp,
    defaultSortOrder: "ascend",
  },
];

export const monitorColoumns: SuperTableColumn[] = [
  {
    title: "Team name",
    dataIndex: "teamName",
    collapse: true,
    defaultSortOrder: "ascend",
  },
  {
    title: "Application",
    dataIndex: "applicationName",
  },
  {
    title: "Service",
    dataIndex: "name",
  },
  {
    title: "Service Type",
    dataIndex: "serviceType",
  },
  {
    title: "NoOfConsumers",
    dataIndex: "consumers",
    render: (consumers) => (Array.isArray(consumers) ? consumers.length : 0),
  },

  // {
  //   title: "Events",
  //   dataIndex: "services",
  //   render: (data) => {
  //     const events = data ? data.filter((type: any) => type?.serviceType === "Event") : 0;
  //     return events.length;
  //   },
  // },
  // {
  //   title: "API's",
  //   dataIndex: "services",
  //   render: (data) => {
  //     const apis = data ? data.filter((type: any) => type?.serviceType === "API") : 0;
  //     return apis.length;
  //   },
  // },
];

export const membersColumns: SuperTableColumn[] = [
  { title: "Name", dataIndex: "name", defaultSortOrder: "ascend", collapse: true },
  { title: "Email", dataIndex: "email" },
];

export const dnsColumns: SuperTableColumn[] = [
  nameColumn,
  { title: "Visible", dataIndex: "visible", render: (val) => val.toString() },
  descriptionColumn,
  tagsColumn,
];

export const applicationsColumns: SuperTableColumn[] = [
  nameColumn,
  descriptionColumn,
  {
    dataIndex: "services",
    title: "Services",
    render: (data) => data?.length,
  },
  tagsColumn,
];

export const allServicesColumns: SuperTableColumn[] = [
  nameColumn,
  serviceTypeColumn,
  {
    title: "Application name",
    dataIndex: "applicationName",
  },
  descriptionColumn,
  tagsColumn,
];

export const servicesColumns: SuperTableColumn[] = [nameColumn, serviceTypeColumn, descriptionColumn, tagsColumn];

export const contractColumns: SuperTableColumn[] = [
  {
    title: "Status",
    dataIndex: "status",
    render: (status: string) => (
      <Tag color={status === "pending" ? "volcano" : "green"} key={status}>
        {status}
      </Tag>
    ),
  },
  { title: "Application", dataIndex: "consumingApplication.name" },
  {
    title: "Date",
    dataIndex: "timestamp",
    collapse: true,
    cellTitle: (value) => new Date(value).toDateString(),
    render: (timestamp: any) => <div>{new Date(timestamp).toDateString()}</div>,
  },
  {
    title: "Team",
    dataIndex: "consumingTeam",
    cellTitle: (value) => value.map((t: any) => t.name).join(", "),
    render: (consumingTeam: any) => <>{consumingTeam[0].name}</>,
  },
  { title: "Contract type", dataIndex: "eventType" },
  { title: "Service", dataIndex: "service", render: (service: any) => <>{service.name}</> },
  {
    title: "Type",
    dataIndex: "serviceType",
    render: (type: string) => <Tag color={type === "event" ? "cyan" : "purple"}>{type.toLocaleUpperCase()}</Tag>,
  },
];

export const consumedServiceColumns: SuperTableColumn[] = [
  {
    title: "Name",
    dataIndex: "service",
    render: (service) => service.name,
    defaultSortOrder: "ascend",
    collapse: true,
  },
  serviceTypeColumn,
  statusColumn,
  {
    title: "Justification",
    dataIndex: "description",
  },
  {
    title: "Tags",
    dataIndex: "service",
    render: (service) => service.tags,
  },
];

export const consumersColumns: SuperTableColumn[] = [
  {
    title: "Name",
    dataIndex: "consumingApplication",
    render: (app) => app.name,
  },
  serviceTypeColumn,
  {
    title: "Description",
    dataIndex: "consumingApplication",
    render: (app) => app.description,
  },
  {
    title: "Tags",
    dataIndex: "consumingApplication",
    render: (app) => app.tags,
  },
];

export const namespacesColumns: SuperTableColumn[] = [
  nameColumn,
  descriptionColumn,
  tagsColumn,
  {
    title: "Status",
    dataIndex: "status",
    render: (namespace) => {
      if (!namespace) {
        return "false";
      } else {
        return "true";
      }
    },
  },
];

function putNamespaceWithNewStatus(namespace: Namespace, isMounted: React.MutableRefObject<boolean>) {
  if (namespace) {
    doFetch(
      "PUT",
      `${DIDBResource.Namespaces}/${namespace._id}`,
      isMounted,
      () => getModalInfo("Updated namespace", `Status field for namespace ${namespace._id} has been updates`),
      getModalError("Put namespace"),
      undefined,
      {
        _id: namespace._id,
        name: namespace.name,
        description: namespace.description,
        teamId: namespace.teamId,
        dnsId: namespace.dnsId,
        env: namespace.env,
        tags: namespace.tags,
        status: !namespace.status,
      }
    );
  }
}
export function getNamespacesAdminColumns(isMounted: React.MutableRefObject<boolean>): SuperTableColumn[] {
  return [
    nameColumn,
    descriptionColumn,
    tagsColumn,
    {
      title: "Status",
      dataIndex: "status",
      render: (value, row) => {
        if (!value) {
          return <Button onClick={() => putNamespaceWithNewStatus(row, isMounted)}> false</Button>;
        } else {
          return <Button onClick={() => putNamespaceWithNewStatus(row, isMounted)}> true</Button>;
        }
      },
    },
  ];
}
export const dnsNamespacesColumns: SuperTableColumn[] = [
  nameColumn,
  { title: "Team", dataIndex: "team" },
  descriptionColumn,
];

export const eventDataObjectsColumn: SuperTableColumn[] = [
  {
    title: "Key name",
    dataIndex: "keyName",
    collapse: true,
  },
  {
    title: "Type",
    dataIndex: "type",
    collapse: true,
    render: (val) => (
      <Tag
        color={
          val === "string"
            ? "green"
            : val === "number"
            ? "blue"
            : val === "boolean"
            ? "red"
            : val === "array"
            ? "purple"
            : val === "object"
            ? "orange"
            : undefined
        }
      >
        {val}
      </Tag>
    ),
  },
  {
    title: "Count",
    dataIndex: "count",
    defaultSortOrder: "descend",
    collapse: true,
    sorter: (a, b) => a.count - b.count,
  },
  {
    title: "Data objects",
    dataIndex: "dataObjectNames",
    render: (val) => val.join(", "),
    cellTitle: (val) => val.join(", "),
  },
];

export const apisListNamespace: SuperTableColumn[] = [
  {
    title: "Application",
    dataIndex: "applicationName",
  },
  {
    title: "API",
    dataIndex: "name",
  },
  {
    title: "Full path",
    dataIndex: "fullPath",
  },
];

export const targetsColumns: SuperTableColumn[] = [
  nameColumn,
  serviceTypeColumn,
  descriptionColumn,
  {
    title: "Notes",
    dataIndex: "notes",
  },
  tagsColumn,
];

export const unallocatedEntitiesColumns: SuperTableColumn[] = [
  nameColumn,
  {
    title: "Type",
    dataIndex: "type",
    collapse: true,
  },
];

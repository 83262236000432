import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { SLabel } from "./sLabel";
import { RegexType } from "../../../lib/definitions/models";

interface SFormInputProps {
  label: string;
  field: string;
  optional?: boolean;
  initialValue?: string;
  textarea?: boolean;
  reservedValuesCaseInsensitive?: boolean;
  disabled?: boolean;
  regex?: RegexType;
  lengthRange?: [number, number];
  addonBefore?: string;
  getReservedValues?(): Promise<string[]>;
  onChange?(event: any): void;
}

export const SFormInput = (props: SFormInputProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [reservedValues, setReservedValues] = useState<string[]>([]);

  const { getReservedValues, reservedValuesCaseInsensitive } = props;

  useEffect(() => {
    (async () => {
      if (getReservedValues) {
        setIsFetching(true);
        try {
          const values = await getReservedValues();
          setReservedValues(reservedValuesCaseInsensitive ? values.map((val) => val.toLowerCase()) : values);
        } catch {}
        setIsFetching(false);
      }
    })();
  }, [getReservedValues, reservedValuesCaseInsensitive]);

  return (
    <>
      <SLabel label={props.label} required={!props.optional} />
      <Form.Item
        name={props.field}
        rules={[
          { required: !props.optional },
          { whitespace: true },
          { pattern: props.regex?.regex },
          props.lengthRange ? { min: props.lengthRange[0], max: props.lengthRange[1] } : {},
          ({ getFieldValue }) => ({
            validator() {
              if (props.regex && !props.regex.regex.test(getFieldValue(props.field))) {
                return Promise.reject(`Field should have the following format: ${props.regex.category}`);
              }
              const value: string = getFieldValue(props.field) || "";
              if (reservedValues.includes(reservedValuesCaseInsensitive ? value.toLowerCase() : value)) {
                return Promise.reject(`${props.label} is already taken`);
              }
              return Promise.resolve();
            },
          }),
        ]}
        validateStatus={isFetching ? "validating" : undefined}
        hasFeedback
        className="sform__item"
        initialValue={props.initialValue}
      >
        {props.textarea ? (
          <TextArea placeholder={`${props.label}...`} rows={3} disabled={props.disabled} onChange={props.onChange} />
        ) : (
          <Input
            autoComplete="off"
            placeholder={`${props.label}...`}
            addonBefore={props.addonBefore}
            disabled={props.disabled}
            onChange={props.onChange}
          />
        )}
      </Form.Item>
      <div />
    </>
  );
};

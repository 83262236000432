export function getRuleDescription(subSchema: any) {
  if (subSchema.type === "string") {
    if (subSchema.enum) {
      return subSchema.enum.join(", ");
    } else if (subSchema.pattern) {
      return `Regex: ${subSchema.pattern}`;
    } else if (subSchema.format) {
      return `Format: ${subSchema.format}`;
    }
  } else if (subSchema.type === "boolean") {
    if (subSchema.boolean + "") {
      return subSchema.boolean + "";
    }
  } else if (subSchema.type === "integer") {
    if (subSchema.enum) {
      return subSchema.enum.sort().join(", ");
    } else if (subSchema.minimum || subSchema.maximum) {
      const minText = subSchema.minimum ? `${subSchema.minimum} ≤` : "";
      const maxText = subSchema.maximum ? `≤ ${subSchema.maximum}` : "";
      return `${minText} value ${maxText}`;
    }
  } else if (subSchema.type === "array") {
    return `Contains one of: ${subSchema.contains?.enum?.join(", ")}`;
  }
  return "";
}

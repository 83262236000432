import React, { useCallback, useEffect, useRef, useState } from "react";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import TextArea from "antd/lib/input/TextArea";
import { Alert, Button, Tooltip } from "antd";
import { CheckCircleTwoTone, ExclamationCircleTwoTone, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { COLOR_GREEN, COLOR_RED } from "../../lib/definitions/style_definitions";
import { JsonReader } from "../fileArea/jsonReader";
import { getSchema } from "../../lib/functions/services_functions";

interface EditSchemaProps {
  initialSchema: object;
  example: object;
  schemaIsOld: boolean;
  onValidSchemaChange(schema: any): void;
}

export function EditSchema(props: EditSchemaProps) {
  const [schema, setSchema] = useState<object>(props.initialSchema);
  const [isValidJson, setIsValidJson] = useState(false);
  const [errorMessage, setErrorMessge] = useState<string>();
  const [generateSuccess, setGenerateSuccess] = useState(false);
  const [editHistoryBack, setEditHistoryBack] = useState<string[]>([]);
  const [editHistoryForward, setEditHistoryForward] = useState<string[]>([]);

  const [editorValue, setEditorValue] = useState(JSON.stringify(props.initialSchema, null, 4));

  const lastValidSchema = useRef("");

  const { onValidSchemaChange } = props;

  const isMounted = useRef(true);
  const wasUndo = useRef(false);

  const updateEditHistory = useCallback(
    (schema: any) => {
      const schemaAsString = JSON.stringify(schema);
      if (editHistoryBack[editHistoryBack.length - 1] === schemaAsString) {
        return;
      }

      setEditHistoryBack((history) => history.concat(schemaAsString));

      if (wasUndo.current) {
        wasUndo.current = false;
      } else {
        setEditHistoryForward([]);
      }
    },
    [editHistoryBack]
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!schema || JSON.stringify(schema) === lastValidSchema.current) {
      return;
    }

    const ajv = new Ajv();
    addFormats(ajv);

    try {
      ajv.compile(schema);
      onValidSchemaChange(schema);
      updateEditHistory(schema);
      setErrorMessge("");
    } catch (err) {
      const errors = ajv.errors;
      if (errors) {
        const errPath = errors[0].dataPath
          .split("/")
          .filter((s) => !!s)
          .join(".");

        const errMessage = "should be a legal type";
        const fullError = `'${errPath}' ${errMessage}`;
        setErrorMessge(fullError);
      } else if (err) {
        setErrorMessge(err.message);
      }
    }
  }, [schema, onValidSchemaChange, updateEditHistory]);

  useEffect(() => {
    try {
      JSON.parse(editorValue);
      lastValidSchema.current = JSON.stringify(getSchema(editorValue));
      setIsValidJson(true);
    } catch {
      setIsValidJson(false);
    }
  }, [editorValue]);

  function format() {
    try {
      setEditorValue(JSON.stringify(JSON.parse(editorValue), null, 4));
    } catch {}
  }

  function generateSchemaFromExample() {
    setEditorValue(JSON.stringify(getSchema(props.example), null, 4));
    setSchema(getSchema(props.example));
  }

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={format} disabled={!isValidJson} style={{ marginRight: "8px" }}>
              Format
            </Button>
            <Tooltip title={isValidJson ? "Input is valid JSON" : "Input is invalid JSON"}>
              {isValidJson ? (
                <CheckCircleTwoTone twoToneColor={COLOR_GREEN} style={{ fontSize: "20px" }} />
              ) : (
                <ExclamationCircleTwoTone twoToneColor={COLOR_RED} style={{ fontSize: "20px" }} />
              )}
            </Tooltip>
            <Button
              style={{ marginLeft: "24px" }}
              icon={<UndoOutlined />}
              disabled={editHistoryBack.length <= 1}
              onClick={() => {
                const schema = JSON.parse(editHistoryBack[editHistoryBack.length - 2]);
                setSchema(schema);
                setEditorValue(JSON.stringify(schema, null, 4));
                setEditHistoryBack((history) => history.slice(0, -1));
                setEditHistoryForward((history) => history.concat(editHistoryBack[editHistoryBack.length - 1]));
              }}
            />
            <Button
              style={{ marginLeft: "8px" }}
              icon={<RedoOutlined />}
              disabled={editHistoryForward.length === 0}
              onClick={() => {
                const schemaAsString = editHistoryForward[editHistoryForward.length - 1];
                const schema = JSON.parse(schemaAsString);
                setSchema(schema);
                setEditorValue(JSON.stringify(schema, null, 4));
                setEditHistoryForward((history) => history.slice(0, -1));
                setEditHistoryBack((history) => history.concat(schemaAsString));
              }}
            />
          </div>
          <Tooltip title={generateSuccess ? "Schema generated!" : "Generate schema from example JSON"}>
            <Button
              onClick={() => {
                generateSchemaFromExample();
                setGenerateSuccess(true);
                setTimeout(() => setGenerateSuccess(false), 2000);
              }}
            >
              Generate from example
            </Button>
          </Tooltip>
        </div>
        {errorMessage && (
          <Alert style={{ marginBottom: "8px" }} message={"Schema invalid: " + errorMessage} type="error" showIcon />
        )}
        {props.schemaIsOld && (
          <Alert
            style={{ marginBottom: "8px" }}
            message={
              <span>
                Example has changed since last update of schema and is now invalid according to schema.{" "}
                <span className="general__link" onClick={generateSchemaFromExample}>
                  Update schema
                </span>
                .
              </span>
            }
            type="warning"
            showIcon
          />
        )}
        <div
          style={{
            height: "500px",
          }}
        >
          <TextArea
            value={editorValue}
            placeholder={JSON.stringify({ type: "object", properties: { someKey: { type: "someType..." } } }, null, 4)}
            onChange={(e) => {
              setEditorValue(e.target.value);
              try {
                setSchema(JSON.parse(e.target.value));
              } catch {}
            }}
            className="general__code-text-area"
          />
        </div>
        <JsonReader
          onFileSelect={(text) => {
            try {
              setEditorValue(JSON.stringify(JSON.parse(text), null, 4));
            } catch {
              setEditorValue(text);
            }
          }}
          style={{ marginTop: "12px" }}
          width="100%"
        />
      </div>
    </div>
  );
}

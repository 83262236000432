import * as localForage from "localforage";
import { DEFAULT_TEAM_ID, DEFAULT_ENV_ID } from "../definitions/localForage_definitions";
import { authProvider } from "../authProvider";


export function setDefaultPreferences() {
  localForage.getItem(DEFAULT_TEAM_ID, (err, val) => {
    if (!err) {
      if (!val) {
        localForage.setItem(DEFAULT_TEAM_ID, "last");
      }
    }
  });
  localForage.getItem(DEFAULT_ENV_ID, (err, val) => {
    if (!err) {
      if (!val) {
        localForage.setItem(DEFAULT_ENV_ID, "last");
      }
    }
  });
}

export function getMyEmail() {
  return authProvider.getAccount().userName.toLowerCase();
}

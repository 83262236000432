import { Tooltip, Button, Checkbox, Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SFormEnum } from "../createForm/sFormEnum";
import { SFormInput } from "../createForm/sFormInput";
import { SFormCustom } from "../createForm/sFormCustom";
import { SFormSection } from "../createForm/sFormSection";
import { SJson } from "../detailView/sJson";
import { EnvironmentContext, MyTeamsContext } from "../../../lib/contexts";
import { SFormSelect } from "../createForm/sFormSelect";
import { SLabel } from "../createForm/sLabel";

interface EndpointSectionType {
  selectedEndpoint?: string;
  setSelectedEndpoint?: React.Dispatch<React.SetStateAction<string>>;
  destination?: string | Array<string>;
  setDestination?: any;
  authType?: string;
  setAuthType?: React.Dispatch<React.SetStateAction<string>>;
  issuer?: string;
  setIssuer?: React.Dispatch<React.SetStateAction<string>>;
  iamChecked?: boolean;
  setIamChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  policyTemplate?: any;
  setPolicyTemplate?: React.Dispatch<any>;
  udt?: boolean;
  setUdt?: React.Dispatch<React.SetStateAction<boolean>>;
  envelope?: boolean;
  setEnvelope?: React.Dispatch<React.SetStateAction<boolean>>;
  decoupled?: boolean;
  setDecoupled?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  apiKey?: string;
  setApiKey?: React.Dispatch<React.SetStateAction<string>>;
  oAuthApplication?: string;
  setOAuthApplication?: React.Dispatch<React.SetStateAction<string>>;
}

export const EndpointSection: React.FC<EndpointSectionType> = (props: EndpointSectionType) => {
  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const [authType, setAuthType] = useState("");
  const [selectedEndpoint, setSelectedEndpoint] = useState("");
  const [destination, setDestination] = useState("");
  const [iamChecked, setIamChecked] = useState<boolean>(false);
  const [udt, setUdt] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [oAuthApplication, setOAuthApplication] = useState("");
  const [envelope, setEnvelope] = useState(false);
  const [decoupled, setDecoupled] = useState(false);
  const [apis, setApis] = useState<any[]>([]);
  const [applications, setApplications] = useState<any[]>([]);
  const [policyTemplate, setPolicyTemplate] = useState<any>({
    Version: "2008-10-17",
    Statement: [
      {
        Effect: "Allow",
        Principal: {
          AWS: "arn:aws:iam::885586782563:role/ebm-dequeue-role",
        },
        Action: ["s3:PutObject", "s3:PutObjectAcl"],
        Resource: "arn:aws:s3:::<bucket name>/*",
      },
    ],
  });
  const pathArr = window.location.pathname.split("/");
  useEffect(() => {
    setPolicyTemplate((prevState: any) => ({
      ...prevState,
      Statement: [
        {
          Effect: "Allow",
          Principal: {
            AWS: "arn:aws:iam::885586782563:role/ebm-dequeue-role",
          },
          Action: ["s3:PutObject", "s3:PutObjectAcl"],
          Resource: "arn:aws:s3:::" + (props.destination ? props.destination : destination) + "/*",
        },
      ],
    }));
  }, [destination, props.destination]);

  useEffect(() => {
    let arr: any[] = [];
    let arr1: any[] = [];
    selectedTeam?.applications.forEach((app) => {
      arr.push(...app.services.filter((service) => service.serviceType === "API"));
      arr1.push(app);
    });
    setApis(
      arr
        .filter((api) => api.env === selectedEnvironment?._id)
        .map((api) => ({
          value: api._id,
          label: api.name,
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value, "en"))
    );
    setApplications(
      arr1
        .filter((app) => app.env === selectedEnvironment?._id)
        .map((app) => ({
          value: app._id,
          label: app.name,
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value, "en"))
    );
  }, [selectedEnvironment, selectedTeam]);

  return (
    <SFormSection title="Endpoint">
      <SFormEnum
        label="Choose Endpoint"
        field="type"
        onChange={props.setSelectedEndpoint ? props.setSelectedEndpoint : setSelectedEndpoint}
        options={[
          { label: "API", value: "api" },
          { label: "S3", value: "s3" },
          { label: "Mail", value: "mail" },
          { label: "i2 API", value: "i2api" },
        ]}
        disabled={props.disabled ? props.disabled : false}
      />

      {(props.selectedEndpoint === "api" || selectedEndpoint === "api") && (
        <>
          <SFormInput
            label="API URL"
            field="destination"
            initialValue={props.destination ? (props.destination as string) : destination}
            onChange={(event) =>
              props.setDestination ? props.setDestination(event.target.value) : setDestination(event.target.value)
            }
            disabled={props.disabled ? props.disabled : false}
          />
          <SFormCustom />
          <SFormEnum
            label="Authentication"
            field="authType"
            options={[
              { label: "JWT", value: "jwt" },
              { label: "API Key", value: "apiKey" },
              { label: "Other", value: "other" },
            ]}
            onChange={(value) => (props.setAuthType ? props.setAuthType(value) : setAuthType(value))}
            disabled={props.disabled ? props.disabled : false}
          />
          {(props.authType === "apiKey" || authType === "apiKey") && (
            <>
              <SFormCustom />
              <SFormInput
                label="API key"
                field="apiKey"
                initialValue={props.apiKey ? (props.apiKey as string) : apiKey}
                onChange={(event) => {
                  props.setApiKey ? props.setApiKey(event.target.value) : setApiKey(event.target.value);
                }}
              ></SFormInput>
            </>
          )}
          {(props.authType === "jwt" || authType === "jwt") && (
            <>
              <SFormCustom />
              <SFormSelect
                label="Application"
                initialValue={props.oAuthApplication ? (props.oAuthApplication as string) : oAuthApplication}
                field="oAuthApplication"
                options={applications}
                disabled={props.disabled}
                onChange={(value) =>
                  props.setOAuthApplication ? props.setOAuthApplication(value as string) : setOAuthApplication(value)
                }
              />
            </>
          )}
          <SFormCustom />
          <SFormEnum
            label="Adapter"
            field="issuer"
            options={[
              { label: "TYK", value: "tyk" },
              { label: "SAP", value: "sap" },
              { label: "None", value: "none" },
            ]}
            onChange={(value) => {
              if (props.setIssuer) {
                props.setIssuer(value);
              }
            }}
            disabled={props.disabled ? props.disabled : false}
          />
        </>
      )}
      {(props.selectedEndpoint === "i2api" || selectedEndpoint === "i2api") && (
        <>
          <SFormSelect
            label="API"
            initialValue={props.destination as string}
            field="destination"
            options={apis}
            disabled={props.disabled}
            onChange={(value) => (props.setDestination ? props.setDestination(value as string) : setDestination(value))}
          />
        </>
      )}
      {(props.selectedEndpoint === "s3" || selectedEndpoint === "s3") && (
        <>
          <SFormInput
            label="S3 URL"
            field="destination"
            initialValue={props.destination ? (props.destination as string) : destination}
            onChange={(event) => {
              props.setDestination ? props.setDestination(event.target.value) : setDestination(event.target.value);
            }}
            disabled={props.disabled ? props.disabled : false}
          />
          <SFormCustom />
          <SFormCustom />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gridColumn: "span 2" }}>
            <Checkbox
              onChange={(value) =>
                props.setIamChecked ? props.setIamChecked(value.target.checked) : setIamChecked(value.target.checked)
              }
              checked={props.iamChecked ? props.iamChecked : iamChecked}
              disabled={props.disabled ? props.disabled : false}
            >
              EBM has access to my S3 Bucket
            </Checkbox>
            <Tooltip
              title={
                <div>
                  <p>Click to open a guide on how to update your IAM Policy in order to receive events from EBM</p>
                  <p>https://confluence.corp.lego.com/display/DI/How+to+receive+data+from+EBM</p>
                </div>
              }
            >
              <Button type="link">
                <a
                  rel="noopener noreferrer"
                  href="https://confluence.corp.lego.com/display/DI/How+to+receive+data+from+EBM"
                  target="_blank"
                >
                  Guide
                </a>
              </Button>
            </Tooltip>
          </div>
          <SFormCustom />
          <SFormCustom />
          <div style={{ gridColumn: "span 8", marginTop: "8px" }}>
            <SJson json={policyTemplate}></SJson>
          </div>
        </>
      )}
      {(props.selectedEndpoint === "mail" || selectedEndpoint === "mail") && (
        <>
          <SLabel label="Mails" required={true}></SLabel>
          <Form.Item
            name="destination"
            initialValue={props.destination}
            rules={[
              {
                required: true,
                message: "Please write one or multiple mail addresses",
                type: "array",
                defaultField: { type: "email" },
              },
            ]}
          >
            <Select
              mode="tags"
              placeholder="Mails.."
              dropdownClassName="services__hide-dropdown"
              onChange={props.setDestination}
              disabled={props.disabled}
            ></Select>
          </Form.Item>
        </>
      )}
      <SFormCustom />

      {(props.selectedEndpoint || selectedEndpoint) && !pathArr.includes("create") && (
        <div style={{ display: "flex", justifyContent: "flex-end", gridColumn: "5" }}>
          <SFormCustom
            first={
              <Checkbox
                onChange={(res) => (props.setUdt ? props.setUdt(res.target.checked) : setUdt(res.target.checked))}
                checked={props.udt ? props.udt : udt}
                disabled={props.disabled ? props.disabled : false}
              >
                UDT
              </Checkbox>
            }
            second={
              <Checkbox
                onChange={(res) =>
                  props.setEnvelope ? props.setEnvelope(res.target.checked) : setEnvelope(res.target.checked)
                }
                checked={props.envelope ? props.envelope : envelope}
                disabled={props.disabled ? props.disabled : false}
              >
                Envelope
              </Checkbox>
            }
            third={
              <Checkbox
                onChange={(res) =>
                  props.setDecoupled ? props.setDecoupled(res.target.checked) : setDecoupled(res.target.checked)
                }
                checked={props.decoupled ? props.decoupled : decoupled}
                disabled={props.disabled ? props.disabled : false}
              >
                Decoupled
              </Checkbox>
            }
          />
        </div>
      )}
    </SFormSection>
  );
};

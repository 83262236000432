import React from "react";
import { Tabs } from "antd";

import { FeatureFeedback } from "./featureFeedback";
import { RatingFeedback } from "./ratingFeedback";

interface FeedbackModalContentProps {
  feedbackType: "rating" | "feature" | "general";
  action: string;
  onClose(): void;
}

export function FeedbackModalContent(props: FeedbackModalContentProps) {
  return (
    <div>
      {props.feedbackType === "feature" && <FeatureFeedback onClose={props.onClose} />}
      {props.feedbackType === "rating" && <RatingFeedback action={props.action} onClose={props.onClose} />}
      {props.feedbackType === "general" && (
        <Tabs>
          <Tabs.TabPane tab="Rate i2" key="1">
            <RatingFeedback action="general" onClose={props.onClose} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Suggest new feature" key="2">
            <FeatureFeedback onClose={props.onClose} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
}

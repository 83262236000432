import { Button, Form, Rate, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { DIDBResource } from "../../lib/definitions/general_definitions";
import { doFetch, getNotificationError, getNotificationSuccess } from "../../lib/functions/general_functions";
import { getMyEmail } from "../../lib/functions/profile_functions";

interface RatingFeedbackProps {
  action: string;
  onClose(): void;
}

export function RatingFeedback(props: RatingFeedbackProps) {
  const isMounted = useRef(true);
  const [isPosting, setIsPosting] = useState(false);
  var isAnonymous = true;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function createRatingFeedback(values: any) {
    setIsPosting(true);
    const action = props.action;

    await doFetch(
      "POST",
      DIDBResource.Feedbacks,
      isMounted,
      () => getNotificationSuccess("Sent", "Feedback"),
      () => getNotificationError("Uploading feedback", "An error occured while uploading feedback"),
      () => {
        props.onClose();
        setIsPosting(false);
      },
      { ...values, action: action, timestamp: Date.now(), userEmail: setUserEmail() }
    );
  }

  function setUserEmail() {
    if (isAnonymous) {
      return "";
    } else {
      return getMyEmail();
    }
  }

  function onChange(e: any) {
    isAnonymous = e.target.checked;
  }

  return (
    <Form onFinish={(values) => createRatingFeedback(values)}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Form.Item name="rating" label="Rating" rules={[{ required: true, message: "A rating must be given" }]}>
            <Rate style={{ marginLeft: "8px" }} />
          </Form.Item>
        </div>
        <Form.Item name="text">
          <TextArea placeholder={"Additional comment"} rows={4} />
        </Form.Item>
        <div
          style={{
            marginBottom: "24px",
          }}
        >
          <Checkbox onChange={(e) => onChange(e)}>Send anonymously</Checkbox>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={props.onClose}> Not Now </Button>
          <Button
            style={{
              backgroundColor: "#1890ff",
              color: "white",
            }}
            loading={isPosting}
            htmlType="submit"
          >
            Send
          </Button>
        </div>
      </div>
    </Form>
  );
}

import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { Tree, Input } from "antd";
import { Key } from "antd/lib/table/interface";
import { getTreeData, getOrderedKeysFromPositions } from "../../lib/functions/services_functions";

interface JsonAsTreeProps {
  json: any;
  checkedKeys?: Key[];
  selectedKey?: Key;
  isTypes?: boolean;
  searchable?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
  maxHeight?: number;
  createTreeData?(json: any, searchInput?: string): { roots: any[]; expKeys: Key[] };
  onClick?(node: any): void;
  setCheckedKeys?(checkedKeys: Key[]): void;
}

export const JsonAsTree: React.FC<JsonAsTreeProps> = (props) => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchInput, setSearchInput] = useState("");

  const canExpand = useRef(true);

  const { json, isTypes, createTreeData } = props;

  useEffect(() => {
    const { roots, expKeys } = createTreeData ? createTreeData(json, searchInput) : getTreeData(json, isTypes);
    setTreeData(roots);
    setExpandedKeys(expKeys);
  }, [json, isTypes, createTreeData, searchInput]);

  function handleExpand(keys: Key[]) {
    if (canExpand.current) {
      setExpandedKeys(keys);
      canExpand.current = false;
      setTimeout(() => (canExpand.current = true), 600);
    }
  }

  let selectedKeysProp = {};

  if (props.selectedKey) {
    selectedKeysProp = { selectedKeys: [props.selectedKey] };
  }

  return (
    <div>
      {props.searchable && (
        <div style={{ marginBottom: "8px" }}>
          <Input
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setSearchInput("");
              }
            }}
          />
        </div>
      )}
      {treeData.length > 0 && (
        <div
          style={{ whiteSpace: "nowrap", maxHeight: props.maxHeight, overflow: props.maxHeight ? "scroll" : "auto" }}
        >
          <Tree
            autoExpandParent={!!searchInput}
            treeData={treeData}
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            onCheck={(_checked, info) => {
              if (props.setCheckedKeys) {
                props.setCheckedKeys(getOrderedKeysFromPositions(info.checkedNodesPositions || []));
              }
            }}
            onClick={(_e, node) => {
              if (props.onClick) {
                props.onClick(node);
              }
            }}
            {...selectedKeysProp}
            checkedKeys={treeData.length === 0 ? [] : props.checkedKeys}
            checkable={!!props.checkedKeys}
            className="general__json-as-tree"
            style={props.style}
          />
        </div>
      )}
    </div>
  );
};

import React, { useRef, useEffect, useContext, useState } from "react";
import { Table, Tag } from "antd";
import { EnvironmentContext, ProviderContractContext, RequesterContractContext } from "../lib/contexts";
import { nameToPath } from "../lib/definitions/general_definitions";
import { Contract } from "../components/home/contract";
import { Route, Switch } from "react-router-dom";
import { useSHistory } from "../lib/functions/general_functions";
import { SuperTableColumn } from "../components/superTable";

export const RequestsPage = () => {
  const { specificTeamProviderContracts } = useContext(ProviderContractContext);
  const { specificTeamConsumerContracts } = useContext(RequesterContractContext);
  const history = useSHistory();
  const isMounted = useRef(true);
  const pathArr = history.location.pathname.split("/");
  const [isRequests, setIsRequests] = useState(false);

  const { environments } = useContext(EnvironmentContext);

  useEffect(() => {
    if (`/${pathArr[1]}` === nameToPath["Requests"]) setIsRequests(true);

    return () => {
      isMounted.current = false;
    };
  }, [pathArr]);

  const rowFunction = (record: any, rowIndex: any) => {
    return {
      onClick: async (event: any) => {
        isRequests
          ? history.push(`${nameToPath["Requests"]}/${record._id}`)
          : history.push(`${nameToPath["Approvals"]}/${record._id}`);
      },
    };
  };

  const contractColumns: SuperTableColumn[] = [
    {
      title: "Environment",
      dataIndex: "env",
      collapse: true,
      render: (environment: string) => (
        <Tag color={environments.find((e) => e._id === environment)?.colorCode} key={environment}>
          {environment}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <Tag color={status === "pending" ? "volcano" : "green"} key={status}>
          {status}
        </Tag>
      ),
    },
    { title: "Application", dataIndex: "consumingApplication.name" },
    {
      title: "Date",
      dataIndex: "timestamp",
      collapse: true,
      cellTitle: (value) => new Date(value).toDateString(),
      render: (timestamp: any) => <div>{new Date(timestamp).toDateString()}</div>,
    },
    {
      title: "Team",
      dataIndex: "consumingTeam",
      cellTitle: (value) => value.map((t: any) => t.name).join(", "),
      render: (consumingTeam: any) => <>{consumingTeam ? (consumingTeam[0] ? consumingTeam[0].name : "") : ""}</>,
    },
    { title: "Contract type", dataIndex: "eventType" },
    { title: "Service", dataIndex: "service", render: (service: any) => <>{service?.name}</> },
    {
      title: "Type",
      dataIndex: "serviceType",
      render: (type: string) => <Tag color={type === "event" ? "cyan" : "purple"}>{type?.toLocaleUpperCase()}</Tag>,
    },
  ];

  return (
    <div>
      <Table
        columns={contractColumns}
        dataSource={
          isRequests
            ? specificTeamProviderContracts.filter((contract) => contract.status === "pending")
            : specificTeamConsumerContracts.filter((contract) => contract.status === "pending")
        }
        onRow={rowFunction}
        pagination={{ defaultPageSize: 5 }}
        rowKey={"_id"}
      />
      <Switch>
        {isRequests ? (
          <Route exact path={[`${nameToPath["Requests"]}/:id`]} component={Contract} />
        ) : (
          <Route exact path={[`${nameToPath["Approvals"]}/:id`]} component={Contract} />
        )}
      </Switch>
    </div>
  );
};

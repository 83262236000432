import React, { useContext, useEffect, useRef } from "react";
import {
  nameToPath,
  pathToName,
  DI_CONFLUENCE_URL,
  TEAMS_COMMUNITY_URL,
  AWS_LOGIN_URL,
} from "../../lib/definitions/general_definitions";
import { ProfileItem } from "./profileItem";
import { EnvironmentSelect } from "./environmentSelect";
import { Dropdown, Menu } from "antd";

import img from "../../lib/images/i2.png";
import { AlphaTag } from "./alphaTag";
import { EnvironmentContext, MeContext } from "../../lib/contexts";
import { KeyOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";
import { useSHistory } from "../../lib/functions/general_functions";

export const Menubar = () => {
  const history = useSHistory();
  const isMounted = useRef(true);

  const selectedEnvironment = useContext(EnvironmentContext);
  const { data } = useContext(MeContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getItem = (pageName: keyof typeof nameToPath) => {
    const path = history.location.pathname;
    const resource = path.split("/")[1];
    let className = "menubar__item";
    className += pageName === pathToName[`/${resource}` as keyof typeof pathToName] ? " menubar__item--selected" : "";

    return (
      <div className={className} onClick={() => history.push(nameToPath[pageName])}>
        {pageName}
      </div>
    );
  };

  return (
    <div className="menubar">
      <div className="menubar__left">
        <img src={img} alt="" style={{ height: "28px", width: "28px", marginRight: "8px" }} />
        <AlphaTag />
        <div style={{ marginRight: "8px" }}>1.0.1</div>
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href={AWS_LOGIN_URL}>
                  AWS Login
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href={TEAMS_COMMUNITY_URL}>
                  Community
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href={DI_CONFLUENCE_URL}>
                  Documentation
                </a>
              </Menu.Item>
              {data.tykUserGroupId && (
                <MenuItem>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={selectedEnvironment.selectedEnvironment?.tyk.ssoUrl}
                  >
                    TYK Dashboard
                  </a>
                </MenuItem>
              )}
            </Menu>
          }
        >
          <span style={{ cursor: "pointer" }}>Help</span>
        </Dropdown>
      </div>
      <div style={{ width: "60px" }} />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          {getItem("Home")}
          {getItem("Services")}
          {getItem("Teams")}
        </div>
        {data.isAdmin && (
          <div style={{ display: "flex", alignItems: "center" }}>
            {getItem("Applications")}
            {getItem("Feedbacks")}
            {getItem("Logs")}
            {getItem("Monitor")}
            {getItem("Namepaces")}
            <KeyOutlined style={{ fontSize: "20px", color: "gray", marginLeft: "4px" }} />
          </div>
        )}
      </div>
      <div className="menubar__right">
        <EnvironmentSelect />
        <ProfileItem />
      </div>
    </div>
  );
};

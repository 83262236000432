import React, { useEffect, useState } from "react";
import img from "../../lib/images/i2.png";

export function InitialLoadingScreen() {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setDots((d) => (d + 1) % 4), 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr", height: "80vh", alignItems: "center" }}>
      <div />
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={img} alt="" style={{ height: "60px", width: "60px", marginRight: "24px" }} />
        <h1 style={{ fontSize: "60px", margin: 0 }}>Integration Portal</h1>
      </div>
      <h1 style={{ fontSize: "60px", margin: 0 }}>{".".repeat(dots)}</h1>
    </div>
  );
}

import { SuperTable } from "../components/superTable";
import React, { useEffect, useRef, useState } from "react";
import { JsonAsTree } from "../components/general/jsonAsTree";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { Namespace } from "../lib/definitions/models";
import { getNamespacesAdminColumns } from "../lib/definitions/table_column_definitions";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { useContext } from "react";
import { EnvironmentContext } from "../lib/contexts";
import { useCallback } from "react";

export function NamespacePage() {
  const [namespaces, setNamespaces] = useState<Namespace[]>([]);
  const [singleNamespace, setSingleNamespaces] = useState<Namespace>();
  const [isFetching, setIsFetching] = useState(false);
  const isMounted = useRef(true);

  const { selectedEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchNamespaces = useCallback(() => {
    setIsFetching(true);
    doFetch(
      "GET",
      `${DIDBResource.Namespaces}`,
      isMounted,
      (res) => setNamespaces(res.value.filter((n: any) => n.env === selectedEnvironment?._id)),
      getModalError("Fetching Namespaces"),
      () => setIsFetching(false)
    );
  }, [selectedEnvironment?._id]);

  useEffect(() => {
    fetchNamespaces();
  }, [fetchNamespaces]);

  return (
    <div>
      <SuperTable
        rows={namespaces}
        columns={getNamespacesAdminColumns(isMounted)}
        onRowClick={setSingleNamespaces}
        selectedRow={singleNamespace}
        rowKey="_id"
        defaultPageSize={20}
        refreshProps={{ isRefreshing: isFetching, onRefresh: fetchNamespaces }}
      />
      <JsonAsTree json={singleNamespace} />
    </div>
  );
}

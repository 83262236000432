import React, { useContext, useRef, useEffect, useState, useCallback } from "react";
import { MyTeamsContext } from "../../lib/contexts";
import {
  getModalNotFound,
  doFetch,
  getModalError,
  getNotificationSuccess,
  useSHistory,
} from "../../lib/functions/general_functions";
import { nameToPath, DIDBResource } from "../../lib/definitions/general_definitions";
import { useParams } from "react-router-dom";
import { DNS } from "../../lib/definitions/models";
import { SView } from "../general/detailView/sView";
import { SSection } from "../general/detailView/sSection";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SElementBoolean } from "../general/detailView/Elements/sElementBoolean";
import { SElementTags } from "../general/detailView/Elements/sElementTags";
import { DeployButton } from "./deployButton";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { SuperTable } from "../superTable";
import { dnsNamespacesColumns } from "../../lib/definitions/table_column_definitions";
import { ReassignButton } from "./reassignButton";

export const DNSPane = () => {
  const [dns, setDns] = useState<DNS>();
  const [namespaces, setNamespaces] = useState<any[]>([]);
  const [mappedNamespaces, setMappedNamespaces] = useState<any[]>([]);
  const [teamNames, setTeamNames] = useState<string[]>([]);
  const [isLoadingNamespaces, setIsLoadingNamespaces] = useState<boolean>(false);
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);

  const history = useSHistory();
  const { id } = useParams<{ id: string }>();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      const dns = selectedTeam?.dns.find((dns: any) => dns._id === id);
      if (dns) {
        setDns(dns);
      } else {
        getModalNotFound("DNS", id, () => history.push(nameToPath["HomeDNS"]));
      }
    }
  }, [id, selectedTeam, history]);

  const fetchNamespaces = useCallback(async () => {
    setIsLoadingNamespaces(true);
    setNamespaces([]);
    if (dns) {
      await doFetch(
        "GET",
        `${DIDBResource.Namespaces}?dnsId=${dns?._id}`,
        isMounted,
        (response) => {
          if (isMounted.current) setNamespaces(response.value);
        },
        getModalError("Fetching Namespaces"),
        undefined,
        undefined
      );
    }
    setIsLoadingNamespaces(false);
  }, [dns]);

  const fetchTeamNames = useCallback(async () => {
    // Must fetch all TeamNames in order to display team names instead of ID's
    await doFetch(
      "GET",
      DIDBResource.Teams,
      isMounted,
      (response) => {
        if (isMounted.current) setTeamNames(response.value);
      },
      getModalError("Fetching Team Names"),
      undefined,
      undefined
    );
  }, []);

  useEffect(() => {
    fetchTeamNames();
    fetchNamespaces();
  }, [dns, fetchNamespaces, fetchTeamNames]);

  useEffect(() => {
    if (namespaces) {
      setMappedNamespaces(
        namespaces.map((namespace: any) => {
          let teamName = "";
          teamNames.forEach((team: any) => {
            if (team._id === namespace.teamId) {
              teamName = team.name;
              return;
            }
          });
          return {
            _id: namespace._id,
            name: `${dns?.name}/${namespace.name}`,
            team: teamName,
            description: namespace.description,
          };
        })
      );
    }
  }, [namespaces, teamNames, dns]);

  async function putDns(dnsValues: any): Promise<boolean> {
    let success = false;
    await doFetch(
      "PUT",
      `${DIDBResource.DNS}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccess("Updated", "DNS", dnsValues.name);
        history.push(`${nameToPath["HomeDNS"]}/${id}`);
        success = true;
      },
      (errorContent: any) => {
        getModalError("Updating DNS")(errorContent);
        success = false;
      },
      undefined,
      dnsValues
    );
    return success;
  }

  async function deleteDns() {
    await doFetch(
      "DELETE",
      `${DIDBResource.DNS}/${id}`,
      isMounted,
      () => {
        getNotificationSuccess("Deleted", "DNS", dns?.name);
        refreshMyTeams();
        history.push(nameToPath["HomeDNS"]);
      },
      getModalError("Deleting DNS")
    );
  }

  return (
    <SView
      key={id}
      title="DNS"
      subTitle={dns?.name}
      object={dns}
      onBack={() => history.push(nameToPath["HomeDNS"])}
      onSave={putDns}
      onDelete={deleteDns}
      extra={[
        <ReassignButton key="1" type="DNS" entityId={id} parentId={dns?.teamId} />,
        <DeployButton key="2" type="DNS" entityId={id} />,
      ]}
    >
      <SSection title="Details">
        <SElementInput label="Name" field="name" />
        <SElementBoolean label="Visible" field="visible" />
        <SElementTags label="Tags" field="tags" />
        <SElementInput label="Description" field="description" fieldType="textarea" lineWidth="double" />
      </SSection>
      <SSectionCustom title="Associated Namespaces" frameless>
        <SuperTable
          rows={mappedNamespaces}
          rowKey={"_id"}
          columns={dnsNamespacesColumns}
          refreshProps={{ isRefreshing: isLoadingNamespaces }}
        />
      </SSectionCustom>
    </SView>
  );
};

import { SuperTable } from "../components/superTable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { eventDataObjectsColumn } from "../lib/definitions/table_column_definitions";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { getKeysFromSchema } from "../lib/functions/services_functions";

interface DataObjectsSchemas {
  schema: Object;
  name: string;
  _id: string;
}

export function DataObjectsPage() {
  const [events, setEvents] = useState<any[]>([]);
  const [dataObjects, setDataObjects] = useState<DataObjectsSchemas[]>([]);
  const [keyOverviews, setKeyOverviews] = useState<any[]>([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const findDataObjects = useCallback(() => {
    const schemas = events.map((event) => ({ schema: event.schema, name: event.name, _id: event._id }));
    setDataObjects(schemas);
  }, [events]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    findDataObjects();
  }, [findDataObjects]);

  useEffect(() => {
    setKeyOverviews(getKeyOverview(dataObjects));
  }, [dataObjects]);

  function fetchEvents() {
    doFetch(
      "GET",
      `${DIDBResource.Events}`,
      isMounted,
      (res) => setEvents(res.value),
      getModalError("Error fetching events")
    );
  }

  function getKeyOverview(dataObjs: DataObjectsSchemas[]) {
    const keyOverview: { [k in string]: string[] } = {};

    dataObjs.forEach((dataObject) => {
      const keys = getKeysFromSchema(dataObject.schema);
      keys.forEach((key) => {
        if (keyOverview[key]) {
          keyOverview[key].push(dataObject.name);
        } else {
          keyOverview[key] = [dataObject.name];
        }
      });
    });

    const rows: { id: string; keyName: string; dataObjectNames: string[]; count: number; type: string }[] = [];

    for (const keyName in keyOverview) {
      const elements = keyName.split(";");
      rows.push({
        id: keyName,
        keyName: elements[0],
        dataObjectNames: keyOverview[keyName].sort(),
        count: keyOverview[keyName].length,
        type: elements[1],
      });
    }
    return rows;
  }

  return (
    <div>
      <SuperTable rows={keyOverviews} rowKey="id" columns={eventDataObjectsColumn} defaultPageSize={20} />
    </div>
  );
}

import { LoginType, MsalAuthProvider } from "react-aad-msal";
import { authProviderMock } from "./authProviderMock"
require('dotenv').config()

const config = {
  auth: {
    clientId: "c9999da2-3f5c-4e83-a2c7-2e14a0b36e8f",
    authority: "https://login.microsoftonline.com/1d063515-6cad-4195-9486-ea65df456faa",
    redirectUri: window.location.origin,
  },
};

const authenticationParameters = {
  scopes: ["user.read"],
};
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export let authProvider

if (process.env.REACT_APP_CYPRESS === "true") {
  authProvider = authProviderMock
} else {
  authProvider = new MsalAuthProvider(config, authenticationParameters, options);
}



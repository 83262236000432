import React from "react";
import { Card, PageHeader } from "antd";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { COLOR_BLUE, COLOR_GREEN } from "../../lib/definitions/style_definitions";
import Title from "antd/lib/typography/Title";
import { useSHistory } from "../../lib/functions/general_functions";

export const SelectTypeOfServiceToCreate = () => {
  const history = useSHistory();

  return (
    <div>
      <PageHeader title={"Select Service"} onBack={history.goBack} className="sdetail-view__small-content">
        <SSectionCustom frameless title="">
          <div className="dashboard__service-grid">
            <Card
              onClick={() => history.push(nameToPath["HomeEvents"] + nameToPath["Create"])}
              hoverable
              className="dashboard__grid--service-card"
            >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <UploadOutlined style={{ fontSize: 72, color: COLOR_BLUE }} />

                <Title level={3} style={{ color: "#000000", marginTop: "8px" }}>
                  Create Event
                </Title>
              </div>
            </Card>
            <Card
              onClick={() => history.push(nameToPath["HomeAPIs"] + nameToPath["Create"])}
              hoverable
              className="dashboard__grid--service-card"
            >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <DownloadOutlined style={{ fontSize: 72, color: COLOR_GREEN }} />

                <Title level={3} style={{ color: "#000000", marginTop: "8px" }}>
                  Create API
                </Title>
              </div>
            </Card>
          </div>
        </SSectionCustom>
      </PageHeader>
    </div>
  );
};

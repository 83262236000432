import React, { useContext, useEffect, useState } from "react";
import { DIDBResource, nameToPath, SelectOption } from "../../lib/definitions/general_definitions";
import { SwapOutlined, LoadingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Select } from "antd";
import { IndexTree } from "./indexTree";
import {
  LogsContext,
  MeContext,
  MyTeamsContext,
  ProviderContractContext,
  RequesterContractContext,
} from "../../lib/contexts";
import { Team } from "../../lib/definitions/models";
import { doFetch, getModalError, useSHistory } from "../../lib/functions/general_functions";

export const SideBar = () => {
  const [otherTeams, setOtherTeams] = useState<SelectOption[]>([]);
  const [teamsDropdownVisible, setTeamsDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { selectedTeam, isFetchingMyTeams, setSelectedTeam, myTeams } = useContext(MyTeamsContext);
  const { data } = useContext(MeContext);
  const { teamList } = useContext(LogsContext);
  const { refreshProviderContracts } = useContext(ProviderContractContext);
  const { refreshConsumerContracts } = useContext(RequesterContractContext);

  const history = useSHistory();

  useEffect(() => {
    if (!data.isAdmin || otherTeams.length > 0) {
      return;
    }

    setOtherTeams(
      teamList
        .map((team: Team) => ({ label: team.name, value: team._id }))
        .sort((a, b) => a.label.localeCompare(b.label))
    );
  }, [data, otherTeams, teamList]);

  function handleItemClick(item: any) {
    if (!item.type) {
      history.push(`/home/${item.key}`);
    } else {
      history.push(`/home/${item.type}/${item.id}`);
    }
  }

  return (
    <div
      style={{
        display: "grid",
        background: "#fafafa",
        border: "1px solid #d2d2d2",
        padding: "12px 12px",
        height: "calc(100vh - 140px)",
        gridTemplateRows: "min-content auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <h3 onClick={() => history.push(nameToPath["Home"])} className="home__tree-header" style={{ width: "80%" }}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={selectedTeam?.name}
          >
            {selectedTeam?.name}
          </div>
        </h3>
        <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
          {isFetchingMyTeams && (
            <LoadingOutlined style={{ fontSize: "20px", color: "#1890ff", margin: "0 8px" }} spin />
          )}
          {(myTeams.length > 1 || data.isAdmin) && (
            <Dropdown
              placement="bottomRight"
              trigger={["click"]}
              onVisibleChange={(visible) => setTeamsDropdownVisible(visible)}
              visible={teamsDropdownVisible}
              overlay={
                <Menu>
                  {myTeams
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((team) => (
                      <Menu.Item
                        key={team._id}
                        onClick={() => {
                          setSelectedTeam(team);
                          setTeamsDropdownVisible(false);
                          history.push(nameToPath["Home"]);
                          // if we are changing from an external team we need refetch contract
                          if (!myTeams.find((t) => t._id === team._id)) {
                            refreshConsumerContracts();
                            refreshProviderContracts();
                          }
                        }}
                      >
                        {team.name}
                      </Menu.Item>
                    ))}
                  {data.isAdmin && (
                    <>
                      <Menu.Divider />
                      <Menu.Item>
                        <Select
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          options={otherTeams}
                          placeholder="Select other team..."
                          showSearch
                          style={{ width: "100%" }}
                          searchValue={searchValue}
                          onSearch={setSearchValue}
                          filterOption={(input, option) =>
                            !!option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
                            option?.label === `Use ${searchValue}?`
                          }
                          dropdownMatchSelectWidth
                          onChange={(teamId) => {
                            setTeamsDropdownVisible(false);
                            refreshConsumerContracts(teamId?.toString());
                            refreshProviderContracts(teamId?.toString());

                            doFetch(
                              "GET",
                              `${DIDBResource.ExpandedTeams}/${teamId}`,
                              { current: true },
                              (res) => {
                                setSelectedTeam(res.value);
                              },
                              getModalError("Fetching team information")
                            );
                          }}
                        />
                      </Menu.Item>
                    </>
                  )}
                </Menu>
              }
            >
              <h3>
                <SwapOutlined title="Switch to another team" style={{ cursor: "pointer" }} />
              </h3>
            </Dropdown>
          )}
        </div>
      </div>
      <div
        style={{
          gridTemplateRows: "auto",
          overflowX: "auto",
        }}
      >
        <IndexTree onClick={handleItemClick} />
      </div>
    </div>
  );
};

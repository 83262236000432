import { SuperTable } from "../components/superTable";
import { Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { JsonAsTree } from "../components/general/jsonAsTree";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { feedbacksColoumns, suggestionsColoumns } from "../lib/definitions/table_column_definitions";
import { doFetch, getNotificationError } from "../lib/functions/general_functions";

interface Feedback {
  userEmail: string;
  text: string;
  rating: number;
  action: string;
}

interface Suggestion {
  userEmail: string;
  text: string;
}

export function FeedbackPage() {
  const [feedback, setFeedback] = useState<any[]>([]);
  const [singleFeedback, setSingleFeedback] = useState<Feedback>();
  const [isFetcingFeedbacks, setIsFetchingFeedbacks] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [singleSuggestion, setSingleSuggestion] = useState<Suggestion>();
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    fetchFeedbacks();
    fectchSuggestions();
    return () => {
      isMounted.current = false;
    };
  }, []);

  function fetchFeedbacks() {
    setIsFetchingFeedbacks(true);
    doFetch(
      "GET",
      DIDBResource.Feedbacks,
      isMounted,
      (res) => setFeedback(res.value),
      () => getNotificationError("Fetching feedbacks", "An error occured fetching "),
      () => setIsFetchingFeedbacks(false)
    );
  }

  function fectchSuggestions() {
    setIsFetchingSuggestions(true);
    doFetch(
      "GET",
      DIDBResource.Suggestions,
      isMounted,
      (res) => setSuggestions(res.value),
      () => getNotificationError("Fetching suggestions", "An error occured fetching suggestions"),
      () => setIsFetchingSuggestions(false)
    );
  }

  function calculateAverageRating() {
    return feedback.length
      ? feedback.reduce((sum, feed) => sum + (feed?.rating ? feed?.rating : 0), 0) / feedback.length
      : 0;
  }

  function createSingleSuggestionWithNoText() {
    const suggestion = {
      Email: singleSuggestion?.userEmail,
    };
    return suggestion;
  }

  function createSingleFeedbackWithNoText() {
    const feedback = {
      Email: singleFeedback?.userEmail,
      Rating: singleFeedback?.rating,
      Action: singleFeedback?.action,
    };
    return feedback;
  }

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "32px",
          fontSize: "24px",
        }}
      >
        <Rate
          style={{
            fontSize: "34px",
          }}
          allowHalf
          disabled
          value={Math.round(calculateAverageRating() * 2) / 2}
        />
        &nbsp; &nbsp;
        {Math.round(calculateAverageRating() * 10) / 10}
      </div>
      <h2>i2 Ratings</h2>
      <SuperTable
        rows={feedback}
        columns={feedbacksColoumns}
        rowKey="_id"
        refreshProps={{ onRefresh: fetchFeedbacks, isRefreshing: isFetcingFeedbacks }}
        onRowClick={setSingleFeedback}
        selectedRow={singleFeedback}
      />
      <JsonAsTree json={createSingleFeedbackWithNoText()} />
      {singleFeedback?.text && <TextArea readOnly value={singleFeedback?.text} />}
      <h2> Feature Suggestions </h2>
      <SuperTable
        rows={suggestions}
        columns={suggestionsColoumns}
        rowKey="_id"
        refreshProps={{ onRefresh: fectchSuggestions, isRefreshing: isFetchingSuggestions }}
        onRowClick={setSingleSuggestion}
        selectedRow={singleSuggestion}
      />
      <JsonAsTree json={createSingleSuggestionWithNoText()} />
      {singleSuggestion?.text && <TextArea readOnly value={singleSuggestion?.text} />}
    </div>
  );
}

import React from "react";
import { getPropArray } from "./general_functions";
import { Key } from "antd/lib/table/interface";
import { COLOR_BLUE, COLOR_PURPLE_ICON, COLOR_VIOLET_ICON } from "../definitions/style_definitions";
import { Application, Service, ServiceAPI, ServiceEvent, ServiceType } from "../definitions/models";
import { Tag } from "antd";
import { ApplicationTree } from "../definitions/general_definitions";
import { NodeCollapseOutlined, NodeExpandOutlined } from "@ant-design/icons";

export function constructTeamInformationFromTeam(team: any) {
  const applications = (team.applications || []).map((application: any) => {
    const services = (application.services || []).map((service: any) => ({
      id: service._id,
      name: service.name,
    }));
    return {
      id: application._id,
      name: application.name,
      services: services,
    };
  });

  return {
    id: team._id,
    name: team.name,
    applications: applications,
  };
}

/** Will go through all services and count unique service types for each Application */
export function countServiceAmount(applications: any[]): any[] {
  let apiCount = 0;
  let eventCount = 0;

  applications.forEach((element: any) => {
    const services = getPropArray(element, "services");
    if (services.length > 0) {
      for (let i = 0; i < services.length; i++) {
        if (services[i].serviceType === "API") apiCount++;
        if (services[i].serviceType === "Event") eventCount++;
      }
    }
  });

  return [
    { type: "APIs", value: apiCount },
    { type: "Events", value: eventCount },
  ];
}

export function getTeamTreeData(
  team: any,
  searchInput: string,
  selectedEnvironmentId: string,
  requesterContracts: any[]
) {
  if (!team || JSON.stringify(team) === "{}") {
    return { roots: [], expKeys: [] };
  }

  const expKeys: Key[] = ["applications", "namespaces", "dns", "unallocatedentities"];

  const consumercontracts = getConsumerContracts(team, searchInput, "applications", requesterContracts, expKeys);

  const namespaces = getNamespaces(team, searchInput);

  const dnss = getDNSs(team, searchInput);

  const events = getEvents(team, searchInput);

  const apis = getAPIs(team, searchInput);

  const treeData = {
    roots: [
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`Applications (${consumercontracts.length})`}</b>
          </span>
        ),
        key: "applications",
        children: consumercontracts,
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`Namespaces (${namespaces.length})`}</b>
          </span>
        ),
        key: "namespaces",
        children: namespaces,
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`DNS (${dnss.length})`}</b>
          </span>
        ),
        key: "dns",
        children: dnss,
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`Events (${events.length})`}</b>
          </span>
        ),
        key: "events",
        children: events,
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`APIs (${apis.length})`}</b>
          </span>
        ),
        key: "apis",
        children: apis,
      },
    ],
    expKeys: expKeys,
  };

  if (selectedEnvironmentId === "dev") {
    const unallocatedEntities: any[] = getPropArray(team, "unallocatedEntities").map((unallocatedEntity) => ({
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
            color:
              searchInput && unallocatedEntity.name.toLowerCase().includes(searchInput?.toLowerCase())
                ? COLOR_BLUE
                : undefined,
          }}
        >
          <Tag style={{ marginRight: "8px" }} color={unallocatedEntity.origin === "AWS" ? "orange" : "green"}>
            {unallocatedEntity.origin}
          </Tag>
          {unallocatedEntity.name}
        </span>
      ),
      key: `unallocatedentities.${unallocatedEntity._id}`,
      id: unallocatedEntity._id,
      type: "unallocatedentities",
      unallocatedApi: unallocatedEntity,
    }));

    treeData.roots.push({
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          <b>{`Unallocated Entities (${unallocatedEntities.length})`}</b>
        </span>
      ),
      key: "unallocatedentities",
      children: unallocatedEntities,
    });
  }

  return treeData;
}

export function filterTeamDataOnEnv(team: any, environment: string) {
  if (!team) {
    return {};
  }

  return {
    ...team,
    applications: getPropArray(team, "applications")
      .filter((app: any) => app.env === environment)
      .map((app: any) => ({
        ...app,
        services: getPropArray(app, "services").filter((service: any) => service.env === environment),
      })),
    namespaces: getPropArray(team, "namespaces").filter((ns: any) => ns.env === environment),
    dns: getPropArray(team, "dns").filter((dns: any) => dns.env === environment),
    dataObjects: getPropArray(team, "dataObjects").filter((dataObject: any) => dataObject.env === environment),
  };
}

export function countServices(services: ApplicationTree[]) {
  let count = 0;

  services.forEach((service) => {
    count += service.children.length;
  });

  return count;
}

export function countContracts(services: ApplicationTree[][]) {
  let count = 0;

  services.forEach((service) => {
    service.forEach((contract) => {
      count += contract.children.length;
    });
  });

  return count;
}

export function getApplications(team: any, searchInput: string, keyPrefix: string) {
  return getPropArray(team, "applications").map((application: Application) => {
    return {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
            color:
              searchInput && application.name.toLowerCase().includes(searchInput?.toLowerCase())
                ? COLOR_BLUE
                : undefined,
          }}
        >
          {application.name}
        </span>
      ),
      key: `${keyPrefix}.${application._id}`,
      id: application._id,
      type: "applications",
      application: application,
    };
  });
}

export function getConsumerContracts(
  team: any,
  searchInput: string,
  keyPrefix: string,
  requesterContracts: any[],
  expKeys: Key[]
) {
  return getPropArray(team, "applications").map((application: Application) => {
    let ct: any[] = [];
    requesterContracts.forEach((element) => {
      if (element.consumingAppId === application._id) {
        ct.push(element);
      }
    });

    const contracts = ct.map((contract: any) => {
      const contractName = contract.service.name;
      const contractKey = `consumerContract.${contract._id}`;
      let direction;
      switch (contract.eventType) {
        case "subscriber":
          direction = <NodeCollapseOutlined style={{ color: COLOR_PURPLE_ICON }} />;
          break;
        case "publisher":
          direction = <NodeExpandOutlined style={{ color: COLOR_VIOLET_ICON }} />;
          break;
      }
      return {
        title: (
          <span
            style={{
              whiteSpace: "nowrap",
              color:
                searchInput && contractName.toLowerCase().includes(searchInput?.toLowerCase())
                  ? (expKeys.push(contractKey), COLOR_BLUE)
                  : undefined,
            }}
          >
            {contractName} {direction}
          </span>
        ),
        key: contractKey,
        id: contract._id,
        applicationId: application._id,
        type: "consumerContract",
        service: contract,
        status: contract.status,
      };
    });

    return {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
            color:
              searchInput && application.name.toLowerCase().includes(searchInput?.toLowerCase())
                ? COLOR_BLUE
                : undefined,
          }}
        >
          {application.name} ({contracts.length})
        </span>
      ),
      key: `${keyPrefix}.${application._id}`,
      children: contracts,
      id: application._id,
      type: "applications",
      application: application,
    };
  });
}

export function getNamespaces(team: any, searchInput: string) {
  return getPropArray(team, "namespaces").map((namespace) => ({
    title: (
      <span
        style={{
          whiteSpace: "nowrap",
          color:
            searchInput && namespace._id.toLowerCase().includes(searchInput?.toLowerCase()) ? COLOR_BLUE : undefined,
        }}
      >
        <span>{namespace.dnsName}</span>/<span style={{ fontWeight: "bold" }}>{namespace.name}</span>
      </span>
    ),
    key: `namespaces.${namespace._id}`,
    id: namespace._id,
    type: "namespaces",
    namespace: namespace,
  }));
}

export function getDNSs(team: any, searchInput: string) {
  return getPropArray(team, "dns").map((dns) => ({
    title: (
      <span
        style={{
          whiteSpace: "nowrap",
          color: searchInput && dns.name.toLowerCase().includes(searchInput?.toLowerCase()) ? COLOR_BLUE : undefined,
        }}
      >
        {dns.name}
      </span>
    ),
    key: `dns.${dns._id}`,
    id: dns._id,
    type: "dns",
    dns: dns,
  }));
}

export function getAPIs(team: any, searchInput: string) {
  let allAPIs: { title: JSX.Element; key: string; id: string; type: string; service: ServiceAPI }[] = [];
  getPropArray(team, "applications").forEach((application: Application) => {
    const services = getPropArray(application, "services")
      .filter((service: Service) => service.serviceType === ServiceType.API)
      .map((service: ServiceAPI) => {
        return {
          title: (
            <span
              style={{
                whiteSpace: "nowrap",
                color:
                  searchInput && service.name.toLowerCase().includes(searchInput?.toLowerCase())
                    ? COLOR_BLUE
                    : undefined,
              }}
            >
              {service.name}
            </span>
          ),
          key: `apis.${service._id}`,
          id: service._id,
          type: "apis",
          service: service,
        };
      });

    allAPIs = allAPIs.concat(services);
  });

  return allAPIs;
}

export function getEvents(team: any, searchInput: string) {
  let allServices: { title: JSX.Element; key: string; id: string; type: string; service: ServiceEvent }[] = [];
  getPropArray(team, "applications").forEach((application: Application) => {
    const services = getPropArray(application, "services")
      .filter((service: Service) => service.serviceType === ServiceType.Event)
      .map((service: ServiceEvent) => {
        return {
          title: (
            <span
              style={{
                whiteSpace: "nowrap",
                color:
                  searchInput && service.name.toLowerCase().includes(searchInput?.toLowerCase())
                    ? COLOR_BLUE
                    : undefined,
              }}
            >
              {service.name}
            </span>
          ),
          key: `events.${service._id}`,
          id: service._id,
          type: "events",
          service: service,
        };
      });

    allServices = allServices.concat(services);
  });
  return allServices;
}

export function getProviderContracts(team: any, searchInput: string, expKeys: Key[], providerContracts: any[]) {
  return getPropArray(team, "applications").map((application: Application) => {
    const services = getPropArray(application, "services").map((service: Service) => {
      let ct: any[] = [];
      providerContracts.forEach((element) => {
        if (element.serviceId === service._id) {
          ct.push(element);
        }
      });

      const contracts = ct.map((contract: any) => {
        const contractName = contract._id;
        const contractKey = `providercontract.${contract._id}`;
        return {
          title: (
            <span
              style={{
                whiteSpace: "nowrap",
                color:
                  searchInput && contractName.toLowerCase().includes(searchInput?.toLowerCase())
                    ? (expKeys.push(contractKey), COLOR_BLUE)
                    : undefined,
              }}
            >
              {contractName} ({contract.service.direction})
            </span>
          ),
          key: contractKey,
          id: contract._id,
          applicationId: application._id,
          type: "providerContract",
          service: contract,
        };
      });

      const serviceType = service.serviceType?.toLowerCase();
      const serviceKey = `providercontract${serviceType}s.${service._id}`;
      return {
        title: (
          <span
            style={{
              whiteSpace: "nowrap",
              color:
                searchInput && service.name.toLowerCase().includes(searchInput?.toLowerCase())
                  ? (expKeys.push(serviceKey), COLOR_BLUE)
                  : undefined,
            }}
          >
            {service.name} ({contracts.length})
          </span>
        ),
        key: serviceKey,
        children: contracts,
        id: service._id,
        applicationId: application._id,
        type: `${serviceType}s`,
        service: service,
      };
    });

    return {
      title: (
        <span
          style={{
            whiteSpace: "nowrap",
            color:
              searchInput && application.name.toLowerCase().includes(searchInput?.toLowerCase())
                ? COLOR_BLUE
                : undefined,
          }}
        >
          {application.name}
        </span>
      ),
      key: `providercontracts.${application._id}`,
      children: services,
      id: application._id,
      type: "applications",
      application: application,
    };
  });
}

export function getTeamAPIsTreeData(team: any, searchInput: string, selectedEnvironmentId: string) {
  if (!team || JSON.stringify(team) === "{}") {
    return { roots: [], expKeys: [] };
  }

  const expKeys: Key[] = ["apis"];

  const apis = getAPIs(team, searchInput);

  const treeData = {
    roots: [
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`APIs (${apis.length})`}</b>
          </span>
        ),
        key: "apis",
        children: apis,
      },
    ],
    expKeys: expKeys,
  };

  return treeData;
}

export function getTeamEventsTreeData(team: any, searchInput: string, selectedEnvironmentId: string) {
  if (!team || JSON.stringify(team) === "{}") {
    return { roots: [], expKeys: [] };
  }

  const expKeys: Key[] = ["events"];

  const events = getEvents(team, searchInput);

  const treeData = {
    roots: [
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>
            <b>{`Events (${events.length})`}</b>
          </span>
        ),
        key: "events",
        children: events,
      },
    ],
    expKeys: expKeys,
  };

  return treeData;
}

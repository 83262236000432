import React, { useContext, useEffect, useRef, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { MyTeamsContext } from "../../lib/contexts";
import { getModalWarning, doFetch, getModalError, useSHistory } from "../../lib/functions/general_functions";
import { DIDBResource, nameToPath } from "../../lib/definitions/general_definitions";
import { SuperTable, SuperTableAction } from "../superTable";
import { namespacesColumns } from "../../lib/definitions/table_column_definitions";
import { SView } from "../general/detailView/sView";

export const NamespacesPane = () => {
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const actions: SuperTableAction[] = [
    {
      icon: <DeleteOutlined />,
      description: "Delete namespace",
      danger: true,
      primary: true,
      onClick: (row) =>
        getModalWarning("Delete namespace")(`Are you sure you want to delete namespace ${row._id}`, (close) =>
          doFetch(
            "DELETE",
            `${DIDBResource.Namespaces}/${row._id}`,
            isMounted,
            refreshMyTeams,
            getModalError("Deleting namespace"),
            close
          )
        ),
    },
  ];

  const namespaces = useMemo(
    () =>
      selectedTeam?.namespaces.map((namespace: any) => ({
        ...namespace,
        key: namespace._id,
        name: `${namespace.dnsName}/${namespace.name}`,
      })) || [],
    [selectedTeam]
  );

  return (
    <SView title="Namespaces" subTitle={namespaces.length} onBack={() => history.push(nameToPath["Home"])}>
      <SuperTable
        rowKey="_id"
        rows={namespaces}
        columns={namespacesColumns}
        onRowClick={(row) => history.push(`${nameToPath["HomeNamespaces"]}/${row._id}`)}
        actions={actions}
        onCreate={() => history.push(nameToPath["HomeNamespaces"] + nameToPath["Create"])}
      />
    </SView>
  );
};

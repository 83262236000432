import React, { useContext, useRef, useEffect } from "react";
import { MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import {
  doFetch,
  doFetchPromise,
  getModalError,
  getNotificationFeedbackRating,
  getNotificationSuccess,
  useSHistory,
} from "../../../lib/functions/general_functions";
import { DIDBResource, dnsNameRegex, nameToPath } from "../../../lib/definitions/general_definitions";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormInput } from "../createForm/sFormInput";
import { SFormTags } from "../createForm/sFormTags";
import { SFormEnum } from "../createForm/sFormEnum";
import { useForm } from "antd/lib/form/Form";

interface CreateDNSProps {
  extraHook?(dnsId: string): void;
}

export const CreateDNS = (props: CreateDNSProps) => {
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const history = useSHistory();

  const isMounted = useRef(true);

  const [form] = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function createDNS(values: any) {
    await doFetch(
      "POST",
      DIDBResource.DNS,
      isMounted,
      (response) => {
        refreshMyTeams(props.extraHook ? undefined : `${nameToPath["HomeDNS"]}/${response.value.id}`);
        getNotificationFeedbackRating("DNS");
        getNotificationSuccess("Created", "DNS", values.name);
        if (props.extraHook) {
          props.extraHook(response.value.id);
        }
      },
      getModalError("Creating DNS"),
      undefined,
      {
        ...values,
        teamId: selectedTeam?._id,
        env: selectedEnvironment?._id,
      }
    );
  }

  async function getDnsNames() {
    try {
      const response = await doFetchPromise("GET", DIDBResource.DNS);
      return response.value.map((dns: any) => dns.name);
    } catch {
      return [];
    }
  }

  return (
    <SForm
      title="Create DNS"
      onBack={props.extraHook ? undefined : () => history.push(nameToPath["HomeDNS"])}
      onCreate={createDNS}
      form={form}
    >
      <SFormSection>
        <SFormInput
          label="Name"
          field="name"
          regex={dnsNameRegex}
          getReservedValues={getDnsNames}
          reservedValuesCaseInsensitive
        />
        <SFormEnum
          label="Visible"
          field="visible"
          options={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
        />
        <SFormInput label="Description" field="description" optional textarea />
        <SFormTags label="Tags" field="tags" optional />
      </SFormSection>
    </SForm>
  );
};

import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { DIDBResource } from "../../lib/definitions/general_definitions";
import { doFetch, getNotificationError, getNotificationSuccess } from "../../lib/functions/general_functions";
import { getMyEmail } from "../../lib/functions/profile_functions";

interface FeatureFeedbackProps {
  onClose(): void;
}

export function FeatureFeedback(props: FeatureFeedbackProps) {
  const [isPosting, setIsPosting] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  async function createSuggestionFeedback(values: any) {
    setIsPosting(true);
    await doFetch(
      "POST",
      DIDBResource.Suggestions,
      isMounted,
      () => getNotificationSuccess("Sent", "Feedback"),
      () => getNotificationError("Uploading feedback", "An error occured while uploading feedback"),
      () => {
        props.onClose();
        setIsPosting(false);
      },
      { ...values, userEmail: getMyEmail(), timestamp: Date.now() }
    );
  }

  return (
    <Form onFinish={(values) => createSuggestionFeedback(values)}>
      <div>
        <label>What feature are we missing</label>
        <Form.Item name="text" rules={[{ required: true, message: "A suggestion must be given" }]}>
          <TextArea
            style={{
              marginTop: "12px",
            }}
            placeholder={"Write here..."}
            rows={4}
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "12px",
          }}
        >
          <Button onClick={props.onClose}> Not Now </Button>
          <Button
            style={{
              backgroundColor: "#1890ff",
              color: "white",
            }}
            loading={isPosting}
            htmlType="submit"
          >
            Send
          </Button>
        </div>
      </div>
    </Form>
  );
}

import React, { useState, useEffect, useContext, useRef } from "react";
import * as localForage from "localforage";
import { DISABLE_BROWSER_POPUP, DEFAULT_TEAM_ID, DEFAULT_ENV_ID } from "../../lib/definitions/localForage_definitions";
import { isChromeBrowser } from "../../lib/functions/general_functions";
import { MyTeamsContext, EnvironmentContext } from "../../lib/contexts";
import { SElementSelect } from "../general/detailView/Elements/sElementSelect";
import { SElementBoolean } from "../general/detailView/Elements/sElementBoolean";
import { SSection } from "../general/detailView/sSection";

export const Preferences = () => {
  const [disableBrowserPopup, setDisableBrowserPopup] = useState(false);
  const [defaultTeamId, setDefaultTeamId] = useState("");
  const [defaultEnvId, setDefaultEnvId] = useState("");

  const { myTeams } = useContext(MyTeamsContext);
  const { environments } = useContext(EnvironmentContext);

  const isMounted = useRef(true);

  useEffect(() => {
    refreshPreferences();

    return () => {
      isMounted.current = false;
    };
  }, []);

  async function refreshPreferences() {
    const disableBrowserPopup = await localForage.getItem(DISABLE_BROWSER_POPUP);
    setDisableBrowserPopup(!!disableBrowserPopup);

    const teamId = await localForage.getItem(DEFAULT_TEAM_ID);
    setDefaultTeamId(teamId + "");

    const envId = await localForage.getItem(DEFAULT_ENV_ID);
    setDefaultEnvId(envId + "");
  }

  return (
    <SSection title="Preferences">
      <SElementSelect
        label="Default team"
        value={defaultTeamId}
        options={myTeams
          .map((team) => ({ label: team.name, value: team._id }))
          .concat({ label: "Last visited", value: "last" })}
        onChange={(teamId) => localForage.setItem(DEFAULT_TEAM_ID, teamId, refreshPreferences)}
        editonly
      />
      <SElementSelect
        label="Default environment"
        value={defaultEnvId}
        options={environments
          .map((environment) => ({ label: environment.name, value: environment._id }))
          .concat({ label: "Last used", value: "last" })}
        onChange={(envId) => localForage.setItem(DEFAULT_ENV_ID, envId, refreshPreferences)}
        editonly
      />
      {!isChromeBrowser() && (
        <SElementBoolean
          label="Disable browser compatibility popup"
          value={disableBrowserPopup}
          onChange={(choice) => localForage.setItem(DISABLE_BROWSER_POPUP, choice, refreshPreferences)}
          editonly
        />
      )}
    </SSection>
  );
};

import React, { useState, useEffect, useRef, useContext } from "react";
import { Radio, Modal, Select, Form, Button, Space, Divider, Tooltip, Input } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  doFetch,
  getModalError,
  getNotificationSuccess,
  doFetchPromise,
  getPropString,
} from "../../lib/functions/general_functions";
import { DIDBResource, pascalCaseRegex } from "../../lib/definitions/general_definitions";
import { getMyEmail } from "../../lib/functions/profile_functions";
import { customValidateMessages } from "../../lib/definitions/sform_definitions";
import { MyTeamsContext } from "../../lib/contexts";
import { Team } from "../../lib/definitions/models";
import { fetchMemberOptions } from "../../lib/functions/ad_functions";

interface RegisterPopupProps {
  open: boolean;
  onClose(): void;
}

export const RegisterPopup: React.FC<RegisterPopupProps> = (props) => {
  const [teamNames, setTeamNames] = useState<string[]>([]);

  const [isFetchingTeamNames, setIsFetchingTeamNames] = useState(false);

  const [choosingExistingTeam, setChoosingExistingTeam] = useState(false);
  const [chosenTeam, setChosenTeam] = useState<any>();
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const [memberOptions, setMemberOptions] = useState<any[]>([]);

  const [isFetchingTeams, setIsFetchingTeams] = useState(false);

  const [isFetchingMembers, setIsFetchingMembers] = useState(false);

  const [isFetchingContacts, setIsFetchingContacts] = useState(false);
  const [contacts, setContacts] = useState<any[]>([]);
  const [isRegistrating, setIsRegistrating] = useState(false);
  const [form] = Form.useForm();
  const { refreshMyTeams } = useContext(MyTeamsContext);

  const isMounted = useRef(true);

  useEffect(() => {
    (async () => {
      try {
        setIsFetchingTeamNames(true);
        const response = await doFetchPromise("GET", DIDBResource.Teams);
        setTeamNames(response.value.map((team: any) => getPropString(team, "name").toLowerCase()));
      } catch {
        setTeamNames([]);
      } finally {
        setIsFetchingTeamNames(false);
      }
    })();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.open) {
      fetchTeamOptions();
      fetchMemberOptions(isMounted, setMemberOptions, setIsFetchingMembers);
    }
  }, [props.open]);

  useEffect(() => {
    if (!chosenTeam) {
      return;
    }
    setIsFetchingContacts(true);
    doFetch(
      "GET",
      `${DIDBResource.AuxMembers}/${chosenTeam._id}`,
      isMounted,
      (response) => {
        setContacts(
          response.map((member: any) => ({
            mail: member.email,
            displayName: member.name,
          }))
        );
      },
      getModalError("Fetching Members"),
      () => setIsFetchingContacts(false)
    );
  }, [chosenTeam]);

  function fetchTeamOptions() {
    setIsFetchingTeams(true);
    doFetch(
      "GET",
      DIDBResource.Teams,
      isMounted,
      (res) => {
        setTeamOptions(
          res.value
            .map((team: Team) => ({
              value: `${team.name}`,
              title: `${team.name} (${team._id})`,
              _id: `${team._id}`,
            }))
            .sort((a: any, b: any) => a.value.localeCompare(b.value, "en"))
        );
      },
      getModalError("Fetching teams"),
      () => setIsFetchingTeams(false)
    );
  }

  function createTeam() {
    setIsRegistrating(true);
    const { teamName, members } = form.getFieldsValue();

    doFetch(
      "POST",
      DIDBResource.Teams,
      isMounted,
      () => {
        getNotificationSuccess("Created", "Team", teamName);
        refreshMyTeams();
        props.onClose();
      },
      getModalError("Creating team"),
      () => setIsRegistrating(false),

      { name: teamName, members: members }
    );
  }

  return (
    <Modal
      centered
      visible={props.open}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        props.onClose();
        setChoosingExistingTeam(false);
        setChosenTeam(undefined);
        form.resetFields();
      }}
      okText="Register"
    >
      <div>
        <Divider>Register on team</Divider>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Radio.Group
            value={choosingExistingTeam}
            onChange={(e) => setChoosingExistingTeam(e.target.value)}
            style={{ marginBottom: "24px" }}
          >
            <Radio.Button value={false}>Create a new team</Radio.Button>
            <Radio.Button value={true}>Join existing team</Radio.Button>
          </Radio.Group>
        </div>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          onFinish={createTeam}
          scrollToFirstError
          validateMessages={customValidateMessages}
        >
          {!choosingExistingTeam ? (
            <>
              <Form.Item
                name="teamName"
                label="Team name"
                validateStatus={isFetchingTeamNames ? "validating" : undefined}
                rules={[
                  { required: true, pattern: pascalCaseRegex.regex },
                  { whitespace: true },
                  ({ getFieldValue }) => ({
                    validator() {
                      /*if (pascalCaseRegex.regex.test(getFieldValue("teamName"))) {
                        return Promise.reject(`Field should have the following format: ${pascalCaseRegex.category}`);
                      }*/
                      const value: string = getFieldValue("teamName");
                      if (teamNames.includes(value.toLowerCase())) {
                        return Promise.reject(`Team name is already taken`);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="Team name..." />
              </Form.Item>
              <Form.Item
                name="members"
                initialValue={[getMyEmail()]}
                label={
                  <span>
                    Add members&nbsp;
                    <Tooltip title="Hover an option to see the name of the user">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <Select placeholder="Add member..." mode="tags" options={memberOptions} loading={isFetchingMembers} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                rules={[{ required: true }]}
                label={
                  <span>
                    Team&nbsp;
                    <Tooltip title="Hover an option to see the ID of the team">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
              >
                <Select
                  options={teamOptions}
                  showSearch
                  onChange={(_, team) => setChosenTeam(team)}
                  placeholder="Select a team..."
                  loading={isFetchingTeams}
                />
              </Form.Item>
              {chosenTeam && (
                <div>
                  <Divider>Contacts</Divider>
                  <h4>Please contact one of the team owners below:</h4>
                  {isFetchingContacts ? (
                    "Loading..."
                  ) : (
                    <ul>
                      {contacts.map((contact) => (
                        <li style={{ marginBottom: "8px" }} key={contact.mail}>
                          <span>{`${contact.displayName}, ${contact.mail}`}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </>
          )}
          <Form.Item style={{ marginBottom: 0 }} wrapperCol={{}}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Space>
                <Button onClick={props.onClose}>{choosingExistingTeam ? "Close" : "Cancel"}</Button>
                {!choosingExistingTeam && (
                  <Button type="primary" htmlType="submit" loading={isRegistrating}>
                    Register
                  </Button>
                )}
              </Space>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

import React, { useContext, useEffect } from "react";
import { MyTeamsContext, BreadcrumbContext } from "../lib/contexts";
import { Route, Switch } from "react-router-dom";
import { Result, Button } from "antd";
import { NamespacesPane } from "../components/home/namespacesPane";
import { nameToPath } from "../lib/definitions/general_definitions";
import { DNSPane } from "../components/home/dnsPane";
import { ApplicationsPane } from "../components/home/applicationsPane";
import { ApplicationPane } from "../components/home/applicationPane";
import { ServicesPane } from "../components/home/servicesPane";
import { CreateApplication } from "../components/general/forms/createApplication";
import { NamespacePane } from "../components/home/namespacePane";
import { CreateNamespace } from "../components/general/forms/createNamespace";
import { CreateDNS } from "../components/general/forms/createDNS";
import { DNSSPane } from "../components/home/dnssPane";
import { DataObjectsPane } from "../components/home/dataObjectsPane";
import { APIsPane } from "../components/home/apisPane";
import { APIPane } from "../components/home/apiPane";
import { EventPane } from "../components/home/eventPane";
import { SelectTypeOfServiceToCreate } from "../components/home/selectTypeOfServiceToCreate";
import { CreateAPIForm } from "../components/general/forms/createAPI";
import { SideBar } from "../components/home/sideBar";
import { CreateEvent } from "../components/general/forms/createEvent";
import { Contract } from "../components/home/contract";
import { DashboardGrid } from "../components/home/dashboardGrid";
import { getPropArray, useSHistory } from "../lib/functions/general_functions";
import { UnallocatedEntitiesPane } from "../components/home/unallocatedEntitiesPane";
import { NotFoundPage } from "./notFoundPage";
import { EventsPane } from "../components/home/eventsPane";

export function HomePage() {
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const { myTeams, myTeamsFetchingError, selectedTeam } = useContext(MyTeamsContext);

  const history = useSHistory();

  useEffect(() => {
    setBreadcrumbs([]);
  }, [setBreadcrumbs]);

  return (
    <>
      {myTeamsFetchingError && (
        <Result
          title="An error occured fetching your teams"
          extra={
            <Button type="primary" onClick={() => window.location.reload(true)}>
              Refresh
            </Button>
          }
        />
      )}
      {myTeams.length === 0 && !myTeamsFetchingError && (
        <Result
          title="You are not registered on any team"
          extra={
            <Button type="primary" onClick={() => history.push(nameToPath["Profile"])}>
              Go to Profile
            </Button>
          }
        />
      )}
      {myTeams.length > 0 && (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "324px 7fr",
              gridColumnGap: "48px",
              alignItems: "flex-start",
            }}
          >
            <SideBar />
            <Switch>
              <Route
                exact
                path={nameToPath["Home"]}
                render={() => (
                  <DashboardGrid applications={getPropArray(selectedTeam, "applications")} isFetchingServices={false} />
                )}
              />
              <Route
                exact
                path={[`${nameToPath["HomeApplications"]}${nameToPath["Create"]}`]}
                component={CreateApplication}
              />
              <Route exact path={[nameToPath["HomeApplications"]]} component={ApplicationsPane} />
              <Route exact path={[`${nameToPath["HomeApplications"]}/:id`]} component={ApplicationPane} />
              <Route exact path={[`${nameToPath["consumerContract"]}/:id?`]} component={Contract} />
              <Route exact path={[`${nameToPath["providerContract"]}/:id?`]} component={Contract} />

              <Route
                exact
                path={nameToPath["HomeServices"] + nameToPath["Create"]}
                component={SelectTypeOfServiceToCreate}
              />

              <Route exact path={[nameToPath["HomeAPIs"]]} component={APIsPane} />
              <Route exact path={`${nameToPath["HomeAPIs"] + nameToPath["Create"]}`} component={CreateAPIForm} />
              <Route exact path={`${nameToPath["HomeAPIs"]}/:id?`} component={APIPane} />

              <Route exact path={[nameToPath["HomeEvents"]]} component={EventsPane} />
              <Route exact path={`${nameToPath["HomeEvents"] + nameToPath["Create"]}`} component={CreateEvent} />
              <Route exact path={`${nameToPath["HomeEvents"]}/:id?`} component={EventPane} />

              <Route exact path={nameToPath["HomeServices"]} component={ServicesPane} />

              <Route
                exact
                path={`${nameToPath["HomeNamespaces"] + nameToPath["Create"]}`}
                component={CreateNamespace}
              />
              <Route exact path={nameToPath["HomeNamespaces"]} component={NamespacesPane} />
              <Route exact path={`${nameToPath["HomeNamespaces"]}/:id`} component={NamespacePane} />

              <Route exact path={`${nameToPath["HomeDNS"] + nameToPath["Create"]}`} component={CreateDNS} />
              <Route exact path={nameToPath["HomeDNS"]} component={DNSSPane} />
              <Route exact path={`${nameToPath["HomeDNS"]}/:id?`} component={DNSPane} />

              <Route exact path={`${nameToPath["HomeDataObjects"]}/:id?`} component={DataObjectsPane} />

              <Route exact path={`${nameToPath["HomeUnallocatedEntities"]}/:id?`} component={UnallocatedEntitiesPane} />

              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface CopyButtonProps {
  content: string;
  title: string;
}

export const CopyButton: React.FC<CopyButtonProps> = (props) => {
  const [copySuccess, setCopySuccess] = useState(false);

  return (
    <Tooltip title={copySuccess ? "Copied!" : props.title}>
      {copySuccess ? (
        <CheckOutlined style={{ color: "#52c41a", fontSize: "20px" }} />
      ) : (
        <CopyOutlined
          style={{ cursor: "pointer", fontSize: "20px" }}
          onClick={() => {
            navigator.clipboard.writeText(props.content);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
          }}
        />
      )}
    </Tooltip>
  );
};

import React, { useState, useEffect, useContext } from "react";
import { Input, Form } from "antd";
import { getPropString } from "../../../../lib/functions/general_functions";
import TextArea from "antd/lib/input/TextArea";
import { getLineWidthSpan } from "../../../../lib/functions/sDetail_functions";
import { SViewContext } from "../../../../lib/contexts";

interface SElementProps {
  label: string;
  addOnBefore?: string;
  field?: string;
  lineWidth?: "default" | "double" | "triple" | "wholeline";
  value?: string;
  fieldType?: "input" | "textarea";
  readonly?: boolean;
  editonly?: boolean;
  disabled?: boolean;
  onChange?(event: any): any;
  render?(value: string): any;
}

export const SElementInput = (props: SElementProps) => {
  const [value, setValue] = useState<string>("");
  const [fieldType] = useState<"input" | "textarea">(props.fieldType ? props.fieldType : "input");
  const { object, isEditing } = useContext(SViewContext);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    } else {
      setValue(getPropString(object, props.field + ""));
    }
  }, [object, props.field, isEditing, props.value]);

  function getEditComponent() {
    switch (fieldType) {
      case "input":
        return (
          <Input
            className="sdetail-element__value"
            onChange={handleOnChange}
            disabled={props.readonly || props.disabled}
            value={value}
            addonBefore={props.addOnBefore}
          ></Input>
        );
      case "textarea":
        return (
          <TextArea
            className="sdetail-element__value"
            onChange={handleOnChange}
            disabled={props.readonly || props.disabled}
          ></TextArea>
        );
    }
  }

  function getViewComponent() {
    switch (fieldType) {
      case "input":
        return props.render ? (
          <div>{props.render(value)}</div>
        ) : (
          <p style={{ overflow: "hidden" }} className="sdetail-element__value">
            {value}
          </p>
        );
      case "textarea":
        return (
          <p style={{ overflow: "hidden" }} className="sdetail-element__value">
            {value}
          </p>
        );
    }
  }

  function handleOnChange(event: any) {
    if (props.onChange) props.onChange(event.target.value);
  }

  const displayView = (
    <span className="sdetail-element__container" style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      {getViewComponent()}
    </span>
  );

  const updateableView = (
    <span className={"sdetail-element__container"} style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      <Form.Item name={props.field} initialValue={value} noStyle>
        {getEditComponent()}
      </Form.Item>
    </span>
  );

  if (props.editonly || isEditing) return updateableView;
  return displayView;
};

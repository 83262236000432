import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { ServiceAPI, Target, ServiceType } from "../../lib/definitions/models";
import { MyTeamsContext, EnvironmentContext } from "../../lib/contexts";
import { useParams } from "react-router-dom";
import {
  getModalNotFound,
  doFetch,
  getModalError,
  getNotificationSuccess,
  getNotificationError,
  useSHistory,
} from "../../lib/functions/general_functions";
import tykPng from "../../lib/images/tyk.png";
import { nameToPath, DIDBResource } from "../../lib/definitions/general_definitions";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SView } from "../general/detailView/sView";
import { SSection } from "../general/detailView/sSection";
import { SElementTags } from "../general/detailView/Elements/sElementTags";
import { SElementSelect } from "../general/detailView/Elements/sElementSelect";
import { DeployButton } from "./deployButton";
import { serviceVisibilityOptions } from "../../lib/definitions/home_definitions";
import { SFiles } from "../general/detailView/sFiles";
import { ReassignButton } from "./reassignButton";
import { useForm } from "antd/lib/form/Form";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { Alert, Button } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload";
import { uploadEntityAttachments } from "../../lib/functions/aws_functions";
import { CheckCircleTwoTone, InboxOutlined } from "@ant-design/icons";
import { CreateApplication } from "../general/forms/createApplication";
import { CreateNamespace } from "../general/forms/createNamespace";
import { serviceApiTypeOptions, serviceAuthenticationOptions } from "../../lib/definitions/home_definitions";
const yaml = require("js-yaml");

export const APIPane = () => {
  const [api, setApi] = useState<ServiceAPI>();
  const [applicationOptions, setApplicationOptions] = useState<any[]>([]);
  const [targetApiGatewayOptions, setTargetApiGatewayOptions] = useState<any[]>([]);
  const [namespaceId, setNamespaceId] = useState("");
  const [listenPath, setListenPath] = useState("");
  const [orgFullPath, setOrgFullPath] = useState("");
  const [fullPath, setFullPath] = useState("");
  const [targetUrl, setTargetUrl] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [swaggerFile, setSwaggerFile] = useState<RcFile>();
  const [isTykEnabled, setIsTykEnabled] = useState(false);

  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const [form] = useForm();

  const history = useSHistory();
  const { id } = useParams<{ id: string }>();

  const isMounted = useRef(true);

  const namespaceOptions = useMemo(
    () =>
      (selectedTeam?.namespaces || [])
        .filter((namespace) => namespace.env === selectedEnvironment?._id)
        .map((namespace: any) => ({
          label: namespace.name,
          value: namespace._id,
          dnsname: namespace.dnsName,
        })),
    [selectedTeam, selectedEnvironment]
  );

  useEffect(() => {
    doFetch(
      "GET",
      DIDBResource.Targets + "?type=api",
      isMounted,
      (response) =>
        setTargetApiGatewayOptions(response.value.map((target: Target) => ({ label: target.name, value: target._id }))),
      getModalError("Fetching target API Gateway options")
    );
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fullPathArray = orgFullPath.split("/");
    const length = fullPathArray.length;
    if (namespaceId) {
      const namespaceOption = namespaceOptions.find((opt) => opt.value === namespaceId);
      if (namespaceOption) {
        fullPathArray[length - 3] = namespaceOption.dnsname;
        fullPathArray[length - 2] = namespaceOption.label;
      }
      fullPathArray[length - 1] = listenPath;
      setFullPath(fullPathArray.join("/"));
    } else {
      setFullPath(targetUrl);
    }
  }, [namespaceId, listenPath, targetUrl, namespaceOptions, orgFullPath]);

  useEffect(
    () =>
      setApplicationOptions(
        selectedTeam?.applications
          .filter((application) => application.env === selectedEnvironment?._id)
          .map((application) => ({ label: application.name, value: application._id })) || []
      ),
    [selectedTeam, selectedEnvironment]
  );

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }
    const apiInner = selectedTeam?.applications
      .reduce((prev: any[], app: any) => prev.concat(...app.services), [])
      .find((service) => service.serviceType === ServiceType.API && service._id === id);

    if (apiInner) {
      setApi(apiInner);
      setNamespaceId(apiInner.namespaceId || "");
      setListenPath(apiInner.listenPath || "");
      setOrgFullPath(apiInner.fullPath || "");
      setTargetUrl(apiInner.targetUrl || "");
      if (apiInner.tykApiId) setIsTykEnabled(true);
    } else {
      getModalNotFound("API", id, () => history.push(nameToPath["HomeServices"]));
    }
  }, [selectedTeam, history, id]);

  async function putApi(apiValues: any): Promise<boolean> {
    delete apiValues.fullPath;
    let success = false;

    if (!apiValues.namespaceId) apiValues.namespaceId = "";
    if (!apiValues.targetApiGatewayId) apiValues.targetApiGatewayId = "";

    await doFetch(
      "PUT",
      `${DIDBResource.APIs}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccess("Updated", "API", apiValues["name"]);
        history.push(`${nameToPath["HomeAPIs"]}/${id}`);
        success = true;
        window.location.reload(); // Temporary solution in order to remove namespace
      },
      (errorContent: any) => {
        getModalError("Updating API")(errorContent);
        success = false;
      },
      undefined,
      {
        name: apiValues.name,
        description: apiValues.description,
        env: apiValues.env,
        serviceType: apiValues.serviceType,
        tags: apiValues.tags,
        targetApiGatewayId: apiValues.targetApiGatewayId,
        visibility: apiValues.visibility,
        targetUrl: apiValues.targetUrl,
        namespaceId: apiValues.namespaceId,
        listenPath: apiValues.listenPath,
        definitionFile: swaggerFile?.name,
        apitype: apiValues.apitype,
        _id: apiValues._id,
      }
    );
    if (selectedTeam && swaggerFile) {
      uploadEntityAttachments(selectedTeam._id, "API", id, [swaggerFile], undefined, () =>
        getNotificationError("Uploading Swagger file", "An error occured")
      );
    }

    return success;
  }

  async function deleteApi() {
    await doFetch(
      "DELETE",
      `${DIDBResource.APIs}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        history.push(nameToPath["HomeServices"]);
        getNotificationSuccess("Deleted", "API", api?.name);
      },
      getModalError("Deleting API")
    );
  }

  function updateFieldsFromSwagger(swaggerfile: any) {
    if (swaggerfile?.openapi) {
      const targetUrl = swaggerfile.servers ? swaggerfile.servers[0]?.url : "";
      form.setFieldsValue({
        name: (swaggerfile.info?.title).replace(/\s/g, "-"),
        description: swaggerfile.info?.description,
        targetUrl: targetUrl,
        tags: swaggerfile.tags?.map((tag: any) => {
          return tag.name;
        }),
      });
    } else if (swaggerfile?.swagger) {
      const targetUrl = swaggerfile.host ? swaggerfile.host : "";
      form.setFieldsValue({
        name: (swaggerfile.info?.title).replace(/\s/g, "-"),
        description: swaggerfile.info?.description,
        targetUrl: targetUrl + (swaggerfile.basePath ? swaggerfile.basePath : ""),
        tags: swaggerfile.tags?.map((tag: any) => {
          return tag.name;
        }),
      });
    }
    form.validateFields(["name", "description", "targetUrl", "listenPath", "tags"]);
  }

  function generateDataFromSwagger(text: string, type: string | undefined) {
    if (type?.toLowerCase() === "yaml") {
      yaml.safeLoadAll(text, (swaggerfile: any) => {
        updateFieldsFromSwagger(swaggerfile);
      });
    } else if (type?.toLowerCase() === "json") {
      updateFieldsFromSwagger(JSON.parse(text));
    }
  }

  return (
    <SView
      title="API"
      subTitle={api?.name}
      object={api}
      onBack={() => history.push(`${nameToPath["HomeApplications"]}/${api?.applicationId}`)}
      onSave={(success: Promise<boolean>) => putApi(success)}
      onDelete={deleteApi}
      onEditMode={setIsEditing}
      form={form}
      key={id}
      extra={[
        <Button key="1" disabled={!isTykEnabled}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              selectedEnvironment?._id === "prod"
                ? `https://tib.dashboard.prod.api.lego.com/#/apis/designer/${api?.tykApiId}`
                : `https://tib.dashboard.nonprod.api.lego.com/#/apis/designer/${api?.tykApiId}`
            }
          >
            <img src={tykPng} alt="Tyk" style={{ width: "32px", marginRight: "8px" }} />
            Api
          </a>
        </Button>,
        <ReassignButton key="2" type="API" entityId={id} parentId={api?.applicationId} />,
        <DeployButton key="3" type="API" entityId={id} />,
      ]}
    >
      <SSection title="Details">
        <SElementInput label="Name" field="name" />
        <SElementInput label="URL" value={fullPath} readonly />
        <SElementSelect label="Visibiliy" field="visibility" options={serviceVisibilityOptions} />
        <SElementSelect
          label="Application"
          field="applicationId"
          options={applicationOptions}
          onNewForm={(close) => <CreateApplication extraHook={close} />}
        />
        <SElementInput label="Target URL" field="targetUrl" onChange={setTargetUrl} />
        <SElementTags label="Tags" field="tags" />
        <SElementSelect label="Auth" field="authentication" options={serviceAuthenticationOptions} />
        <SElementSelect label="API Type" field="apitype" options={serviceApiTypeOptions} />
        <SElementInput label="Description" field="description" fieldType="textarea" lineWidth="double" />
      </SSection>
      <SSection title="Virtualization">
        <SElementSelect
          label="Namespace"
          field="namespaceId"
          onChange={(value) => {
            setNamespaceId(value);
            if (!value) {
              form.setFieldsValue({ targetApiGatewayId: "" });
              form.setFieldsValue({ listenPath: "" });
            }
          }}
          options={namespaceOptions}
          onNewForm={(close) => <CreateNamespace extraHook={close} />}
        />
        <SElementSelect
          label="API Gateway"
          field="targetApiGatewayId"
          options={targetApiGatewayOptions}
          disabled={!form.getFieldValue("namespaceId")}
        />
        <SElementInput
          label="Listen path"
          field="listenPath"
          onChange={setListenPath}
          addOnBefore="/"
          disabled={!form.getFieldValue("namespaceId")}
        />
      </SSection>
      <SSectionCustom title="Swagger file">
        {api?.definitionFile ? (
          <h4>Current Swagger file: {api.definitionFile}</h4>
        ) : !isEditing ? (
          <h4>No Swagger file attached</h4>
        ) : null}
        {isEditing && (
          <div>
            {swaggerFile && (
              <Alert
                message="Successfully generated fields from Swaggerfile. File has been added as attachement"
                type="success"
                style={{ marginBottom: "4px" }}
              />
            )}
            <Dragger
              name="file"
              style={{ marginBottom: "8px" }}
              accept=".yaml,.json"
              beforeUpload={(file: RcFile) => {
                file.text().then((text: string) => generateDataFromSwagger(text, file.name.split(".").pop()));
                setSwaggerFile(file);
                return false;
              }}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                {!swaggerFile ? <InboxOutlined /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}
              </p>
              <p className="ant-upload-text">Click or drag a .yaml or .json file to this area to update API fields</p>
              <p className="ant-upload-hint">
                The Swagger file will be uploaded as an attachment when saving your changes
              </p>
            </Dragger>
          </div>
        )}
      </SSectionCustom>
      <SFiles key={id} title="Attachments" teamId={selectedTeam?._id} entityType="API" entityId={api?._id} />
    </SView>
  );
};

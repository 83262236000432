import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { EditDataObject } from "./editDataObject";
import { EditSchema } from "./editSchema";
import { generateMask, getKeysFromMask, getSchema, parseSchemaTypes } from "../../lib/functions/services_functions";
import Ajv from "ajv";
import { getModalError } from "../../lib/functions/general_functions";
import { JsonAsTree } from "../general/jsonAsTree";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { COLOR_RED, COLOR_YELLOW } from "../../lib/definitions/style_definitions";

interface DataObjectEditorProps {
  example: any;
  routingMask: string;
  schema: any;
  onExampleChange(example: object): void;
  onRoutingMaskChange(routingMask: string): void;
  onSchemaChange(schema: object): void;
  onErrorChange(errorMessage: string): void;
}

export function DataObjectEditor(props: DataObjectEditorProps) {
  const [schema, setSchema] = useState<object>(getSchema(props.example));
  const [example, setExample] = useState<object>();
  const [schemaAsTypeObject, setSchemaAsTypeObject] = useState<object>();
  const [errorMessage, setErrorMessage] = useState("");
  const [lastChanged, setLastChanged] = useState<string>();

  useEffect(() => {
    if (!schema || !example) {
      return;
    }
    const ajv = new Ajv();
    let validator;
    try {
      validator = ajv.compile(schema);
    } catch (error) {
      getModalError("Unexpected error occured");
    }
    if (validator) {
      if (!validator(example) && validator.errors) {
        const errors = validator.errors;
        const errorMessage = errors[0].message;
        const errorPath = errors[0].dataPath
          .split("/")
          .filter((s) => !!s)
          .join(".");
        const fullError = `'${errorPath}' ${errorMessage}`;
        setErrorMessage(fullError);
        props.onErrorChange(fullError);
      } else {
        setErrorMessage("");
        props.onErrorChange("");
      }
    }
  }, [example, schema, props]);

  useEffect(() => {
    setLastChanged("example");
  }, [example]);

  useEffect(() => {
    setLastChanged("schema");
  }, [schema]);

  useEffect(() => {
    setSchemaAsTypeObject(parseSchemaTypes(schema));
  }, [schema]);

  return (
    <div>
      <Tabs>
        <Tabs.TabPane
          tab={
            <div style={{ display: "flex", alignItems: "center" }}>
              {errorMessage && <ExclamationCircleTwoTone twoToneColor={COLOR_RED} style={{ fontSize: "16px" }} />}
              Example
            </div>
          }
          key="1"
        >
          <EditDataObject
            initialJson={props.example}
            onValidExampleJsonChange={(example) => {
              props.onExampleChange(example);
              setExample(example);
            }}
            errorMessage={errorMessage}
            onChangeToSchemaTab={() => {}}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <div style={{ display: "flex", alignItems: "center" }}>
              {errorMessage && lastChanged === "example" && (
                <ExclamationCircleTwoTone twoToneColor={COLOR_YELLOW} style={{ fontSize: "16px" }} />
              )}
              Schema
            </div>
          }
          key="2"
        >
          <EditSchema
            initialSchema={props.schema}
            example={props.example}
            schemaIsOld={lastChanged === "example" && !!errorMessage}
            onValidSchemaChange={(schema) => {
              props.onSchemaChange(schema);
              setSchema(schema);
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Routing Keys"
          key="3"
          style={{
            background: "rgb(243,243,243)",
            maxHeight: "660px",
            overflow: "auto",
          }}
        >
          {JSON.stringify(schemaAsTypeObject) === "{}" ? (
            "{}"
          ) : (
            <JsonAsTree
              isTypes
              json={schemaAsTypeObject}
              checkedKeys={getKeysFromMask(props.routingMask, schemaAsTypeObject)}
              setCheckedKeys={(keys) => props.onRoutingMaskChange(generateMask(keys))}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

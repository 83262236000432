import { MSGraphResource } from "../definitions/general_definitions";
import { doFetchPromise, getModalError } from "../functions/general_functions";

//fce9cd47-8730-4a70-8c1c-783d6a3f1ce8 (internals)
//efdd0c83-1ed5-4059-a0c8-f19743c10846 (externals)
async function fetchMembersOfDigitalALL() {
  const members: Map<string, any> = new Map();

  async function addAllInGroup(groupId: string) {
    let nextUrl = `${MSGraphResource.Groups}/${groupId}/transitiveMembers?$select=mail,displayName`;
    while (nextUrl) {
      const value = await doFetchPromise("GET", nextUrl);

      value.value.forEach((m: any) => members.set(m.mail, m));

      nextUrl = value["@odata.nextLink"];
    }
  }

  await addAllInGroup("fce9cd47-8730-4a70-8c1c-783d6a3f1ce8");
  await addAllInGroup("efdd0c83-1ed5-4059-a0c8-f19743c10846");

  return Array.from(members.values());
}

export async function fetchMemberOptions(
  isMounted: any,
  setMemberOptions: (members: any[]) => void,
  setIsFetchingMembers: (bool: boolean) => void
) {
  setIsFetchingMembers(true);
  try {
    let members = await fetchMembersOfDigitalALL();
    if (!isMounted.current) return;

    setMemberOptions(
      members
        .filter((member) => member.mail)
        .map((member) => ({
          value: member.mail,
          title: `${member.mail} (${member.displayName})`,
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value, "en"))
    );
  } catch (error) {
    if (!isMounted.current) return;
    getModalError("Fetching members")(error);
  } finally {
    if (!isMounted.current) return;
    setIsFetchingMembers(false);
  }
}

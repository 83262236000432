import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DeleteOutlined, ReadOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { MessageCard } from "../components/profile/messageCard";
import { MessagesContext, MyTeamsContext } from "../lib/contexts";
import { MessageWTeamName } from "../lib/definitions/models";
import { doFetch, getModalError } from "../lib/functions/general_functions";
import { DIDBResource } from "../lib/definitions/general_definitions";

export function InboxPage() {
  const [selectedMessage, setSelectedMessage] = useState<MessageWTeamName>();
  const [isUpdatingReadStatus, setIsUpdatingReadStatus] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { messages, isFetchingMessages, refreshMessages } = useContext(MessagesContext);
  const { myTeams } = useContext(MyTeamsContext);

  const messagesWithTeamName: MessageWTeamName[] = useMemo(
    () =>
      messages
        .map((m) => ({ ...m, teamName: myTeams.find((t) => t._id === m.teamId)?.name || "No team name" }))
        .sort((a, b) => b.timestamp - a.timestamp),
    [messages, myTeams]
  );

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setSelectedMessage((sm) => messagesWithTeamName.find((m) => m._id === sm?._id));
  }, [messagesWithTeamName]);

  function changeReadStatus() {
    setIsUpdatingReadStatus(true);
    doFetch(
      "POST",
      `${DIDBResource.AuxMessages}/${selectedMessage?._id}`,
      isMounted,
      refreshMessages,
      getModalError("Update read status on message"),
      () => setIsUpdatingReadStatus(false)
    );
  }

  function deleteMessage() {
    setIsDeleting(true);
    doFetch(
      "DELETE",
      DIDBResource.AuxMessages + "/" + selectedMessage?._id,
      isMounted,
      refreshMessages,
      getModalError("Deleting message"),
      () => setIsDeleting(false)
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "800px", padding: "24px 24px 0 24px", background: "#fafafa", border: "1px solid #d1d1d1" }}>
        <h1>{`Team Inbox (${messages.filter((m) => !m.read).length})`}</h1>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex" }}>
            <div>{`Messages: ${messagesWithTeamName.length}`}</div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={changeReadStatus}
              icon={<ReadOutlined />}
              loading={isUpdatingReadStatus}
              style={{ marginRight: "8px" }}
              disabled={!selectedMessage}
            >
              {`Mark as ${selectedMessage?.read ? "unread" : "read"}`}
            </Button>
            <Button
              onClick={deleteMessage}
              icon={<DeleteOutlined />}
              loading={isDeleting}
              style={{ marginRight: "24px" }}
              danger
              disabled={!selectedMessage}
            >
              Delete
            </Button>
            <Button onClick={refreshMessages} icon={<ReloadOutlined />} loading={isFetchingMessages} />
          </div>
        </div>
        <Divider style={{ marginTop: "12px", marginBottom: 0 }} className="general__gray-divider" />
        <div style={{ overflow: "auto", paddingTop: "12px", height: "800px" }}>
          {messagesWithTeamName.length === 0 ? (
            <div style={{ textAlign: "center", fontSize: "32px", color: "gray", marginTop: "48px" }}>
              No messages...
            </div>
          ) : (
            messagesWithTeamName.map((m) => (
              <MessageCard
                key={m._id}
                message={m}
                onClick={() => setSelectedMessage(m)}
                selectedMessageId={selectedMessage?._id}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

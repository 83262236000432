import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doFetch, getPropString, getModalError } from "../lib/functions/general_functions";
import {
  DIDBResource,
  nameToPath,
  getServiceBreadcrumb,
  getPageBreadcrumb,
  getTeamBreadcrumb,
  getApplicationBreadcrumb,
} from "../lib/definitions/general_definitions";
import { BreadcrumbContext } from "../lib/contexts";
import { Spinner } from "../components/general/spinner";
import { SView } from "../components/general/detailView/sView";
import { downloadAttachment } from "../lib/functions/aws_functions";
import { SSection } from "../components/general/detailView/sSection";
import { SElementText } from "../components/general/detailView/Elements/sElementText";
import { ArrowRightOutlined, FileUnknownTwoTone } from "@ant-design/icons";
import Button from "antd/lib/button";
import List from "antd/lib/list";

interface APIDetailsPageProps {
  api?: any;
}

export function APIDetailsPage(props: APIDetailsPageProps) {
  const [api, setApi] = useState<any>();
  const [isFetchingService, setIsFetchingService] = useState(false);
  const isMounted = useRef(true);

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setBreadcrumbs = useContext(BreadcrumbContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.api) {
      setApi(props.api);
      return;
    }
    setIsFetchingService(true);
    doFetch(
      "GET",
      `${DIDBResource.ExpandedServices}/${id}`,
      isMounted,
      (res) => setApi(res.value),
      (err) => {
        getModalError("Fetching service")(err);
        history.push(nameToPath["Services"]);
      },
      () => setIsFetchingService(false)
    );
  }, [id, history, props.api]);

  useEffect(() => {
    if (props.api) {
      setBreadcrumbs([
        getApplicationBreadcrumb({
          name: props.api.applicationName,
          _id: props.api.applicationId,
        }),
        getServiceBreadcrumb("api", props.api),
      ]);
    } else {
      setBreadcrumbs([
        getPageBreadcrumb("Services"),
        getTeamBreadcrumb({
          name: getPropString(api, "teamName"),
          _id: getPropString(api, "teamId"),
        }),
        getApplicationBreadcrumb({
          name: getPropString(api, "applicationName"),
          _id: getPropString(api, "applicationId"),
        }),
        getServiceBreadcrumb("api", api),
      ]);
    }
  }, [api, setBreadcrumbs, props.api]);

  return (
    <div className="services">
      {isFetchingService ? (
        <Spinner margin={100} />
      ) : (
        <SView
          title={getPropString(api, "name")}
          subTitle="API"
          object={api}
          wide
          onBack={() => history.push(nameToPath["Services"])}
          extra={
            <span>
              <Button
                icon={<ArrowRightOutlined />}
                onClick={() => history.push(`${nameToPath["Services"]}/api/${id}/swaggerui`)}
              >
                Swagger UI
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "8px" }}
                onClick={() => history.push(`${nameToPath["Services"]}/api/${id}${nameToPath["Request"]}`)}
              >
                Create request
              </Button>
            </span>
          }
        >
          <SSection title="Details">
            <SElementText label="Team" field="teamName" fieldType="input" />
            <SElementText label="Application" field="applicationName" fieldType="input" />
            <SElementText label="URL" field="fullPath" fieldType="input" lineWidth="double" />
            <SElementText label="API description" field="description" fieldType="textarea" lineWidth="double" />
            <div />
            <div />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr", gridColumn: "span 3" }}>
              <h4 style={{ alignSelf: "center" }}>API definition file: </h4>
              <List style={{ padding: "8px", overflow: "auto", maxHeight: "300px" }}>
                {api?.definitionFile ? (
                  <List.Item
                    key={1}
                    className="sdetail-files__list-item"
                    onClick={() => {
                      if (getPropString(api, "definitionFile")) {
                        downloadAttachment(api?.teamId, "API", api?._id, api?.definitionFile);
                      }
                    }}
                    style={{ cursor: "pointer", alignSelf: "center" }}
                  >
                    <List.Item.Meta
                      title={getPropString(api, "definitionFile")}
                      avatar={<FileUnknownTwoTone className="sdetail-files__icons" />}
                      className="sdetail-files__list-item-meta"
                    />
                  </List.Item>
                ) : (
                  <p>No definition file</p>
                )}
              </List>
            </div>
          </SSection>
        </SView>
      )}
    </div>
  );
}

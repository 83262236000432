import { getPropString, isOnProductionUrl } from "../functions/general_functions";
import { Application, RegexType, Service } from "./models";

export const S3_ATTACHMENTS_BUCKET_ALPHA = "di-attachments-eu-west-1-533189355125";
export const S3_ATTACHMENTS_BUCKET_PROD = "di-attachments-eu-west-1-450809307534";

export const AWS_REGION = "eu-west-1";

export const DI_CONFLUENCE_URL = "https://confluence.corp.lego.com/display/DI";
export const TEAMS_COMMUNITY_URL =
  "https://teams.microsoft.com/l/channel/19%3a47826ae379af4e728cab56e0ea180b14%40thread.skype/General?groupId=9f192ac7-dc2d-4ed4-87db-08792931a4c0&tenantId=1d063515-6cad-4195-9486-ea65df456faa";

export const AWS_LOGIN_URL =
  "https://adfs1.corp.lego.com/adfs/ls/IdpInitiatedSignOn.aspx?loginToRp=urn:amazon:webservices/";

const API_GATEWAY_URL_ALPHA = "https://nnp4kf1mm8.execute-api.eu-west-1.amazonaws.com/alpha/";
const API_GATEWAY_URL_PROD = "https://hdtudtf4aj.execute-api.eu-west-1.amazonaws.com/prod/";
const API_GATEWAY_URL_TEST = "https://yy1jkjo6ie.execute-api.eu-west-1.amazonaws.com/alpha/";

let API_URL;
if (process.env.REACT_APP_CYPRESS === "true") {
  API_URL = API_GATEWAY_URL_TEST;
} else {
  API_URL = isOnProductionUrl() ? API_GATEWAY_URL_PROD : API_GATEWAY_URL_ALPHA;
}

const DIDBResourceNotFrozen = {
  APIs: API_URL + "apis",
  Applications: API_URL + "applications",
  AuxAttachmentsCredentials: API_URL + "aux/attachmentscredentials",
  AuxEmailService: API_URL + "aux/emailservice",
  AuxLeanIXApplications: API_URL + "aux/leanixapplications",
  AuxMe: API_URL + "aux/me",
  AuxMembers: API_URL + "aux/members",
  AuxSynchronizeTyk: API_URL + "aux/tyk/synchronize",
  AuxChangeUserGroupTyk: API_URL + "aux/tyk/changeUserGroup",
  AuxMessages: API_URL + "aux/messages",
  Consumers: API_URL + "consumers/event",
  DNS: API_URL + "dns",
  DLQ: API_URL + "dlq",
  Environments: API_URL + "environments",
  EventPublishers: API_URL + "eventpublishers",
  Events: API_URL + "events",
  EventSubscribers: API_URL + "eventsubscribers",
  ExpandedApplications: API_URL + "expanded/applications",
  ExpandedApis: API_URL + "expanded/apis",
  ExpandedServices: API_URL + "expanded/services",
  ExpandedTeams: API_URL + "expanded/teams",
  Feedbacks: API_URL + "aux/feedbacks",
  Logs: API_URL + "aux/logs",
  MyLogs: API_URL + "aux/logs/me",
  Deploy: API_URL + "deploy",
  MyRequests: API_URL + "myrequests",
  MyTeams: API_URL + "expanded/teams/me",
  Namespaces: API_URL + "namespaces",
  Publishers: API_URL + "publishers",
  Request: API_URL + "requestservice",
  Suggestions: API_URL + "aux/suggestions",
  Targets: API_URL + "targets",
  Teams: API_URL + "teams",
  Queue: API_URL + "queue",
  UnallocatedEntities: API_URL + "unallocatedentities",
  Contracts: API_URL + "contracts",
};
export const DIDBResource = Object.freeze(DIDBResourceNotFrozen);

const MICROSOFT_GRAPH_URL = "https://graph.microsoft.com/v1.0/";
const MSGraphResourceNotFrozen = {
  Me: MICROSOFT_GRAPH_URL + "me",
  Users: MICROSOFT_GRAPH_URL + "users",
  Groups: MICROSOFT_GRAPH_URL + "groups",
  GetMemberGroups: MICROSOFT_GRAPH_URL + "me/getMemberGroups",
};
export const MSGraphResource = Object.freeze(MSGraphResourceNotFrozen);

export const RegExpInteger = /^0$|^[1-9][0-9]*$/;
export const RegExpAtLeastOneCharacter = /^(?=.*[a-zA-Z0-9])/;

export const nameToPath = {
  Teams: "/teams",
  Applications: "/applications",
  Services: "/services",
  Create: "/create",
  Feedbacks: "/feedbacks",
  Members: "/members",
  Home: "/home",
  HomeAPIs: "/home/apis",
  HomeServices: "/home/services",
  HomeApplications: "/home/applications",
  HomeEvents: "/home/events",
  HomeNamespaces: "/home/namespaces",
  HomeDNS: "/home/dns",
  HomeDataObjects: "/home/dataobjects",
  HomeUnallocatedEntities: "/home/unallocatedentities",
  "Publishers/Subscribers": "/publishersandsubscribers",
  Logs: "/logs",
  MyLogs: "/logs/me",
  Monitor: "/monitor",
  Profile: "/profile",
  Requests: "/requests",
  Suggestions: "/suggestions",
  Approvals: "/approvals",
  Errors: "/errors",
  Namepaces: "/namespaces",
  DataObjects: "/dataobjects",
  Targets: "/targets",
  Request: "/request",
  consumerContract: "/home/consumerContract",
  providerContract: "/home/providerContract",
  Inbox: "/inbox",
};

export const pathToName: { [key in string]: keyof typeof nameToPath } = {
  "/teams": "Teams",
  "/applications": "Applications",
  "/services": "Services",
  "/home": "Home",
  "/publishersandsubscribers": "Publishers/Subscribers",
  "/requests": "Requests",
  "/logs": "Logs",
};

export interface BreadcrumbItem {
  name: string;
  link: string;
}

export const BreadcrumbLoadingText = "Loading...";

export const getPageBreadcrumb = (pageName: keyof typeof nameToPath, customLink?: string) => ({
  name: pageName,
  link: customLink || nameToPath[pageName],
});
export const getTeamBreadcrumb = (team?: any) => ({
  name: team ? team.name || "No team name" : BreadcrumbLoadingText,
  link: `${nameToPath["Teams"]}/${getPropString(team, "_id")}`,
});
export const getApplicationBreadcrumb = (application?: any) => ({
  name: application ? application.name || "No application name" : BreadcrumbLoadingText,
  link: `${nameToPath["Applications"]}/${getPropString(application, "_id")}`,
});
export const getServiceBreadcrumb = (type: "api" | "event", service: any) => ({
  name: service ? service.name || "No service name" : BreadcrumbLoadingText,
  link: `${nameToPath["Services"]}/${type}/${getPropString(service, "_id")}`,
});

export interface SettingsItem {
  name: string;
  icon: React.ReactNode;
  color?: string;
  onClick: () => void;
}

export interface SelectOption {
  label: string;
  value: any;
}

export interface ApplicationTree {
  title: JSX.Element;
  key: string;
  children: {
    title: JSX.Element;
    key: string;
    id: string;
    type: string;
    service: Service;
  }[];
  id: string;
  type: string;
  application: Application;
}

export type DocumentType =
  | "Team"
  | "Application"
  | "API"
  | "Event"
  | "DNS"
  | "Namespace"
  | "Data Object"
  | "Request"
  | "Event Request"
  | "Group"
  | "Admin"
  | "Direction"
  | "SubscriberContract"
  | "PublisherContract"
  | "Feedback"
  | "Attachments"
  | "Contract"
  | "Unallocated API"
  | "AD user"
  | "Unallocated Entity";

export const pascalCaseRegex: RegexType = {
  regex: /^([A-Z][a-z0-9]+)((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?$/,
  category: "PascalCase",
};
export const camelCaseRegex: RegexType = {
  regex: /^[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?$/,
  category: "camelCase",
};
export const lowerCaseRegex: RegexType = {
  regex: /^[a-z]+$/,
  category: "lowercase",
};
export const dnsNameRegex: RegexType = { regex: /^[a-z0-9.-]+$/, category: "URL" };
export const nameLengthRange: [number, number] = [3, 40];

export const apiKeyDescription: string =
  "You should only use an api key for your application if your application is not capable of using OAuth. We strongly encourage you to use OAuth by creating an AD user instead.";

export const adUserDescription: string =
  "We will send two seperate emails with the usename and password for the ad user. Be aware that you will not be able to use your ad user immediately since the full creation takes 30 minutes - 1 hour. You will receive an email when your ad user is ready to use.";

import React, { useState, useEffect, useContext } from "react";
import { Form, Select, Tag, Tooltip } from "antd";
import { getPropArray } from "../../../../lib/functions/general_functions";
import { getLineWidthSpan } from "../../../../lib/functions/sDetail_functions";
import { SViewContext } from "../../../../lib/contexts";

interface SElementProps {
  label: string;
  field?: string;
  lineWidth?: "default" | "double" | "triple" | "wholeline";
  value?: string[];
  editonly?: boolean;
  readonly?: boolean;
  onChange?(value: any): any;
}

export const SElementTags = (props: SElementProps) => {
  const [tags, setTags] = useState<string[]>();
  const [tagChildren, setTagChildren] = useState<any[]>();
  const { object, isEditing } = useContext(SViewContext);
  const { Option } = Select;

  useEffect(() => {
    if (props.value) {
      setTags(props.value);
    } else {
      setTags(getPropArray(object, props.field + ""));
    }
  }, [object, props.field, isEditing, props.value]);

  useEffect(() => {
    if (!tags) return;
    const children = [];
    for (let i = 0; i < tags?.length; i++) {
      children.push(
        <Option key={i} value={tags[i]}>
          {tags[i]}
        </Option>
      );
    }
    setTagChildren(children);
  }, [tags, Option]);

  function getTagElements() {
    if (!tags) return;
    const children = [];
    for (let i = 0; i < tags?.length; i++) {
      children.push(
        <Tooltip placement="top" key={i} title={tags[i]}>
          <Tag
            key={i}
            style={{
              padding: "2px 6px 2px 6px",
              margin: "2px",
              maxWidth: "180px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {tags[i]}
          </Tag>
        </Tooltip>
      );
    }
    return children;
  }

  function handleOnChange(value: any) {
    if (props.onChange) props.onChange(value);
  }

  const displayView = (
    <span className="sdetail-element__container" style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      <p className="sdetail-element__value">{getTagElements()}</p>
    </span>
  );

  const updateableView = (
    <span className={"sdetail-element__container"} style={getLineWidthSpan(props.lineWidth)}>
      <h4 className="sdetail-element__header">{props.label + ": "}</h4>
      <Form.Item name={props.field} initialValue={tags} noStyle>
        <Select mode="tags" onChange={handleOnChange}>
          {tagChildren}
        </Select>
      </Form.Item>
    </span>
  );

  if (props.editonly || isEditing) return updateableView;
  return displayView;
};

import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Menubar } from "./components/menubar/menubar";
import { MainContainer } from "./components/general/mainContainer";
import { notification, Button, Spin } from "antd";
import {
  doFetch,
  getModalError,
  getNotificationError,
  isChromeBrowser,
  isOnProductionUrl,
  pageWasRefreshed,
  useSHistory,
} from "./lib/functions/general_functions";
import { DIDBResource, nameToPath } from "./lib/definitions/general_definitions";
import * as localForage from "localforage";
import {
  DISABLE_BROWSER_POPUP,
  LAST_OWN_TEAM_VISITED,
  DEFAULT_TEAM_ID,
  DEFAULT_ENV_ID,
  LAST_ENV_USED,
} from "./lib/definitions/localForage_definitions";
import { setDefaultPreferences } from "./lib/functions/profile_functions";
import {
  MyTeamsContext,
  EnvironmentContext,
  MeContext,
  RequesterContractContext,
  ProviderContractContext,
  LogsContext,
  EditModeContext,
  MessagesContext,
} from "./lib/contexts";
import { Environment, MeData, Message, TeamExpanded } from "./lib/definitions/models";
import { filterTeamDataOnEnv } from "./lib/functions/home_functions";
import { InitialLoadingScreen } from "./components/general/initialLoadingScreen";
import { Spinner } from "./components/general/spinner";
import { LoadingOutlined } from "@ant-design/icons";

export const App = () => {
  const [meData, setMeData] = useState<MeData>({
    isAdmin: false,
    tykUserGroupId: "",
  });

  const [hasYetToFetchTeams, setHasYetToFetchTeams] = useState(true);

  const [myTeams, setMyTeams] = useState<any[]>([]);
  const [isFetchingMyTeams, setIsFetchingMyTeams] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<TeamExpanded>();
  const [teamRequests, setTeamRequests] = useState<any[]>([]);
  const [myTeamsFetchingError, setMyTeamsFetchingError] = useState(false);

  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment>();
  const [isFetchingEnvironments, setIsFetchingEnvironments] = useState(false);

  const [requesterContracts, setRequesterContracts] = useState<any[]>([]);
  const [isFethcingRequesterContracts, setIsFethcingRequesterContracts] = useState(false);

  const [providerContracts, setProviderContracts] = useState<any[]>([]);
  const [isFethcingProviderContracts, setIsFethcingProviderContracts] = useState(false);

  const [specificTeamProviderContracts, setSpecificTeamProviderContracts] = useState<any[]>([]);
  const [specificTeamConsumerContracts, setSpecificTeamConsumerContracts] = useState<any[]>([]);

  const [logs, setLogs] = useState<any>();
  const [mappedLogs, setMappedLogs] = useState<any[]>([]);
  const [transactionLogs, setTransactionLogs] = useState<any[]>([]);
  const [errorLogs, setErrorLogs] = useState<any[]>([]);
  const [teamList, setTeamList] = useState<any[]>([]);
  const [applicationsList, setApplicationsList] = useState<any[]>([]);
  const [eventList, setEventList] = useState<any[]>([]);
  const [isFetchingLogs, setIsFetchingLogs] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);

  const [inEditMode, setInEditMode] = useState(false);

  const toggleRef = useRef(false);
  const hasMounted = useRef(false);
  const history = useSHistory();

  const WEEK_IN_MILLI_SECONDS = 604800000;

  const notOnTeamPopupContent = useMemo(
    () => (
      <div>
        <div>
          It seems that you are not yet registered in the i2 system. Visit your profile page to do register yourself on
          a team.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              history.push(nameToPath["Profile"]);
              notification.close("register-popup");
            }}
          >
            Go to Profile
          </Button>
        </div>
      </div>
    ),
    [history]
  );

  const updateSelectedTeam = useCallback(
    (team: any, environment: string) => {
      if (myTeams.find((t) => t._id === team._id)) {
        localForage.setItem(LAST_OWN_TEAM_VISITED, team._id);
      }
      const newTeam = filterTeamDataOnEnv(team, environment);
      if (JSON.stringify(selectedTeam) !== JSON.stringify(newTeam)) {
        setSelectedTeam(newTeam);
      }
    },
    [selectedTeam, myTeams]
  );

  const fetchProviderContracts = useCallback(
    (teamId?: string) => {
      setIsFethcingProviderContracts(true);
      doFetch(
        "GET",
        `${DIDBResource.Events}/consumers/${teamId || (meData.isAdmin ? selectedTeam?._id : "")}`,
        { current: true },
        (res) => setProviderContracts(res.value),
        getModalError("Fetching requester contracts"),
        () => setIsFethcingProviderContracts(false)
      );
    },
    [selectedTeam, meData.isAdmin]
  );

  const fetchRequesterContracts = useCallback(
    (teamId?: string) => {
      setIsFethcingRequesterContracts(true);
      doFetch(
        "GET",
        `${DIDBResource.Applications}/consuming/${teamId || (meData.isAdmin ? selectedTeam?._id : "")}`,
        { current: true },
        (res) => setRequesterContracts(res.value),
        getModalError("Fetching requester contracts"),
        () => setIsFethcingRequesterContracts(false)
      );
    },
    [selectedTeam, meData.isAdmin]
  );

  useEffect(() => {
    // Check preferences
    (async function () {
      const prefToDisable = await localForage.getItem(DISABLE_BROWSER_POPUP);

      if (!isChromeBrowser() && !prefToDisable) {
        notification["warning"]({
          message: "Browser support",
          duration: 8,
          description:
            "The i2 portal is developed for use in Google Chrome. The experience in other browser are not guaranteed to be great.",
        });
      }
    })();

    setDefaultPreferences();
    fetchEnvironments();
    fetchRequesterContracts();
    fetchProviderContracts();
    refreshMeData();
    refreshMessages();

    if (!isOnProductionUrl()) {
      document.title = document.title.replace("(Alpha) ", "");
      document.title = `(Alpha) ${document.title}`;
    }

    function refreshOnVisibilityChange() {
      if (toggleRef.current) {
        refreshMessages();
      }
      toggleRef.current = !toggleRef.current;
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", refreshOnVisibilityChange);
    };
  }, [fetchProviderContracts, fetchRequesterContracts]);

  useEffect(() => {
    window.onunload = () =>
      localStorage.setItem(
        "refresh_detection",
        JSON.stringify({ page: window.location.pathname, timestamp: Date.now() })
      );
  }, [selectedTeam]);

  useEffect(() => {
    if (myTeams.find((t) => t._id === selectedTeam?._id)) {
      setSpecificTeamProviderContracts(
        providerContracts.filter((contract) => contract.ownerTeamId === selectedTeam?._id)
      );
    }
  }, [providerContracts, selectedTeam, myTeams]);

  useEffect(() => {
    if (myTeams.find((t) => t._id === selectedTeam?._id)) {
      setSpecificTeamConsumerContracts(
        requesterContracts.filter((contract) => contract.consumingTeam[0]._id === selectedTeam?._id)
      );
    }
  }, [requesterContracts, selectedTeam, myTeams]);

  useEffect(() => {
    (async () => {
      if (myTeams.length === 0 || !selectedEnvironment) {
        setSelectedTeam(undefined);
        return;
      }

      if (selectedTeam) {
        let team = myTeams.find((team) => team._id === selectedTeam._id);
        if (!team && meData.isAdmin) {
          team = teamList.find((team) => team._id === selectedTeam._id);
        }

        if (team) {
          updateSelectedTeam(team, selectedEnvironment._id);
        }
        return;
      }

      try {
        let defaultTeamId = await localForage.getItem(DEFAULT_TEAM_ID);
        if (defaultTeamId === "last" || pageWasRefreshed()) {
          defaultTeamId = await localForage.getItem(LAST_OWN_TEAM_VISITED);
        }
        const team = myTeams.find((team) => team._id === defaultTeamId);
        if (team) {
          updateSelectedTeam(team, selectedEnvironment._id);
        } else {
          localForage.setItem(DEFAULT_TEAM_ID, "last");
          updateSelectedTeam(myTeams[0], selectedEnvironment._id);
        }
      } catch (error) {
        getModalError("Updating team information")("An error occured");
      }
    })();
  }, [myTeams, updateSelectedTeam, selectedTeam, selectedEnvironment, meData.isAdmin, teamList]);

  useEffect(() => {
    (async () => {
      if (environments.length === 0) {
        setSelectedEnvironment(undefined);
        return;
      }
      try {
        let defaultEnvironment = await localForage.getItem(DEFAULT_ENV_ID);
        if (defaultEnvironment) {
          if (defaultEnvironment === "last") {
            defaultEnvironment = await localForage.getItem(LAST_ENV_USED);
          }
          const env = environments.find((env) => env._id === defaultEnvironment);
          if (env) {
            updateSelectedEnvironment(env);
          } else {
            updateSelectedEnvironment(environments.find((env) => env._id === "dev") || environments[0]);
          }
        }
      } catch (error) {
        getModalError("Fetching environments")("An error occured");
      }
    })();
  }, [environments]);

  function refreshMeData() {
    doFetch(
      "GET",
      DIDBResource.AuxMe,
      { current: true },
      (res) => setMeData(res.value),
      () => {}
    );
  }

  function refreshMessages() {
    setIsFetchingMessages(true);
    doFetch(
      "GET",
      DIDBResource.AuxMessages,
      { current: true },
      (res) => setMessages(res.value),
      (err) => getNotificationError("Fetching messages", err),
      () => setIsFetchingMessages(false)
    );
  }

  function updateSelectedEnvironment(env: Environment) {
    localStorage.setItem(LAST_ENV_USED, env._id);
    setSelectedEnvironment(env);
  }

  const fetchMyTeams = useCallback(
    (redirect?: string) => {
      setIsFetchingMyTeams(true);
      doFetch(
        "GET",
        DIDBResource.MyTeams,
        { current: true },
        (res) => {
          if (res.value.length === 0) {
            notification["info"]({
              message: "Not registered in the system",
              duration: null,
              key: "register-popup",
              style: { marginTop: "30px" },
              description: notOnTeamPopupContent,
            });
          }
          setMyTeams(res.value);
          if (redirect) {
            history.push(redirect);
          }
        },
        (errorMessage) => {
          getModalError("Fetching your teams")(errorMessage);
          setMyTeamsFetchingError(true);
        },
        () => {
          setHasYetToFetchTeams(false);
          setIsFetchingMyTeams(false);
        }
      );
    },
    [notOnTeamPopupContent, history]
  );

  function fetchEnvironments() {
    setIsFetchingEnvironments(true);
    doFetch(
      "GET",
      DIDBResource.Environments,
      { current: true },
      (res) => setEnvironments(res.value.sort((a: any, b: any) => a.stageIndex - b.stageIndex)),
      getModalError("Fetching available environments"),
      () => setIsFetchingEnvironments(false)
    );
  }

  const fetchLogs = useCallback(
    (from?: string, to?: string) => {
      if (!selectedEnvironment) {
        return;
      }
      let fetchString = "";
      let resource = "";

      meData.isAdmin ? (resource = DIDBResource.Logs) : (resource = DIDBResource.MyLogs);

      from && to
        ? (fetchString = `${resource}?from=${new Date(from).getTime()}&to=${new Date(to).getTime()}&env=${
            selectedEnvironment?._id
          }`)
        : (fetchString = `${resource}?from=${Date.now() - WEEK_IN_MILLI_SECONDS}&to=${Date.now()}&env=${
            selectedEnvironment?._id
          }`);

      setIsFetchingLogs(true);
      doFetch(
        "GET",
        fetchString,
        { current: true },
        (res) => setLogs(res.value),
        (err) => getNotificationError("Fetching logs", err)
      );
    },
    [selectedEnvironment, meData.isAdmin]
  );

  const fetchResources = useCallback(async () => {
    // setIsFetchingLogs(true);
    const a = doFetch(
      "GET",
      `${meData.isAdmin ? DIDBResource.ExpandedTeams : DIDBResource.Teams}`,
      { current: true },
      (res) => setTeamList(res.value),
      getModalError("Fetching Teams"),
      () => console.log
    );
    const b = doFetch(
      "GET",
      `${DIDBResource.Applications}`,
      { current: true },
      (res) => setApplicationsList(res.value),
      getModalError("Fetching Applications"),
      () => console.log
    );
    const c = doFetch(
      "GET",
      `${DIDBResource.Events}`,
      { current: true },
      (res) => setEventList(res.value),
      getModalError("Fetching Events"),
      () => console.log
    );

    await Promise.allSettled([a, b, c]);
  }, [meData.isAdmin]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);
  useEffect(fetchLogs, [fetchLogs]);

  useEffect(() => {
    if (logs && teamList && applicationsList && eventList) {
      setMappedLogs(
        logs.logs.map((log: any) => {
          const application = applicationsList.find((application) => application._id === log.application);
          return {
            ...log,
            application: application?.name,
            event: eventList.find((event) => event._id === log.event)?.name,
            team: teamList.find((team) => team._id === application?.teamId)?.name,
            teamId: teamList.find((team) => team._id === application?.teamId)?._id,
          };
        })
      );
      setIsFetchingLogs(false);
    }
  }, [logs, teamList, applicationsList, eventList]);

  useEffect(() => {
    if (!hasMounted.current) {
      fetchMyTeams();
      hasMounted.current = true;
    }
  }, [fetchMyTeams]);

  return (
    <MyTeamsContext.Provider
      value={{
        myTeams,
        selectedTeam,
        teamRequests,
        myTeamsFetchingError,
        isFetchingMyTeams,
        setSelectedTeam,
        setMyTeams,
        setTeamRequests,
        refreshMyTeams: async (redirect) => {
          await fetchResources();
          fetchMyTeams(redirect);
        },
      }}
    >
      <EnvironmentContext.Provider
        value={{
          selectedEnvironment,
          environments,
          isFetchingEnvironments,
          setSelectedEnvironment,
          setEnvironments,
        }}
      >
        <RequesterContractContext.Provider
          value={{
            requesterContracts,
            setRequesterContracts,
            isFethcingRequesterContracts,
            setIsFethcingRequesterContracts,
            refreshConsumerContracts: fetchRequesterContracts,
            specificTeamConsumerContracts,
            setSpecificTeamConsumerContracts,
          }}
        >
          <ProviderContractContext.Provider
            value={{
              providerContracts,
              setProviderContracts,
              isFethcingProviderContracts,
              setIsFethcingProviderContracts,
              refreshProviderContracts: fetchProviderContracts,
              specificTeamProviderContracts,
              setSpecificTeamProviderContracts,
            }}
          >
            <LogsContext.Provider
              value={{
                logs,
                setLogs,
                mappedLogs,
                setMappedLogs,
                transactionLogs,
                setTransactionLogs,
                errorLogs,
                setErrorLogs,
                teamList,
                setTeamList,
                applicationsList,
                setApplicationsList,
                eventList,
                setEventList,
                isFetchingLogs,
                setIsFetchingLogs,
                refreshLogs: fetchLogs,
              }}
            >
              <EditModeContext.Provider
                value={{
                  inEditMode,
                  setInEditMode,
                }}
              >
                <MeContext.Provider value={{ data: meData, refreshMeData: refreshMeData }}>
                  <MessagesContext.Provider
                    value={{
                      messages,
                      refreshMessages,
                      isFetchingMessages,
                    }}
                  >
                    {hasYetToFetchTeams &&
                    ["/", "/#", "/home", "/#home", "/home#"].includes(window.location.pathname) ? (
                      <InitialLoadingScreen />
                    ) : (
                      <>
                        <Menubar />
                        {hasYetToFetchTeams ? (
                          <Spinner margin={200} />
                        ) : (
                          <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 30, color: "#1890ff" }} spin />}
                            spinning={
                              hasYetToFetchTeams ||
                              isFetchingMyTeams ||
                              isFethcingRequesterContracts ||
                              isFethcingProviderContracts
                            }
                          >
                            <MainContainer />
                          </Spin>
                        )}
                      </>
                    )}
                  </MessagesContext.Provider>
                </MeContext.Provider>
              </EditModeContext.Provider>
            </LogsContext.Provider>
          </ProviderContractContext.Provider>
        </RequesterContractContext.Provider>
      </EnvironmentContext.Provider>
    </MyTeamsContext.Provider>
  );
};

import React from "react";
import { setProp, getPropArray, doMask, getProp } from "./general_functions";
import { Key } from "antd/lib/table/interface";
import { forEachKey } from "./general_functions";
import toJsonSchema from "to-json-schema";
import { RuleDefinition } from "../definitions/services_definitions";

export function filterItems(items: any[], searchValue: string) {
  return items.filter((item) => {
    let hit = false;
    forEachKey(item, (_k, v) => {
      if ((v + "").toLowerCase().includes(searchValue.toLowerCase())) {
        hit = true;
      }
    });
    return hit;
  });
}

export function getKeysFromSchema(schema: any) {
  const keys: string[] = [];

  if (!schema?.properties) {
    return [];
  }

  function extractKeys(subSchema: any) {
    if (subSchema.type === "object") {
      for (const prop in subSchema.properties) {
        const keyName = prop + ";" + subSchema.properties[prop].type;
        if (!keys.includes(keyName)) {
          keys.push(keyName);
        }
        extractKeys(subSchema.properties[prop]);
      }
    } else if (subSchema.type === "array") {
      for (const prop in subSchema.items?.properties) {
        const keyName = prop + ";" + subSchema.items.properties[prop].type;
        if (!keys.includes(keyName)) {
          keys.push(keyName);
        }
        extractKeys(subSchema.items.properties[prop]);
      }
    }
  }
  extractKeys(schema);
  return keys;
}

export function getTreeData(obj: any, isTypes?: boolean): { roots: any; expKeys: Key[] } {
  if (!obj || JSON.stringify(obj) === "{}") {
    return { roots: [], expKeys: [] };
  }

  const expandedKeys: Key[] = [];

  function build(val: any, name: string, pathKey: string): any {
    if (val && typeof val === "object") {
      const isArray = val.___arrayType;

      expandedKeys.push(pathKey);

      const elems: any[] = [];

      Object.keys(val).forEach((prop) => {
        if (prop !== "___arrayType") {
          elems.push(build(val[prop], prop, pathKey + "." + prop));
        }
      });

      const title = Array.isArray(val) ? `${name} (${elems.length})` : name;

      return {
        title: isTypes ? (
          <span>
            <b>{`${title}: `}</b>
            {isArray ? `array of ${isArray}s` : "object"}
          </span>
        ) : (
          <span>
            <b>{title}</b>
          </span>
        ),
        key: pathKey,
        children: elems,
      };
    } else {
      return {
        title: (
          <span>
            <b>{`${name}: `}</b>
            {`${val}`}
          </span>
        ),

        key: pathKey,
      };
    }
  }

  const elems: any[] = [];
  if (obj) {
    Object.keys(obj).forEach((prop) => {
      elems.push(build(obj[prop], prop, prop));
    });
  }
  return { roots: elems, expKeys: expandedKeys };
}

export function parseSchemaTypes(schema: any) {
  if (!schema || JSON.stringify(schema) === "{}" || schema.type !== "object") {
    return {};
  }

  const build = (obj: any) => {
    if (!obj || JSON.stringify(obj) === "{}" || !obj.type) {
      return {};
    }

    if (obj.type === "object") {
      const o = {};
      for (const prop in obj.properties) {
        setProp(o, prop, build(obj.properties[prop]));
      }
      return o;
    } else if (obj.type === "array") {
      if (obj.items) {
        const o = { ___arrayType: obj.items.type };
        for (const prop in obj.items.properties) {
          setProp(o, prop, build(obj.items.properties[prop]));
        }
        return o;
      } else {
        return "array of unknown";
      }
    } else {
      return obj.type;
    }
  };

  return build(schema);
}

export function parseExample(schema: any): any {
  if (!schema || JSON.stringify(schema) === "{}") {
    return {};
  }

  const result = {};

  for (const prop in schema.properties) {
    const example = getPropArray(schema.properties[prop], "examples")[0];
    if (example + "") {
      setProp(result, prop, example);
    }
  }

  return result;
}

export function getKeysFromMask(generatedMask: string, jsonTypes: object | undefined) {
  if (generatedMask === "" || !jsonTypes) {
    return [];
  }

  const maskedTypes = doMask(jsonTypes, generatedMask);

  const keys: Key[] = [];

  function getKey(obj: any, pathTo: string) {
    if (typeof obj === "object") {
      if (obj.___arrayType && obj.___arrayType !== "object") {
        keys.push(pathTo);
      } else {
        for (const prop in obj) {
          if (prop !== "___arrayType") {
            getKey(obj[prop], pathTo + "." + prop);
          }
        }
      }
    } else {
      keys.push(pathTo);
    }
  }

  for (const prop in maskedTypes) {
    getKey(maskedTypes[prop], prop);
  }

  return keys;
}

export function generateMask(keys: Key[]) {
  const tempObj: any = {};

  let copyOfKeys = [...keys];

  [...keys].sort().forEach((key) => {
    const hit = copyOfKeys.find((copyKey) => copyKey.toString().includes(key + "."));
    if (hit) {
      copyOfKeys = copyOfKeys.filter((copyKey) => copyKey !== key);
    }
  });

  copyOfKeys.forEach((key) => setProp(tempObj, key.toString(), ""));
  let result = "";
  for (const prop in tempObj) {
    result += getMaskFromObj(tempObj[prop], prop) + ",";
  }

  return result.slice(0, -1);
}

function getMaskFromObj(obj: any, name: string) {
  let result = "";
  if (typeof obj === "object") {
    for (const prop in obj) {
      result += getMaskFromObj(obj[prop], prop) + ",";
    }
    result = result.slice(0, -1);
    result = `${name}(${result})`;
  } else {
    result = name;
  }

  return result;
}

export function getOrderedKeysFromPositions(checkedNodesPositions: any[]) {
  function stringSort(a: string, b: string) {
    const aIndecies = a.split("-").map((v) => Number.parseInt(v));
    const bIndecies = b.split("-").map((v) => Number.parseInt(v));

    for (let i = 0; i < aIndecies.length; i++) {
      if (aIndecies[i] > bIndecies[i]) {
        return 1;
      } else if (aIndecies[i] < bIndecies[i]) {
        return -1;
      }
    }
    return 0;
  }

  const orderedNodes = checkedNodesPositions.sort((a, b) => stringSort(a.pos, b.pos));

  return orderedNodes.map((nodePos) => nodePos.node.key);
}

export function generateRuleSchema(exampleJson: any, routingKey: string) {
  return getSchema(doMask(exampleJson, routingKey));
}

export function getSchema(json: any) {
  return toJsonSchema(json, {
    arrays: { mode: "first" },
    strings: {
      detectFormat: false,
    },
    postProcessFnc: (type, schema) => ({ ...schema, type: type === "integer" ? "number" : type }),
  });
}

export function getSchemaKeyFromRuleKey(ruleKey: Key, schema: any) {
  const path = ruleKey.toString().split(".");

  let currentSubPath = path.reverse().pop();
  let currentSchema = schema;
  let schemaKey = "";

  while (currentSubPath) {
    if (schemaKey) {
      schemaKey += ".";
    }
    if (currentSchema.properties) {
      currentSchema = currentSchema.properties[currentSubPath];
      schemaKey += `properties.${currentSubPath}`;
    } else if (currentSchema.items) {
      currentSchema = currentSchema.items.properties[currentSubPath];
      schemaKey += `items.properties.${currentSubPath}`;
    } else {
      throw new Error("An unexpected error occurred");
    }
    currentSubPath = path.pop();
  }

  return schemaKey;
}

export function getSubSchemaFromRuleKey(ruleKey: Key, schema: any) {
  const schemaKey = getSchemaKeyFromRuleKey(ruleKey, schema);
  return getProp(schema, schemaKey);
}

function getNewSubSchema(schemaKey: string, schema: any, rule: RuleDefinition) {
  const oldSubSchema = getProp(schema, schemaKey);

  const newSubSchema: any = { type: oldSubSchema.type };

  if (oldSubSchema.properties) {
    newSubSchema.properties = oldSubSchema.properties;
  }
  if (oldSubSchema.items) {
    newSubSchema.items = oldSubSchema.items;
  }

  for (const prop in rule) {
    newSubSchema[prop] = rule[prop as keyof RuleDefinition];
  }

  return newSubSchema;
}

export function updateRuleSchema(ruleKey: Key, schema: any, rule: RuleDefinition) {
  const schemaKey = getSchemaKeyFromRuleKey(ruleKey, schema);
  const newSubSchema = getNewSubSchema(schemaKey, schema, rule);
  const newSchema = JSON.parse(JSON.stringify(schema));
  setProp(newSchema, schemaKey, newSubSchema);
  return newSchema;
}

export function clearRulesForRuleKey(ruleKey: Key, schema: any) {
  const schemaKey = getSchemaKeyFromRuleKey(ruleKey, schema);
  const oldSubSchema = getProp(schema, schemaKey);
  const newSubSchema: any = { type: oldSubSchema.type };

  if (oldSubSchema.properties) {
    newSubSchema.properties = oldSubSchema.properties;
  }
  if (oldSubSchema.items) {
    newSubSchema.items = oldSubSchema.items;
  }
  const newSchema = JSON.parse(JSON.stringify(schema));
  setProp(newSchema, schemaKey, newSubSchema);
  return newSchema;
}

function getRuleKeysFromTypeJson(typeJson: any): string[] {
  const ruleKeys: string[] = [];

  function loopEntity(entity: any, path: string) {
    if (entity.___arrayType) {
      ruleKeys.push(path);
    } else if (typeof entity === "object") {
      for (const prop in entity) {
        loopEntity(entity[prop], `${path}${path ? "." : ""}${prop}`);
      }
    } else {
      ruleKeys.push(path);
    }
  }

  loopEntity(typeJson, "");

  return ruleKeys;
}

export function getRegisteredRuleKeys(typeJson: any, ruleSchema: string): Key[] {
  if (JSON.stringify(ruleSchema) === "{}" || !ruleSchema) {
    return [];
  }

  const registeredRuleKeys: Key[] = [];

  const ruleKeys = getRuleKeysFromTypeJson(typeJson);

  ruleKeys.forEach((ruleKey) => {
    try {
      const subSchema = getSubSchemaFromRuleKey(ruleKey, ruleSchema);
      const subSchemaHasRule =
        (subSchema.type === "array" && Object.keys(subSchema).length > 2) ||
        (subSchema.type !== "array" && Object.keys(subSchema).length > 1);
      if (subSchemaHasRule) {
        registeredRuleKeys.push(ruleKey);
      }
    } catch {
      console.log("Warning: Mismatching rule key and rule schema");
    }
  });

  return registeredRuleKeys;
}

import "./main.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { App } from "./app";
import ScrollToTop from "./components/general/scrollToTop";
import AzureAD from "react-aad-msal";
import { authProvider } from "./lib/authProvider";

ReactDOM.render(
  process.env.REACT_APP_CYPRESS === "true" ? (
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  ) : (
    <AzureAD provider={authProvider} forceLogin>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </AzureAD>
  ),

  document.getElementById("root")
);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SView } from "../components/general/detailView/sView";
import { DIDBResource } from "../lib/definitions/general_definitions";
import { getSignedUrl } from "../lib/functions/aws_functions";
import { doFetchPromise, getModalError, useSHistory } from "../lib/functions/general_functions";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Spinner } from "../components/general/spinner";

export function SwaggerUIPage() {
  const [specUrl, setSpecUrl] = useState("");
  const [isFetchingSpecs, setIsFetchingSpecs] = useState(false);

  const history = useSHistory();
  const { id } = useParams<{ id: string }>();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onBackPath = useMemo(() => history.location.pathname.split("/").slice(0, -1).join("/"), [history]);

  useEffect(() => {
    setIsFetchingSpecs(true);

    (async () => {
      try {
        const apiResponse = await doFetchPromise("GET", `${DIDBResource.ExpandedServices}/${id}`);
        const urlResponse = await getSignedUrl(apiResponse.value.teamId, "API", id, apiResponse.value.definitionFile);
        setSpecUrl(urlResponse);
        setIsFetchingSpecs(false);
      } catch {
        getModalError("Fetching OpenAPI documentation")("An error occurred fetching documention for API");
        history.push(onBackPath);
      }
    })();
  }, [id, history, onBackPath]);

  return (
    <SView title="Swagger UI" onBack={() => history.push(onBackPath)}>
      {isFetchingSpecs ? <Spinner /> : <SwaggerUI url={specUrl} />}
    </SView>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Form, Select, Button } from "antd";
import { SSelectOption } from "../detailView/Elements/sElementSelect";
import { SLabel } from "./sLabel";
import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";

interface SFormSelectProps {
  label: string;
  field?: string;
  optional?: boolean;
  initialValue?: string;
  options?: SSelectOption[];
  disabled?: boolean;
  allowFreeText?: boolean;
  multiple?: boolean;
  onChange?(value: string, option: any): void;
  onNewForm?(closeModal: () => void): React.ReactNode;
  getOptions?(): Promise<SSelectOption[]>;
}

export const SFormSelect = (props: SFormSelectProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState<SSelectOption[]>([]);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [preppedOptions, setPreppedOptions] = useState<SSelectOption[]>([]);

  const isMounted = useRef(true);

  const selectRef = useRef<any>(null);

  const { getOptions } = props;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (getOptions) {
        if (!isMounted.current) return;
        setIsFetching(true);
        try {
          const options = await getOptions();
          if (!isMounted.current) return;
          setOptions(options);
        } catch {}
        if (!isMounted.current) return;
        setIsFetching(false);
      }
    })();
  }, [getOptions]);

  useEffect(() => {
    let newOptions = props.options || [];
    if (props.getOptions) {
      newOptions = options;
    }
    newOptions = newOptions
      .map((option) => ({
        ...option,
        title: option.title || `${option.label}, ${option.value}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (props.allowFreeText && searchValue && !newOptions.find((opt) => opt.label === searchValue)) {
      setPreppedOptions(newOptions.concat({ label: `Use ${searchValue}?`, value: searchValue, title: searchValue }));
    } else {
      setPreppedOptions(newOptions);
    }
  }, [props.allowFreeText, searchValue, props.options, options, props.getOptions]);

  return (
    <>
      <SLabel label={props.label} required={!props.optional} />
      <Form.Item
        name={props.field}
        rules={[{ required: !props.optional }]}
        hasFeedback
        validateStatus={isFetching ? "validating" : undefined}
        style={{ display: "flex" }}
        className="sform__item"
        initialValue={props?.initialValue}
      >
        <Select
          ref={selectRef}
          placeholder={`${props.label}...`}
          showSearch
          showArrow
          mode={props.multiple ? "multiple" : undefined}
          searchValue={searchValue}
          onSearch={setSearchValue}
          filterOption={(input, option) =>
            !!option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
            option?.label === `Use ${searchValue}?`
          }
          onChange={(value, option) => {
            if (props.onChange) {
              if (value === searchValue) {
                setPreppedOptions((opts) =>
                  opts.filter((opt) => opt.value === searchValue).concat({ label: searchValue, value: searchValue })
                );
              }
              if (value) {
                props.onChange(value.toString(), option);
              } else {
                props.onChange("", option);
              }
            }
          }}
          options={preppedOptions}
          allowClear={props.optional}
          getPopupContainer={() => document.getElementById("main-container-content")!}
          disabled={props.disabled}
        />
      </Form.Item>
      {props.onNewForm ? (
        <>
          <Button icon={<PlusOutlined />} onClick={() => setVisible(true)} style={{ marginRight: "8px" }} />
          <Modal
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            className="sform__modal"
            destroyOnClose
          >
            {props.onNewForm(() => setVisible(false))}
          </Modal>
        </>
      ) : (
        <div />
      )}
    </>
  );
};

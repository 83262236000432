import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { doFetch, getPropString, getProp, getModalError } from "../lib/functions/general_functions";
import {
  DIDBResource,
  nameToPath,
  getServiceBreadcrumb,
  getPageBreadcrumb,
  getTeamBreadcrumb,
  getApplicationBreadcrumb,
} from "../lib/definitions/general_definitions";
import { BreadcrumbContext } from "../lib/contexts";
import { Button, Collapse, Popover } from "antd";
import { Spinner } from "../components/general/spinner";
import { parseSchemaTypes, getKeysFromMask } from "../lib/functions/services_functions";
import { MultiTree } from "../components/services/multiTree";
import { Key } from "antd/lib/table/interface";
import { SView } from "../components/general/detailView/sView";
import { SSectionCustom } from "../components/general/detailView/sSectionCustom";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface EventDetailsPageProps {
  serviceEvent?: any;
}

export function EventDetailsPage(props: EventDetailsPageProps) {
  const [parsedTypes, setParsedTypes] = useState();
  const [parsedExample, setParsedExample] = useState();
  const [routings, setRoutingKeys] = useState<Key[]>([]);
  const [serviceEvent, setServiceEvent] = useState<any>();
  const [members, setMembers] = useState<any[]>([]);
  const [isFetchingService, setIsFetchingService] = useState(false);
  const isMounted = useRef(true);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setBreadcrumbs = useContext(BreadcrumbContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.serviceEvent) {
      setServiceEvent(props.serviceEvent);
      return;
    }
    setIsFetchingService(true);
    doFetch(
      "GET",
      `${DIDBResource.ExpandedServices}/${id}`,
      isMounted,
      (res) => setServiceEvent(res.value),
      (err) => {
        getModalError("Fetching service")(err);
        history.push(nameToPath["Services"]);
      },
      () => setIsFetchingService(false)
    );
  }, [id, history, props.serviceEvent]);

  useEffect(() => {
    if (props.serviceEvent) {
      setBreadcrumbs([
        getApplicationBreadcrumb({
          name: props.serviceEvent.applicationName,
          _id: props.serviceEvent.applicationId,
        }),
        getServiceBreadcrumb("event", props.serviceEvent),
      ]);
    } else {
      setBreadcrumbs([
        getPageBreadcrumb("Services"),
        getTeamBreadcrumb({
          name: getPropString(serviceEvent, "teamName"),
          _id: getPropString(serviceEvent, "teamId"),
        }),
        getApplicationBreadcrumb({
          name: getPropString(serviceEvent, "applicationName"),
          _id: getPropString(serviceEvent, "applicationId"),
        }),
        getServiceBreadcrumb("event", serviceEvent),
      ]);
    }

    const jsonTypes = parseSchemaTypes(getProp(serviceEvent, "schema"));

    setParsedTypes(jsonTypes);
    setParsedExample(getProp(serviceEvent, "example"));
    setRoutingKeys(getKeysFromMask(getPropString(serviceEvent, "routingKey"), jsonTypes));
  }, [serviceEvent, setBreadcrumbs, props.serviceEvent]);

  const pStyles = { marginLeft: "16px", marginBottom: "30px" };

  useEffect(() => {
    const teamId = getPropString(serviceEvent, "teamId");
    doFetch(
      "GET",
      `${DIDBResource.AuxMembers}/${teamId}`,
      isMounted,
      (res) => setMembers(res.value),
      () => getModalError("Fetching members")
    );
  }, [serviceEvent]);

  return (
    <div className="services">
      {isFetchingService ? (
        <Spinner margin={100} />
      ) : (
        <SView
          title={getPropString(serviceEvent, "name")}
          subTitle="Event"
          wide
          onBack={() => history.push(nameToPath["Services"])}
          extra={
            <>
              <Button
                type="primary"
                onClick={() => history.push(`${nameToPath["Services"]}/event/${id}${nameToPath["Request"]}/subscribe`)}
                icon={<PlusOutlined />}
                data-cy="subscription"
              >
                Subscription
              </Button>
              <Button
                type="primary"
                onClick={() => history.push(`${nameToPath["Services"]}/event/${id}${nameToPath["Request"]}/publish`)}
                icon={<PlusOutlined />}
                style={{ marginLeft: "8px" }}
                data-cy="publication"
              >
                Publication
              </Button>
            </>
          }
        >
          <SSectionCustom title="Details">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: "60px",
              }}
            >
              <div>
                <h3>Team</h3>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p style={pStyles}>{getPropString(serviceEvent, "teamName") || "No team"}</p> &nbsp;
                  <Popover
                    title="Team Members"
                    content={members.map((member) => (
                      <div key={member.name}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto",
                            gridTemplateRows: "auto",
                            columnGap: "18px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <div>{member.name}</div>
                          {member.email}
                        </div>
                      </div>
                    ))}
                  >
                    <InfoCircleOutlined
                      style={{
                        color: "blue",
                      }}
                    />
                  </Popover>
                </div>
                <h3>Application</h3>
                <p style={pStyles}>{getPropString(serviceEvent, "applicationName") || "No application"}</p>
                <h3>Event description</h3>
                <p style={pStyles}>{getPropString(serviceEvent, "description") || "No description"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "auto",
                }}
              >
                <Collapse defaultActiveKey={1}>
                  <Collapse.Panel header="Data Object" key={1} disabled={!getProp(serviceEvent, "schema")}>
                    <div style={{ overflow: "auto", maxHeight: "450px" }}>
                      <MultiTree jsonTypes={parsedTypes} jsonExample={parsedExample} routingKeys={routings} />
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </SSectionCustom>
        </SView>
      )}
    </div>
  );
}

import { getModalError, doFetchPromise, isOnProductionUrl, getNotificationError } from "./general_functions";
import {
  DIDBResource,
  S3_ATTACHMENTS_BUCKET_ALPHA,
  S3_ATTACHMENTS_BUCKET_PROD,
} from "../definitions/general_definitions";
import * as AWS from "aws-sdk";
import { Attachment, AttachmentEntityType } from "../definitions/aws_definitions";
import { String } from "aws-sdk/clients/batch";

export async function listEntityAttachments(
  selectedTeamId: string,
  entityType: AttachmentEntityType,
  entityId: string,
  onOK: (attachments: Attachment[]) => void,
  onNotOK?: (error: any) => void
) {
  const s3 = new AWS.S3({
    ...(await getAttachmentsCredentials()),
  });

  try {
    const attachments = await s3
      .listObjects({
        Bucket: isOnProductionUrl() ? S3_ATTACHMENTS_BUCKET_PROD : S3_ATTACHMENTS_BUCKET_ALPHA,
        Prefix: generateAttachmentPrefix(selectedTeamId, entityType, entityId),
      })
      .promise();

    onOK(
      attachments.Contents?.map((file) => {
        const elements = file.Key?.split("/") || [];
        return {
          name: elements[elements?.length - 1],
          key: file.Key || "",
        };
      }) || []
    );
  } catch (error) {
    if (onNotOK) {
      onNotOK(error);
    }
  }
}

export async function uploadEntityAttachments(
  selectedTeamId: string,
  entityType: AttachmentEntityType,
  entityId: string,
  attachments: any[],
  onOK?: () => void,
  onNotOK?: (error: any) => void
) {
  if (!attachments) {
    return;
  }

  const s3 = new AWS.S3({
    ...(await getAttachmentsCredentials()),
  });
  try {
    await Promise.all(
      attachments.map(
        async (file) =>
          await s3
            .upload({
              Bucket: isOnProductionUrl() ? S3_ATTACHMENTS_BUCKET_PROD : S3_ATTACHMENTS_BUCKET_ALPHA,
              Key: `${generateAttachmentPrefix(selectedTeamId, entityType, entityId)}/${file.name}`,
              Body: file,
            })
            .promise()
      )
    );
    if (onOK) {
      onOK();
    }
  } catch (error) {
    if (onNotOK) {
      onNotOK(error);
    }
  }
}

export async function deleteEntityAttachment(
  selectedTeamId: string,
  entityType: AttachmentEntityType,
  entityId: string,
  fileName: string
) {
  const s3 = new AWS.S3({ ...(await getAttachmentsCredentials()) });

  await s3
    .deleteObject({
      Bucket: isOnProductionUrl() ? S3_ATTACHMENTS_BUCKET_PROD : S3_ATTACHMENTS_BUCKET_ALPHA,
      Key: `${generateAttachmentPrefix(selectedTeamId, entityType, entityId)}/${fileName}`,
    })
    .promise();
}

function generateAttachmentPrefix(selectedTeamId: string, entityType: AttachmentEntityType, entityId: string) {
  return `${selectedTeamId}/${entityType}s/${entityId}`;
}

export async function getAttachmentsCredentials() {
  try {
    const { accessKeyId, secretAccessKey, sessionToken } = (
      await doFetchPromise("GET", DIDBResource.AuxAttachmentsCredentials)
    ).value;

    return {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
  } catch (error) {
    getModalError("Fetching AWS S3 credentials")(error.message);
  }
  return { accessKeyId: "", secretAccessKey: "", sessionToken: "" };
}

export async function getSignedUrl(
  teamId: string,
  entityType: AttachmentEntityType,
  entityId: string,
  fileName: String
) {
  const response = await doFetchPromise("POST", DIDBResource.AuxAttachmentsCredentials, {
    key: `${generateAttachmentPrefix(teamId, entityType, entityId)}/${fileName}`,
  });

  return response.value.signedUrl;
}

export async function downloadAttachment(
  teamId: string,
  entityType: AttachmentEntityType,
  entityId: string,
  fileName: String
) {
  try {
    const signedUrl = await getSignedUrl(teamId, entityType, entityId, fileName);
    window.open(signedUrl, "_blank");
  } catch {
    getNotificationError("Downloading attachment", "An error occured trying to download attachment");
  }
}

import { createContext } from "react";
import { BreadcrumbItem } from "./definitions/general_definitions";
import { TeamExpanded, Environment, MeData, Message } from "./definitions/models";

export const BreadcrumbContext = createContext<(breadcrumbs: BreadcrumbItem[] | undefined) => void>(() => {});

export const MyTeamsContext = createContext<{
  myTeams: TeamExpanded[];
  selectedTeam: TeamExpanded | undefined;
  teamRequests: any[];
  myTeamsFetchingError: boolean;
  isFetchingMyTeams: boolean;
  setSelectedTeam(selectedTeam: TeamExpanded): void;
  setMyTeams(myTeams: TeamExpanded[]): void;
  setTeamRequests(requests: any[]): void;
  refreshMyTeams(redirect?: string): void;
}>({
  myTeams: [],
  selectedTeam: undefined,
  teamRequests: [],
  myTeamsFetchingError: false,
  isFetchingMyTeams: false,
  setSelectedTeam: () => {},
  setMyTeams: () => {},
  setTeamRequests: () => {},
  refreshMyTeams: () => {},
});

export const EnvironmentContext = createContext<{
  selectedEnvironment: Environment | undefined;
  environments: Environment[];
  isFetchingEnvironments: boolean;
  setSelectedEnvironment(environment: Environment): void;
  setEnvironments(environments: Environment[]): void;
}>({
  selectedEnvironment: undefined,
  environments: [],
  isFetchingEnvironments: false,
  setSelectedEnvironment: () => {},
  setEnvironments: () => {},
});

export const RequesterContractContext = createContext<{
  requesterContracts: any[];
  setRequesterContracts(contracts: any[]): void;
  isFethcingRequesterContracts: boolean;
  setIsFethcingRequesterContracts(fetching: boolean): void;
  refreshConsumerContracts(teamId?: string): void;
  specificTeamConsumerContracts: any[];
  setSpecificTeamConsumerContracts(contracts: any[]): void;
}>({
  requesterContracts: [],
  setRequesterContracts: () => {},
  isFethcingRequesterContracts: false,
  setIsFethcingRequesterContracts: () => {},
  refreshConsumerContracts: () => {},
  specificTeamConsumerContracts: [],
  setSpecificTeamConsumerContracts: () => {},
});

export const ProviderContractContext = createContext<{
  providerContracts: any[];
  setProviderContracts(contracts: any[]): void;
  isFethcingProviderContracts: boolean;
  setIsFethcingProviderContracts(fetching: boolean): void;
  refreshProviderContracts(teamId?: string): void;
  specificTeamProviderContracts: any[];
  setSpecificTeamProviderContracts(contracts: any[]): void;
}>({
  providerContracts: [],
  setProviderContracts: () => {},
  isFethcingProviderContracts: false,
  setIsFethcingProviderContracts: () => {},
  refreshProviderContracts: () => {},
  specificTeamProviderContracts: [],
  setSpecificTeamProviderContracts: () => {},
});

export const SViewContext = createContext<{
  object: any;
  isEditing: boolean;
}>({
  object: undefined,
  isEditing: false,
});

export const MeContext = createContext<{ data: MeData; refreshMeData: () => void }>({
  data: {
    isAdmin: false,
    tykUserGroupId: "",
  },
  refreshMeData: () => {},
});

export const EditModeContext = createContext<{
  inEditMode: boolean;
  setInEditMode(mode: boolean): void;
}>({
  inEditMode: false,
  setInEditMode: () => {},
});

export const LogsContext = createContext<{
  logs: any[];
  setLogs(logs: any[]): void;
  mappedLogs: any[];
  setMappedLogs(mappedLogs: any[]): void;
  transactionLogs: any[];
  setTransactionLogs(filteredLogs: any[]): void;
  errorLogs: any[];
  setErrorLogs(errorLogs: any[]): void;
  teamList: any[];
  setTeamList(teams: any[]): void;
  applicationsList: any[];
  setApplicationsList(applications: any[]): void;
  eventList: any[];
  setEventList(events: any[]): void;
  isFetchingLogs: boolean;
  setIsFetchingLogs(fetching: boolean): void;
  refreshLogs(from?: string, to?: string): void;
}>({
  logs: [],
  setLogs: () => {},
  mappedLogs: [],
  setMappedLogs: () => {},
  transactionLogs: [],
  setTransactionLogs: () => {},
  errorLogs: [],
  setErrorLogs: () => {},
  teamList: [],
  setTeamList: () => {},
  applicationsList: [],
  setApplicationsList: () => {},
  eventList: [],
  setEventList: () => {},
  isFetchingLogs: false,
  setIsFetchingLogs: () => {},
  refreshLogs: () => {},
});

export const MessagesContext = createContext<{
  messages: Message[];
  refreshMessages: () => void;
  isFetchingMessages: boolean;
}>({
  messages: [],
  refreshMessages: () => {},
  isFetchingMessages: false,
});

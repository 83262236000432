/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  nameToPath,
  DIDBResource,
  apiKeyDescription,
  adUserDescription,
} from "../../lib/definitions/general_definitions";
import { useHistory, useParams } from "react-router-dom";
import { MyTeamsContext, RequesterContractContext } from "../../lib/contexts";
import {
  getPropArray,
  doFetch,
  getModalError,
  getModalNotFound,
  getNotificationSuccess,
  getNotificationSuccessCustom,
  getModalWarning,
  getModalInfo,
  useSHistory,
} from "../../lib/functions/general_functions";
import { ApplicationExpanded, ServiceType } from "../../lib/definitions/models";
import { consumedServiceColumns, servicesColumns } from "../../lib/definitions/table_column_definitions";
import { SView } from "../general/detailView/sView";
import { SElementTags } from "../general/detailView/Elements/sElementTags";
import { SSection } from "../general/detailView/sSection";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SElementSelect } from "../general/detailView/Elements/sElementSelect";
import { DeployButton } from "./deployButton";
import { ReassignButton } from "./reassignButton";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { SFiles } from "../general/detailView/sFiles";
import { RegionOptions } from "../../lib/definitions/aws_definitions";
import { CheckCircleTwoTone, KeyOutlined, MinusCircleOutlined, UserOutlined } from "@ant-design/icons";
import { SuperTable } from "../superTable";
import { SElementCustom } from "../general/detailView/Elements/sElementCustom";
import { Button, Divider, Dropdown, Menu, Popover } from "antd";

export const ApplicationPane = () => {
  const [application, setApplication] = useState<ApplicationExpanded>();
  const [leanixApplicationsOptions, setLeanixApplicationsOptions] = useState<any[]>([]);
  const [consumedServices, setConsumedServices] = useState<any[]>([]);
  const history = useSHistory();
  const reactHistory = useHistory();

  const { id } = useParams<{ id: string }>();
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  // const { selectedEnvironment } = useContext(EnvironmentContext);
  const { requesterContracts } = useContext(RequesterContractContext);
  const [isCreatingAzureAd, setIsCreatingAzureAd] = useState(false);
  const [adUser, setAdUser] = useState("");
  const [isApiKeyAttached, setIsApiKeyAttached] = useState(false);
  const [isHandlingApiKey, setIsHandlingApiKey] = useState(false);
  const [useOwnAdId, setUseOwnAdId] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    doFetch(
      "GET",
      DIDBResource.AuxLeanIXApplications,
      isMounted,
      (response) =>
        setLeanixApplicationsOptions(
          response.value.map((application: any) => ({ label: application.name, value: application.id }))
        ),
      () => {} //getNotificationError("Fetching LeanIX application options", "Failed to fetch LeanIX application options")
    );
  }, [id]);

  useEffect(() => {
    if (!id || !selectedTeam) {
      return;
    }
    let ct: any[] = [];
    requesterContracts.forEach((element) => {
      if (element.consumingAppId === id) {
        ct.push(element);
      }
    });
    setConsumedServices(ct);
    const application = selectedTeam?.applications.find((application) => application._id === id);
    if (application) {
      setApplication(application);
      setAdUser(application?.azureAdId ? application?.azureAdId : "");
      setIsApiKeyAttached(application?.apiKeyId ? true : false);
    } else {
      getModalNotFound("Application", id, () => reactHistory.push(nameToPath["Home"]));
    }
  }, [id, selectedTeam, reactHistory, requesterContracts]);

  async function putApplication(applicationValues: any): Promise<boolean> {
    let success = false;
    delete applicationValues.oid;
    delete applicationValues.apiKeyId;
    await doFetch(
      "PUT",
      `${DIDBResource.Applications}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccess("Updated", "Application", applicationValues.name);
        success = true;
      },
      (errorContent: any) => {
        getModalError("Updating application")(errorContent);
        success = false;
      },
      undefined,
      applicationValues
    );

    return success;
  }

  async function deleteApplication() {
    await doFetch(
      "DELETE",
      `${DIDBResource.Applications}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        history.push(nameToPath["HomeApplications"]);
        getNotificationSuccess("Deleted", "Application", application?.name);
      },
      getModalError("Deleting application")
    );
  }

  async function createADUser(type: string) {
    setUseOwnAdId(false);
    setIsCreatingAzureAd(true);
    getNotificationSuccessCustom("Creating AD user", "");
    await doFetch(
      "PUT",
      `${DIDBResource.Applications}/${id}/aduser?type=${type}`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccessCustom("Created AD user", "Azure AD user succesfully created");
      },
      getModalError("Creating AD user"),
      undefined,
      {}
    );
    setIsCreatingAzureAd(false);
  }
  async function deleteADUser() {
    setIsCreatingAzureAd(true);
    await doFetch(
      "DELETE",
      `${DIDBResource.Applications}/${id}/aduser`,
      isMounted,
      () => {
        setAdUser("");
        refreshMyTeams();
        getNotificationSuccess("Deleted", "AD user");
      },
      getModalError("Deleting AD user")
    );
    setIsCreatingAzureAd(false);
  }

  async function createAPIKey() {
    setIsHandlingApiKey(true);
    await doFetch(
      "PUT",
      `${DIDBResource.Applications}/${id}/apikey`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccessCustom("Created API key", "API Key succesfully created");
        getModalInfo("Info", "We've sent the api key to your mail box. Remember to check you spam mail");
      },
      getModalError("Creating API key"),
      undefined,
      {}
    );
    setIsHandlingApiKey(false);
  }

  async function deleteAPIKey() {
    setIsHandlingApiKey(true);
    await doFetch(
      "DELETE",
      `${DIDBResource.Applications}/${id}/apikey`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccessCustom("Deleted API key", "API Key succesfully deleted");
      },
      getModalError("Deleting API key"),
      undefined,
      {}
    );
    setIsHandlingApiKey(false);
  }

  async function onUseOwnAdId() {
    setUseOwnAdId(true);
    if (adUser.length > 0) await deleteADUser();
  }

  const services = getPropArray(application, "services");

  const menu = (
    <Menu>
      {adUser.length > 0 ? (
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={() => deleteADUser()}>
            Delete Azure AD
          </a>
        </Menu.Item>
      ) : (
        <>
          <Menu.Item>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => getModalWarning("Caution!")(adUserDescription, () => createADUser("external"))}
            >
              Create external Azure AD
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => getModalWarning("Caution!")(adUserDescription, () => createADUser("internal"))}
            >
              Create internal Azure AD
            </a>
          </Menu.Item>
        </>
      )}
      {!useOwnAdId && (
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer" onClick={() => onUseOwnAdId()}>
            Use Own Azure AD
          </a>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <SView
      key={id}
      title="Application"
      subTitle={application?.name}
      onBack={() => history.push(nameToPath["HomeApplications"])}
      object={application}
      onSave={putApplication}
      onDelete={deleteApplication}
      extra={[
        <Button
          key="4"
          icon={<KeyOutlined />}
          loading={isHandlingApiKey}
          onClick={() => {
            isApiKeyAttached ? deleteAPIKey() : getModalWarning("Caution!")(apiKeyDescription, () => createAPIKey());
          }}
        >
          {isApiKeyAttached ? "Delete API key" : "Create API key"}
        </Button>,
        <Dropdown key="1" overlay={menu} placement="bottomLeft">
          <Button style={{ marginLeft: "8px" }} icon={<UserOutlined />} loading={isCreatingAzureAd}>
            Azure AD
          </Button>
        </Dropdown>,
        <ReassignButton key="2" type="Application" entityId={id} parentId={application?.teamId} />,
        <DeployButton key="3" type="Application" entityId={id} />,
      ]}
    >
      <SSection title="Details">
        <SElementInput label="Name" field="name" />
        <SElementSelect label="LeanIX application" field="leanIXId" options={leanixApplicationsOptions} />
        <SElementInput label="Azure AD" field="azureAdId" readonly={!useOwnAdId} />
        <SElementTags label="Tags" field="tags" />
        <SElementInput label="Description" field="description" fieldType="textarea" lineWidth="double" />
        <SElementCustom lineWidth="double">
          <Divider style={{ gridColumn: "span 2", margin: "8px 0" }} />
        </SElementCustom>
        <SElementInput label="AWS ID" field="awsId" />
        <SElementInput label="AWS Role ARN" field="awsRole.arn" />
        <SElementInput label="AWS Role External ID" field="awsRole.externalId" />
        <SElementSelect label="AWS Regions" field="awsRole.regions" multiple options={RegionOptions} />
        <SElementCustom
          label="Api key enabled"
          initialValue={undefined}
          children={
            <Popover content="This shows wether you have attached an api key to your application">
              {isApiKeyAttached ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <MinusCircleOutlined />}
            </Popover>
          }
        />
        <SElementInput label="Issuer Url" field="issuerUrl" />
        <SElementInput label="Client Id" field="clientId" />
        <SElementInput label="Client Secret" field="clientSecret" />
        <SElementInput label="Scope" field="scope" />
      </SSection>
      <SSectionCustom title={`Provided Services (${services.length})`} frameless>
        <SuperTable
          rows={services}
          rowKey="_id"
          columns={servicesColumns}
          onRowClick={(service) =>
            history.push(
              `${nameToPath[service.serviceType === ServiceType.API ? "HomeAPIs" : "HomeEvents"]}/${service._id}`
            )
          }
          onCreate={() => history.push(nameToPath["HomeServices"] + nameToPath["Create"])}
        />
      </SSectionCustom>

      <SSectionCustom title={`Contracts (${consumedServices.length})`} frameless>
        <SuperTable
          rows={consumedServices}
          rowKey="_id"
          columns={consumedServiceColumns}
          onCreate={() => history.push(nameToPath["Services"])}
          onRowClick={(contract) => {
            history.push(`${nameToPath["consumerContract"]}/${contract._id}`);
          }}
        />
      </SSectionCustom>
      <SFiles
        key={id}
        title="Attachments"
        teamId={selectedTeam?._id}
        entityType="Application"
        entityId={application?._id}
      />
    </SView>
  );
};

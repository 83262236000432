import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { BreadcrumbContext, MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import {
  nameToPath,
  DIDBResource,
  nameLengthRange,
  camelCaseRegex,
} from "../../../lib/definitions/general_definitions";
import {
  getModalError,
  doFetch,
  getNotificationSuccess,
  doFetchPromise,
  getNotificationError,
  getNotificationFeedbackRating,
  useQuery,
  getModalWarning,
  useSHistory,
} from "../../../lib/functions/general_functions";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormInput } from "../createForm/sFormInput";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormTags } from "../createForm/sFormTags";
import { serviceVisibilityOptions } from "../../../lib/definitions/home_definitions";
import { SFormEnum } from "../createForm/sFormEnum";
import { SFormFiles } from "../createForm/sFormFiles";
import { uploadEntityAttachments } from "../../../lib/functions/aws_functions";
import { useForm } from "antd/lib/form/Form";
import { CreateApplication } from "./createApplication";
import toJsonSchema from "to-json-schema";
import { SSectionCustom } from "../detailView/sSectionCustom";
import { DataObjectEditor } from "../../services/dataObjectEditor";

const defaultExample = {
  someKey1: "someValue1",
  someKey2: "someValue2",
  someKey3: "someValue3",
};

export const CreateEvent = () => {
  const [jsonExample, setJsonExample] = useState(defaultExample);
  const [schema, setSchema] = useState(toJsonSchema(defaultExample));
  const [routingMask, setRoutingMask] = useState("");
  const [events, setEvents] = useState<any>();
  const [errorMessage, setErrorMessage] = useState("");

  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const { query, getNewQuery } = useQuery();
  const history = useSHistory();

  const [form] = useForm();

  const setBreadcrumbs = useContext(BreadcrumbContext);

  const filesRef = useRef<any>();
  const isMounted = useRef(true);

  useEffect(() => {
    setBreadcrumbs([]);
    return () => {
      isMounted.current = false;
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs]);

  useEffect(() => {
    const applicationId = query.get("applicationid");
    if (applicationId) form.setFieldsValue({ applicationId });
  }, [form, query]);

  async function createEvent(values: any, files: any[]) {
    const endpoint = {
      type: values.type,
      destination: values.destination,
      authType: values.authType,
      issuer: values.issuer,
    };

    delete values?.type;
    delete values?.destination;
    delete values?.authType;
    delete values?.issuer;

    await doFetch(
      "POST",
      DIDBResource.Events,
      isMounted,
      (response) => {
        getNotificationFeedbackRating("event");
        getNotificationSuccess("Created", "Event", values.name);
        getModalWarning("Create contract now!")(
          "You need a publisher or a subscriber contract to be able to use your event. You can do that now or later",
          () => {
            refreshMyTeams();
            history.push(`/services/event/${response.value.id}`, undefined, true);
          },
          {
            okText: "Let's go",
            cancelText: "Not now",
            onCancel: () => {
              refreshMyTeams(`${nameToPath["Home"]}`);
            },
          }
        );

        if (selectedTeam?._id) {
          uploadEntityAttachments(selectedTeam._id, "Event", response.value.id, files, undefined, () =>
            getNotificationError("Uploading attachments", "An error occured")
          );
        }
      },
      getModalError("Creating Event"),
      undefined,
      {
        ...values,
        name: values.name,
        visibility: values.visibility,
        applicationId: values.applicationId,
        schema: schema,
        endpoint: endpoint,
        example: jsonExample,
        routingKey: routingMask,
        env: selectedEnvironment?._id,
      }
    );
  }

  const applicationOptions = useMemo(
    () =>
      (selectedTeam?.applications || [])
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application: any) => ({
          label: application.name,
          value: application._id,
        })),
    [selectedTeam, selectedEnvironment]
  );

  async function getEventNames() {
    if (events) return;

    try {
      const events = await doFetchPromise("GET", DIDBResource.Events);
      setEvents(events.value);
      return events.value.map((event: any) => event.name);
    } catch {
      return [];
    }
  }

  return (
    <SForm
      title="Create Event"
      onBack={() => history.push(nameToPath["HomeServices"] + nameToPath["Create"])}
      onCreate={(values) => createEvent(values, filesRef.current?.getFiles())}
      form={form}
      errorMessage={errorMessage}
    >
      <SFormSection>
        <SFormInput
          label="Name"
          field="name"
          regex={camelCaseRegex}
          lengthRange={nameLengthRange}
          getReservedValues={getEventNames}
          reservedValuesCaseInsensitive
        />
        <SFormEnum label="Visibility" field="visibility" options={serviceVisibilityOptions} />
        <SFormSelect
          label="Application"
          field="applicationId"
          options={applicationOptions}
          onNewForm={(close) => (
            <CreateApplication
              extraHook={(applicationId) => {
                close();
                history.push({
                  pathname: history.location.pathname,
                  search: getNewQuery("applicationid", applicationId),
                });
              }}
            />
          )}
        />
        <SFormInput label="Description" field="description" textarea optional />
        <SFormTags label="Tags" field="tags" optional />
        <SFormFiles label="Attachments" ref={filesRef} />
      </SFormSection>
      <SSectionCustom title="Data Object">
        <DataObjectEditor
          example={jsonExample}
          schema={schema}
          onExampleChange={setJsonExample}
          routingMask={routingMask}
          onRoutingMaskChange={setRoutingMask}
          onSchemaChange={setSchema}
          onErrorChange={setErrorMessage}
        />
      </SSectionCustom>
    </SForm>
  );
};

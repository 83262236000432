import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { MyTeamsContext } from "../../lib/contexts";
import {
  doFetch,
  getModalError,
  getModalNotFound,
  getPropArray,
  useSHistory,
} from "../../lib/functions/general_functions";
import { DIDBResource, nameToPath } from "../../lib/definitions/general_definitions";
import { SuperTable } from "../superTable";
import { unallocatedEntitiesColumns } from "../../lib/definitions/table_column_definitions";
import { SView } from "../general/detailView/sView";
import { DIDBError, UnallocatedEntity } from "../../lib/definitions/models";
import { JsonAsTree } from "../general/jsonAsTree";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { Card, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { COLOR_GREEN, COLOR_RED } from "../../lib/definitions/style_definitions";
import * as localForage from "localforage";

export function UnallocatedEntitiesPane() {
  const [selectedAwsEntity, setSelectedAwsEntity] = useState<UnallocatedEntity>();
  const [selectedTykEntity, setSelectedTykEntity] = useState<UnallocatedEntity>();
  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState<DIDBError[]>([]);

  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);

  const { id } = useParams<{ id: string }>();

  const history = useSHistory();
  const isMounted = useRef(true);

  useEffect(() => {
    (async () => setErrors((await localForage.getItem("unallocatedEntitiesErrors")) || []))();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!id || !selectedTeam) {
      setSelectedAwsEntity(undefined);
      setSelectedTykEntity(undefined);
      return;
    }

    const unallocatedEntity = getPropArray(selectedTeam, "unallocatedEntities").find((entity) => entity._id === id);

    if (!unallocatedEntity) {
      getModalNotFound("Unallocated Entity", id, () => history.push(nameToPath["Home"]));
    }

    if (unallocatedEntity?.origin === "AWS") {
      setSelectedAwsEntity(unallocatedEntity);
      setSelectedTykEntity(undefined);
    } else if (unallocatedEntity?.origin === "Tyk") {
      setSelectedAwsEntity(undefined);
      setSelectedTykEntity(unallocatedEntity);
    }
  }, [selectedTeam, id, history]);

  function fetchUnallocatedEntities() {
    setIsFetching(true);
    doFetch(
      "GET",
      `${DIDBResource.UnallocatedEntities}/${selectedTeam?._id}`,
      isMounted,
      (res) => {
        localForage.setItem("unallocatedEntitiesErrors", res.errors);
        refreshMyTeams(nameToPath["HomeUnallocatedEntities"]);
      },
      getModalError("Fetching unallocated entities"),
      () => setIsFetching(false)
    );
  }

  return (
    <SView
      title="Unallocated APIs"
      subTitle={getPropArray(selectedTeam, "unallocatedEntities").length}
      onBack={() => history.push(nameToPath["Home"])}
    >
      <SSectionCustom title="Overview">
        <p>
          This page list all entities registered on external platforms that might be of interest to register in the i2
          portal. To refresh the list of unallocated entities, please click the button below.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Card onClick={fetchUnallocatedEntities} hoverable className="dashboard__grid--service-card">
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <SyncOutlined style={{ fontSize: 72, color: COLOR_GREEN }} spin={isFetching} />
              <Typography.Title level={3} style={{ color: "#000000", marginTop: "8px" }}>
                Refresh
              </Typography.Title>
            </div>
          </Card>
        </div>
        {errors.length > 0 && (
          <div>
            <h2>Errors</h2>
            {errors.length === 0
              ? "No errors"
              : errors.map((error) => (
                  <Card key={Math.random()} style={{ marginBottom: "16px" }}>
                    <div style={{ fontWeight: "bold", color: COLOR_RED }}>{error.code}</div>
                    <div style={{ whiteSpace: "nowrap" }}>{`Message: ${error.message}`}</div>
                  </Card>
                ))}
          </div>
        )}
      </SSectionCustom>
      <SSectionCustom title="AWS API Gateways">
        <p>
          Specify an AWS Role ARN on your applications to enable search-through of unallocated API Gateways. See{" "}
          <a
            href="https://confluence.corp.lego.com/spaces/viewspace.action?key=DI"
            target="_blank"
            rel="noopener noreferrer"
          >
            this
          </a>{" "}
          for a guide to how set up the role correctly.
        </p>
        <SuperTable
          rows={getPropArray(selectedTeam, "unallocatedEntities").filter((entity) => entity.origin === "AWS")}
          columns={unallocatedEntitiesColumns}
          rowKey="_id"
          onRowClick={(row) => history.push(`${nameToPath["HomeUnallocatedEntities"]}/${row._id}`)}
          selectedRow={selectedAwsEntity}
        />
        <JsonAsTree json={selectedAwsEntity?.entity} maxHeight={400} />
      </SSectionCustom>
      <SSectionCustom title="Tyk APIs">
        <p>
          Make sure your team are registered in Tyk. To register, visit your{" "}
          <Link to={nameToPath["Profile"]}>profile page</Link> and click the 'Synchronize' button at your team's panel.
        </p>
        <SuperTable
          rows={getPropArray(selectedTeam, "unallocatedEntities").filter((entity) => entity.origin === "Tyk")}
          columns={unallocatedEntitiesColumns}
          rowKey="_id"
          onRowClick={(row) => history.push(`${nameToPath["HomeUnallocatedEntities"]}/${row._id}`)}
          selectedRow={selectedTykEntity}
        />
        <JsonAsTree json={selectedTykEntity?.entity} maxHeight={400} />
      </SSectionCustom>
    </SView>
  );
}

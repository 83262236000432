import React, { useContext, useEffect } from "react";
import { Result, Button } from "antd";
import { nameToPath } from "../lib/definitions/general_definitions";
import { BreadcrumbContext } from "../lib/contexts";
import { useSHistory } from "../lib/functions/general_functions";

export const NotFoundPage = () => {
  const history = useSHistory();
  const setBreadcrumbs = useContext(BreadcrumbContext);

  useEffect(() => setBreadcrumbs(undefined), [setBreadcrumbs]);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={() => history.push(nameToPath["Home"])} type="primary">
          Back Home
        </Button>
      }
    />
  );
};

export type MultiTreeTab = "Types" | "Example" | "Routings";

export interface ColumnSelection {
  name: string;
  checked: boolean;
  hits: number;
}

export interface RoutingSelection {
  key: string;
  type: string;
}

export type StringFormat =
  | "date-time"
  | "time"
  | "date"
  | "email"
  | "idn-email"
  | "hostname"
  | "idn-hostname"
  | "ipv4"
  | "ipv6"
  | "uri"
  | "uri-reference"
  | "iri"
  | "iri-reference"
  | "uri-template"
  | "json-pointer"
  | "relative-json-pointer";

export type DataType = "object" | "array" | "string" | "number" | "integer" | "boolean";

export interface RuleDefinition {
  enum?: any[];
  pattern?: string;
  boolean?: boolean;
  minimum?: number;
  maximum?: number;
  format?: StringFormat;
  contains?: {
    const?: any;
    enum?: any[];
  };
}

export const IntelligentInfoEbm = {
  application: "",
  event: "",
  fullLoad: false,
  timeStamp: "2020-09-10T13:33:58Z",
  messageId: "687218-3214830912-2149124",
  data: {},
};
export const IntelligentInfoAPIKey = "ZDa0sV8fB119QR82fsCbw2Gx8Q940bfq6UHWW6Ov";

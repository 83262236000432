import React, { useEffect, useState, useRef, useContext, useMemo, Key } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ServiceEvent } from "../../../lib/definitions/models";
import {
  MyTeamsContext,
  EnvironmentContext,
  BreadcrumbContext,
  ProviderContractContext,
  RequesterContractContext,
} from "../../../lib/contexts";
import {
  getPageBreadcrumb,
  getServiceBreadcrumb,
  nameToPath,
  DIDBResource,
} from "../../../lib/definitions/general_definitions";
import {
  parseSchemaTypes,
  generateMask,
  generateRuleSchema,
  getKeysFromMask,
} from "../../../lib/functions/services_functions";
import {
  getProp,
  doFetch,
  getModalError,
  getPropString,
  getNotificationSuccess,
  getModalWarning,
  doMask,
  setProp,
} from "../../../lib/functions/general_functions";
import { CopyButton } from "../copyButton";
import { JsonAsTree } from "../jsonAsTree";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormInput } from "../createForm/sFormInput";
import { MultiTree } from "../../services/multiTree";
import { Divider } from "antd";
import { getMyEmail } from "../../../lib/functions/profile_functions";
import { RuleSchemaEditor } from "../../services/ruleSchemaEditor";
import { EndpointSection } from "./endpointSection";
import { CaretRightFilled } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { Spinner } from "../spinner";

export function CreateEventRequestSubscriber() {
  const [serviceEvent, setServiceEvent] = useState<ServiceEvent>();
  const [isFetching, setIsFetching] = useState(false);
  const [parsedTypes, setParsedTypes] = useState();
  const [parsedExample, setParsedExample] = useState();
  const [maskedExample, setMaskedExample] = useState<any[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [ruleSchema, setRuleSchema] = useState<any>({});

  const [selectedEndpoint, setSelectedEndpoint] = useState("");
  const [iamChecked, setIamChecked] = useState<boolean>(false);
  const [udt, setUdt] = useState(false);
  const [envelope, setEnvelope] = useState(false);

  const { id } = useParams<{ id: string }>();
  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const setBreadcrumbs = useContext(BreadcrumbContext);
  const { refreshProviderContracts } = useContext(ProviderContractContext);
  const { refreshConsumerContracts } = useContext(RequesterContractContext);
  const isMounted = useRef(true);
  const history = useHistory();

  const [form] = useForm();

  useEffect(() => {
    if (serviceEvent) return;
    setIsFetching(true);
    doFetch(
      "GET",
      `${DIDBResource.Events}/${id}`,
      isMounted,
      (res) => setServiceEvent(res.value),
      (errorMessage) => {
        getModalError("Fetching event information")(errorMessage);
        history.goBack();
      },
      () => setIsFetching(false)
    );
  }, [history, id, serviceEvent]);

  useEffect(() => {
    setBreadcrumbs([
      getPageBreadcrumb("Services"),
      getServiceBreadcrumb("event", serviceEvent),
      getPageBreadcrumb("Request", `${nameToPath["Services"]}/event/${id}${nameToPath["Request"]}`),
    ]);

    setParsedTypes(parseSchemaTypes(getProp(serviceEvent, "schema")));
    setParsedExample(getProp(serviceEvent, "example"));
  }, [setBreadcrumbs, serviceEvent, id]);

  const generatedMask = useMemo(() => generateMask(checkedKeys), [checkedKeys]);

  useEffect(() => {
    const maskedExample = generatedMask ? doMask(parsedExample, generatedMask) : {};

    const keys = getKeysFromMask(generatedMask, parsedTypes);

    keys.forEach((k) => {
      if (typeof getProp(parsedExample, k.toString()) === "undefined") {
        setProp(maskedExample, k.toString(), "*Not in example*");
      }
    });

    setMaskedExample(maskedExample);
  }, [checkedKeys, serviceEvent, generatedMask, parsedExample, parsedTypes]);

  useEffect(() => {
    if (serviceEvent) {
      setRuleSchema(generateRuleSchema(serviceEvent?.example, getPropString(serviceEvent, "routingKey")));
    }
  }, [serviceEvent]);

  async function createRequest(values: any) {
    await doFetch(
      "POST",
      `${DIDBResource.EventSubscribers}`,
      isMounted,
      () => {
        getNotificationSuccess("Created", "Request", id);
        history.push(nameToPath["Services"]);
      },
      getModalError("Creating event request"),
      () => {
        refreshConsumerContracts();
        refreshProviderContracts();
      },
      {
        description: values.description,
        consumingAppId: values.applicationId,
        requester_mail: getMyEmail(),
        serviceId: id,
        rule: ruleSchema,
        mask: generatedMask,
        env: selectedEnvironment?._id,
        endpoint: {
          type: values.type,
          destination: values.destination,
          authType: values.authType,
          issuer: values.issuer,
        },
        udt: udt,
        envelope: envelope,
      }
    );
  }

  const applicationOptions = useMemo(
    () =>
      (selectedTeam?.applications || [])
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application: any) => ({ label: application.name, value: application._id })),
    [selectedTeam, selectedEnvironment]
  );

  //TODO: fix bug: cannot create event when createRequest is called from here
  async function getCreateModal() {
    getModalWarning("EBM Access")(
      <div>
        <p>
          Did you update your IAM Policy to give EBM access to your S3 Bucket? EBM will not be able to access the bucket
          without access.
        </p>
        <h4 style={{ margin: 0 }}>Do you wish to proceed?</h4>
      </div>,
      createRequest,
      {
        okButtonProps: { danger: true, type: "primary" },
        okText: "Create Subscription Request",
      }
    );
  }

  const maskedTypeJson = useMemo(
    () => (serviceEvent ? doMask(parseSchemaTypes(serviceEvent.schema), serviceEvent.routingKey) : undefined),
    [serviceEvent]
  );

  return (
    <div>
      {isFetching && <Spinner />}
      {serviceEvent && (
        <SForm
          title="Create Subscription Request"
          onBack={() => history.push(`${nameToPath["Services"]}/event/${id}`)}
          onCreate={iamChecked || selectedEndpoint !== "s3" ? createRequest : getCreateModal}
          form={form}
        >
          <SFormSection title="Request object" custom>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ overflow: "auto", maxHeight: "450px", width: "45%", marginLeft: "16px" }}>
                <MultiTree
                  disableCopy
                  checkedKeys={checkedKeys}
                  setCheckedKeys={setCheckedKeys}
                  jsonTypes={parsedTypes}
                  jsonExample={parsedExample}
                  defaultTab="Types"
                />
              </div>
              <CaretRightFilled style={{ alignSelf: "center", fontSize: "20px" }} />
              <div
                style={{
                  overflow: "auto",
                  maxHeight: "450px",
                  width: "45%",
                  marginRight: "16px",
                  background: "#efefef",
                  padding: "8px 16px 16px 16px",
                }}
              >
                <div
                  style={{
                    height: "44px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                  }}
                >
                  <b style={{ marginRight: "12px" }}>Target object</b>
                  <CopyButton title="Copy request object" content={JSON.stringify(maskedExample, null, 4)} />
                </div>
                {generatedMask === "" ? (
                  "Select keys on the left to start building the request object"
                ) : (
                  <JsonAsTree json={maskedExample} />
                )}
              </div>
            </div>
            <Divider />
            <RuleSchemaEditor
              maskedTypeJson={maskedTypeJson}
              ruleSchema={serviceEvent.schema}
              setRuleSchema={setRuleSchema}
            />
          </SFormSection>
          <SFormSection title="Details">
            <SFormSelect label="Application" field="applicationId" options={applicationOptions} />
            <SFormInput label="Justification" field="description" textarea optional />
          </SFormSection>
          <EndpointSection
            selectedEndpoint={selectedEndpoint}
            setSelectedEndpoint={setSelectedEndpoint}
            iamChecked={iamChecked}
            setIamChecked={setIamChecked}
            udt={udt}
            setUdt={setUdt}
            envelope={envelope}
            setEnvelope={setEnvelope}
          />
        </SForm>
      )}
    </div>
  );
}

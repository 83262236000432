import { Modal, notification } from "antd";
import { ModalFuncProps } from "antd/lib/modal";
import React, { useContext } from "react";
import { authProvider } from "../authProvider";
import { ServiceAPI, TeamExpanded } from "../definitions/models";
import { DocumentType } from "../definitions/general_definitions";
import mask from "json-mask";
import { FeedbackModalContent } from "../../components/general/feedbackModalContent";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { EditModeContext } from "../contexts";

export async function doFetchPromise(
  method: "GET" | "POST" | "PUT" | "DELETE" | { method: "GET"; env: string },
  url: string,
  body?: any,
  extraHeaders?: object,
  noErrorStringFormatting?: boolean
): Promise<any | undefined> {
  const httpMethod = typeof method === "object" ? "GET" : method;
  const queryParams = typeof method === "object" ? `?env=${method.env}` : "";
  const response = await fetch(url + queryParams, {
    headers: await getHeaders(extraHeaders),
    method: httpMethod,
    body: body && (method === "PUT" || method === "POST") ? JSON.stringify(body) : undefined, // needed
  });

  if (!response.ok) {
    let errorMessage = "";
    try {
      const responseAsJson = await response.json();
      errorMessage = noErrorStringFormatting ? responseAsJson : formatDidbError(responseAsJson);
    } catch (error) {
      errorMessage = response.status + (response.statusText ? ": " + response.statusText : "");
    }
    throw new Error(errorMessage);
  }

  try {
    return await response.json();
  } catch {
    return response.status + " " + response.statusText;
  }
}

export async function doFetch(
  method: "GET" | "POST" | "PUT" | "DELETE" | { method: "GET"; env: string },
  url: string,
  isMounted: React.MutableRefObject<boolean>,
  onOK: (json: any) => void,
  onNotOK: (json: any) => void,
  finallyCallback?: () => void,
  body?: any,
  extraHeaders?: object,
  noErrorStringFormatting?: boolean
) {
  const httpMethod = typeof method === "object" ? "GET" : method;
  const queryParams = typeof method === "object" ? `?env=${method.env}` : "";
  try {
    const response = await fetch(url + queryParams, {
      headers: await getHeaders(extraHeaders),
      method: httpMethod,
      body: body ? JSON.stringify(body) : undefined, // the undefined part needed,
    });
    if (response.ok) {
      if (!isMounted.current) return;
      try {
        onOK(await response.json());
      } catch {
        onOK(`${response.status} ${response.statusText}`);
      }
    } else {
      try {
        if (!isMounted.current) return;
        const responseAsJson = await response.json();
        onNotOK(noErrorStringFormatting ? responseAsJson : formatDidbError(responseAsJson));
      } catch (error) {
        onNotOK(response.statusText);
      }
    }
  } catch (error) {
    if (!isMounted.current) return;
    console.log(error);
    onNotOK("An error occured");
  } finally {
    if (isMounted.current && finallyCallback) {
      finallyCallback();
    }
  }
}

export async function getHeaders(extraHeaders?: any) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  if (process.env.REACT_APP_CYPRESS === "true") {
    if (process.env.REACT_APP_TEST_API_KEY) headers.append("x-api-key", process.env.REACT_APP_TEST_API_KEY);
  } else {
    headers.append("Authorization", (await authProvider.getAccessToken()).accessToken);
  }

  if (extraHeaders) {
    Object.entries(extraHeaders).forEach(([key, val]) => headers.append(key, val + ""));
  }

  return headers;
}

export function objectReducer(obj: any, action: { field?: string; value?: any; init?: any }): any {
  if (typeof action.init === "object" || (typeof action.init === "undefined" && !action.field && !action.value)) {
    return action.init;
  }

  const copy = JSON.parse(JSON.stringify(obj));
  if (typeof action.field !== "string") {
    throw Error("objectReducer error: 'field' must have type string");
  }
  if (!("value" in action)) {
    throw Error("objectReducer error: 'value' key must be present in action object when 'init' is not used");
  }
  setProp(copy, action.field, action.value);
  return copy;
}

export function setProp(obj: any, path: string, value: any, dontOverwrite?: boolean) {
  if (!obj || path.length === 0) {
    return;
  }
  const keyPath = path.split(".");
  let lastKeyIndex = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    let key = keyPath[i];
    if (!(key in obj) || !obj[key]) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  if (dontOverwrite && obj[keyPath[lastKeyIndex]]) {
    return;
  }
  obj[keyPath[lastKeyIndex]] = value;
}

export function getProp(object: any, property: string) {
  if (!object || !property) {
    return undefined;
  }

  const properties = property.split(".");
  let currentObject = object;
  let index = 0;
  let currentProperty = properties[index];

  while (currentObject.hasOwnProperty(currentProperty)) {
    if (index === properties.length - 1) {
      return currentObject[currentProperty];
    } else {
      currentObject = currentObject[currentProperty];
      if (currentObject === undefined) {
        return undefined;
      }
      index++;
      currentProperty = properties[index];
    }
    if (!currentObject) {
      return undefined;
    }
  }

  return undefined;
}

export function getPropString(object: any, property: string): string {
  const value = getProp(object, property);
  if (typeof value === "undefined" || value === null) {
    return "";
  }
  if (typeof value === "object") {
    return JSON.stringify(value);
  }
  if (value && value.toString) {
    return value.toString();
  }
  return value + "";
}

export function getPropArray(object: any, property: string): any[] {
  const value = getProp(object, property);
  return Array.isArray(value) ? value : [];
}

export function getPropNumber(object: any, property: string): number {
  const value = getProp(object, property);

  if (/^-[1-9][0-9]*$|^[0-9]+$/.test(value)) {
    return Number.parseInt(value);
  }
  return -1;
}

export function forEachKey(obj: any, callback: (key: string, value: any) => void) {
  for (var prop in obj) {
    callback(prop, obj[prop]);
    if (typeof obj[prop] === "object") {
      // dive deeper in
      forEachKey(obj[prop], callback);
    } else if (Array.isArray(obj[prop])) {
      obj[prop].forEach((elem: any) => forEachKey(elem, callback));
    }
  }
}

export function getModalInfo(
  title: string,
  infoContent: any,
  onOk?: (close: () => void) => void,
  extraProps?: ModalFuncProps
) {
  Modal.info({
    title: title,
    content: <div style={{ marginLeft: "-24px" }}> {infoContent}</div>,
    centered: true,
    onOk: onOk,
    ...extraProps,
  });
}

export function getModalError(title: string) {
  return (errorContent: any, onOk?: (close: () => void) => void, extraProps?: ModalFuncProps) => {
    Modal.error({
      title: title,
      content: <div style={{ marginLeft: "-24px" }}> {errorContent}</div>,
      centered: true,
      onOk: onOk,
      ...extraProps,
    });
  };
}

export function getModalWarning(title: string) {
  return (warningContent: any, onOk?: (close: () => void) => void, extraProps?: ModalFuncProps) => {
    Modal.warning({
      title: title,
      content: <div style={{ marginLeft: "-24px" }}> {warningContent}</div>,
      centered: true,
      onOk: onOk,
      okCancel: !!onOk,
      ...extraProps,
    });
  };
}

export function getModalFeedback(title: string) {
  return (feedbackContent: (close: () => void) => any, extraProps?: ModalFuncProps) => {
    const feedbackModal = Modal.info({
      title: title,
      centered: true,
      icon: <InfoCircleOutlined />,
      ...extraProps,
      className: "general__feedback-modal",
    });

    feedbackModal.update({ content: feedbackContent(feedbackModal.destroy) });
  };
}

export function filterArraysInObj(obj: any, filter: (item: any) => boolean) {
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      obj = obj.map((item) => filterArraysInObj(item, filter));
      return obj.filter(filter);
    }

    for (const prop in obj) {
      obj[prop] = filterArraysInObj(obj[prop], filter);
    }
    return obj;
  }

  return obj;
}

export function doMask(obj: any, maskAsString: string) {
  const maskedObj = mask(obj, maskAsString);
  return filterArraysInObj(maskedObj, (item) => JSON.stringify(item) !== "{}");
}

export function getModalNotFound(type: DocumentType, id: string, onOK: () => void) {
  return getModalError(`${type} not found`)(`Unable to retrieve information on document with ID '${id}'`, (close) => {
    close();
    onOK();
  });
}

export function getNotificationSuccess(
  action:
    | "Created"
    | "Updated"
    | "Deleted"
    | "Deployed"
    | "Copied"
    | "Sent"
    | "Uploaded"
    | "Denied"
    | "Approved"
    | "Reassigned",
  type: DocumentType,
  name?: string | undefined
) {
  getNotificationSuccessCustom(
    `${action} ${type === "API" || type === "DNS" ? type : type.toLowerCase()}`,
    `Successfully ${action.toLowerCase()} ${type === "API" || type === "DNS" ? type : type.toLowerCase()} ${
      name ? "'" + name + "'" : ""
    }`
  );
}

export function getNotificationSuccessCustom(title: string, description: string) {
  notification["success"]({
    message: title,
    description: description,
    duration: 2.5,
    style: { marginTop: "30px" },
  });
}

export function getNotificationFeedbackRating(
  action: "application" | "service" | "event" | "API" | "DNS" | "namespace"
) {
  if (process.env.CREATE_REACT_APP === "true") return;
  notification["info"]({
    key: "feedback",
    message: `Help i2 improve by giving your feedback!`,
    description: `Click here to give your feedback`,
    duration: 2,
    onClick: () => {
      getModalFeedback(`How was the experience creating your ${action}?`)((close) => (
        <FeedbackModalContent feedbackType="rating" action={action} onClose={close} />
      ));
      notification.close("feedback");
    },
    style: { marginTop: "30px", cursor: "pointer" },
  });
}

export function getNotificationError(message: string, description: string) {
  notification["error"]({
    message: message,
    description: description,
    duration: null,
    style: { marginTop: "30px" },
  });
}

export function getNotificationWarning(message: string, description: string) {
  notification["warning"]({
    message: message,
    description: description,
    style: { marginTop: "30px" },
  });
}

export function isChromeBrowser() {
  return navigator.userAgent.indexOf("Chrome") !== -1;
}

export function removeDuplicatesOnKey(elements: any[], key: string) {
  const setOfValues = new Set(elements.map((elem) => getPropString(elem, key)));
  const uniqueArray: any[] = [];
  setOfValues.forEach((val) => {
    const elem = elements.find((elem) => getPropString(elem, key) === val);
    if (elem) {
      uniqueArray.push(elem);
    }
  });
  return uniqueArray;
}

export function formatDidbError(json: any) {
  return `${getPropString(json, "message")}. Request ID: ${getPropString(json, "requestId")}`;
}

export function uppercaseFirstLetter(string: string) {
  if (typeof string !== "string") {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function normalizeDocumentType(type: DocumentType) {
  return type === "API" || type === "DNS" ? type : type.toLowerCase();
}

export function isOnProductionUrl() {
  return window.location.host === "d27dvxxcayukjt.cloudfront.net" || window.location.host === "i2.legogroup.io";
}

export function generateCheckedKeys(checkedKeys: string[], tokens: any, scope: string) {
  if (!tokens) {
    return [];
  }

  let treeData: string[] = checkedKeys;
  const keys = Object.keys(tokens);
  for (const key of keys) {
    if (tokens[key].type === "object") {
      treeData.push(scope + key);
    } else {
      treeData = generateCheckedKeys(treeData, tokens[key].properties, scope + key + ".");
    }
  }
  return treeData;
}

export function isNumberOrBlank(v: string) {
  return /^(|0|[1-9][0-9]*)$/.test(v) || /^[+-]?([0-9]*[.])?[0-9]+$/.test(v);
}

export function pageWasRefreshed(): boolean {
  const data = localStorage.getItem("refresh_detection");

  if (!data) {
    return false;
  }

  try {
    const dataParsed = JSON.parse(data);

    return Date.now() - dataParsed.timestamp < 5000 && window.location.pathname === dataParsed.page;
  } catch {
    return false;
  }
}

export function getImgUrl(requesterMail: string): any {
  authProvider.getAccessToken().then((res: any) => {
    fetch(`https://graph.microsoft.com/v1.0/users/${requesterMail}/photo/$value`, {
      headers: {
        Authorization: "" + res.accessToken,
      },
      method: "GET",
    })
      .then((res) => res.blob())
      .then((img) => {
        return URL.createObjectURL(img);
      });
  });
}

export function useQuery() {
  const query = new URLSearchParams(useLocation().search);

  function getNewQuery(param: string, value: string) {
    const params: string[] = [];

    let contains = false;

    query.forEach((val, key) => {
      if (key === param) {
        contains = true;
        params.push(`${param}=${value}`);
      } else {
        params.push(`${key}=${val}`);
      }
    });

    if (!contains) {
      params.push(`${param}=${value}`);
    }

    return params.join("&");
  }

  return { query: query, getNewQuery: getNewQuery };
}

export function useSHistory() {
  const history = useHistory();
  const { inEditMode, setInEditMode } = useContext(EditModeContext);

  const title = "Abandon changes?";
  const content = "You have unsaved changes. Do you wish to proceed?";

  function push(pathname: any, search?: string, skipWarning?: boolean) {
    if (!skipWarning && inEditMode) {
      Modal.warning({
        title: title,
        content: content,
        centered: true,
        okText: "Leave",
        okType: "danger",
        cancelText: "Stay",
        onOk: () => {
          setInEditMode(false);
          history.push(pathname, search);
        },
        okCancel: true,
      });
    } else {
      history.push(pathname, search);
    }
  }

  function goBack() {
    if (inEditMode) {
      Modal.warning({
        title: title,
        content: content,
        centered: true,
        okText: "Leave",
        okType: "danger",
        cancelText: "Stay",
        onOk: () => {
          setInEditMode(false);
          history.goBack();
        },
        okCancel: true,
      });
    } else {
      history.goBack();
    }
  }

  return { location: history.location, action: history.action, replace: history.replace, push: push, goBack: goBack };
}

export function getApisFromTeam(team: TeamExpanded | undefined, envId: string) {
  if (!team || !envId) {
    return [];
  }

  const apis: ServiceAPI[] = [];
  team.applications.forEach((app) =>
    apis.push(...(app.services.filter((service) => service.serviceType === "API") as ServiceAPI[]))
  );

  return apis.filter((api) => api.env === envId).sort((a, b) => a.name.localeCompare(b.name));
}

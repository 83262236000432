import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

interface SpinnerProps {
  margin?: number;
}

export const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: props.margin ? `${props.margin}px` : 0,
        marginBottom: props.margin ? `${props.margin}px` : 0,
      }}
    >
      <LoadingOutlined style={{ fontSize: 30, color: "#1890ff" }} spin />
    </div>
  );
};

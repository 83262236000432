import React, { useState, useEffect, useRef, useContext } from "react";
import { Tree, Input } from "antd";
import { Key } from "antd/lib/table/interface";
import { getTeamEventsTreeData } from "../../lib/functions/home_functions";
import { useLocation } from "react-router-dom";
import { EnvironmentContext, MyTeamsContext } from "../../lib/contexts";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { COLOR_BLUE_ICON } from "../../lib/definitions/style_definitions";
import { SView } from "../general/detailView/sView";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { useSHistory } from "../../lib/functions/general_functions";

interface EventTreeProps {
  onClick?(node: any): void;
}

export const EventsPane: React.FC<EventTreeProps> = (props) => {
  const [tree, setTree] = useState<any[]>([]);
  const [iconTree, setIconTree] = useState<any[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedKey, setSelectedKey] = useState("");

  const { selectedTeam } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);

  const { pathname } = useLocation();

  const canExpand = useRef(true);
  const history = useSHistory();

  useEffect(() => {
    if (!selectedEnvironment) {
      return;
    }
    const { roots, expKeys } = getTeamEventsTreeData(selectedTeam, searchInput, selectedEnvironment._id);
    setTree(roots);
    setExpandedKeys(expKeys);
  }, [selectedTeam, searchInput, selectedEnvironment]);

  useEffect(() => {
    let treeCopy = tree;
    treeCopy.forEach((parent: any) => {
      parent.children.forEach((child: any) => {
        if (child.type === "applications") {
          child.children.forEach((subChild: any) => {
            subChild.icon = getMatchingIcon(subChild.type);
          });
        } else {
          child.icon = getMatchingIcon(child.type);
        }
      });

      setIconTree(tree);
    });
  }, [tree]);

  function getMatchingIcon(type: string) {
    switch (type) {
      case "events":
        return <VerticalAlignTopOutlined style={{ color: COLOR_BLUE_ICON }} />;
    }
  }

  useEffect(() => setSelectedKey(pathname.replace("/home/", "").replace("/", ".")), [pathname]);

  function handleExpand(keys: Key[]) {
    if (canExpand.current) {
      setExpandedKeys(keys);
      canExpand.current = false;
      setTimeout(() => (canExpand.current = true), 600);
    }
  }

  return (
    <SView title="Events" onBack={() => history.push(nameToPath["Home"])}>
      <Input
        placeholder="Search..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setSearchInput("");
          }
        }}
        style={{ marginBottom: "8px" }}
      />

      <Tree
        autoExpandParent={!!searchInput}
        selectedKeys={[selectedKey]}
        treeData={iconTree}
        onExpand={handleExpand}
        expandedKeys={expandedKeys}
        onClick={(_e, node) => {
          if (props.onClick) {
            props.onClick(node);
          }
        }}
        showIcon
        className="general__json-as-tree"
        style={{
          overflowX: "auto",
        }}
      />
    </SView>
  );
};

import React, { useEffect } from "react";
import { ColumnSelection } from "../../lib/definitions/services_definitions";
import { SelectionList } from "./selectionList";

interface SearchColumnProps {
  searchedServices: any[];
  serviceTypeSelections: ColumnSelection[];
  applicationsSelections: ColumnSelection[];
  teamsSelections: ColumnSelection[];
  setServiceTypeSelections: React.Dispatch<React.SetStateAction<ColumnSelection[]>>;
  setApplicationsSelections: React.Dispatch<React.SetStateAction<ColumnSelection[]>>;
  setTeamsSelections: React.Dispatch<React.SetStateAction<ColumnSelection[]>>;
}

export const FilterColumn: React.FC<SearchColumnProps> = (props) => {
  const { setServiceTypeSelections, setApplicationsSelections, setTeamsSelections } = props;

  // update hits
  useEffect(() => {
    const typeMap = new Map();
    const applicationMap = new Map();
    const teamMap = new Map();

    props.searchedServices.forEach((s) => {
      if (s.serviceType) {
        typeMap.set(s.serviceType, (typeMap.get(s.serviceType) || 0) + 1);
      }
      if (s.applicationName) {
        applicationMap.set(s.applicationName, (applicationMap.get(s.applicationName) || 0) + 1);
      }
      if (s.teamName) {
        teamMap.set(s.teamName, (teamMap.get(s.teamName) || 0) + 1);
      }
    });

    const nameSorter = (a: any, b: any) => a.name.localeCompare(b.name);

    const typeMapSelections = Array.from(typeMap.entries())
      .map((v) => ({
        name: v[0],
        checked: false,
        hits: v[1],
      }))
      .sort(nameSorter);
    const applicationMapSelections = Array.from(applicationMap.entries())
      .map((v) => ({
        name: v[0],
        checked: false,
        hits: v[1],
      }))
      .sort(nameSorter);
    const teamMapSelections = Array.from(teamMap.entries())
      .map((v) => ({
        name: v[0],
        checked: false,
        hits: v[1],
      }))
      .sort(nameSorter);

    setServiceTypeSelections(typeMapSelections);
    setApplicationsSelections(applicationMapSelections);
    setTeamsSelections(teamMapSelections);
  }, [props.searchedServices, setServiceTypeSelections, setApplicationsSelections, setTeamsSelections]);

  function onCheck(type: "serviceType" | "applications" | "teams", name: string, checked: boolean) {
    const setSelections =
      type === "serviceType"
        ? props.setServiceTypeSelections
        : type === "applications"
        ? props.setApplicationsSelections
        : props.setTeamsSelections;

    setSelections((selections) =>
      selections.map((sel) => {
        if (sel.name === name) {
          return { ...sel, checked: checked };
        }
        return sel;
      })
    );
  }

  return (
    <div>
      <SelectionList
        header="Service type"
        selections={props.serviceTypeSelections}
        onCheck={(name, checked) => onCheck("serviceType", name, checked)}
      />
      <SelectionList
        header="Teams"
        selections={props.teamsSelections}
        onCheck={(name, checked) => onCheck("teams", name, checked)}
      />
      <SelectionList
        header="Applications"
        selections={props.applicationsSelections}
        onCheck={(name, checked) => onCheck("applications", name, checked)}
      />
    </div>
  );
};

import React, { useEffect, useContext, useRef, useCallback, useState } from "react";
import { MyTeamsContext, EnvironmentContext } from "../../../lib/contexts";
import {
  doFetch,
  doFetchPromise,
  getModalError,
  getNotificationFeedbackRating,
  getNotificationSuccess,
  getPropArray,
  useQuery,
  useSHistory,
} from "../../../lib/functions/general_functions";
import {
  DIDBResource,
  nameLengthRange,
  nameToPath,
  lowerCaseRegex,
} from "../../../lib/definitions/general_definitions";
import { SForm } from "../createForm/sForm";
import { SFormSection } from "../createForm/sFormSection";
import { SFormInput } from "../createForm/sFormInput";
import { SFormSelect } from "../createForm/sFormSelect";
import { SFormTags } from "../createForm/sFormTags";
import { CreateDNS } from "./createDNS";
import { useForm } from "antd/lib/form/Form";

interface CreateNamespaceProps {
  extraHook?(namespaceId: string): void;
}

export const CreateNamespace = (props: CreateNamespaceProps) => {
  const [dnsId, setDnsId] = useState("");
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);

  const { query, getNewQuery } = useQuery();
  const history = useSHistory();

  const [form] = useForm();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const dnsId = query.get("dnsid");
    if (dnsId) {
      form.setFieldsValue({ dnsId });
      setDnsId(dnsId);
    }
  }, [form, query]);

  async function createNamespace(values: any) {
    await doFetch(
      "POST",
      DIDBResource.Namespaces,
      isMounted,
      (response) => {
        refreshMyTeams(props.extraHook ? undefined : `${nameToPath["HomeNamespaces"]}/${response.value.id}`);
        getNotificationFeedbackRating("namespace");
        getNotificationSuccess("Created", "Namespace", values.name);
        if (props.extraHook) {
          props.extraHook(response.value.id);
        }
      },
      getModalError("Creating namespace"),
      undefined,
      {
        ...values,
        dnsId: dnsId,
        teamId: selectedTeam?._id,
        env: selectedEnvironment?._id,
      }
    );
  }

  const getNamespaceNames = useCallback(async () => {
    try {
      const response = await doFetchPromise("GET", `${DIDBResource.Namespaces}?dnsId=${dnsId}`);
      return response.value.map((namespace: any) => namespace.name);
    } catch {
      return [];
    }
  }, [dnsId]);

  const getDnsOptions = useCallback(async () => {
    try {
      const response = await doFetchPromise("GET", `${DIDBResource.DNS}?env=${selectedEnvironment!._id}`);

      const dnssFiltered = response.value
        .filter((dns: any) => dns.visible && dns.teamId !== selectedTeam!._id)
        .map((dns: any) => ({ label: dns.name, value: dns._id }));

      const teamDns = getPropArray(selectedTeam, "dns").map((dns) => ({ label: dns.name, value: dns._id }));

      return dnssFiltered.concat(...teamDns);
    } catch {
      return [];
    }
  }, [selectedTeam, selectedEnvironment]);

  return (
    <SForm
      title="Create Namespace"
      onBack={() => history.push(nameToPath["HomeNamespaces"])}
      onCreate={createNamespace}
      form={form}
    >
      <SFormSection>
        <SFormSelect
          label="DNS"
          field="dnsId"
          onChange={setDnsId}
          getOptions={getDnsOptions}
          onNewForm={(close) => (
            <CreateDNS
              extraHook={(dnsId: string) => {
                close();
                history.push({ pathname: history.location.pathname, search: getNewQuery("dnsid", dnsId) });
              }}
            />
          )}
        />
        <SFormInput
          label="Name"
          field="name"
          getReservedValues={getNamespaceNames}
          reservedValuesCaseInsensitive
          disabled={!dnsId}
          regex={lowerCaseRegex}
          lengthRange={nameLengthRange}
        />
        <SFormTags label="Tags" field="tags" optional />
        <SFormInput label="Description" field="description" optional textarea />
      </SFormSection>
    </SForm>
  );
};

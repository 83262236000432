import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { useContext, useMemo, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { LogsContext, MyTeamsContext } from "../../lib/contexts";
import { logsColumns } from "../../lib/definitions/table_column_definitions";
import { getLastSevenDaysDates } from "../../lib/functions/logs_functions";
import { SuperTable } from "../superTable";

interface LogsChartProps {
  seeAllLogs: boolean;
}

export function LogsChart(props: LogsChartProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const { mappedLogs } = useContext(LogsContext);
  const { selectedTeam } = useContext(MyTeamsContext);

  const lastWeeksLogs = useMemo(
    () =>
      mappedLogs
        .filter((l) => props.seeAllLogs || l.teamId === selectedTeam?._id)
        .filter((l) => new Date(l.timestamp).getTime() > Date.now() - 604_800_000),
    [props.seeAllLogs, mappedLogs, selectedTeam?._id]
  );

  const filteredLogs = useMemo(
    () =>
      getLastSevenDaysDates().map((dataPoint) => {
        const publishedLogs: any[] = [];
        const dequeuedLogs: any[] = [];

        lastWeeksLogs
          .filter((l) => !searchValue || JSON.stringify(l).toLowerCase().includes(searchValue.toLowerCase()))
          .forEach((l) => {
            const ts = new Date(l.timestamp).getTime();
            if (dataPoint.fromTimestamp <= ts && ts < dataPoint.toTimestamp) {
              if (l.source === "EBM-Publisher") {
                publishedLogs.push(l);
              } else if (l.source === "EBM-Dequeue") {
                dequeuedLogs.push(l);
              }
            }
          });

        return {
          ...dataPoint,
          Published: publishedLogs.length,
          Dequeued: dequeuedLogs.length,
          publishedLogs: publishedLogs,
          dequeuedLogs: dequeuedLogs,
        };
      }),
    [searchValue, lastWeeksLogs]
  );

  const selectedLogs = useMemo(() => {
    const logs = filteredLogs.find((d) => d.dateAsString === selectedDate);
    return (logs?.dequeuedLogs || []).concat(...(logs?.publishedLogs || []));
  }, [selectedDate, filteredLogs]);

  return (
    <div>
      <h1>Weekly overview</h1>
      <div style={{ width: "256px", margin: "12px 0" }}>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search last weeks logs..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          width={300}
        />
      </div>
      <div style={{ height: "400px" }}>
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            data={filteredLogs}
            width={300}
            onClick={(e: any) => {
              if (e?.activeLabel) {
                setSelectedDate(e.activeLabel);
              }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dateAsString" style={{ userSelect: "none" }} />
            <YAxis style={{ userSelect: "none" }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Published" fill="#ffb55f" />
            <Bar dataKey="Dequeued" fill="#b400ec" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      {selectedDate && (
        <div style={{ marginTop: "48px" }}>
          <h1 style={{ alignItems: "center", display: "flex" }}>
            <Button icon={<CloseOutlined />} style={{ marginRight: "16px" }} onClick={() => setSelectedDate("")} />
            Logs on {selectedDate}
            {searchValue ? `, with '${searchValue}'` : ""}
          </h1>
          <SuperTable rowKey="_id" rows={selectedLogs} columns={logsColumns} />
        </div>
      )}
    </div>
  );
}

import React from "react";

interface SFormCustomProps {
  first?: React.ReactNode;
  second?: React.ReactNode;
  third?: React.ReactNode;
}

export function SFormCustom(props: SFormCustomProps) {
  return (
    <>
      {<div style={{ display: "flex", justifyContent: "flex-end" }}>{props.first}</div> || <div />}
      {props.second || <div />}
      {props.third || <div />}
    </>
  );
}

import React, { useState, useRef, useEffect, useContext } from "react";
import { PageHeader, Button, Form, Tooltip } from "antd";
import { customValidateMessages } from "../../../lib/definitions/sform_definitions";
import { FormInstance } from "antd/lib/form/Form";
import { EditModeContext } from "../../../lib/contexts";

interface SFormProps {
  children: React.ReactNode;
  title: string;
  form: FormInstance<any>;
  errorMessage?: string;
  onBack?(): void;
  onCreate(values: object): Promise<void>;
}

export const SForm = (props: SFormProps) => {
  const [isCreating, setIsCreating] = useState(false);
  const setInEditMode = useContext(EditModeContext).setInEditMode;

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      setInEditMode(false);
    };
  }, [setInEditMode]);

  return (
    <Form
      onFinish={async (values) => {
        setIsCreating(true);
        await props.onCreate(values);
        if (isMounted.current) {
          setIsCreating(false);
        }
      }}
      scrollToFirstError
      validateMessages={customValidateMessages}
      form={props.form}
      onFieldsChange={() => setInEditMode(true)}
    >
      <PageHeader title={props.title} onBack={props.onBack} className="sdetail-view__page-content">
        {props.children}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title={props.errorMessage}>
            <Button
              type="primary"
              loading={isCreating}
              onClick={props.form?.submit}
              data-cy="submit"
              disabled={!!props.errorMessage}
            >
              {props.title}
            </Button>
          </Tooltip>
        </div>
      </PageHeader>
    </Form>
  );
};

import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import {
  MyTeamsContext,
  EnvironmentContext,
  ProviderContractContext,
  RequesterContractContext,
} from "../../lib/contexts";
import { useHistory, useParams } from "react-router-dom";
import {
  getModalNotFound,
  doFetch,
  getModalError,
  objectReducer,
  getNotificationSuccess,
  useSHistory,
} from "../../lib/functions/general_functions";
import { nameToPath, DIDBResource } from "../../lib/definitions/general_definitions";
import { SView } from "../general/detailView/sView";
import { SSection } from "../general/detailView/sSection";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { SElementTags } from "../general/detailView/Elements/sElementTags";
import { SElementSelect } from "../general/detailView/Elements/sElementSelect";
import { DeployButton } from "./deployButton";
import { serviceVisibilityOptions } from "../../lib/definitions/home_definitions";
import { getKeysFromMask, parseSchemaTypes } from "../../lib/functions/services_functions";
import { ServiceType } from "../../lib/definitions/models";
import { contractColumns } from "../../lib/definitions/table_column_definitions";
import { SFiles } from "../general/detailView/sFiles";
import { SuperTable } from "../superTable";
import { ReassignButton } from "./reassignButton";

import { Tabs } from "antd";
import { DataObjectEditor } from "../services/dataObjectEditor";
import TextArea from "antd/lib/input/TextArea";
import { JsonAsTree } from "../general/jsonAsTree";

export const EventPane = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [serviceEvent, updateServiceEvent] = useReducer(objectReducer, {});
  const [applicationOptions, setApplicationOptions] = useState<any[]>([]);
  const [providerContractsForEvent, setProviderContractsForEvent] = useState<any>([]);

  const [example, setExample] = useState<object>();
  const [routingMask, setRoutingMask] = useState<string>("");
  const [schema, setSchema] = useState<object>();
  const [errorMessage, setErrorMessage] = useState("");

  const { selectedTeam, refreshMyTeams } = useContext(MyTeamsContext);
  const { selectedEnvironment } = useContext(EnvironmentContext);
  const { providerContracts } = useContext(ProviderContractContext);
  const { requesterContracts } = useContext(RequesterContractContext);

  const history = useSHistory();
  const reactHistory = useHistory();
  const { id } = useParams<{ id: string }>();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setApplicationOptions(
      selectedTeam?.applications
        .filter((application) => application.env === selectedEnvironment?._id)
        .map((application) => ({ label: application.name, value: application._id })) || []
    );
  }, [selectedTeam, selectedEnvironment, id]);

  /*useEffect(() => {
    if (selectedEnvironment) {
      selectedEnvironment?._id === "prod" ? setConnectEnv("") : setConnectEnv(selectedEnvironment?._id + ".");
    }
  }, [selectedEnvironment]);*/

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }
    let ct: any[] = [];
    providerContracts.forEach((element) => {
      if (element.serviceId === id) {
        ct.push(element);
      }
    });
    setProviderContractsForEvent(ct);

    const eventInner = selectedTeam?.applications
      .reduce((prev: any[], app: any) => prev.concat(...app.services), [])
      .find((service) => service.serviceType === ServiceType.Event && service._id === id);

    if (eventInner) {
      updateServiceEvent({ init: eventInner });
      setExample(eventInner.example);
      setSchema(eventInner.schema);
      setRoutingMask(eventInner.routingKey);
    } else {
      getModalNotFound("API", id, () => reactHistory.push(nameToPath["HomeServices"]));
    }
  }, [selectedTeam, reactHistory, id, providerContracts, requesterContracts]);
  async function putEvent(eventValues: any): Promise<boolean> {
    delete eventValues?.type;
    delete eventValues?.destination;
    delete eventValues?.authType;
    delete eventValues?.issuer;

    let success = true;
    await doFetch(
      "PUT",
      `${DIDBResource.Events}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        getNotificationSuccess("Updated", "Event", eventValues.name);
        history.push(`${nameToPath["HomeEvents"]}/${id}`, undefined, true);
        success = true;
      },
      (errorContent: any) => {
        getModalError("Updating event")(errorContent);
        success = false;
      },
      undefined,
      { ...eventValues, routingKey: routingMask, example: example, schema: schema }
    );
    return success;
  }

  async function deleteEvent() {
    await doFetch(
      "DELETE",
      `${DIDBResource.Events}/${id}`,
      isMounted,
      () => {
        refreshMyTeams();
        history.push(nameToPath["HomeServices"]);
        getNotificationSuccess("Deleted", "Event", serviceEvent.name);
      },
      getModalError("Deleting Event")
    );
  }

  return (
    <SView
      title="Event"
      subTitle={serviceEvent?.name}
      object={serviceEvent}
      onBack={() => history.push(nameToPath["HomeServices"])}
      onSave={putEvent}
      onEditMode={setIsEditing}
      onDelete={deleteEvent}
      errorMessage={errorMessage}
      key={id}
      extra={[
        <ReassignButton key="2" type="Event" entityId={id} parentId={serviceEvent.applicationId} />,
        <DeployButton key="3" type="Event" entityId={id} />,
      ]}
    >
      <SSection title="Details">
        <SElementInput label="Name" field="name" />
        <SElementSelect label="Application" field="applicationId" options={applicationOptions} />
        <SElementSelect label="Visibility" field="visibility" options={serviceVisibilityOptions} />
        <SElementTags label="Tags" field="tags" />
        <div />
        <SElementInput label="Description" field="description" fieldType="textarea" lineWidth="double" />
      </SSection>
      <SFiles key={id} title="Attachments" teamId={selectedTeam?._id} entityType="Event" entityId={serviceEvent?._id} />
      <SSectionCustom title="Data object">
        {isEditing ? (
          <DataObjectEditor
            example={example}
            routingMask={routingMask}
            schema={schema}
            onSchemaChange={setSchema}
            onExampleChange={setExample}
            onRoutingMaskChange={setRoutingMask}
            onErrorChange={setErrorMessage}
          />
        ) : (
          <div style={{ maxHeight: "450px" }}>
            <Tabs>
              <Tabs.TabPane tab="Example" key="1">
                <div style={{ height: "350px" }}>
                  {example && (
                    <TextArea value={JSON.stringify(example, null, 4)} readOnly className="general__code-text-area" />
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Schema" key="2">
                <div style={{ height: "350px" }}>
                  {schema && (
                    <TextArea value={JSON.stringify(schema, null, 4)} readOnly className="general__code-text-area" />
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="Routing Keys"
                key="3"
                style={{
                  height: "350px",
                  overflow: "auto",
                }}
              >
                <JsonAsTree
                  json={parseSchemaTypes(serviceEvent.schema)}
                  checkedKeys={getKeysFromMask(serviceEvent.routingKey, parseSchemaTypes(serviceEvent.schema))}
                  isTypes
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        )}
      </SSectionCustom>

      <SSectionCustom title="Contracts">
        <SuperTable
          rows={providerContractsForEvent}
          rowKey="_id"
          columns={contractColumns}
          onRowClick={(contract) => {
            history.push(`${nameToPath["providerContract"]}/${contract._id}`);
          }}
          onCreate={
            serviceEvent.visibility === "team"
              ? undefined
              : () => {
                  history.push(`${nameToPath["Services"]}/event/${serviceEvent._id}`);
                }
          }
        />
      </SSectionCustom>
    </SView>
  );
};

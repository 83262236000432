export const COLOR_GREEN = "#5cd17d";
export const COLOR_YELLOW = "#ecdc00";
export const COLOR_BLUE = "#1890ff";
export const COLOR_RED = "#ff4d4f";
export const COLOR_RED_HOVER = "#ff6b6d";
export const COLOR_GRAY = "#d2d2d2";
export const COLOR_BLUE_LINK = "#40a9ff";
export const COLOR_BLUE_TAG = "blue";
export const COLOR_GREEN_TAG = "green";

export const COLOR_ORANGE_ICON = "#FFA500";
export const COLOR_PURPLE_ICON = "#4B0082";
export const COLOR_VIOLET_ICON = "#FF00FF";
export const COLOR_GREEN_ICON = "#3cb55e";
export const COLOR_BLUE_ICON = "#317abd";
export const COLOR_RED_ICON = "#448ac9";
export const COLOR_YELLOW_ICON = "#448ac9";

export const RADIO_STYLE = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

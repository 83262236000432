import React, { useState, useContext, useRef, useMemo } from "react";
import { Tag, Button, Tooltip, Collapse } from "antd";
import { Key } from "antd/lib/table/interface";
import { SView } from "../general/detailView/sView";
import { SElementInput } from "../general/detailView/Elements/sElementInput";
import { SSection } from "../general/detailView/sSection";

import {
  doFetch,
  doFetchPromise,
  generateCheckedKeys,
  getImgUrl,
  getModalError,
  getModalNotFound,
  getNotificationSuccess,
  getProp,
} from "../../lib/functions/general_functions";
import { generateMask, parseSchemaTypes } from "../../lib/functions/services_functions";
import { compile } from "json-mask";
import { useEffect } from "react";
import { DeployButton } from "./deployButton";
import { DIDBResource, DocumentType, nameToPath } from "../../lib/definitions/general_definitions";
import { useHistory, useParams } from "react-router-dom";
import { ProviderContractContext, RequesterContractContext } from "../../lib/contexts";
import { getMyEmail } from "../../lib/functions/profile_functions";
import { RequesterInfo } from "./contract/requesterInfo";
import { RequestDetailsInfo } from "./contract/requestDetailsInfo";
import { EndpointSection } from "../general/forms/endpointSection";
import { SSectionCustom } from "../general/detailView/sSectionCustom";
import { SJson } from "../general/detailView/sJson";
import { IntelligentInfoAPIKey, IntelligentInfoEbm } from "../../lib/definitions/services_definitions";
import { authProvider } from "../../lib/authProvider";
import { useForm } from "antd/lib/form/Form";
import { JsonAsTree } from "../general/jsonAsTree";
import { RuleSchemaEditor } from "../services/ruleSchemaEditor";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { COLOR_GREEN, COLOR_RED } from "../../lib/definitions/style_definitions";
import { useCallback } from "react";

export const Contract = () => {
  const [checkedKeys, setCheckedKeys] = useState<Key[]>();
  const [requesterName, setRequesterName] = useState("");
  const [url, setUrl] = useState("");
  const [EPDestination, setEPDestination] = useState<string[]>();
  const [EPType, setEPType] = useState("");
  const [issuer, setIssuer] = useState("");
  const [authType, setAuthType] = useState("");

  const [isWaitingForDeleteRespond, setIsWaitingForDeleteRespond] = useState(false);
  const [isWaitingForUpdateRespond, setIsWaitingForUpdateRespond] = useState(false);
  const [isFetchingContract, setIsFetchingContract] = useState(true);
  const [contractType, setContractType] = useState<DocumentType | "">("");
  const [selectedContract, setSelectedContract] = useState<any>({});

  const [ruleSchema, setRuleSchema] = useState<any>({});
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isMounted = useRef(true);
  const pathArr = history.location.pathname.split("/");
  const path = `${pathArr[0]}/${pathArr[1]}/${pathArr[2]}`;
  const [connectTemplate, setConnectTemplate] = useState(IntelligentInfoEbm);

  const [deployable, setDeployable] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [udt, setUdt] = useState(false);
  const [apiKey, setApikey] = useState("");
  const [oAuthApplication, setOAuthApplication] = useState("");
  const [envelope, setEnvelope] = useState(false);
  const [decoupled, setDecoupled] = useState(false);
  const [dlqMessages, setDlqMessages] = useState(0);
  const [isFetchingDlq, setIsFetchingDlq] = useState(false);

  const [queuesExists, setQueuesExists] = useState(true);
  const [isFetchingQueueStatus, setIsFetchingQueueStatus] = useState(false);
  const [errorOccuredFetchingStatus, setErrorOccuredFetchingStatus] = useState(false);
  const [isFixingQueues, setIsFixingQueues] = useState(false);

  const apiKeyConnectJson =
    "POST /events/ HTTP/1.1\n" +
    "Host: " +
    (selectedContract.env === "prod" ? "" : selectedContract.env + ".") +
    "api.lego.com\n" +
    "Content-Type: application/json\n" +
    "x-api-key: " +
    IntelligentInfoAPIKey +
    "\n";

  const openIdConnectJson =
    "POST /events/ HTTP/1.1\n" +
    "Host: " +
    (selectedContract.env === "prod" ? "" : selectedContract.env + ".") +
    "api.lego.com\n" +
    "Content-Type: application/json\n" +
    "Authorization: " +
    "Bearer <JWT>" +
    "\n";

  const [connectJson, setConnectJson] = useState(apiKeyConnectJson);

  const { requesterContracts, refreshConsumerContracts, isFethcingRequesterContracts } = useContext(
    RequesterContractContext
  );
  const { providerContracts, refreshProviderContracts, isFethcingProviderContracts } = useContext(
    ProviderContractContext
  );

  const [form] = useForm();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchQueueStatus = useCallback(() => {
    setIsFetchingQueueStatus(true);
    doFetch(
      "GET",
      DIDBResource.Queue + `/?subscriptionId=${id}`,
      isMounted,
      (res) => setQueuesExists(res.value),
      (err) => {
        getModalError("Fetching queue status")(err);
        setErrorOccuredFetchingStatus(true);
      },
      () => setIsFetchingQueueStatus(false)
    );
  }, [id]);

  useEffect(fetchQueueStatus, [fetchQueueStatus]);

  //useEffect to find and setup contract
  useEffect(() => {
    if (!id || isFethcingProviderContracts || isFethcingRequesterContracts) {
      return;
    }
    let contract: any;
    if (path === nameToPath["consumerContract"]) {
      setEditable(true);
      contract = requesterContracts.find((contract) => contract._id === id);
      if (["active", "owner"].includes(contract?.status)) {
        setDeployable(true);
      }
      if (contract?.eventType === "subscriber") {
        setIsFetchingDlq(true);
        doFetch(
          "GET",
          `${DIDBResource.DLQ}/${id}`,
          isMounted,
          (res) => setDlqMessages(JSON.parse(res.value.toString()).ApproximateNumberOfMessages),
          () => getModalError("couldn't fetch dlq"),
          () => setIsFetchingDlq(false)
        );
      }
    } else if (path === nameToPath["providerContract"]) {
      contract = providerContracts.find((contract) => contract._id === id);
    } else if (path.slice(0, -25) === nameToPath["Requests"]) {
      contract = providerContracts.find((contract) => contract._id === id);
      setIsRequest(true);
    } else if (path.slice(0, -25) === nameToPath["Approvals"]) {
      contract = requesterContracts.find((contract) => contract._id === id);
      setEditable(true);
    }
    if (contract) {
      setSelectedContract(contract);
    } else {
      getModalNotFound("Contract", id, () => {
        history.push(nameToPath["Home"]);
      });
      return;
    }
    if (contract.eventType) {
      if (contract.eventType === "subscriber") {
        const checked: Key[] = generateCheckedKeys([], compile(contract.mask), "");

        setCheckedKeys(checked);

        setEPDestination(contract?.endpoint?.destination);
        setEPType(contract?.endpoint?.type);
        setIssuer(contract?.endpoint?.issuer);
        setAuthType(contract?.endpoint?.authType);
        setContractType("SubscriberContract");
        setUdt(contract?.udt);
        setDecoupled(contract?.decoupled);
        setApikey(contract?.endpoint?.apiKey);
        setOAuthApplication(contract?.endpoint?.oAuthApplication);
        setEnvelope(contract?.envelope);

        form.setFieldsValue({
          type: contract?.endpoint?.type,
          destination: contract.endpoint?.destination,
          issuer: contract?.endpoint?.issuer,
          authType: contract?.endpoint?.authType,
          udt: contract?.udt,
          apiKey: contract?.endpoint?.apiKey,
          oAuthApplication: contract?.endpoint?.oAuthApplication,
          envelope: contract?.envelope,
        });
      } else if (contract.eventType === "publisher") {
        setContractType("PublisherContract");
        if (contract?.consumingApplication?.azureAdId !== "") {
          setConnectJson(openIdConnectJson);
        } else {
          setConnectJson(apiKeyConnectJson);
        }
      }
    }

    doFetchPromise("GET", `https://graph.microsoft.com/v1.0/users/${contract.requester_mail}/`)
      .then((res) => setRequesterName(res?.displayName))
      .catch(() => {});

    authProvider.getAccessToken().then((res: any) => {
      fetch(`https://graph.microsoft.com/v1.0/users/${contract.requester_mail}/photo/$value`, {
        headers: {
          Authorization: "" + res.accessToken,
        },
        method: "GET",
      })
        .then((res) => res.blob())
        .then((img) => {
          setUrl(img && URL.createObjectURL(img));
        });
    });
    if (contract.requester_mail) setUrl(getImgUrl(contract.requester_mail));
    setIsFetchingContract(false);
  }, [
    id,
    requesterContracts,
    path,
    providerContracts,
    isFethcingProviderContracts,
    isFethcingRequesterContracts,
    history,
    form,
    apiKeyConnectJson,
    openIdConnectJson,
  ]);

  useEffect(() => setRuleSchema(selectedContract.rule), [selectedContract]);

  function handleRequest(method: "PUT" | "DELETE") {
    let setIsWaitingForRespond: any;
    let body: any = {};
    method === "PUT"
      ? (setIsWaitingForRespond = setIsWaitingForUpdateRespond)
      : (setIsWaitingForRespond = setIsWaitingForDeleteRespond);

    setIsWaitingForRespond(true);
    if (path === nameToPath["consumerContract"] || path.slice(0, -25) === nameToPath["Approvals"]) {
      switch (selectedContract.serviceType) {
        case "event":
          if (selectedContract.eventType === "subscriber") {
            doFetch(
              method,
              `${DIDBResource.EventSubscribers}/${selectedContract._id}`,
              isMounted,
              () => {
                getNotificationSuccess(method === "PUT" ? "Updated" : "Deleted", "Contract");
                if (method === "DELETE") {
                  history.push(nameToPath["Home"]);
                }
                refreshProviderContracts();
                refreshConsumerContracts();
              },
              (err) => getModalError("Handling contract")(err, () => history.goBack()),
              () => setIsWaitingForRespond(false),
              {
                description: selectedContract.description,
                ...(path === nameToPath["consumerContract"] && { rule: ruleSchema }),
                mask: generateMask(checkedKeys as Key[]),
                requester_mail: getMyEmail(),
                endpoint: {
                  destination: EPDestination,
                  type: EPType,
                  issuer: issuer,
                  authType: authType,
                  apiKey: apiKey,
                  oAuthApplication: oAuthApplication,
                },
                decoupled: decoupled,
                udt: udt,
                envelope: envelope,
              }
            );
          } else if (selectedContract.eventType === "publisher") {
            doFetch(
              method,
              `${DIDBResource.EventPublishers}/${selectedContract._id}`,
              isMounted,
              () => {
                getNotificationSuccess(method === "PUT" ? "Updated" : "Deleted", "Contract");
                if (method === "DELETE") {
                  history.push(nameToPath["Home"]);
                }
                refreshConsumerContracts();
                refreshProviderContracts();
              },
              getModalError("Handeling contract"),
              () => setIsWaitingForRespond(false),
              {
                description: selectedContract.description,
                requester_mail: getMyEmail(),
              }
            );
          }
      }
    } else if (path === nameToPath["providerContract"]) {
      switch (selectedContract.serviceType) {
        case "event":
          if (selectedContract.eventType === "subscriber" && method === "PUT") {
            body["mask"] = generateMask(checkedKeys as Key[]);
            body["rule"] = ruleSchema;
          } else {
            body = undefined;
          }
          doFetch(
            method,
            `${DIDBResource.Contracts}/${selectedContract._id}?eventType=${selectedContract?.eventType}&serviceType=${selectedContract.serviceType}`,
            isMounted,
            () => {
              getNotificationSuccess(method === "PUT" ? "Updated" : "Deleted", "Contract");
              if (method === "DELETE") {
                history.push(nameToPath["Home"]);
              }
              refreshProviderContracts();
              refreshConsumerContracts();
            },
            getModalError("Handeling contract"),
            () => setIsWaitingForRespond(false),
            body
          );
      }
    } else if (path.slice(0, -25) === nameToPath["Requests"]) {
      switch (selectedContract.serviceType) {
        case "event":
          if (selectedContract.eventType === "subscriber" && method === "PUT") {
            body["mask"] = generateMask(checkedKeys as Key[]);
            body["rule"] = ruleSchema;
          } else if (selectedContract.eventType === "publisher" && method === "PUT") {
            body["type"] = "publisher";
          }
          doFetch(
            method,
            `${DIDBResource.MyRequests}/${selectedContract._id}?eventType=${selectedContract?.eventType}&serviceType=${selectedContract.serviceType}`,
            isMounted,
            () => {
              getNotificationSuccess(method === "PUT" ? "Approved" : "Denied", "Contract");
              if (method === "DELETE") {
                history.push(nameToPath["Home"]);
              } else if (method === "PUT") {
                history.push(nameToPath["Requests"]);
              }
              refreshProviderContracts();
              refreshConsumerContracts();
            },
            getModalError("Handling Request"),
            () => setIsWaitingForRespond(false),
            body
          );
      }
    }
  }

  useEffect(() => {
    if (selectedContract.ProvidingApp) {
      setConnectTemplate((prevState: any) => ({
        ...prevState,
        application:
          selectedContract?.service?.direction === "out"
            ? selectedContract?.ProvidingApp[0]?.name
            : selectedContract?.consumingApplication?.name,
        event: selectedContract?.service?.name,
        timeStamp: selectedContract?.timestamp,
        messageId: selectedContract?._id,
        data: selectedContract?.service?.example,
      }));
    }
  }, [selectedContract]);

  const typeJson = useMemo(() => parseSchemaTypes(selectedContract.rule), [selectedContract]);
  const typeJsonRequested = useMemo(() => parseSchemaTypes(selectedContract.requestedValues?.rule), [selectedContract]);

  const isSubscription = !!selectedContract.rule;

  return (
    <>
      {!isFetchingContract ? (
        <SView
          title={
            `Contract - ${isSubscription ? "Subscription" : "Publication"}` +
            (selectedContract.status === "pending" ? " (Pending)" : "")
          }
          object={selectedContract}
          onBack={() => history.goBack()}
          form={form}
          extra={
            deployable && (
              <DeployButton
                type={contractType as DocumentType}
                entityId={selectedContract._id}
                subscriberContract={selectedContract}
                callback={() => {
                  refreshConsumerContracts();
                  refreshProviderContracts();
                }}
              />
            )
          }
        >
          <RequesterInfo requesterName={requesterName} selectedContract={selectedContract} url={url} />

          <SSection title="Service">
            <SElementInput label="Name" value={selectedContract?.service.name} />
            <SElementInput label="Application" value={selectedContract?.ProvidingApp[0].name} />
            <SElementInput
              label="Type"
              value={selectedContract?.serviceType}
              render={(val) => <Tag color="cyan">{val.toLocaleUpperCase()}</Tag>}
            />
          </SSection>

          <SSection title="Queue status">
            <div>
              {isFetchingQueueStatus ? (
                <>
                  <LoadingOutlined style={{ marginRight: "16px", color: "#1890ff" }} />
                  Fetching status...
                </>
              ) : errorOccuredFetchingStatus ? (
                <>
                  <CloseOutlined style={{ color: COLOR_RED, marginRight: "8px" }} /> Could not fetch queue status...
                </>
              ) : queuesExists ? (
                <>
                  <CheckOutlined style={{ color: COLOR_GREEN, marginRight: "8px" }} /> Queues are up and running
                </>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseOutlined style={{ color: COLOR_RED, marginRight: "8px" }} />
                  <div>Queues are not correctly set up...</div>
                  <Button
                    type="primary"
                    style={{ marginLeft: "8px" }}
                    loading={isFixingQueues}
                    onClick={() => {
                      setIsFixingQueues(true);
                      doFetch(
                        "POST",
                        DIDBResource.Queue + `?subscriptionId=${id}`,
                        isMounted,
                        fetchQueueStatus,
                        getModalError("Fixing queues"),
                        () => setIsFixingQueues(false)
                      );
                    }}
                  >
                    Fix Queues
                  </Button>
                </div>
              )}
            </div>
          </SSection>

          {selectedContract?.eventType === "subscriber" ? (
            <>
              <RequestDetailsInfo
                selectedContract={selectedContract}
                dlqMessages={dlqMessages}
                setDlqMessages={setDlqMessages}
                isFetchingDlq={isFetchingDlq}
                setIsFetchingDlq={setIsFetchingDlq}
                deployable={deployable}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
                editable={editable}
                isMounted={isMounted}
                id={id}
                type={selectedContract.serviceType}
                typeJson={typeJson}
                ruleSchema={ruleSchema}
                setRuleSchema={setRuleSchema}
                dlqIsLive={queuesExists}
              />
              {selectedContract.status === "pending" && (
                <SSectionCustom title="Pending mask and rules">
                  <Collapse style={{ marginTop: "24px", marginBottom: "8px" }}>
                    <Collapse.Panel header="Data Object" key="1">
                      <JsonAsTree
                        json={parseSchemaTypes(getProp(selectedContract?.service, "schema"))}
                        checkedKeys={generateCheckedKeys([], compile(selectedContract?.requestedValues?.mask), "")}
                      />
                    </Collapse.Panel>
                    <Collapse.Panel header="Routing rule" key="2">
                      <RuleSchemaEditor
                        maskedTypeJson={typeJsonRequested}
                        ruleSchema={selectedContract.requestedValues?.rule}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </SSectionCustom>
              )}
            </>
          ) : (
            <SSectionCustom title="Connect">
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <p style={{ margin: 0 }}>To post data into EBM through API, use the following guide: </p>
                <Tooltip
                  title={
                    <div>
                      <p>https://confluence.corp.lego.com/pages/viewpage.action?pageId=67895606</p>
                    </div>
                  }
                >
                  <Button type="link">
                    <a
                      rel="noopener noreferrer"
                      href="https://confluence.corp.lego.com/pages/viewpage.action?pageId=67895606"
                      target="_blank"
                    >
                      Guide
                    </a>
                  </Button>
                </Tooltip>
              </div>
              <Collapse ghost style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                <Collapse.Panel header={"Request Template"} key={1}>
                  <SJson json={connectTemplate} extraCode={connectJson} />
                </Collapse.Panel>
              </Collapse>
            </SSectionCustom>
          )}

          {selectedContract?.eventType === "subscriber" && (
            <EndpointSection
              selectedEndpoint={EPType}
              setSelectedEndpoint={setEPType}
              destination={EPDestination}
              setDestination={setEPDestination}
              issuer={issuer}
              setIssuer={setIssuer}
              authType={authType}
              setAuthType={setAuthType}
              udt={udt}
              setUdt={setUdt}
              apiKey={apiKey}
              setApiKey={setApikey}
              oAuthApplication={oAuthApplication}
              setOAuthApplication={setOAuthApplication}
              decoupled={decoupled}
              setDecoupled={setDecoupled}
              envelope={envelope}
              setEnvelope={setEnvelope}
              disabled={!editable}
            />
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={() => handleRequest("PUT")}
              style={{ marginRight: "20px" }}
              loading={isWaitingForUpdateRespond}
            >
              {isRequest ? "Approve" : "Update"}
            </Button>
            <Button type="primary" onClick={() => handleRequest("DELETE")} loading={isWaitingForDeleteRespond} danger>
              {isRequest ? "Deny" : "Delete"}
            </Button>
          </div>
        </SView>
      ) : (
        <></>
      )}
    </>
  );
};

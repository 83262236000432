import React from "react";

interface ShowInfoJsonProps {
  object: any;
}

export const ShowInfoJson: React.FC<ShowInfoJsonProps> = (props) => {
  return (
    <div>
      <pre>{JSON.stringify(props.object, null, 4)}</pre>
    </div>
  );
};

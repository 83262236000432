import React, { useState, useEffect } from "react";
import { Form } from "antd";
import { getLineWidthSpan } from "../../../../lib/functions/sDetail_functions";

interface SElementProps {
  children: React.ReactNode;
  initialValue?: any;
  field?: string;
  label?: string;
  lineWidth?: "default" | "double" | "triple" | "wholeline";
}

// SDetailElement is used instead of Form.Item. Only fields which are compatible with Form.Item
// can be used (such as Inputs, Buttons, etc.).
// Tables can be put directly inside SDetailSection
export const SElementCustom = (props: SElementProps) => {
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  if (props.label) {
    const lineWidth = props.lineWidth ? props.lineWidth : "default";
    return (
      <span className="sdetail-element__container" style={getLineWidthSpan(lineWidth)}>
        <h4 className="sdetail-element__header">{props.label + ": "}</h4>
        <Form.Item name={props.field} initialValue={value} noStyle>
          {props.children}
        </Form.Item>
      </span>
    );
  }

  return (
    <Form.Item name={props.field} initialValue={value} noStyle>
      {props.children}
    </Form.Item>
  );
};

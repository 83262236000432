import React, { useMemo } from "react";
import { Card, Typography, Tooltip } from "antd";
import { nameToPath } from "../../lib/definitions/general_definitions";
import { ServiceTypeTag } from "./serviceTypeTag";
import { useSHistory } from "../../lib/functions/general_functions";

interface ServiceCardProps {
  service: any;
}

export const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  const history = useSHistory();

  const text = useMemo(
    () => (
      <Typography.Paragraph ellipsis={{ rows: 3 }} className="services__grid-card-p">
        <b>{props.service.serviceType ? props.service.serviceType : "No type"}</b>
        {": " + (props.service.description ? props.service.description : "No description")}
      </Typography.Paragraph>
    ),
    [props.service]
  );

  return (
    <Card
      title={
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "12px 0",
            }}
            title={props.service.name}
          >
            {props.service.name}
          </div>
          <ServiceTypeTag type={props.service.serviceType} />
        </>
      }
      hoverable
      className="services__grid--card"
      onClick={() =>
        history.push(`${nameToPath["Services"]}/${props.service.serviceType?.toLowerCase()}/${props.service._id}`)
      }
      data-cy="serviceCard"
    >
      <div>
        <p className="services__grid--card-sub-title" title={props.service.applicationName}>
          {props.service.applicationName || "No application name"}
        </p>
        <Tooltip title={props.service.serviceType + ": " + props.service.description} placement="bottom">
          {text}
        </Tooltip>
      </div>
    </Card>
  );
};

import React from "react";
import { Form, Radio } from "antd";
import { SLabel } from "./sLabel";

export interface SEnumOption {
  label: string;
  value: string | number | boolean;
}

interface SFormEnumProps {
  label: string;
  options: SEnumOption[];
  field?: string;
  optional?: boolean;
  disabled?: boolean;
  onChange?(value: string): void;
}

export const SFormEnum = (props: SFormEnumProps) => {
  return (
    <>
      <SLabel label={props.label} required={!props.optional} />
      <Form.Item name={props.field} rules={[{ required: !props.optional }]} hasFeedback className="sform__item">
        <Radio.Group
          onChange={(e) => {
            if (props.onChange) {
              props.onChange(e.target.value);
            }
          }}
          disabled={props.disabled}
        >
          {props.options.map((option) => (
            <Radio.Button key={option.value + ""} value={option.value}>
              {option.label}
            </Radio.Button>
          ))}
          {props.optional && <Radio value={undefined}>None</Radio>}
        </Radio.Group>
      </Form.Item>
      <div />
    </>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import { Table, Tooltip, Input, Button, Space } from "antd";
import { ColumnType } from "antd/lib/table";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { SearchOutlined, SyncOutlined, PlusOutlined } from "@ant-design/icons";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface SuperTableProps {
  rows: any[];
  columns: SuperTableColumn[];
  rowKey: string;
  actions?: SuperTableAction[];
  selectedRow?: any;
  size?: SizeType;
  noSearch?: boolean;
  noPagination?: boolean;
  defaultPageSize?: number;
  excludedFromSearch?: string[];
  refreshProps?: {
    isRefreshing: boolean;
    onRefresh?(): void;
  };
  className?: string;
  onRowClick?(clickedRow: any): void;
  onCreate?(): void;
}

export interface SuperTableColumn extends Omit<ColumnType<any>, "key" | "ellipsis"> {
  title: string;
  dataIndex: string;
  width?: number;
  collapse?: boolean;
  cellTitle?(value: any, row?: any, index?: number): string;
  sorter?(a: any, b: any): number;
}

export interface SuperTableAction {
  icon?: React.ReactNode;
  buttonText?: string;
  description: string;
  onClick(row: any): void;
  primary?: boolean;
  danger?: boolean;
  loading?(row: any): boolean;
}

export const SuperTable: React.FC<SuperTableProps> = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchedRows, setSearchedRows] = useState<any[]>([]);

  useEffect(() => {
    setSearchedRows(searchValue ? searchRows(props.rows, searchValue, props.excludedFromSearch || []) : props.rows);
  }, [props.rows, searchValue, props.excludedFromSearch]);

  const columns = useMemo(
    () =>
      props.columns.map((column) => ({
        ...column,
        sorter: column.sorter ? column.sorter : getStringSorter(column.dataIndex),
      })),
    [props.columns]
  );

  const showMenuBar = !props.noSearch;

  return (
    <div style={{ overflowX: "auto" }}>
      {showMenuBar && (
        <div className="super-table__menubar">
          <div className="super-table__menubar--left">
            {!props.noSearch && (
              <div style={{ width: 250 }}>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setSearchValue("");
                    }
                  }}
                />
              </div>
            )}
            {props.refreshProps?.onRefresh && (
              <SyncOutlined
                spin={props.refreshProps.isRefreshing}
                onClick={props.refreshProps.onRefresh}
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  marginLeft: "8px",
                }}
              />
            )}
          </div>
          {props.onCreate && <Button onClick={props.onCreate} icon={<PlusOutlined />} />}
        </div>
      )}
      <Table
        dataSource={searchedRows}
        size={props.size ? props.size : "small"}
        rowKey={props.rowKey}
        rowSelection={
          props.onRowClick
            ? {
                type: "radio",
                selectedRowKeys: props.selectedRow ? [props.selectedRow[props.rowKey]] : [],
              }
            : undefined
        }
        onRow={(row) => ({
          onClick: () => {
            if (props.onRowClick) {
              props.onRowClick(row);
            }
          },
        })}
        loading={props.refreshProps?.isRefreshing}
        pagination={props.noPagination ? false : { defaultPageSize: props.defaultPageSize || 10 }}
        className={props.className + " super-table" + (props.onRowClick ? " super-table__selectable" : "")}
      >
        <>
          {columns.map((column) => (
            <Table.Column
              key={JSON.stringify(column.dataIndex)}
              {...column}
              ellipsis={false}
              dataIndex={column.dataIndex.split(".")}
              render={(value, row, index) => ({
                children: (
                  <Tooltip
                    title={column.cellTitle ? column.cellTitle(value, row, index) : safeToString(value)}
                    placement="bottom"
                    mouseEnterDelay={0.5}
                  >
                    {column.width || column.collapse ? (
                      column.render ? (
                        column.render(value, row, index)
                      ) : (
                        safeToString(value)
                      )
                    ) : (
                      <div className="super-table__cell-ellipsis">
                        {column.render ? column.render(value, row, index) : safeToString(value)}
                      </div>
                    )}
                  </Tooltip>
                ),
                props: {
                  style: {
                    maxWidth: column.width || column.collapse ? undefined : "1px",
                  },
                },
              })}
              className={column.collapse ? "super-table__cell-collapse" : ""}
            />
          ))}
          {props.actions && (
            <Table.Column
              key="__actions"
              title="Actions"
              render={(_text, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Space size="small">
                    {props.actions!.map((action) => (
                      <Button
                        key={action.onClick.toString()}
                        size="small"
                        type={action.primary ? "primary" : "default"}
                        icon={action.icon}
                        danger={action.danger}
                        loading={action.loading ? action.loading(row) : false}
                        onClick={(e) => {
                          e.stopPropagation();
                          action.onClick(row);
                        }}
                        title={action.description}
                      >
                        {action.buttonText}
                      </Button>
                    ))}
                  </Space>
                </div>
              )}
              className="super-table__cell-collapse"
            />
          )}
        </>
      </Table>
    </div>
  );
};

function getStringSorter(dataIndex: string) {
  return (a: any, b: any) => stGetPropString(a, dataIndex).localeCompare(stGetPropString(b, dataIndex), "en");
}

function searchRows(rows: any[], searchTerm: string, excluded: string[]): any[] {
  const testField = (field: string, path: string): boolean => {
    if (searchTerm === "") {
      return true;
    }
    if (excluded.includes(path)) {
      return false;
    }

    if (field) {
      return field.toString().toUpperCase().includes(searchTerm.toUpperCase());
    }
    return false;
  };

  let result = [...rows];

  result = result.filter((row) => testAllFields(row, searchTerm, testField, ""));

  return result;
}

function testAllFields(
  obj: any,
  searchTerm: string,
  predicate: (field: string, path: string) => boolean,
  currentPath: string
): boolean {
  let positiveHit = false;
  if (!obj) {
    return false;
  }
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object") {
      if (testAllFields(obj[key], searchTerm, predicate, currentPath + key + ".")) {
        positiveHit = true;
      }
    } else if (predicate(obj[key] + "", currentPath + key)) {
      positiveHit = true;
    }
  });

  return positiveHit;
}

function stGetProp(object: any, property: string) {
  if (!object) {
    return undefined;
  }

  const properties = property.split(".");
  let currentObject = object;
  let index = 0;
  let currentProperty = properties[index];

  while (currentObject.hasOwnProperty(currentProperty)) {
    if (index === properties.length - 1) {
      return currentObject[currentProperty];
    } else {
      currentObject = currentObject[currentProperty];
      if (currentObject === undefined) {
        return undefined;
      }
      index++;
      currentProperty = properties[index];
    }
    if (!currentObject) {
      return undefined;
    }
  }

  return undefined;
}

function stGetPropString(object: any, property: string): string {
  const value = stGetProp(object, property);

  if (typeof value === "undefined" || value === null) {
    return "";
  }

  if (typeof value === "object") {
    return JSON.stringify(value);
  }

  if (value && value.toString) {
    return value.toString();
  }

  return value + "";
}

function safeToString(value: any) {
  if (typeof value === "object" && value !== null) {
    return JSON.stringify(value);
  }

  return value;
}
